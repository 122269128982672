import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Decimal from 'decimal.js';
import { createInstance, types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import config from '../../config';

export const USER_REVIEWS_QUERY_KEY = 'userReviews';

const { BigDecimal } = sdkTypes;
const baseUrl = config.sdk.baseUrl ? { baseUrl: config.sdk.baseUrl } : {};

let sdk;
if (config.sdk.clientId) {
  sdk = createInstance({
    transitVerbose: config.sdk.transitVerbose,
    clientId: config.sdk.clientId,
    secure: config.usingSSL,
    typeHandlers: [
      {
        type: BigDecimal,
        customType: Decimal,
        writer: v => new BigDecimal(v.toString()),
        reader: v => new Decimal(v.value),
      },
    ],
    ...baseUrl,
  });
}

const fetchUserReviewsFromApi = async ({ userId }) => {
  const response = await axios.post(
    config.graphQLapi,
    {
      query: `
        query FetchUserReviews($userId: ID!) {
          UserReviews(input: { userId: $userId }) {
            items {
              customer {
                authorId
                authorRole
                createdAt
                id
                listingId
                packageId
                rating
                reviewContent
                reviewQuestions
                transactionId
                status
                photos
              }
              provider {
                authorId
                authorRole
                createdAt
                id
                listingId
                packageId
                rating
                reviewContent
                reviewQuestions
                transactionId
                status
                photos
              }
            }
            meta {
              ratingAvg
              totalReviews
            }
          }
        }`,
      variables: {
        userId,
      },
    },
    {
      headers: {
        'X-Api-Key': `${process.env.REACT_APP_ACTIVITY_GRAPHQL_API_KEY}`,
      },
    }
  );

  const reviews = response.data?.data?.UserReviews?.items || [];
  const reviewsMeta = response.data?.data?.UserReviews?.meta || {
    ratingAvg: 0,
  };

  // Unique author ids
  const reviewsAuthorsIdsSet = new Set();

  reviews.forEach(item => {
    if (item.customer) {
      reviewsAuthorsIdsSet.add(item.customer.authorId);
    }
    if (item.provider) {
      reviewsAuthorsIdsSet.add(item.provider.authorId);
    }
  });

  const reviewsAuthorsIds = Array.from(reviewsAuthorsIdsSet);

  // Fetch user data for each author ID
  const authorDataPromises = reviewsAuthorsIds.map(authorId =>
    sdk.users.show({
      id: authorId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
  );
  const authorsData = (await Promise.all(authorDataPromises)).map(authorResponse => {
    const entities = denormalisedResponseEntities(authorResponse);
    const user = entities[0];

    return user;
  });

  // Create a map of author data for quick lookup
  const authorsMap = new Map();
  authorsData.forEach(author => {
    authorsMap.set(author.id.uuid, author);
  });

  const updatedReviews = reviews.map(review => ({
    ...review,
    customer:
      review.customer && authorsMap.has(review.customer.authorId)
        ? { ...review.customer, author: authorsMap.get(review.customer.authorId) }
        : review.customer,
    provider:
      review.provider && authorsMap.has(review.provider.authorId)
        ? { ...review.provider, author: authorsMap.get(review.provider.authorId) }
        : review.provider,
  }));

  reviewsMeta.ratingAvg = parseFloat(reviewsMeta.ratingAvg).toFixed(1);

  return {
    reviews: updatedReviews,
    reviewsRatingAvg: reviewsMeta.ratingAvg,
  };
};

export const useUserReviews = ({ userId }) => {
  return useQuery({
    queryKey: [USER_REVIEWS_QUERY_KEY, userId],
    queryFn: () =>
      fetchUserReviewsFromApi({
        userId,
      }),
    enabled: !!userId,
    staleTime: 1000 * 60 * 30, // Consider data stale after 30 minutes
    cacheTime: 1000 * 60 * 60, // Keep data in cache for 1 hour
  });
};
