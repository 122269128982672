import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fetchMoreMessages, updatePartyMemberDetails } from '../../ducks/Transaction.duck';
import { ensureTransaction } from '../../util/data';

import InboxFeed from './InboxFeed/InboxFeed';

import css from './InboxMessages.css';

export const InboxMessages = ({
  transaction,
  currentUser,
  onShowMoreMessages,
  fetchMessagesInProgress,
  fetchMessagesError,
  totalMessagePages,
  oldestMessagePageFetched,
  messages,
  initialMessageFailedToTransaction,
  isFetching,
  transactionState,
  onUpdatePartyMemberDetails,
  isCustomer,
}) => {
  if (!transaction) {
    return null;
  }

  const currentTransaction = ensureTransaction(transaction);

  const initialMessageFailed = !!(
    initialMessageFailedToTransaction &&
    currentTransaction.id &&
    initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  );

  const contentClassName = classNames(css.content, {
    [css.contentLoading]: isFetching,
  });

  return (
    <div className={css.root}>
      <div className={contentClassName}>
        {isFetching ? (
          <div>Loading...</div>
        ) : (
          <>
            <InboxFeed
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onShowMoreMessages={() => onShowMoreMessages(transaction.id)}
              totalMessagePages={totalMessagePages}
              transactionState={transactionState}
              onUpdatePartyMemberDetails={onUpdatePartyMemberDetails}
              isCustomer={isCustomer}
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    initialMessageFailedToTransaction,
    sendMessageInProgress,
    sendMessageError,
  } = state.Transaction;

  return {
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    initialMessageFailedToTransaction,
    sendMessageInProgress,
    sendMessageError,
  };
};

const mapDispatchToProps = dispatch => ({
  onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
  onUpdatePartyMemberDetails: (values, transaction) =>
    dispatch(updatePartyMemberDetails(values, transaction)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(InboxMessages));
