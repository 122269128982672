/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import Decimal from 'decimal.js';
import moment from 'moment-timezone';
import { get } from 'lodash';
import {
  shouldExcludeFromSubtotal,
  LINE_ITEMS,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_LODGING_TAX,
  LINE_ITEM_LODGING_FEE,
  LINE_ITEM_CONSERVATION_DONATION,
  LINE_ITEM_PROMO_CODE,
  LINE_ITEM_INSURANCE,
  LINE_ITEM_UNITS,
  LINE_ITEM_PARTY_SIZE,
  LINE_ITEM_NEGOTIATION,
  LINE_ITEM_ACCESS_FEE,
  LINE_ITEM_FISHING_FEE,
  INSURANCE_PERCENTAGE,
  CUSTOMER_COMMISSION_PERCENTAGE,
} from '../shared/transaction';
import { ensureTransaction } from './data';
import { convertMoneyToNumber, convertUnitToSubUnit, unitDivisor } from './currency';
// eslint-disable-next-line import/no-cycle

import { types as sdkTypes } from './sdkLoader';
import { dateFromAPIToLocalNoon, dateFromLocalToAPI, daysAdded, daysBetween } from './dates';
import config from '../config';
import { speciesFoundations } from '../marketplace-custom-config';
import { getListingImages, getListingTimezone, getPackageImages } from './listing';
import { LODGING_TYPE } from './package';

const { Money, UUID } = sdkTypes;

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */
// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// depracated transition, we keep it in the UI to display the info in inbox/transaction panel
export const TRANSITION_ENQUIRE = 'transition/enquire';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE_GATED = 'transition/enquire-gated';
export const TRANSITION_ENQUIRE_EXPIRE = 'transition/enquire-expire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_EXPIRED =
  'transition/request-payment-after-enquiry-expired';
export const TRANSITION_REQUEST_PAYMENT_CONSERVATION_PROGRAM =
  'transition/request-payment-conservation-program';

// A provider can initiate a tranaction with a special offer
export const TRANSITION_PROVIDER_SPECIAL_OFFER = 'transition/provider-special-offer';
export const TRANSITION_PROVIDER_SPECIAL_OFFER_AFTER_EXPIRED_ENQUIRY =
  'transition/provider-special-offer-after-expired-enquiry';

// A Customer Can accept or decline the offer
export const TRANSITION_CUSTOMER_ACCEPT_OFFER = 'transition/customer-accept-offer';
export const TRANSITION_CUSTOMER_DECLINE_OFFER = 'transition/customer-decline-offer';

// A provider can cancel the offer, or it can expire
export const TRANSITION_OFFER_EXPIRE = 'transition/offer-expire';
export const TRANSITION_PROVIDER_CANCEL_OFFER = 'transition/provider-cancel-offer';
export const TRANSITION_SPECIAL_CUSTOMER_PAYMENT = 'transition/special-customer-payment';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';

// When a booking is preauthorized, accepted, or customer-cancellable-non-refundable,
// the sportman can provide names and phone numbers of party members for a discount
export const TRANSITION_PARTY_MEMBERS_SUBMITTED_1 = 'transition/party-members-submitted-1';
export const TRANSITION_PARTY_MEMBERS_SUBMITTED_2 = 'transition/party-members-submitted-2';
export const TRANSITION_PARTY_MEMBERS_SUBMITTED_3 = 'transition/party-members-submitted-3';

// Automatic Cancellation Transitions
// After the accepted state, the backend will automatically be transitioned
// into one of two states depending if the booking date is within 48 hours or after 48 hours
export const TRANSITION_LATE_BOOKING = 'transition/late-booking';

// transition that triggers to let landowners know the booking request will expire in 24 hours
export const TRANSITION_REQUEST_EXPIRES_SOON = 'transition/request-expires-soon';

// refund period/48 hours til booking/booking start date
export const TRANSITION_REFUND_PERIOD_OVER = 'transition/refund-period-over';
export const TRANSITION_BOOKING_START_GETTING_CLOSER = 'transition/booking-start-getting-closer';
export const TRANSITION_BOOKING_START = 'transition/booking-start';

// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE = 'transition/expire';

// Customer cancel transitions.
export const TRANSITION_CUSTOMER_CANCEL_WITH_REFUND = 'transition/customer-cancel-with-refund';
export const TRANSITION_CUSTOMER_CANCEL_WITHOUT_REFUND =
  'transition/customer-cancel-without-refund';
export const TRANSITION_CUSTOMER_LATE_CANCEL = 'transition/customer-late-cancel';

// Provider cancel transitions.
export const TRANSITION_PROVIDER_EARLY_CANCEL = 'transition/provider-early-cancel';
export const TRANSITION_PROVIDER_CANCEL = 'transition/provider-cancel';
export const TRANSITION_PROVIDER_LATE_CANCEL = 'transition/provider-late-cancel';

// Admin/operator can also cancel the transition.
export const TRANSITION_CANCEL = 'transition/cancel';
export const TRANSITION_OPERATOR_EARLY_CANCEL = 'transition/operator-early-cancel';
export const TRANSITION_OPERATOR_CANCEL = 'transition/operator-cancel';
export const TRANSITION_OPERATOR_LATE_CANCEL = 'transition/operator-late-cancel';
export const TRANSITION_OPERATOR_CLOSE_ENQUIRY = 'transition/operator-close-enquiry';
export const TRANSITION_OPERATOR_CANCEL_ENQUIRY = 'transition/operator-cancel-enquiry';

// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';
export const TRANSITION_COMPLETE_AFTER_CANCELLATION_PERIODS =
  'transition/complete-after-cancellation-periods';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
// If Provider cancels within 48 hours of booking start, the customer will have the ability to review the transaction
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';
export const TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL =
  'transition/review-by-customer-late-cancel';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD_LATE_CANCEL =
  'transition/expire-customer-review-period-late-cancel';

// Booking changes
export const TRANSITION_REQUESTED_CHANGES = 'transition/customer-requested-changes';
export const TRANSITION_ACCEPTED_CHANGES = 'transition/provider-accepted-changes';
export const TRANSITION_DECLINED_CHANGES = 'transition/provider-declined-changes';
export const TRANSITION_OPERATOR_CANCEL_AFTER_CHANGES = 'transition/operator-cancel-after-changes';
export const TRANSITION_CHANGES_REQUEST_EXPIRE = 'transition/request-changes-expire';
export const TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD =
  'transition/customer-requested-changes-after-refund-period';
export const TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD =
  'transition/provider-accepted-changes-after-refund-period';
export const TRANSITION_DECLINED_CHANGES_AFTER_REFUND_PERIOD =
  'transition/provider-declined-changes-after-refund-period';
export const TRANSITION_CHANGES_REQUEST_EXPIRE_AFTER_REFUND =
  'transition/request-changes-expire-after-refund-period';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_ENQUIRY_EXPIRED = 'enquiry-expired';
const STATE_SPECIAL_OFFER = 'special-offer';
const STATE_SPECIAL_PENDING_PAYMENT = 'special-pending-payment';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_DECLINED = 'declined';
const STATE_ACCEPTED = 'accepted';
const STATE_CANCELLED = 'cancelled';
const STATE_DELIVERED = 'delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
const STATE_CUSTOMER_CANCELLABLE_NON_REFUNDABLE = 'customer-cancellable-non-refundable';
const STATE_BOOKING_STARTS_SOON = 'booking-starts-soon';
const STATE_BOOKING_STARTED = 'booking-started';
const STATE_PROVIDER_CANCELLED_LATE = 'provider-cancelled-late';
const STATE_CHANGES_REQUESTED = 'changes-requested';
const STATE_CHANGES_REQUESTED_AFTER_REFUND_PERIOD = 'changes-requested-after-refund-period';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'preauth-with-custom-pricing/release-2',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States

  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_ENQUIRE_GATED]: STATE_ENQUIRY,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_PAYMENT_CONSERVATION_PROGRAM]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT,
        [TRANSITION_PROVIDER_SPECIAL_OFFER]: STATE_SPECIAL_OFFER,
        [TRANSITION_OPERATOR_CANCEL_ENQUIRY]: STATE_CANCELLED,
        [TRANSITION_ENQUIRE_EXPIRE]: STATE_ENQUIRY_EXPIRED,
      },
    },
    [STATE_ENQUIRY_EXPIRED]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_EXPIRED]: STATE_PENDING_PAYMENT,
        [TRANSITION_PROVIDER_SPECIAL_OFFER_AFTER_EXPIRED_ENQUIRY]: STATE_SPECIAL_OFFER,
        [TRANSITION_OPERATOR_CLOSE_ENQUIRY]: STATE_CANCELLED,
      },
    },
    [STATE_SPECIAL_OFFER]: {
      on: {
        [TRANSITION_CUSTOMER_ACCEPT_OFFER]: STATE_SPECIAL_PENDING_PAYMENT,
        [TRANSITION_OFFER_EXPIRE]: STATE_DECLINED,
        [TRANSITION_PROVIDER_CANCEL_OFFER]: STATE_DECLINED,
        [TRANSITION_CUSTOMER_DECLINE_OFFER]: STATE_DECLINED,
      },
    },
    [STATE_SPECIAL_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_SPECIAL_CUSTOMER_PAYMENT]: STATE_ACCEPTED,
      },
    },

    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_DECLINE]: STATE_DECLINED,
        [TRANSITION_EXPIRE]: STATE_DECLINED,
        [TRANSITION_ACCEPT]: STATE_ACCEPTED,
        [TRANSITION_PARTY_MEMBERS_SUBMITTED_1]: STATE_PREAUTHORIZED,
        [TRANSITION_REQUEST_EXPIRES_SOON]: STATE_PREAUTHORIZED,
      },
    },
    [STATE_DECLINED]: {},
    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_LATE_BOOKING]: STATE_BOOKING_STARTS_SOON,
        [TRANSITION_REFUND_PERIOD_OVER]: STATE_CUSTOMER_CANCELLABLE_NON_REFUNDABLE,
        [TRANSITION_CUSTOMER_CANCEL_WITH_REFUND]: STATE_CANCELLED,
        [TRANSITION_PROVIDER_EARLY_CANCEL]: STATE_CANCELLED,
        [TRANSITION_OPERATOR_EARLY_CANCEL]: STATE_CANCELLED,
        [TRANSITION_PARTY_MEMBERS_SUBMITTED_2]: STATE_ACCEPTED,
        [TRANSITION_REQUESTED_CHANGES]: STATE_CHANGES_REQUESTED,
      },
    },
    [STATE_CHANGES_REQUESTED]: {
      on: {
        [TRANSITION_ACCEPTED_CHANGES]: STATE_ACCEPTED,
        [TRANSITION_DECLINED_CHANGES]: STATE_ACCEPTED,
        [TRANSITION_OPERATOR_CANCEL_AFTER_CHANGES]: STATE_CANCELLED,
        [TRANSITION_CHANGES_REQUEST_EXPIRE]: STATE_ACCEPTED,
      },
    },
    [STATE_CUSTOMER_CANCELLABLE_NON_REFUNDABLE]: {
      on: {
        [TRANSITION_CUSTOMER_CANCEL_WITHOUT_REFUND]: STATE_CANCELLED,
        [TRANSITION_PROVIDER_CANCEL]: STATE_CANCELLED,
        [TRANSITION_BOOKING_START_GETTING_CLOSER]: STATE_BOOKING_STARTS_SOON,
        [TRANSITION_OPERATOR_CANCEL]: STATE_CANCELLED,
        [TRANSITION_PARTY_MEMBERS_SUBMITTED_3]: STATE_CUSTOMER_CANCELLABLE_NON_REFUNDABLE,
        [TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD]: STATE_CHANGES_REQUESTED_AFTER_REFUND_PERIOD,
      },
    },
    [STATE_CHANGES_REQUESTED_AFTER_REFUND_PERIOD]: {
      on: {
        [TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD]: STATE_CUSTOMER_CANCELLABLE_NON_REFUNDABLE,
        [TRANSITION_DECLINED_CHANGES_AFTER_REFUND_PERIOD]: STATE_CUSTOMER_CANCELLABLE_NON_REFUNDABLE,
        [TRANSITION_CHANGES_REQUEST_EXPIRE_AFTER_REFUND]: STATE_CUSTOMER_CANCELLABLE_NON_REFUNDABLE,
      },
    },
    [STATE_BOOKING_STARTS_SOON]: {
      on: {
        [TRANSITION_PROVIDER_LATE_CANCEL]: STATE_PROVIDER_CANCELLED_LATE,
        [TRANSITION_CUSTOMER_LATE_CANCEL]: STATE_CANCELLED,
        [TRANSITION_BOOKING_START]: STATE_BOOKING_STARTED,
        [TRANSITION_OPERATOR_LATE_CANCEL]: STATE_CANCELLED,
      },
    },
    [STATE_PROVIDER_CANCELLED_LATE]: {
      on: {
        [TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD_LATE_CANCEL]: STATE_REVIEWED,
      },
    },
    [STATE_BOOKING_STARTED]: {
      on: {
        [TRANSITION_COMPLETE]: STATE_DELIVERED,
      },
    },
    [STATE_CANCELLED]: {},
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsEnquiryExpired = tx =>
  getTransitionsToState(STATE_ENQUIRY_EXPIRED).includes(txLastTransition(tx));

export const txIsSpecialOffer = tx =>
  getTransitionsToState(STATE_SPECIAL_OFFER).includes(txLastTransition(tx));

export const txIsSpecialPendingPayment = tx =>
  getTransitionsToState(STATE_SPECIAL_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));

export const txIsDeclined = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txAreChangesRequested = tx =>
  getTransitionsToState(STATE_CHANGES_REQUESTED).includes(txLastTransition(tx));

export const txAreChangesAccepted = tx => txLastTransition(tx) === TRANSITION_ACCEPTED_CHANGES;

export const txAreChangesDeclined = tx => txLastTransition(tx) === TRANSITION_DECLINED_CHANGES;

export const txAreChangesAcceptedAfterRefundPeriod = tx =>
  txLastTransition(tx) === TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD;

export const txAreChangesDeclinedAfterRefundPeriod = tx =>
  txLastTransition(tx) === TRANSITION_DECLINED_CHANGES_AFTER_REFUND_PERIOD;

export const txAreChangesRequestedAfterRefundPeriod = tx =>
  getTransitionsToState(STATE_CHANGES_REQUESTED_AFTER_REFUND_PERIOD).includes(txLastTransition(tx));

export const txHasChangesRequestExpired = tx =>
  txLastTransition(tx) === TRANSITION_CHANGES_REQUEST_EXPIRE;

export const txHasChangesRequestExpiredAfterRefund = tx =>
  txLastTransition(tx) === TRANSITION_CHANGES_REQUEST_EXPIRE_AFTER_REFUND;
// Were changes accepted before refund period
export const txWereChangesAcceptedBeforeRefundPeriod = tx => {
  const allTransitions = get(tx, 'attributes.transitions', []);

  return allTransitions.some(transition => {
    return transition.transition === TRANSITION_ACCEPTED_CHANGES;
  });
};
export const txHasCustomerRequestedChangesAfterRefundPeriod = tx => {
  return get(tx, 'attributes.transitions', []).some(
    transition => transition.transition === TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD
  );
};

export const txIsCustomerCancellableNonRefundable = tx =>
  getTransitionsToState(STATE_CUSTOMER_CANCELLABLE_NON_REFUNDABLE).includes(txLastTransition(tx));

export const txIsBookingStartsSoon = tx =>
  getTransitionsToState(STATE_BOOKING_STARTS_SOON).includes(txLastTransition(tx));

export const txIsBookingStarted = tx =>
  getTransitionsToState(STATE_BOOKING_STARTED).includes(txLastTransition(tx));

export const txIsProviderCancelledLate = tx =>
  getTransitionsToState(STATE_PROVIDER_CANCELLED_LATE).includes(txLastTransition(tx));

export const txIsCancelled = tx =>
  getTransitionsToState(STATE_CANCELLED).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx => {
  const transitions = getTransitionsToState(state);

  return transitions.filter(t => hasPassedTransition(t, tx)).length > 0;
};

export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

export const getLateReviewTransition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL : null;

export const isSpecialOfferPastTransition = transition => {
  return [
    TRANSITION_PROVIDER_SPECIAL_OFFER,
    TRANSITION_CUSTOMER_ACCEPT_OFFER,
    TRANSITION_CUSTOMER_DECLINE_OFFER,
    TRANSITION_OFFER_EXPIRE,
    TRANSITION_PROVIDER_CANCEL_OFFER,
    TRANSITION_SPECIAL_CUSTOMER_PAYMENT,
  ].includes(transition);
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ACCEPT,
    TRANSITION_CANCEL,
    TRANSITION_LATE_BOOKING,
    TRANSITION_REFUND_PERIOD_OVER,
    TRANSITION_BOOKING_START_GETTING_CLOSER,
    TRANSITION_BOOKING_START,
    TRANSITION_CUSTOMER_CANCEL_WITH_REFUND,
    TRANSITION_CUSTOMER_CANCEL_WITHOUT_REFUND,
    TRANSITION_CUSTOMER_LATE_CANCEL,
    TRANSITION_OPERATOR_EARLY_CANCEL,
    TRANSITION_OPERATOR_CANCEL,
    TRANSITION_OPERATOR_LATE_CANCEL,
    TRANSITION_PROVIDER_EARLY_CANCEL,
    TRANSITION_PROVIDER_CANCEL,
    TRANSITION_PROVIDER_LATE_CANCEL,
    TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL,
    TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD_LATE_CANCEL,
    TRANSITION_COMPLETE,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_DECLINE,
    TRANSITION_EXPIRE,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_PROVIDER_SPECIAL_OFFER,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_EXPIRED,
    TRANSITION_CUSTOMER_ACCEPT_OFFER,
    TRANSITION_CUSTOMER_DECLINE_OFFER,
    TRANSITION_OFFER_EXPIRE,
    TRANSITION_PROVIDER_CANCEL_OFFER,
    TRANSITION_SPECIAL_CUSTOMER_PAYMENT,
    TRANSITION_ENQUIRE_GATED,
    TRANSITION_ENQUIRE_EXPIRE,
    TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_PAYMENT,
    TRANSITION_COMPLETE_AFTER_CANCELLATION_PERIODS,
    TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_REVIEW_PERIOD,
    TRANSITION_PARTY_MEMBERS_SUBMITTED_1,
    TRANSITION_PARTY_MEMBERS_SUBMITTED_2,
    TRANSITION_PARTY_MEMBERS_SUBMITTED_3,
    TRANSITION_REQUEST_EXPIRES_SOON,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_EXPIRED,
    TRANSITION_PROVIDER_SPECIAL_OFFER_AFTER_EXPIRED_ENQUIRY,
    TRANSITION_REQUESTED_CHANGES,
    TRANSITION_ACCEPTED_CHANGES,
    TRANSITION_DECLINED_CHANGES,
    TRANSITION_OPERATOR_CANCEL_AFTER_CHANGES,
    TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD,
    TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD,
    TRANSITION_DECLINED_CHANGES_AFTER_REFUND_PERIOD,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL,
  ].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const theLastTransition = transaction => {
  const transObj = (transaction.attributes.transitions || []).find(obj => {
    if (transaction.attributes.lastTransition === obj.transition) {
      return obj;
    }
  });

  return transObj;
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const { customer } = tx;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  }
  throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

/**
 * Checks if line item represents commission
 */
export const isLineItemCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

export const isLineItemTax = lineItem => {
  return lineItem.code === LINE_ITEM_LODGING_TAX;
};

export const isLineItemConservationDonation = lineItem => {
  return lineItem.code === LINE_ITEM_CONSERVATION_DONATION;
};

/**
 * Returns non-commission, non-reversal line items
 */
export const getNonCommissionNonReversalLineItems = transaction => {
  return transaction.attributes.lineItems.filter(
    item =>
      !isLineItemCommission(item) &&
      !item.reversal &&
      !isLineItemTax(item) &&
      !isLineItemConservationDonation(item)
  );
};

export const getLineItemsForSubtotal = transaction => {
  return transaction.attributes.lineItems.filter(
    item => !shouldExcludeFromSubtotal(item.code) && !item.reversal
  );
};

/**
 * Calculates the total price in sub units for multiple line items. differs on listing page than on booking page
 */
export const getLineItemsTotal = (lineItems, currency) => {
  // filtering out li/units if there is no value
  const filtered = lineItems.filter(value => value.code !== 'line-item/units');
  const spliced = lineItems.slice(-1)[0];
  const splicedArray = (itemsArray = []) => {
    itemsArray.push(spliced);
    return itemsArray;
  };
  if (spliced.lineTotal.amount === 0) {
    const amount = filtered.reduce((total, item) => {
      return total.plus(item.lineTotal.amount);
    }, new Decimal(0));
    return new Money(amount, currency);
  }
  const amount = splicedArray().reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  return new Money(amount, currency);
};

export const isConservationProgramTransition = transitions =>
  !!transitions.find(item => item.transition === TRANSITION_REQUEST_PAYMENT_CONSERVATION_PROGRAM);

export const getBookingDates = transaction => {
  const { booking, attributes: txAttributes } = transaction;
  const { protectedData } = txAttributes;
  const { attributes: bookingAttibutes } = booking || {};

  const { start, end, displayStart, displayEnd } = bookingAttibutes || {};

  let bookingStart;
  let bookingEnd;
  let isSingleDay;

  if (protectedData && protectedData.bookingDates) {
    // We will convert the dates into the transaction listing timezone.
    // This date is relative to the listing date then.
    // The timezone is set on the CheckoutPage.
    const { timezone } = protectedData.bookingDates;
    bookingStart = moment.tz(displayStart, timezone);
    bookingEnd = moment.tz(displayEnd, timezone);
    isSingleDay = daysBetween(bookingStart, bookingEnd) === 0;
  } else {
    // Legacy booking dates handling
    bookingStart = moment(dateFromAPIToLocalNoon(displayStart || start));
    bookingEnd = moment(dateFromAPIToLocalNoon(displayEnd || end));
    isSingleDay = daysAdded(bookingStart.utc().format(), bookingEnd.utc().format()) === 1;
  }

  return {
    bookingStart,
    bookingEnd,
    isSingleDay,
  };
};

export const calculateLodgingTaxUnitPrice = (lodgingTax, nights, lodgingPrice) => {
  const lodgingFeePlusTaxes =
    lodgingPrice && lodgingPrice?.amount > 0 ? lodgingPrice.amount : lodgingTax?.basePrice;
  const lodgingFeeTaxes = nights * lodgingFeePlusTaxes * lodgingTax.taxValue;
  return new Money(lodgingFeeTaxes, config.currency);
};

export const calculateLodgingOnlyUnitPrice = (lodgingPrice, nights) => {
  return new Money(lodgingPrice?.amount * nights, config.currency);
};

export const isPromoCodeApplied = transaction => {
  const lineItems = get(transaction, 'attributes.lineItems', []);

  return lineItems.some(lineItem => lineItem.code === LINE_ITEM_PROMO_CODE);
};

export const getNumberOfGuests = transaction => {
  const specialOfferPackage = get(transaction, 'attributes.protectedData.selectedPackage', null);
  const lastTransition = get(transaction, 'attributes.lastTransition', null);

  let guestSize = 0;

  // Special offer
  if (specialOfferPackage) {
    // Special case when provider sent offer, we don't have guests yet
    if (lastTransition === TRANSITION_PROVIDER_SPECIAL_OFFER) {
      guestSize = 0;
    } else {
      guestSize = get(transaction, 'attributes.protectedData.partySize', 0);
    }
  } else {
    const packageLineItem = get(transaction, 'attributes.protectedData.packageLineItem', null);

    if (packageLineItem) {
      const bookingPackage = get(transaction, 'attributes.protectedData.packageLineItem', {});

      guestSize = bookingPackage?.guestSize || 0;
    } else {
      // None package bookings
      const lineItems = get(transaction, 'attributes.lineItems', []);
      const unitLineItem = lineItems.find(item => item.code === LINE_ITEM_UNITS);
      const unitQuantityCheck = unitLineItem && unitLineItem.quantity && unitLineItem.quantity.d;
      const numberOfDays = unitQuantityCheck ? unitLineItem.quantity.d[0] : null;
      const partySizeLineItem = lineItems.find(item => item.code === LINE_ITEM_PARTY_SIZE);
      const quantity = partySizeLineItem ? partySizeLineItem.quantity.d[0] / numberOfDays + 1 : 1;

      guestSize = quantity || 0;
    }
  }

  return guestSize;
};

export const HEADING_ENQUIRED = 'enquired';
export const HEADING_PAYMENT_PENDING = 'pending-payment';
export const HEADING_PAYMENT_EXPIRED = 'payment-expired';
export const HEADING_SPECIAL_OFFER = 'special-offer';
export const HEADING_SPECIAL_CUSTOMER_PAYMENT = 'special-customer-payment';
export const HEADING_REQUESTED = 'requested';
export const HEADING_ACCEPTED = 'accepted';
export const HEADING_DECLINED = 'declined';
export const HEADING_CANCELLED = 'cancelled';
export const HEADING_DELIVERED = 'delivered';
export const HEADING_UNKNOWN = 'unknown';
export const HEADING_CUSTOMER_CANCELLABLE_NON_REFUNDABLE = 'customer-cancellable-non-refundable';
export const HEADING_BOOKING_STARTS_SOON = 'booking-starts-soon';
export const HEADING_BOOKING_STARTED = 'booking-started';
export const HEADING_PROVIDER_CANCELLED_LATE = 'provider-cancelled-late';
export const HEADING_REVIEWED = 'reviewed';
export const HEADING_CUSTOMER_CHANGES_REQUESTED = 'customer-changes-requested';
export const HEADING_PROVIDER_CHANGES_ACCEPTED = 'provider-changes-accepted';
export const HEADING_PROVIDER_CHANGES_DECLINED = 'provider-changes-declined';

export const TRANSACTION_MESSAGE_SOURCE_SHARETRIBE = 'sharetribe';
export const TRANSACTION_MESSAGE_SOURCE_TWILIO = 'twilio';
export const TRANSACTION_MESSAGE_SOURCE_SMS = 'sms';
export const TRANSACTION_MESSAGE_SOURCE_SYSTEM = 'system';

export const getLineItemByCode = (transaction, lineItem) => {
  return transaction?.attributes?.lineItems?.filter(item => item.code === lineItem);
};

export const findPackageOrDiyLineItem = allTransaction => {
  return allTransaction.attributes.lineItems
    .filter(item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal)
    .filter(
      item =>
        item.code !== LINE_ITEM_UNITS &&
        item.code !== LINE_ITEM_PARTY_SIZE &&
        item.code !== LINE_ITEM_NEGOTIATION &&
        item.code !== LINE_ITEM_LODGING_TAX &&
        item.code !== LINE_ITEM_LODGING_FEE &&
        item.code !== LINE_ITEM_CONSERVATION_DONATION &&
        item.code !== LINE_ITEM_PROMO_CODE &&
        item.code !== LINE_ITEM_INSURANCE &&
        item.code !== LINE_ITEM_ACCESS_FEE &&
        item.code !== LINE_ITEM_FISHING_FEE
    );
};

export const isBookingChangeAllowed = tx =>
  tx.attributes.processVersion >= parseInt(config.enableBookingChanges, 10);

export const isBookingChangeAfterRefundPeriodAllowed = tx =>
  tx.attributes.processVersion >= parseInt(config.enableBookingChangesAfterRefundPeriod, 10);

export const getTxState = (
  intl,
  tx,
  transactionRole,
  currentCustomer,
  currentProvider,
  nextTransitions = []
) => {
  const isCustomerLoaded = !!currentCustomer?.id;
  const isCustomerBanned = isCustomerLoaded && currentCustomer.attributes.banned;
  const isProviderLoaded = !!currentProvider?.id;
  const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
  const isCustomer = transactionRole === 'customer';
  const isProvider = transactionRole === 'provider';

  const defaultStateOptions = {
    showDetailCardHeadings: isCustomer,
    showAddress: isCustomer,
    showPartyMemberSection: false,
    showBookingDetails: true,
    showPropertyAddress: true,
    showViewPackage: true,
    showArrivalInstructions: true,
    showListingWaypoints: true,
    showCancelButtonCustomerWithRefund: false,
    showCancelButtonProviderEarlyCancel: false,
    showCancelButtonCustomerWithoutRefund: false,
    showCancelButtonProviderCancel: false,
    showBookingChangesLinkCustomer: false,
    showViewRequestButton: false,
    showCustomerAcceptSpecialOffer: false,
    showCancelOffer: false,
    showBookingPanel: false,
    showSpecialOffer: false,
    showPotentialEarnings: false,
    potentialEarningsMoney: null,
    showDeclinedStatus: false,
    showCustomerPaymentDetails: false,
  };

  const currentOrExpiredInquiryState = txIsEnquired(tx) || txIsEnquiryExpired(tx);
  if (currentOrExpiredInquiryState) {
    const transitions = Array.isArray(nextTransitions)
      ? nextTransitions.map(transition => {
          return transition.attributes.name;
        })
      : [];

    const hasCorrectNextTransition =
      transitions.length > 0 &&
      (transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY) ||
        transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_EXPIRED));

    const hasSpecialOffer =
      transitions.length > 0 &&
      (transitions.includes(TRANSITION_PROVIDER_SPECIAL_OFFER) ||
        transitions.includes(TRANSITION_PROVIDER_SPECIAL_OFFER_AFTER_EXPIRED_ENQUIRY));
    const potentialEarnings = get(tx, 'attributes.protectedData.potentialEarnings', null);

    const potentialEarningsMoney = isProvider
      ? new Money(potentialEarnings?.amount * 100, potentialEarnings?.currency)
      : null;

    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateInquiry',
      }),
      headingState: HEADING_ENQUIRED,
      ...defaultStateOptions,
      showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
      showSpecialOffer: isProvider && !isCustomerBanned && hasSpecialOffer,
      showPotentialEarnings: isProvider && potentialEarnings,
      potentialEarningsMoney,
      showBookingDetails: isProvider,
      showPropertyAddress: false,
      showViewPackage: false,
      showArrivalInstructions: false,
      showListingWaypoints: false,
    };
  }

  if (txIsSpecialOffer(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateSpecialOffer',
      }),
      headingState: HEADING_SPECIAL_OFFER,
      ...defaultStateOptions,
      showCustomerAcceptSpecialOffer: isCustomer && !isCustomerBanned,
      showCancelOffer: isProvider && !isCustomerBanned,
      showPropertyAddress: false,
      showViewPackage: false,
      showArrivalInstructions: false,
      showListingWaypoints: false,
    };
  }

  if (txIsSpecialPendingPayment(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateSpecialOffer',
      }),
      headingState: HEADING_SPECIAL_CUSTOMER_PAYMENT,
      ...defaultStateOptions,
      showCustomerPaymentDetails: isCustomer && !isCustomerBanned,
      showCancelOffer: isProvider && !isCustomerBanned,
      showPropertyAddress: false,
      showViewPackage: false,
      showArrivalInstructions: false,
      showListingWaypoints: false,
    };
  }

  if (txIsPaymentPending(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_PAYMENT_PENDING,
      ...defaultStateOptions,
    };
  }

  if (txIsPaymentExpired(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateCancelled',
      }),
      headingState: HEADING_PAYMENT_EXPIRED,
      ...defaultStateOptions,
    };
  }

  if (txIsRequested(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_REQUESTED,
      ...defaultStateOptions,
      showSaleButtons: isProvider && !isCustomerBanned,
      showPartyMemberSection: isCustomer,
      showWithdrawBookingLinkCustomer: isCustomer,
      showPropertyAddress: false,
      showArrivalInstructions: false,
      showListingWaypoints: false,
    };
  }

  if (txAreChangesAccepted(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_PROVIDER_CHANGES_ACCEPTED,
      ...defaultStateOptions,
      showCancelButtonCustomerWithRefund: isCustomer && !isProviderBanned,
      showCancelButtonProviderEarlyCancel: isProvider && !isCustomerBanned,
    };
  }

  if (txHasChangesRequestExpired(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_ACCEPTED,
      ...defaultStateOptions,
      showCancelButtonCustomerWithRefund: isCustomer && !isProviderBanned,
      showCancelButtonProviderEarlyCancel: isProvider && !isCustomerBanned,
      showPartyMemberSection: isCustomer,
      showBookingChangesLinkCustomer: isCustomer && isBookingChangeAllowed(tx),
    };
  }

  if (txAreChangesAcceptedAfterRefundPeriod(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_PROVIDER_CHANGES_ACCEPTED,
      ...defaultStateOptions,
      showCancelButtonCustomerWithoutRefund: isCustomer && !isProviderBanned,
      showCancelButtonProviderCancel: isProvider && !isCustomerBanned,
    };
  }

  if (txHasChangesRequestExpiredAfterRefund(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_ACCEPTED,
      ...defaultStateOptions,
      showCancelButtonCustomerWithoutRefund: isCustomer && !isProviderBanned,
      showCancelButtonProviderCancel: isProvider && !isCustomerBanned,
      showPartyMemberSection: isCustomer,
      showBookingChangesLinkCustomer:
        isCustomer &&
        isBookingChangeAllowed(tx) &&
        !txHasCustomerRequestedChangesAfterRefundPeriod(tx),
    };
  }

  if (txAreChangesDeclined(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_PROVIDER_CHANGES_DECLINED,
      ...defaultStateOptions,
      showCancelButtonCustomerWithRefund: isCustomer && !isProviderBanned,
      showCancelButtonProviderEarlyCancel: isProvider && !isCustomerBanned,
    };
  }

  if (txAreChangesDeclinedAfterRefundPeriod(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_PROVIDER_CHANGES_DECLINED,
      ...defaultStateOptions,
      showCancelButtonCustomerWithoutRefund: isCustomer && !isProviderBanned,
      showCancelButtonProviderCancel: isProvider && !isCustomerBanned,
    };
  }

  if (txIsAccepted(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_ACCEPTED,
      ...defaultStateOptions,
      showCancelButtonCustomerWithRefund: isCustomer && !isProviderBanned,
      showCancelButtonProviderEarlyCancel: isProvider && !isCustomerBanned,
      showPartyMemberSection: isCustomer,
      showBookingChangesLinkCustomer: isCustomer && isBookingChangeAllowed(tx),
    };
  }

  if (txAreChangesRequested(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_CUSTOMER_CHANGES_REQUESTED,
      ...defaultStateOptions,
      showBookingChangesLinkCustomer: isCustomer,
      showViewRequestButton: true,
    };
  }

  if (txIsCustomerCancellableNonRefundable(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_CUSTOMER_CANCELLABLE_NON_REFUNDABLE,
      ...defaultStateOptions,
      showCancelButtonCustomerWithoutRefund: isCustomer && !isProviderBanned,
      showCancelButtonProviderCancel: isProvider && !isCustomerBanned,
      showBookingChangesLinkCustomer:
        isCustomer &&
        isBookingChangeAfterRefundPeriodAllowed(tx) &&
        !txWereChangesAcceptedBeforeRefundPeriod(tx),
    };
  }

  if (txAreChangesRequestedAfterRefundPeriod(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_CUSTOMER_CHANGES_REQUESTED,
      ...defaultStateOptions,
      showBookingChangesLinkCustomer: isCustomer,
      showViewRequestButton: true,
    };
  }

  if (txIsBookingStartsSoon(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_BOOKING_STARTS_SOON,
      ...defaultStateOptions,
      showCancelButtonCustomerLateCancel: isCustomer && !isProviderBanned,
      showCancelButtonProviderLateCancel: isProvider && !isCustomerBanned,
    };
  }

  if (txIsBookingStarted(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateBookingRequest',
      }),
      headingState: HEADING_BOOKING_STARTED,
      ...defaultStateOptions,
      showCancelButtonCustomer: false,
      showCancelButtonProvider: false,
    };
  }

  if (txIsProviderCancelledLate(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateCancelled',
      }),
      headingState: HEADING_PROVIDER_CANCELLED_LATE,
      ...defaultStateOptions,
    };
  }

  if (txIsDeclined(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateCancelled',
      }),
      headingState: HEADING_DECLINED,
      ...defaultStateOptions,
      showDeclinedStatus: true,
      showPropertyAddress: false,
      showArrivalInstructions: false,
      showListingWaypoints: false,
    };
  }

  if (txIsCancelled(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateCancelled',
      }),
      headingState: HEADING_CANCELLED,
      ...defaultStateOptions,
      showCancelButtonCustomer: false,
      showCancelButtonProvider: false,
      showDeclinedStatus: true,
      showPropertyAddress: false,
      showArrivalInstructions: false,
      showListingWaypoints: false,
    };
  }

  if (txHasBeenDelivered(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateTripComplete',
      }),
      headingState: HEADING_DELIVERED,
      ...defaultStateOptions,
    };
  }

  if (txIsReviewed(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateTripComplete',
      }),
      headingState: HEADING_REVIEWED,
      ...defaultStateOptions,
    };
  }

  if (txIsInFirstReviewBy(tx)) {
    return {
      state: intl.formatMessage({
        id: 'InboxPage.stateTripComplete',
      }),
      ...defaultStateOptions,
    };
  }

  // eslint-disable-next-line no-console
  console.warn('This transition is unknown:', tx.id.uuid, tx.attributes.lastTransition);
  return null;
};

export const isLegacyPaymentFlow = transaction => {
  if (process.env.REACT_APP_ENV !== 'production') {
    return false;
  }

  const processVersion = get(transaction, 'attributes.processVersion', null);

  if (processVersion >= config.paymentElementProcessVersion) {
    return false;
  }

  return true;
};

export const checkUpdatedTransaction = tx => {
  // Check if transaction is already updated
  const bookingChanges = get(tx, 'attributes.protectedData.bookingChanges', null);

  const updatedTransaction = tx;

  // Check if changes has been approved
  const hasProviderAcceptedChanges = get(tx, 'attributes.transitions', []).some(transition =>
    [TRANSITION_ACCEPTED_CHANGES, TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD].includes(
      transition.transition
    )
  );

  if (bookingChanges && hasProviderAcceptedChanges) {
    const updatedLineItems = get(bookingChanges, 'updatedLineItems', []);
    const formattedLineItems = JSON.parse(updatedLineItems).map(lineItem => {
      return {
        ...lineItem,
        unitPrice: new Money(lineItem.unitPrice.amount, config.currency),
        lineTotal: new Money(lineItem.lineTotal.amount, config.currency),
      };
    });

    if (formattedLineItems.length > 0) {
      updatedTransaction.attributes.lineItems = formattedLineItems;
    }

    const updatedAmounts = get(bookingChanges, 'updatedAmounts', {});
    const newPayInTotal = new Money(updatedAmounts.newCustomerTotal.amount * 100, config.currency);
    const newPayOutTotal = new Money(updatedAmounts.newProviderTotal.amount * 100, config.currency);

    updatedTransaction.attributes.payinTotal = newPayInTotal;
    updatedTransaction.attributes.payoutTotal = newPayOutTotal;
  }

  return updatedTransaction;
};

export const hasPaymentIntent = (tx, type) => {
  const stripePaymentIntents = get(tx, 'attributes.protectedData.stripePaymentIntents', null);

  if (
    stripePaymentIntents &&
    stripePaymentIntents[type] &&
    stripePaymentIntents[type].stripePaymentIntentId
  ) {
    return true;
  }

  return false;
};

export const calculateBookingChangesPrices = (
  currentPackage,
  currentTransaction,
  newGuestSize,
  newBookingDates,
  hasCustomerRequestedChanges
) => {
  if (hasCustomerRequestedChanges) {
    const bookingChanges = get(currentTransaction, 'attributes.protectedData.bookingChanges', {});
    const updatedAmounts = get(bookingChanges, 'updatedAmounts', {});
    const { newCustomerTotal, newProviderTotal } = updatedAmounts;
    const updatedLineItems = JSON.parse(bookingChanges.updatedLineItems);

    return {
      newCustomerPrice: newCustomerTotal.amount * 100,
      newProviderPrice: newProviderTotal.amount * 100,
      updatedLineItems,
    };
  }

  const originalLineItems = get(currentTransaction, 'attributes.lineItems', []);
  const originalLodgingPrice = get(currentTransaction, 'attributes.protectedData.lodgingPrice', {});
  const originalLodgingTaxInfo = get(
    currentTransaction,
    'attributes.protectedData.lodgingTaxInfo',
    {}
  );
  const originalPromoCode = get(currentTransaction, 'attributes.protectedData.promoCode', {});
  const originalHasTripInsurance = get(
    currentTransaction,
    'attributes.protectedData.tripInsurance',
    false
  );

  const originalUnitLodgingFee = originalLodgingPrice.price;
  const originalUnitLodgingTaxFee = originalLodgingPrice.priceForTax;

  const packageTitle = get(currentPackage, 'title', '').replace(/\s/g, '-');
  const { startDate, endDate } = newBookingDates;
  const numberOfDays = daysAdded(startDate, endDate);
  const numberOfNights = numberOfDays - 1;

  let updatedLineItems = originalLineItems.map(lineItem => {
    switch (lineItem.code) {
      case `line-item/${packageTitle}`: {
        const unitPrice = lineItem.unitPrice.amount;

        const units = numberOfDays * newGuestSize;

        return {
          ...lineItem,
          lineTotal: {
            ...lineItem.lineTotal,
            amount: unitPrice * units,
          },
          quantity: `${units}`,
        };
      }

      case LINE_ITEM_LODGING_TAX: {
        const taxUnitPrice = calculateLodgingTaxUnitPrice(
          originalLodgingTaxInfo,
          numberOfNights,
          new Money(originalUnitLodgingTaxFee, config.currency)
        );

        return {
          ...lineItem,
          lineTotal: taxUnitPrice,
          unitPrice: taxUnitPrice,
        };
      }

      case LINE_ITEM_LODGING_FEE: {
        return {
          ...lineItem,
          lineTotal: {
            ...lineItem.lineTotal,
            amount: originalUnitLodgingFee * numberOfNights,
          },
          unitPrice: {
            ...lineItem.unitPrice,
            amount: originalUnitLodgingFee * numberOfNights,
          },
        };
      }

      default:
        return lineItem;
    }
  });

  const lineItemsForSubtotal = updatedLineItems.filter(
    lineItem => !shouldExcludeFromSubtotal(lineItem.code)
  );

  const subtotal = lineItemsForSubtotal.reduce((acc, lineItem) => {
    return acc + lineItem.lineTotal.amount;
  }, 0);

  // Customer commission
  updatedLineItems = updatedLineItems.map(lineItem => {
    if (lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION) {
      const customerCommissionUnitPrice = new Decimal(subtotal)
        .times(lineItem.percentage.toNumber())
        .dividedBy(100)
        .toNearest(1, Decimal.ROUND_HALF_UP)
        .toNumber();

      const customerCommissionPrice = new Money(customerCommissionUnitPrice * 100, config.currency);
      const customerCommissionAmount = convertMoneyToNumber(customerCommissionPrice);

      return {
        ...lineItem,
        lineTotal: {
          ...lineItem.lineTotal,
          amount: customerCommissionAmount,
        },
        unitPrice: {
          ...lineItem.unitPrice,
          amount: subtotal,
        },
      };
    }

    return lineItem;
  });

  // Provider commission
  updatedLineItems = updatedLineItems.map(lineItem => {
    if (lineItem.code === LINE_ITEM_PROVIDER_COMMISSION) {
      const providerCommissionUnitPrice = new Decimal(subtotal)
        .times(lineItem.percentage.toNumber())
        .dividedBy(100)
        .toNearest(1, Decimal.ROUND_HALF_UP)
        .toNumber();

      const providerCommissionPrice = new Money(providerCommissionUnitPrice * 100, config.currency);
      const providerCommissionAmount = convertMoneyToNumber(providerCommissionPrice);

      return {
        ...lineItem,
        lineTotal: {
          ...lineItem.lineTotal,
          amount: providerCommissionAmount,
        },
        unitPrice: {
          ...lineItem.unitPrice,
          amount: subtotal,
        },
      };
    }

    return lineItem;
  });

  // Promo code
  const hasPromoCode = updatedLineItems.some(lineItem => lineItem.code === LINE_ITEM_PROMO_CODE);
  let promoCodeAmount = 0;

  if (hasPromoCode) {
    const promoCodePercentageAmount = subtotal * (originalPromoCode.percentage / 100);

    if (promoCodePercentageAmount > originalPromoCode.max) {
      promoCodeAmount = originalPromoCode.max;
    } else {
      promoCodeAmount = promoCodePercentageAmount;
    }

    updatedLineItems = updatedLineItems.map(lineItem => {
      if (lineItem.code === LINE_ITEM_PROMO_CODE) {
        return {
          ...lineItem,
          lineTotal: {
            ...lineItem.lineTotal,
            amount: -promoCodeAmount,
          },
          unitPrice: {
            ...lineItem.unitPrice,
            amount: subtotal,
          },
        };
      }

      return lineItem;
    });
  }

  // insurance
  let insuranceAmount = 0;

  if (originalHasTripInsurance) {
    const lineItemsForInsurance = updatedLineItems.filter(
      lineItem =>
        (!lineItem.includeFor || lineItem.includeFor.includes('customer')) &&
        lineItem.code !== LINE_ITEM_INSURANCE
    );

    const subtotalForInsurance = lineItemsForInsurance.reduce((acc, lineItem) => {
      return acc + lineItem.lineTotal.amount;
    }, 0);

    const insuranceUnitPrice = new Decimal(subtotalForInsurance)
      .times(INSURANCE_PERCENTAGE)
      .dividedBy(100)
      .toNearest(1, Decimal.ROUND_HALF_UP);

    const insurancePrice = new Money(insuranceUnitPrice, config.currency);
    insuranceAmount = convertMoneyToNumber(insurancePrice);

    updatedLineItems = updatedLineItems.map(lineItem => {
      if (lineItem.code === LINE_ITEM_INSURANCE) {
        return {
          ...lineItem,
          lineTotal: {
            ...lineItem.lineTotal,
            amount: insuranceAmount * 100,
          },
          unitPrice: {
            ...lineItem.unitPrice,
            amount: insuranceAmount * 100,
          },
        };
      }

      return lineItem;
    });
  }

  const customerLineItems = updatedLineItems.filter(lineItem => {
    return lineItem.includeFor.includes('customer');
  });

  const newCustomerPrice = customerLineItems.reduce((acc, lineItem) => {
    return acc + lineItem.lineTotal.amount;
  }, 0);

  const providerLineItems = updatedLineItems.filter(lineItem => {
    return lineItem.includeFor.includes('provider');
  });

  const newProviderPrice = providerLineItems.reduce((acc, lineItem) => {
    return acc + lineItem.lineTotal.amount;
  }, 0);

  return {
    newCustomerPrice,
    newProviderPrice,
    updatedLineItems,
  };
};

export const formatDatesForTransaction = (dates, timezone) => {
  const bookingStartDate = moment(dates.startDate).format('YYYY-MM-DD');
  const bookingEndDate = moment(dates.endDate).format('YYYY-MM-DD');

  const bookingStartDateInLocale = moment.tz(`${bookingStartDate} 00:00:00`, timezone);
  const bookingEndDateInLocale = moment.tz(`${bookingEndDate} 23:59:59`, timezone);

  return {
    startDate: bookingStartDateInLocale.format(),
    endDate: bookingEndDateInLocale.format(),
  };
};

export const hasProviderAcceptedChanges = tx => {
  return get(tx, 'attributes.transitions', []).some(
    transition => transition.transition === TRANSITION_ACCEPTED_CHANGES
  );
};

export const estimatedTotalPrice = (
  unitPrice,
  unitCount,
  speciesFeeLineItems,
  partySize,
  packageItem,
  lodgingFeeLineItemMaybe
) => {
  const numericPrice = convertMoneyToNumber(unitPrice);
  const partyDecimal = new Decimal(partySize || 1);
  const packageFeePrice = packageItem ? convertMoneyToNumber(packageItem.unitPrice) : 0;

  const speciesFeesPrice = speciesFeeLineItems
    .map(specieLineItem => {
      return convertMoneyToNumber(specieLineItem.lineTotal);
    })
    .reduce((a, c) => a + c, 0);

  const lodgingFeePriceTotal = lodgingFeeLineItemMaybe
    ? convertMoneyToNumber(lodgingFeeLineItemMaybe?.lineTotal)
    : 0;

  const numericTotalPrice = new Decimal(numericPrice)
    .plus(speciesFeesPrice)
    .plus(packageFeePrice)
    .times(unitCount)
    .times(partyDecimal)
    .plus(lodgingFeePriceTotal)
    .toNumber();

  return new Money(
    convertUnitToSubUnit(numericTotalPrice, unitDivisor(unitPrice.currency)),
    unitPrice.currency
  );
};

export const estimatedTransaction = (
  unitType,
  bookingStart,
  bookingEnd,
  unitPrice,
  partySize,
  packageItem,
  lodgingTax,
  listing,
  ...animalFees
) => {
  const now = new Date();
  const unitCount = daysAdded(bookingStart, bookingEnd);
  const partyDecimal = new Decimal(partySize || 1);

  const partySizeLineItems = {
    code: LINE_ITEM_PARTY_SIZE,
    includeFor: ['customer', 'provider'],
    unitPrice,
    quantity: partyDecimal,
    lineTotal: unitPrice,
    reversal: false,
  };

  const speciesFeeLineItems = Object.entries(animalFees[0]).map(([name, fee]) => {
    return {
      code: `line-item/${name}`,
      includeFor: ['customer', 'provider'],
      unitPrice: fee,
      quantity: new Decimal(1),
      lineTotal: fee,
      reversal: false,
    };
  });
  const packageFeeLineItemMaybe = packageItem ? [packageItem] : [];

  // We wanna get the date at the listing location.
  // This will then be used for the display dates which are hooked
  // with our process.end transitions
  const timezone = getListingTimezone(listing);

  const bookingStartDate = moment(bookingStart).format('YYYY-MM-DD');
  const bookingEndDate = moment(bookingEnd).format('YYYY-MM-DD');

  const bookingStartDateInLocale = moment.tz(`${bookingStartDate} 00:00:00`, timezone);
  const bookingEndDateInLocale = moment.tz(`${bookingEndDate} 23:59:59`, timezone);
  const lodgingPrices = get(packageItem, 'lodgingFees', []);

  let lodgingTaxLineItemMaybe = null;
  let lodgingFeeLineItemMaybe = null;

  const nights = daysAdded(bookingStartDateInLocale, bookingEndDateInLocale) - 1;

  if (lodgingTax && nights > 0) {
    const lodgingPriceAmount = lodgingPrices
      .filter(lodgingPrice => [LODGING_TYPE.HOUSE, LODGING_TYPE.CABIN].includes(lodgingPrice.type))
      .reduce((acc, lodgingFee) => {
        if (lodgingFee?.price?.amount) {
          return acc + lodgingFee.price.amount;
        }

        return acc;
      }, 0);

    const lodgingPrice = new Money(lodgingPriceAmount, config.currency);

    const taxUnitPrice = calculateLodgingTaxUnitPrice(lodgingTax, nights, lodgingPrice);

    lodgingTaxLineItemMaybe = {
      code: LINE_ITEM_LODGING_TAX,
      includeFor: ['customer'],
      unitPrice: taxUnitPrice,
      quantity: 1,
      lineTotal: taxUnitPrice,
    };
  }

  let lodgingFeePrice = 0;

  lodgingPrices.forEach(lodgingPrice => {
    const lodgingFeeLineItemAdded = lodgingPrice && lodgingPrice?.price?.amount > 0 && nights > 0;

    if (lodgingFeeLineItemAdded) {
      const lodgingFeeUnitPrice = calculateLodgingOnlyUnitPrice(lodgingPrice.price, nights);
      lodgingFeePrice += lodgingFeeUnitPrice.amount;
    }
  });

  if (lodgingFeePrice > 0) {
    lodgingFeeLineItemMaybe = {
      code: LINE_ITEM_LODGING_FEE,
      includeFor: ['customer', 'provider'],
      unitPrice: new Money(lodgingFeePrice, config.currency),
      quantity: 1,
      lineTotal: new Money(lodgingFeePrice, config.currency),
    };
  }

  const subtotalPrice = estimatedTotalPrice(
    unitPrice,
    unitCount,
    speciesFeeLineItems,
    partySize,
    packageItem,
    lodgingFeeLineItemMaybe
  );

  const convertedSubtotalPrice = convertMoneyToNumber(subtotalPrice);

  const estimatedCommission = new Money(
    convertedSubtotalPrice * CUSTOMER_COMMISSION_PERCENTAGE,
    unitPrice.currency
  );

  const customerCommissionLineItems = {
    code: LINE_ITEM_CUSTOMER_COMMISSION,
    includeFor: ['customer'],
    unitPrice,
    quantity: new Decimal(1),
    lineTotal: estimatedCommission,
    reversal: false,
  };

  const customerCommissionLineItemMaybe = customerCommissionLineItems
    ? [customerCommissionLineItems]
    : [];

  Object.entries(animalFees[0])
    // eslint-disable-next-line array-callback-return
    .map(([name]) => {
      if (speciesFoundations[name]) {
        customerCommissionLineItemMaybe.push({
          code: LINE_ITEM_CUSTOMER_COMMISSION,
          donation: speciesFoundations[name],
          includeFor: ['customer'],
          unitPrice,
          quantity: new Decimal(1),
          lineTotal: new Money(
            convertUnitToSubUnit(10, unitDivisor(unitPrice.currency)),
            unitPrice.currency
          ),
          reversal: false,
        });
      }
    });
  const isDonation = customerCommissionLineItemMaybe[1] || packageItem;

  if (packageItem) {
    customerCommissionLineItemMaybe.push({
      code: LINE_ITEM_CUSTOMER_COMMISSION,
      donation: packageItem.donation,
      includeFor: ['customer'],
      unitPrice,
      quantity: new Decimal(1),
      lineTotal: new Money(
        convertUnitToSubUnit(10, unitDivisor(unitPrice.currency)),
        unitPrice.currency
      ),
      reversal: false,
    });
  }

  const customerCommissionswithSingleDonation = customerCommissionLineItemMaybe[2]
    ? customerCommissionLineItemMaybe.slice(0, 2)
    : customerCommissionLineItemMaybe;

  const lodgingTaxPrice = lodgingTaxLineItemMaybe
    ? convertMoneyToNumber(lodgingTaxLineItemMaybe.lineTotal)
    : 0;

  const totalPrice = isDonation
    ? new Money(
        (lodgingTaxPrice +
          convertedSubtotalPrice +
          convertMoneyToNumber(estimatedCommission) +
          10) *
          100,
        unitPrice.currency
      )
    : new Money(
        (lodgingTaxPrice + convertedSubtotalPrice + convertMoneyToNumber(estimatedCommission)) *
          100,
        unitPrice.currency
      );

  // bookingStart: "Fri Mar 30 2018 12:00:00 GMT-1100 (SST)" aka "Fri Mar 30 2018 23:00:00 GMT+0000 (UTC)"
  // Server normalizes night/day bookings to start from 00:00 UTC aka "Thu Mar 29 2018 13:00:00 GMT-1100 (SST)"
  // The result is: local timestamp.subtract(12h).add(timezoneoffset) (in eg. -23 h)

  // local noon -> startOf('day') => 00:00 local => remove timezoneoffset => 00:00 API (UTC)
  const serverDayStart = dateFromLocalToAPI(
    moment(bookingStart)
      .startOf('day')
      .toDate()
  );
  const serverDayEnd = dateFromLocalToAPI(
    moment(bookingEnd)
      .startOf('day')
      .toDate()
  );

  const lineItems = [
    partySizeLineItems,
    ...speciesFeeLineItems,
    ...packageFeeLineItemMaybe,
    ...customerCommissionswithSingleDonation,

    {
      code: unitType,
      includeFor: ['customer', 'provider'],
      unitPrice,
      quantity: new Decimal(unitCount),
      lineTotal: subtotalPrice,
      reversal: false,
    },
  ];

  if (lodgingTaxLineItemMaybe) {
    lineItems.push(lodgingTaxLineItemMaybe);
  }

  if (lodgingFeeLineItemMaybe) {
    lineItems.push(lodgingFeeLineItemMaybe);
  }

  return {
    id: new UUID('estimated-transaction'),
    type: 'transaction',
    attributes: {
      createdAt: now,
      lastTransitionedAt: now,
      lastTransition: TRANSITION_REQUEST_PAYMENT,
      payinTotal: totalPrice,
      payoutTotal: totalPrice,
      lineItems,
      transitions: [
        {
          createdAt: now,
          by: TX_TRANSITION_ACTOR_CUSTOMER,
          transition: TRANSITION_REQUEST_PAYMENT,
        },
      ],
      protectedData: {
        packageLineItem: packageItem || false,
        isEstimate: true,
        lodgingTaxInfo: lodgingTax,
      },
    },
    booking: {
      id: new UUID('estimated-booking'),
      type: 'booking',
      attributes: {
        start: serverDayStart,
        end: serverDayEnd,
      },
    },
  };
};

export const generateTransaction = (bookingData, listing) => {
  const {
    unitType,
    startDate,
    endDate,
    unitPrice,
    partySize,
    quantity,
    packageItem,
    lodgingTax,
    ...animalFees
  } = bookingData;

  const isUnits = unitType === 'LINE_ITEM_UNITS';
  const quantityIfUsingUnits = !isUnits || Number.isInteger(quantity);
  const canEstimatePrice = startDate && endDate && unitPrice && quantityIfUsingUnits;

  if (!canEstimatePrice) {
    return null;
  }

  return estimatedTransaction(
    unitType,
    startDate,
    endDate,
    unitPrice,
    partySize,
    packageItem,
    lodgingTax,
    listing,
    animalFees
  );
};

const calculateRemainingMinutes = (lastTransitionDate, timeLimit) =>
  timeLimit * 60 - moment().diff(lastTransitionDate, 'minutes');

export const calculateRemainingTimeForTransaction = (transaction, user) => {
  const ownRole = getUserTxRole(user.id, transaction);
  const isSpecialOffer = txIsSpecialOffer(transaction);
  const isRequested = txIsRequested(transaction);
  const isEnquiry = txIsEnquired(transaction);
  const { attributes, messages } = transaction;
  const { lastTransitionedAt } = attributes || {};
  let lastTransitionDate = moment(lastTransitionedAt);
  const lastMessage = messages && messages.length > 0 ? messages[messages.length - 1] : null;
  let isBookingRequest = false;

  if (isRequested) {
    const confirmPayment = attributes.transitions.find(
      t => t.transition === TRANSITION_CONFIRM_PAYMENT
    );

    if (confirmPayment) {
      isBookingRequest = true;
      lastTransitionDate = moment(confirmPayment.createdAt);
    }
  }

  let remainingMinutes = null;

  switch (ownRole) {
    case 'customer':
      if (isSpecialOffer) {
        remainingMinutes = calculateRemainingMinutes(lastTransitionDate, 48);
      }

      break;

    case 'provider':
      if (isEnquiry) {
        const lastMessageSender = lastMessage?.sender?.id?.uuid;

        if (lastMessageSender && lastMessageSender !== user?.id?.uuid) {
          remainingMinutes = calculateRemainingMinutes(lastTransitionDate, 24);
        }
      } else if (isBookingRequest) {
        remainingMinutes = calculateRemainingMinutes(lastTransitionDate, 72);
      } else if (lastMessage) {
        const lastMessageDate = moment(lastMessage.attributes.createdAt);

        const lastMessageSender = lastMessage?.sender?.id?.uuid;

        if (lastMessageSender && lastMessageSender !== user?.id?.uuid) {
          remainingMinutes = calculateRemainingMinutes(lastMessageDate, 48);
        }
      }
      break;

    default:
  }

  if (!remainingMinutes) {
    return {
      remainingMinutes: null,
      remainingHours: null,
    };
  }

  return {
    remainingMinutes,
    remainingHours: Math.round(remainingMinutes / 60),
  };
};

export const getUserTxRoleGraphQL = (currentUserId, transaction) => {
  const { customer } = transaction;
  if (currentUserId && currentUserId.uuid && transaction.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  }
  throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
};

const ARCHIVED_TRANSACTIONS_KEY = 'archivedTransactions';

export const storeArchivedTransaction = (transaction, userType) => {
  try {
    const archivedTransactions = JSON.parse(localStorage.getItem(ARCHIVED_TRANSACTIONS_KEY)) || {};

    if (!archivedTransactions[userType]) {
      archivedTransactions[userType] = [];
    }

    // Add transaction to the appropriate userType array if not already present
    const existingIndex = archivedTransactions[userType].findIndex(tx => tx.id === transaction.id);
    if (existingIndex === -1) {
      archivedTransactions[userType].push(transaction);
      localStorage.setItem(ARCHIVED_TRANSACTIONS_KEY, JSON.stringify(archivedTransactions));
    }
  } catch (error) {
    console.error('Error storing archived transaction:', error);
  }
};

export const getArchivedTransactions = userType => {
  try {
    const archivedTransactions = JSON.parse(localStorage.getItem(ARCHIVED_TRANSACTIONS_KEY)) || {};
    return archivedTransactions[userType] || [];
  } catch (error) {
    console.error('Error getting archived transactions:', error);
    return [];
  }
};

export const removeArchivedTransaction = (transactionId, userType) => {
  try {
    const archivedTransactions = JSON.parse(localStorage.getItem(ARCHIVED_TRANSACTIONS_KEY)) || {};

    if (archivedTransactions[userType]) {
      archivedTransactions[userType] = archivedTransactions[userType].filter(
        tx => tx.id !== transactionId
      );
      localStorage.setItem(ARCHIVED_TRANSACTIONS_KEY, JSON.stringify(archivedTransactions));
    }
  } catch (error) {
    console.error('Error removing archived transaction:', error);
  }
};

// Constants and functions for transaction messages
const TRANSACTION_MESSAGES_KEY = 'transactionMessages';

export const storeTransactionMessage = (transactionId, message) => {
  try {
    const transactionMessages = JSON.parse(localStorage.getItem(TRANSACTION_MESSAGES_KEY)) || {};

    if (!transactionMessages[transactionId]) {
      transactionMessages[transactionId] = [];
    }

    // Add message if not already present
    const existingIndex = transactionMessages[transactionId].findIndex(
      msg => msg.createdAt === message.createdAt
    );
    if (existingIndex === -1) {
      transactionMessages[transactionId].push(message);
      localStorage.setItem(TRANSACTION_MESSAGES_KEY, JSON.stringify(transactionMessages));
    }
  } catch (error) {
    console.error('Error storing transaction message:', error);
  }
};

export const getTransactionMessages = transactionId => {
  try {
    const transactionMessages = JSON.parse(localStorage.getItem(TRANSACTION_MESSAGES_KEY)) || {};
    return transactionMessages[transactionId] || [];
  } catch (error) {
    console.error('Error getting transaction messages:', error);
    return [];
  }
};

export const removeTransactionMessage = (transactionId, messageId) => {
  try {
    const transactionMessages = JSON.parse(localStorage.getItem(TRANSACTION_MESSAGES_KEY)) || {};

    if (transactionMessages[transactionId]) {
      transactionMessages[transactionId] = transactionMessages[transactionId].filter(
        msg => msg.messageId !== messageId
      );

      // Clean up empty arrays
      if (transactionMessages[transactionId].length === 0) {
        delete transactionMessages[transactionId];
      }

      localStorage.setItem(TRANSACTION_MESSAGES_KEY, JSON.stringify(transactionMessages));
    }
  } catch (error) {
    console.error('Error removing transaction message:', error);
  }
};

export const getTxStatus = tx => {
  const status = [];
  const { lastTransition } = tx?.attributes;
  const { metadata } = tx?.attributes;

  // Open Inquiries - active inquiries people are interesting in booking
  if (['transition/enquire', 'transition/enquire-gated'].includes(lastTransition)) {
    status.push('open_inquiry');
  }

  // Booking requests
  if (
    [
      'transition/request-payment',
      'transition/confirm-payment',
      'transition/party-members-submitted-1',
      'transition/request-expires-soon',
      'transition/request-payment-after-enquiry',
    ].includes(lastTransition)
  ) {
    status.push('booking_request');
  }

  // Upcoming trips as landtrust ui
  if (
    [
      'transition/accept',
      'transition/booking-start',
      'transition/booking-start-getting-closer',
      'transition/late-booking',
      'transition/party-members-submitted-1',
      'transition/party-members-submitted-2',
      'transition/party-members-submitted-3',
      'transition/refund-period-over',
      'transition/special-customer-payment',
      'transition/customer-requested-changes',
      'transition/provider-accepted-changes',
      'transition/provider-declined-changes',
      'transition/request-changes-expire',
      'transition/customer-requested-changes-after-refund-period',
      'transition/provider-accepted-changes-after-refund-period',
      'transition/provider-declined-changes-after-refund-period',
      'transition/request-changes-expire-after-refund-period',
    ].includes(lastTransition)
  ) {
    status.push('upcoming_trip');
  }

  // Past Trips
  if (
    [
      'transition/complete',
      'transition/expire-customer-review-period',
      'transition/expire-customer-review-period-late-cancel',
      'transition/expire-provider-review-period',
      'transition/review-1-by-customer',
      'transition/review-1-by-provider',
      'transition/review-2-by-customer',
      'transition/review-2-by-provider',
      'transition/review-by-customer-late-cancel',
      'transition/cancel',
      'transition/complete-after-cancellation-periods',
      'transition/customer-cancel-with-refund',
      'transition/customer-cancel-without-refund',
      'transition/customer-late-cancel',
      'transition/expire-review-period',
      'transition/operator-cancel',
      'transition/operator-early-cancel',
      'transition/operator-late-cancel',
      'transition/provider-cancel',
      'transition/provider-cancel-offer',
      'transition/provider-early-cancel',
      'transition/provider-late-cancel',
      'transition/operator-cancel-after-changes',
    ].includes(lastTransition)
  ) {
    status.push('past_trip');
  }

  // Archived
  if (metadata?.archivedByProvider) {
    status.push('archived_by_provider');
  }

  if (metadata?.archivedByCustomer) {
    status.push('archived_by_customer');
  }

  // Expired/Canceled - booking request and inquiry expired
  if (
    [
      'transition/expire',
      'transition/enquire-expire',
      'transition/enquiry-expired',
      'transition/expire-payment',
      'transition/offer-expire',
      'transition/offer-expire-after-accept',
      'transition/operator-cancel-enquiry',
      'transition/operator-early-cancel',
      'transition/operator-cancel-after-changes',
      'transition/operator-cancel',
      'transition/customer-cancel-with-refund',
      'transition/customer-cancel-without-refund',
      'transition/operator-late-cancel',
      'transition/operator-close-enquiry',
      'transition/provider-cancel',
      'transition/provider-cancel-offer',
      'transition/provider-early-cancel',
      'transition/provider-late-cancel',
      'transition/operator-early-cancel-changes-requested',
    ].includes(lastTransition)
  ) {
    status.push('expired_canceled');
  }

  // Declined - booking request declined
  if (['transition/decline', 'transition/customer-decline-offer'].includes(lastTransition)) {
    status.push('declined');
  }

  return status;
};

export const formatTransaction = transaction => {
  const transactionId = transaction.id.uuid;
  const customerId = transaction.customer?.id.uuid || null;
  const providerId = transaction.provider?.id.uuid || null;

  if (!customerId || !providerId || !transactionId) {
    console.warn(`Skipping transaction due to missing data. TID: ${transactionId}`);
    return null;
  }

  const { protectedData } = transaction.attributes;
  const packageItem = protectedData.packageLineItem ?? protectedData.selectedPackage;
  const packageId = packageItem?.id ?? packageItem?.packageId;

  const { listing } = transaction;
  const packagesImages = packageId ? getPackageImages(listing, packageId) : null;
  const listingImage = getListingImages(listing)[0] || null;
  const firstImage = packagesImages?.length > 0 ? packagesImages[0] : listingImage;
  const packageImage = firstImage?.attributes?.variants?.default?.url;
  const status = getTxStatus(transaction);

  return {
    transactionId,
    processVersion: transaction.attributes.processVersion,
    processName: transaction.attributes.processName,
    payinTotal: transaction.attributes.payinTotal,
    payoutTotal: transaction.attributes.payoutTotal,
    lastTransitionedAt: transaction.attributes.lastTransitionedAt?.toISOString() || null,
    protectedData: {
      bookingDates: transaction.attributes.protectedData?.bookingDates,
      emailStart: transaction.attributes.protectedData?.emailStart,
      startDate: transaction.attributes.protectedData?.startDate,
      partySize: transaction.attributes.protectedData?.partySize,
      endDate: transaction.attributes.protectedData?.endDate,
      potentialEarnings: transaction.attributes.protectedData?.potentialEarnings,
      selectedPackage: transaction.attributes.protectedData?.selectedPackage,
      packageLineItem: transaction.attributes.protectedData?.packageLineItem,
      message: transaction.attributes.protectedData?.message,
    },
    imageUrl: packageImage,
    lastTransition: transaction.attributes.lastTransition,
    transitions: transaction.attributes.transitions,
    metadata: transaction.attributes.metadata || {},
    lineItems: transaction.attributes.lineItems || [],
    createdAt: transaction.attributes.createdAt?.toISOString() || null,
    customer: {
      id: customerId,
      profile: {
        abbreviatedName: transaction.customer?.attributes?.profile.abbreviatedName,
        displayName: transaction.customer?.attributes?.profile.displayName,
        deleted: transaction.customer?.attributes?.deleted,
        banned: transaction.customer?.attributes?.banned,
        profileImage: {
          id: transaction.customer?.profileImage?.id.uuid,
          height: transaction.customer?.profileImage?.attributes?.variants?.default?.height,
          width: transaction.customer?.profileImage?.attributes?.variants?.default?.width,
          url: transaction.customer?.profileImage?.attributes?.variants?.default?.url,
        },
      },
    },
    provider: {
      id: providerId,
      profile: {
        abbreviatedName: transaction.provider?.attributes?.profile?.abbreviatedName,
        displayName: transaction.provider?.attributes?.profile.displayName,
        deleted: transaction.provider?.attributes?.deleted,
        banned: transaction.provider?.attributes?.banned,
        profileImage: {
          id: transaction.provider?.profileImage?.id.uuid,
          height: transaction.provider?.profileImage?.attributes?.variants?.default?.height,
          width: transaction.provider?.profileImage?.attributes?.variants?.default?.width,
          url: transaction.provider?.profileImage?.attributes?.variants?.default?.url,
        },
      },
    },
    booking: {
      id: transaction.booking?.id.uuid,
      start: transaction.booking?.attributes?.start?.toISOString(),
      end: transaction.booking?.attributes?.end?.toISOString(),
      displayStart: transaction.booking?.attributes?.displayStart?.toISOString(),
      displayEnd: transaction.booking?.attributes?.displayEnd?.toISOString(),
      seats: transaction.booking?.attributes?.seats,
      status: transaction.booking?.attributes?.state,
    },
    listing: {
      id: transaction.listing?.id.uuid || null,
      title: transaction.listing?.attributes?.title || null,
    },
    customerId,
    providerId,
    status: status.join(','),
  };
};
