import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { ResponsiveModal, TertiaryButton } from '..';

import css from './DeclineModal.css';

const DeclineModal = props => {
  const {
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onDecline,
    inProgress,
    disabled,
    isSpecialOffer,
  } = props;

  return (
    <ResponsiveModal
      id={id}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      content={
        <>
          <div className={css.header}>
            {isSpecialOffer ? (
              <FormattedMessage id="DeclineModal.specialHeader" />
            ) : (
              <FormattedMessage id="DeclineModal.header" />
            )}
          </div>
        </>
      }
      buttons={
        <TertiaryButton
          className={css.buttonYes}
          onClick={onDecline}
          disabled={disabled}
          inProgress={inProgress}
          isFullWidth
        >
          {isSpecialOffer ? (
            <FormattedMessage id="DeclineModal.buttonYesSpecialOffer" />
          ) : (
            <FormattedMessage id="DeclineModal.buttonYes" />
          )}
        </TertiaryButton>
      }
      closeButtonMessage={intl.formatMessage({ id: 'DeclineModal.goBack' })}
    />
  );
};

const { func } = PropTypes;

DeclineModal.defaultProps = {};

DeclineModal.propTypes = {
  intl: intlShape.isRequired,
  onDecline: func.isRequired,
  onCloseModal: func.isRequired,
};

export default injectIntl(DeclineModal);
