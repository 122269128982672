/* eslint-disable no-nested-ternary */
/* eslint-disable max-classes-per-file */
import React, { Component, useState, useLayoutEffect, useRef, useEffect } from 'react';
import { bool, func, object, shape, string, oneOfType, node, number } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { useMediaLayout } from 'use-media';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { shouldShowErrorForField } from '../../util/forms';
import { ValidationError, FieldLabel, FieldCopy, ProtipTooltip, ProtipLink } from '..';

import css from './FieldTextInput.css';

const CONTENT_MAX_LENGTH = 5000;

const FieldTextInputComponent = ({
  rootClassName,
  className,
  inputRootClass,
  inputClassName,
  customErrorText,
  customSuccessText,
  id,
  label,
  labelDescription,
  help,
  type,
  input,
  meta,
  isUncontrolled,
  inputRef,
  protip,
  charLimit,
  ...rest
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const textAreaRef = useRef(null);

  useLayoutEffect(() => {
    setIsReady(true);
  }, []);

  useEffect(() => {
    const adjustTextAreaHeight = () => {
      if (textAreaRef.current) {
        // Reset the height
        textAreaRef.current.style.height = 'inherit';

        // Set it based on scrollHeight
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    };

    adjustTextAreaHeight();
  }, [input.value]);

  // We use this hook to control different tooltip behaviour on mobile an desktop
  const isWide = useMediaLayout({ minWidth: '1024px' });

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { invalid, touched, error } = meta;
  const isTextarea = type === 'textarea';

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);
  const fieldMeta = { touched: hasError, error: errorText };

  // Uncontrolled input uses defaultValue instead of value.
  const { value: defaultValue, ...inputWithoutValue } = input;
  // Use inputRef if it is passed as prop.
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses =
    inputRootClass ||
    classNames(css.input, inputClassName, {
      [css.inputError]: hasError,
      [css.textarea]: isTextarea,
    });

  const maxLength = CONTENT_MAX_LENGTH;
  const inputProps = isTextarea
    ? { className: inputClasses, id, rows: 1, maxLength, ...refMaybe, ...input, ...rest }
    : isUncontrolled
    ? {
        className: inputClasses,
        id,
        type,
        defaultValue,
        ...refMaybe,
        ...inputWithoutValue,
        ...rest,
      }
    : { className: inputClasses, id, type, ...refMaybe, ...input, ...rest };
  const fieldHelp = help ? <FieldCopy>{help}</FieldCopy> : null;

  const classes = classNames(rootClassName || css.root, className);

  const handleOnBlur = e => {
    setIsTooltipVisible(false);
    inputProps.onBlur(e);
  };

  const handleOnFocus = e => {
    if (isWide) {
      setIsTooltipVisible(true);
    }
    inputProps.onFocus(e);
  };

  const handleTooltipVisibility = isVisible => {
    setIsTooltipVisible(isVisible);
  };

  const renderField = () => {
    if (isTextarea) {
      return (
        <textarea
          {...inputProps}
          ref={textAreaRef}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          data-hj-allow
        />
      );
    }

    return <input {...inputProps} onBlur={handleOnBlur} onFocus={handleOnFocus} data-hj-allow />;
  };

  // Need that state for onfocus event and use-media hook. Everthing need to be in the DOM first
  // If not ready, show skeleton loader to avoid CLS
  if (!isReady) {
    return (
      <div className={classes}>
        {label ? (
          <FieldLabel htmlFor={id} description={labelDescription}>
            {label}
          </FieldLabel>
        ) : null}
        <Skeleton
          className={classNames(css.input, { [css.textarea]: isTextarea })}
          containerClassName={css.skeletonContainer}
        />
      </div>
    );
  }

  return (
    <div className={classes}>
      {label ? (
        <FieldLabel htmlFor={id} description={labelDescription}>
          {label}
        </FieldLabel>
      ) : null}

      {protip ? (
        <ProtipTooltip
          placement={isWide ? 'right' : 'bottom'}
          body={protip}
          tooltipShown={isTooltipVisible}
          onTooltipShown={handleTooltipVisibility}
          className={isTextarea ? css.proTipTextArea : ''}
          trigger="none"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: ({ placement }) => {
                  if (placement === 'bottom') {
                    return [0, isTextarea ? 8 : 24];
                  }

                  return [0, 12];
                },
              },
            },
          ]}
        >
          {renderField()}
        </ProtipTooltip>
      ) : (
        renderField()
      )}

      <div className={css.helpWrapper}>
        {!customSuccessText && (
          <div className={css.errorWrapper}>
            {shouldShowErrorForField(fieldMeta) ? (
              <ValidationError fieldMeta={fieldMeta} />
            ) : charLimit ? (
              <FieldCopy>
                {input.value.length}/{charLimit}
              </FieldCopy>
            ) : (
              fieldHelp
            )}
          </div>
        )}

        {customSuccessText && (
          <div className={css.successWrapper}>
            <FieldCopy>{customSuccessText}</FieldCopy>
          </div>
        )}

        {protip && (
          <div className={css.proTipWrapper}>
            <ProtipLink
              onClick={() => {
                setIsTooltipVisible(!isTooltipVisible);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

FieldTextInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  inputClassName: null,
  onUnmount: null,
  customErrorText: null,
  customSuccessText: '',
  id: null,
  label: null,
  labelDescription: null,
  help: null,
  isUncontrolled: false,
  inputRef: null,
  protip: null,
  charLimit: undefined,
};

FieldTextInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  inputClassName: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  customSuccessText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,
  labelDescription: string,
  help: oneOfType([string, node]),

  // Either 'textarea' or something that is passed to the input element
  type: string.isRequired,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
  protip: string,
  charLimit: number,
};

class FieldTextInput extends Component {
  componentWillUnmount() {
    const { onUnmount } = this.props;
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (onUnmount) {
      onUnmount();
    }
  }

  render() {
    return <Field component={FieldTextInputComponent} {...this.props} />;
  }
}

export default FieldTextInput;
