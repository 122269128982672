/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { dropWhile, get } from 'lodash';
import { ensureTransaction, ensureUser } from '../../../util/data';
import { InlineTextButton } from '../..';
import InboxFeedItem from './InboxFeedItem';

import css from './InboxFeedItems.css';
import {
  getTransactionMessages,
  getUserTxRole,
  removeTransactionMessage,
  theLastTransition,
  TRANSACTION_MESSAGE_SOURCE_SHARETRIBE,
  TRANSITION_ENQUIRE_EXPIRE,
  TRANSITION_ENQUIRE_GATED,
  TRANSITION_PROVIDER_SPECIAL_OFFER,
  txRoleIsProvider,
} from '../../../util/transaction';
import InboxTransitionEnquireGated from '../../InboxTransition/InboxTransitionEnquireGated';
import InboxTransitionEnquireExpire from '../../InboxTransition/InboxTransitionEnquireExpire';
import InboxPartyMember from '../../InboxDetails/InboxPartyMember';

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  }
  return items;
};

const InboxFeedItems = ({
  messages,
  transaction,
  currentUser,
  hasOlderMessages,
  onShowOlderMessages,
  fetchMessagesInProgress,
  onUpdatePartyMemberDetails,
  transactionState,
  isCustomer,
}) => {
  const currentTransaction = ensureTransaction(transaction);
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const ownRole = getUserTxRole(currentUser.id, currentTransaction);
  const user = txRoleIsProvider(ownRole) ? currentCustomer : currentProvider;
  const stMessages = get(currentTransaction, 'messages', []).map(message => {
    return {
      content: message.attributes.content,
      createdAt: `${message.attributes.createdAt.getTime()}`,
      customerId: currentCustomer.id.uuid,
      messageId: message.id.uuid,
      messageSource: TRANSACTION_MESSAGE_SOURCE_SHARETRIBE,
      providerId: currentProvider.id.uuid,
      senderId: message.sender.id.uuid,
      transactionId: currentTransaction.id.uuid,
    };
  });

  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];

  const inquiryMessage = get(transaction, 'attributes.protectedData.message', null);
  const specialOfferMessage = get(transaction, 'attributes.protectedData.offerMessage', null);

  // Make unique messages
  const messagesIds = messages
    .filter(message => message != null)
    .map(message => {
      // For SMS messages, we should only use messageId
      if (message.messageSource === 'sms') {
        return message.messageId;
      }
      // For other messages, try sharetribeMessageId first, fall back to messageId
      return message.sharetribeMessageId || message.messageId;
    })
    .filter(Boolean);
  const missingMessages = stMessages.filter(message => !messagesIds.includes(message.messageId));
  const localMessages = getTransactionMessages(currentTransaction.id.uuid) || [];

  stMessages.forEach(message => {
    const exists = localMessages.some(localMsg => localMsg.messageId === message.messageId);

    if (exists) {
      removeTransactionMessage(currentTransaction.id.uuid, message.messageId);
    }
  });

  const mergedMessages = [...messages, ...missingMessages];

  let newMessages = mergedMessages
    .filter(message => message && message.content !== null) // Only filter out null content, allow undefined or other values
    .map(message => {
      let sender;

    if (message.senderId === currentCustomer.id.uuid) {
      sender = currentCustomer;
    } else if (message.senderId === currentProvider.id.uuid) {
      sender = currentProvider;
    }

    return {
      attributes: {
        content: message.content,
        createdAt: new Date(parseInt(message.createdAt, 10)),
      },
      sender,
      type: 'message',
      source: message.messageSource,
    };
  });

  if (inquiryMessage) {
    let inquiryMessageCreatedAt;
    const enquireGatedTransition = transitions.find(t => t.transition === TRANSITION_ENQUIRE_GATED);

    if (enquireGatedTransition) {
      inquiryMessageCreatedAt = enquireGatedTransition.createdAt;
    } else {
      inquiryMessageCreatedAt = currentTransaction.attributes.createdAt;
    }

    const sender = currentTransaction.customer;

    const inquiryMessageObj = {
      attributes: {
        content: inquiryMessage,
        createdAt: inquiryMessageCreatedAt,
      },
      sender,
      type: 'message',
      source: TRANSACTION_MESSAGE_SOURCE_SHARETRIBE,
    };

    newMessages = [inquiryMessageObj, ...newMessages];
  }

  if (specialOfferMessage) {
    let specialOfferMessageCreatedAt;
    const specialOfferTransition = transitions.find(
      t => t.transition === TRANSITION_PROVIDER_SPECIAL_OFFER
    );

    if (specialOfferTransition) {
      specialOfferMessageCreatedAt = specialOfferTransition.createdAt;
    } else {
      specialOfferMessageCreatedAt = currentTransaction.attributes.createdAt;
    }

    const sender = currentTransaction.provider;

    const specialOfferMessageObj = {
      attributes: {
        content: specialOfferMessage,
        createdAt: specialOfferMessageCreatedAt,
      },
      sender,
      type: 'message',
      source: TRANSACTION_MESSAGE_SOURCE_SHARETRIBE,
    };

    newMessages = [specialOfferMessageObj, ...newMessages];
  }

  // Hack nr 1,Filter out messages which content starts with 'LandTrust:'
  let filteredMessages = newMessages.filter(message => {
    return !message.attributes.content.startsWith('LandTrust:');
  });

  // Hack nr 2, Filter out messages which source is twilio and created at is before 2025-03-09
  filteredMessages = filteredMessages.filter(message => {
    if (message.source === 'twilio' && message.attributes.createdAt < new Date('2025-03-09')) {
      return false;
    }

    return true;
  });

  // combine messages and transaction transitions
  const items = organizedItems(
    filteredMessages,
    transitions,
    hasOlderMessages || fetchMessagesInProgress
  );

  const renderTransitionItems = () => {
    const lastTransition = theLastTransition(transaction);

    switch (lastTransition.transition) {
      case TRANSITION_ENQUIRE_EXPIRE:
        return (
          <InboxTransitionEnquireExpire
            transaction={transaction}
            ownRole={ownRole}
            buttons={[]}
            user={user}
            isMessage
          />
        );

      case TRANSITION_ENQUIRE_GATED:
        return (
          <InboxTransitionEnquireGated
            transaction={transaction}
            ownRole={ownRole}
            buttons={[]}
            user={user}
            isMessage
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className={css.root}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}

      {renderTransitionItems()}

      {items
        .filter(item => isMessage(item))
        .map((item, index) => (
          <InboxFeedItem key={`item-${index}`} message={item} currentUser={currentUser} />
        ))}

      <InboxPartyMember
        currentTransaction={currentTransaction}
        currentUser={currentUser}
        onUpdatePartyMemberDetails={onUpdatePartyMemberDetails}
        transactionState={transactionState}
        isCustomer={isCustomer}
      />
    </div>
  );
};

export default InboxFeedItems;
