import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../../ducks/UI.duck';
import {
  cancelSaleCustomerWithRefund,
  cancelSaleCustomerWithoutRefund,
  cancelSaleCustomerLate,
  cancelSaleProviderEarly,
  cancelSaleProvider,
  cancelSaleProviderLate,
  cancelSpecialOffer,
} from '../../../ducks/Transaction.duck';
import { LinkWithIcon } from '../..';
import { ReactComponent as IconCancel } from '../../../assets/icons/icon-cancel.svg';
import CancelModal from '../../CancelModal/CancelModal';

const CancelButton = ({
  intl,
  transactionState,
  currentTransaction,
  onManageDisableScrolling,
  onCancelOffer,
  onCancelSaleCustomerWithRefund,
  onCancelSaleCustomerWithoutRefund,
  onCancelSaleCustomerLate,
  onCancelSaleProviderEarly,
  onCancelSaleProvider,
  onCancelSaleProviderLate,
  cancelInProgress,
  cancelSaleError,
  onRefetchTransaction,
}) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  return (
    <>
      <LinkWithIcon
        icon={IconCancel}
        onClick={() => setIsCancelModalOpen(true)}
        label={intl.formatMessage({
          id: transactionState.showCancelOffer
            ? 'TransactionPanel.cancelOfferButton'
            : 'TransactionPanel.cancelTripButton',
        })}
      />
      <CancelModal
        showCancelOffer={transactionState.showCancelOffer}
        showCancelButtonCustomerWithRefund={transactionState.showCancelButtonCustomerWithRefund}
        showCancelButtonCustomerWithoutRefund={
          transactionState.showCancelButtonCustomerWithoutRefund
        }
        showCancelButtonProviderEarlyCancel={transactionState.showCancelButtonProviderEarlyCancel}
        showCancelButtonProviderCancel={transactionState.showCancelButtonProviderCancel}
        showCancelButtonCustomerLateCancel={transactionState.showCancelButtonCustomerLateCancel}
        showCancelButtonProviderLateCancel={transactionState.showCancelButtonProviderLateCancel}
        cancelInProgress={cancelInProgress}
        cancelSaleError={cancelSaleError}
        onCancelOffer={() => onCancelOffer(currentTransaction.id)}
        onCancelSaleCustomerWithRefund={() => onCancelSaleCustomerWithRefund(currentTransaction.id)}
        onCancelSaleCustomerWithoutRefund={() =>
          onCancelSaleCustomerWithoutRefund(currentTransaction.id)
        }
        onCancelSaleCustomerLate={() => onCancelSaleCustomerLate(currentTransaction.id)}
        onCancelSaleProviderEarly={() => onCancelSaleProviderEarly(currentTransaction.id)}
        onCancelSaleProvider={() => onCancelSaleProvider(currentTransaction.id)}
        onCancelSaleProviderLate={() => onCancelSaleProviderLate(currentTransaction.id)}
        id="CancelModal"
        isOpen={isCancelModalOpen}
        onCloseModal={() => setIsCancelModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        onRefetchTransaction={onRefetchTransaction}
        currentTransaction={currentTransaction}
      />
    </>
  );
};

const mapStateToProps = state => {
  const { cancelInProgress, cancelSaleError } = state.Transaction;

  return {
    cancelInProgress,
    cancelSaleError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onCancelOffer: transactionId => dispatch(cancelSpecialOffer(transactionId)),
  onCancelSaleCustomerWithRefund: transactionId =>
    dispatch(cancelSaleCustomerWithRefund(transactionId)),
  onCancelSaleCustomerWithoutRefund: transactionId =>
    dispatch(cancelSaleCustomerWithoutRefund(transactionId)),
  onCancelSaleCustomerLate: transactionId => dispatch(cancelSaleCustomerLate(transactionId)),
  onCancelSaleProviderEarly: transactionId => dispatch(cancelSaleProviderEarly(transactionId)),
  onCancelSaleProvider: transactionId => dispatch(cancelSaleProvider(transactionId)),
  onCancelSaleProviderLate: transactionId => dispatch(cancelSaleProviderLate(transactionId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CancelButton);
