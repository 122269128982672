/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
/**
 *
 *
 * THIS PROFILE PAGE IS DEPRECATED!!!
 * This page was moved to the new UI!
 * Make any necessary changes in lambtrust-ui
 *
 *
 *
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  AvatarLarge,
  NamedLink,
  ListingCard,
  Reviews,
  ReviewRating,
} from '../../components';
import { Container } from '../../components/Layout';
import LayoutProfile from '../../components/LayoutProfile/LayoutProfile';
import Modal from '../../components/Modal/Modal';
import SpeciesCard from '../../components/SectionBySpecies/SpeciesCard';
import { TopbarContainer, NotFoundPage } from '..';
import config from '../../config';
import { ReactComponent as IconStar } from '../../assets/icons/rating-star.svg';
import { ReactComponent as IconLodging } from '../../assets/icons/lodging.svg';
import { ReactComponent as IconHunting } from '../../assets/icons/crosshairs.svg';
import { ReactComponent as IconFishing } from '../../assets/icons/fishing.svg';
import whitetailImage from '../../assets/images/species/whitetail.png';
import turkeyImage from '../../assets/images/species/turkey.png';
import muleDeerImage from '../../assets/images/species/mule-deer.png';
import elkImage from '../../assets/images/species/elk.png';
import uplandImage from '../../assets/images/species/upland.png';
import waterfowlImage from '../../assets/images/species/waterfowl.png';
import hogImage from '../../assets/images/species/hog.png';
import whitetailImageWebP from '../../assets/images/species/whitetail.webp';
import turkeyImageWebP from '../../assets/images/species/turkey.webp';
import muleDeerImageWebP from '../../assets/images/species/mule-deer.webp';
import elkImageWebP from '../../assets/images/species/elk.webp';
import uplandImageWebP from '../../assets/images/species/upland.webp';
import antelopeImageWebP from '../../assets/images/species/antelope.webp';
import antelopeImage from '../../assets/images/species/antelopepng.png';
import prairieDogImageWebP from '../../assets/images/species/prairie-dog.webp';
import prairieDogImage from '../../assets/images/species/prairiedogpng.png';
import gopherImageWebP from '../../assets/images/species/gopher.webp';
import gopherImage from '../../assets/images/species/gopherpng.png';
import squirrelImageWebP from '../../assets/images/species/squirrel.webp';
import squirrelImage from '../../assets/images/species/squirrelpng.png';
import rabbitImageWebP from '../../assets/images/species/rabbit.webp';
import rabbitImage from '../../assets/images/species/rabbitpng.png';
import raccoonImageWebP from '../../assets/images/species/raccoon.webp';
import raccoonImage from '../../assets/images/species/raccoonpng.png';
import coyoteImageWebP from '../../assets/images/species/coyote.webp';
import coyoteImage from '../../assets/images/species/coyotepng.png';
import waterfowlImageWebP from '../../assets/images/species/waterfowl.webp';
import hogImageWebP from '../../assets/images/species/hog.webp';
import { speciesSearchGroups, profileHuntingWeapons } from '../../marketplace-custom-config';

import { loadData } from './ProfilePage.duck';
import css from './ProfilePage.css';

const { UUID } = sdkTypes;

const getSearchQuery = group => {
  return `?address=&bounds=69.96271704%2C-56.89389338%2C-18.35556663%2C-131.22017221&pub_gameTypeKeys=has_any:${speciesSearchGroups.hunt[
    group
  ]?.searchSpecies.join()}`;
};

const imageMap = {
  whitetail: {
    png: whitetailImage,
    webp: whitetailImageWebP,
  },
  turkey: {
    png: turkeyImage,
    webp: turkeyImageWebP,
  },
  'mule-deer': {
    png: muleDeerImage,
    webp: muleDeerImageWebP,
  },
  elk: {
    png: elkImage,
    webp: elkImageWebP,
  },
  pheasant: {
    png: uplandImage,
    webp: uplandImageWebP,
  },
  waterfowl: {
    png: waterfowlImage,
    webp: waterfowlImageWebP,
  },
  hog: {
    png: hogImage,
    webp: hogImageWebP,
  },
  antelope: {
    png: antelopeImage,
    webp: antelopeImageWebP,
  },
  'prairie-dog': {
    png: prairieDogImage,
    webp: prairieDogImageWebP,
  },
  gopher: {
    png: gopherImage,
    webp: gopherImageWebP,
  },
  squirrel: {
    png: squirrelImage,
    webp: squirrelImageWebP,
  },
  rabbit: {
    png: rabbitImage,
    webp: rabbitImageWebP,
  },
  raccoon: {
    png: raccoonImage,
    webp: raccoonImageWebP,
  },
  coyote: {
    png: coyoteImage,
    webp: coyoteImageWebP,
  },
};

export const ProfilePageComponent = props => {
  const {
    scrollingDisabled,
    currentUser,
    user,
    userShowError,
    listings,
    intl,
    reviews = [],
    reviewsRatingAvg,
    userListingRatings,
  } = props;

  const [isModalOpen, setOpenModal] = useState(false);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const profileUser = ensureUser(user);

  const isCurrentUser =
    ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;

  const { profile, createdAt } = profileUser.attributes;

  const { displayName, bio, publicData } = profile;
  const nameParts = displayName ? displayName.split(' ') : [];
  const firstName = nameParts[0];

  const usState = publicData?.usState?.selectedPlace?.address;
  const likeToFish = publicData?.likeToFish;
  const preferredHuntingWeapons =
    publicData && publicData.preferredHuntingWeapons ? publicData.preferredHuntingWeapons : [];
  let preferredHuntingWeaponsText;
  if (preferredHuntingWeapons.length > 0) {
    preferredHuntingWeaponsText = preferredHuntingWeapons
      .filter(weapon => profileHuntingWeapons[weapon])
      .map(weapon => profileHuntingWeapons[weapon].label)
      .join(', ');
  }

  const preferredHuntingSpecies =
    publicData && publicData.preferredHuntingSpecies ? publicData.preferredHuntingSpecies : [];

  const hasBio = !!bio;
  const hasListings = listings.length > 0;

  if (userShowError && userShowError.status === 404) {
    return <NotFoundPage />;
  }

  const schemaTitle = intl.formatMessage(
    {
      id: 'ProfilePage.schemaTitle',
    },
    {
      name: displayName,
      siteTitle: config.siteTitle,
    }
  );

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ProfilePage',
        name: schemaTitle,
      }}
      noindex
      nofollow
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ProfilePage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <Container>
            <LayoutProfile
              infoComponent={
                <>
                  <div className={css.profileInfoContainer}>
                    <div className={css.avatar} onClick={() => setOpenModal(true)} role="button">
                      <AvatarLarge className={css.avatar} user={profileUser} disableProfileLink />
                    </div>
                    <Modal
                      isOpen={isModalOpen}
                      onClose={() => setOpenModal(!isModalOpen)}
                      containerClassName={css.modalContainer}
                      customCloseButton={css.closeButton}
                      customCloseIcon={css.closeIcon}
                    >
                      <div className={css.avatarModalContainer}>
                        <AvatarLarge
                          className={css.avatarImage}
                          user={profileUser}
                          disableProfileLink
                        />
                      </div>
                    </Modal>
                    <div className={css.profileInfo}>
                      <div className={css.profileName}>{displayName}</div>

                      {createdAt && (
                        <div className={css.profileCreated}>
                          <FormattedMessage
                            id="ProfilePage.joined"
                            values={{ createdAt: format(createdAt, 'MMM DD, YYYY') }}
                          />
                        </div>
                      )}

                      {reviews?.length > 0 && (
                        <div className={css.profileReviews}>
                          <ReviewRating
                            rating={parseInt(reviewsRatingAvg, 10)}
                            className={css.reviewRating}
                            reviewStarClassName={css.reviewRatingStar}
                            gold
                          />

                          <FormattedMessage
                            id="ProfilePage.reviews"
                            values={{ count: reviews.length }}
                          />
                        </div>
                      )}

                      <div className={css.profileLink}>
                        {isCurrentUser ? (
                          <NamedLink className={css.profileLink} name="ProfileSettingsBasePage">
                            <FormattedMessage id="ProfilePage.editProfile" />
                          </NamedLink>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              }
              mainComponent={
                <>
                  <div className={css.profileContent}>
                    <div className={css.profileBio}>
                      <div className={css.profileBioHeader}>
                        <FormattedMessage id="ProfilePage.about" values={{ firstName }} />
                      </div>

                      {hasBio ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: bio,
                          }}
                        />
                      ) : (
                        <FormattedMessage id="ProfilePage.missingBio" values={{ firstName }} />
                      )}
                    </div>

                    {usState && (
                      <div className={css.profileInfoItem}>
                        <div className={css.profileInfoIcon}>
                          <IconLodging />
                        </div>
                        <FormattedMessage id="ProfilePage.livesIn" values={{ usState }} />
                      </div>
                    )}

                    {likeToFish && (
                      <div className={css.profileInfoItem}>
                        <div className={css.profileInfoIcon}>
                          <IconFishing />
                        </div>

                        <FormattedMessage id="ProfilePage.likeToFish" />
                      </div>
                    )}

                    {preferredHuntingWeapons.length > 0 && (
                      <div className={css.profileInfoItem}>
                        <div className={css.profileInfoIcon}>
                          <IconHunting />
                        </div>

                        <FormattedMessage
                          id="ProfilePage.huntsWith"
                          values={{ weapons: preferredHuntingWeaponsText }}
                        />
                      </div>
                    )}
                  </div>

                  {hasListings && (
                    <div className={css.profileListingsWrapper}>
                      <div className={css.profileListingsHeader}>
                        <FormattedMessage id="ProfilePage.myProperties" />
                      </div>

                      <div className={css.profileListings}>
                        {listings.map(l => {
                          const rating = (userListingRatings || []).find(
                            r => r.listingId === l.id.uuid
                          );

                          return (
                            <div className={css.profileListing} key={l.id.uuid}>
                              <ListingCard listing={l} rating={rating?.averageRating} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {preferredHuntingSpecies.length > 0 && (
                    <div className={css.profileSpeciesWrapper}>
                      <div className={css.profileSpeciesHeader}>
                        <FormattedMessage id="ProfilePage.huntedSpecies" values={{ firstName }} />
                      </div>

                      <div className={css.profileSpecies}>
                        {preferredHuntingSpecies.map(species => (
                          <SpeciesCard
                            key={species}
                            src={imageMap[species]}
                            title={speciesSearchGroups.hunt[species]?.label}
                            routeName="SearchPage"
                            className={css.profileSpeciesItem}
                            imageClassName={css.profileSpeciesItemImage}
                            bgClassName={css.profileSpeciesItemBg}
                            titleClassName={css.profileSpeciesItemTitle}
                            imageContainerClassName={css.profileSpeciesImageContainer}
                            routeTo={{
                              search: getSearchQuery(species),
                              gameFilter: species,
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  <div className={css.profileReviewsWrapper}>
                    <div className={css.profileReviewsHeader}>
                      <FormattedMessage
                        id="ProfilePage.reviewsHeader"
                        values={{ count: reviews?.length }}
                      />

                      {reviews?.length > 0 && (
                        <>
                          <span>
                            <IconStar className={css.reviewStarIcon} />
                          </span>
                          <span>{reviewsRatingAvg}</span>
                        </>
                      )}
                    </div>

                    <div className={css.profileReviewsTabsWrapper}>
                      <div className={css.profileReviewsTabsContent}>
                        <Reviews reviews={reviews} />
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </Container>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
};

const { bool, arrayOf } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    userId,
    userShowError,
    queryListingsError,
    userListingRefs,
    queryReviewsError,
    reviews,
    reviewsRatingAvg,
    userListingRatings,
  } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  const listings = getMarketplaceEntities(state, userListingRefs);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    queryListingsError,
    listings,
    queryReviewsError,
    reviews,
    reviewsRatingAvg,
    userListingRatings,
  };
};

const ProfilePage = compose(
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
  const id = new UUID(params.id);
  return loadData(id);
};

export default ProfilePage;
