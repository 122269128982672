/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { txIsDelivered } from '../../util/transaction';
import { PrimaryButton } from '../Button/Button';
import InboxTransitionBase from './InboxTransitionBase';
import css from './InboxTransitionBase.css';

const InboxTransitionComplete = ({ transaction, transition, onGoToReview, buttons, review }) => {
  // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
  const reviewPeriodJustStarted =
    review?.status === 'pending' || (!review && txIsDelivered(transaction));

  const handleLinkClick = e => {
    e.preventDefault();

    if (review?.status === 'pending') {
      window.location.href = `/v2/review/${review.authorRole}/${review.id}`;
    } else {
      onGoToReview();
    }
  };

  const reviewAsFirstLink = reviewPeriodJustStarted ? (
    <PrimaryButton onClick={handleLinkClick} isAnimating>
      <FormattedMessage id="BookingStatus.leaveAReview" />
    </PrimaryButton>
  ) : null;

  let header = (
    <FormattedMessage id="BookingStatus.transitionComplete" values={{ reviewLink: '' }} />
  );
  let description = null;

  switch (review?.status) {
    case 'pending':
      description = (
        <>
          <a
            href={`/v2/review/${review.authorRole}/${review.id}`}
            target="_blank"
            rel="noreferrer"
            className={css.link}
          >
            <FormattedMessage id="BookingStatus.transitionCompleteSubtext1" />
          </a>{' '}
          <FormattedMessage id="BookingStatus.transitionCompleteSubtext2" />
        </>
      );

      if (reviewAsFirstLink) {
        buttons.push(reviewAsFirstLink);
      }
      break;
    case 'submitted':
      header = <FormattedMessage id="BookingStatus.transitionCompleteReviewSubmitted" />;
      description = (
        <FormattedMessage
          id="BookingStatus.transitionCompleteReviewSubmittedSubtext"
          values={{ role: review.authorRole === 'customer' ? 'landowner' : 'guest' }}
        />
      );
      break;

    case 'public':
      header =
        review.authorRole === 'customer' ? (
          <FormattedMessage id="BookingStatus.transitionCompleteReviewPublicCustomer" />
        ) : (
          <FormattedMessage id="BookingStatus.transitionCompleteReviewPublicProvider" />
        );
      description =
        review.authorRole === 'customer' ? (
          <FormattedMessage id="BookingStatus.transitionCompleteReviewPublicSubtextCustomer" />
        ) : (
          <FormattedMessage id="BookingStatus.transitionCompleteReviewPublicSubtextProvider" />
        );
      break;
    case 'expired':
      header = (
        <FormattedMessage id="BookingStatus.transitionComplete" values={{ reviewLink: '' }} />
      );
      description = null;
      break;
    default:
      description = (
        <>
          <a href="#" onClick={handleLinkClick} className={css.link}>
            <FormattedMessage id="BookingStatus.transitionCompleteSubtext1" />
          </a>{' '}
          <FormattedMessage id="BookingStatus.transitionCompleteSubtext2" />
        </>
      );

      if (reviewAsFirstLink) {
        buttons.push(reviewAsFirstLink);
      }
  }

  return (
    <InboxTransitionBase
      header={header}
      date={transition.createdAt}
      direction="left"
      buttons={buttons}
    >
      {description}
    </InboxTransitionBase>
  );
};

const mapStateToProps = state => {
  const { review } = state.Transaction;

  return {
    review,
  };
};

export default compose(connect(mapStateToProps))(InboxTransitionComplete);
