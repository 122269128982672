import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';
import { differenceInDays } from 'date-fns';
import {
  theLastTransition,
  isRelevantPastTransition,
  getUserTxRole,
  getTxState,
  txRoleIsProvider,
  TRANSITION_ACCEPT,
  TRANSITION_LATE_BOOKING,
  TRANSITION_REFUND_PERIOD_OVER,
  TRANSITION_BOOKING_START_GETTING_CLOSER,
  TRANSITION_BOOKING_START,
  TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD_LATE_CANCEL,
  TRANSITION_OPERATOR_EARLY_CANCEL,
  TRANSITION_OPERATOR_CANCEL,
  TRANSITION_OPERATOR_LATE_CANCEL,
  TRANSITION_CUSTOMER_CANCEL_WITH_REFUND,
  TRANSITION_CUSTOMER_CANCEL_WITHOUT_REFUND,
  TRANSITION_CUSTOMER_LATE_CANCEL,
  TRANSITION_PROVIDER_EARLY_CANCEL,
  TRANSITION_PROVIDER_CANCEL,
  TRANSITION_PROVIDER_LATE_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE_GATED,
  TRANSITION_ENQUIRE_EXPIRE,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_PROVIDER_SPECIAL_OFFER,
  TRANSITION_CUSTOMER_ACCEPT_OFFER,
  TRANSITION_SPECIAL_CUSTOMER_PAYMENT,
  TRANSITION_CUSTOMER_DECLINE_OFFER,
  TRANSITION_OFFER_EXPIRE,
  TRANSITION_PROVIDER_CANCEL_OFFER,
  TRANSITION_PARTY_MEMBERS_SUBMITTED_1,
  TRANSITION_PARTY_MEMBERS_SUBMITTED_2,
  TRANSITION_PARTY_MEMBERS_SUBMITTED_3,
  TRANSITION_REQUEST_EXPIRES_SOON,
  TRANSITION_PROVIDER_SPECIAL_OFFER_AFTER_EXPIRED_ENQUIRY,
  TRANSITION_REQUESTED_CHANGES,
  TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD,
  TRANSITION_OPERATOR_CANCEL_AFTER_CHANGES,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  calculateRemainingTimeForTransaction,
  TRANSITION_ACCEPTED_CHANGES,
  TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD,
  TRANSITION_DECLINED_CHANGES,
  TRANSITION_DECLINED_CHANGES_AFTER_REFUND_PERIOD,
  TRANSITION_OPERATOR_CANCEL_ENQUIRY,
  TRANSITION_OPERATOR_CLOSE_ENQUIRY,
} from '../../util/transaction';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import routeConfiguration from '../../routeConfiguration';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';

import InboxTransitionProviderSpecialOffer from '../InboxTransition/InboxTransitionProviderSpecialOffer';
import InboxTransitionEnquireGated from '../InboxTransition/InboxTransitionEnquireGated';
import InboxTransitionEnquireExpire from '../InboxTransition/InboxTransitionEnquireExpire';
import InboxTransitionCustomerAcceptOffer from '../InboxTransition/InboxTransitionCustomerAcceptOffer';
import InboxTransitionSpecialCustomerPayment from '../InboxTransition/InboxTransitionSpecialCustomerPayment';
import InboxTransitionCustomerDeclineOffer from '../InboxTransition/InboxTransitionCustomerDeclineOffer';
import InboxTransitionOfferExpire from '../InboxTransition/InboxTransitionOfferExpire';
import InboxTransitionProviderCancelOffer from '../InboxTransition/InboxTransitionProviderCancelOffer';
import InboxTransitionConfirmPayment from '../InboxTransition/InboxTransitionConfirmPayment';
import InboxTransitionRequesrExpiresSoon from '../InboxTransition/InboxTransitionRequestExpiresSoon';
import InboxTransitionPartyMembersSubmitted1 from '../InboxTransition/InboxTransitionPartyMembersSubmitted1';
import InboxTransitionPartyMembersSubmitted2 from '../InboxTransition/InboxTransitionPartyMembersSubmitted2';
import InboxTransitionPartyMembersSubmitted3 from '../InboxTransition/InboxTransitionPartyMembersSubmitted3';
import InboxTransitionAccept from '../InboxTransition/InboxTransitionAccept';
import InboxTransitionDecline from '../InboxTransition/InboxTransitionDecline';
import InboxTransitionExpire from '../InboxTransition/InboxTransitionExpire';
import InboxTransitionLateBooking from '../InboxTransition/InboxTransitionLateBooking';
import InboxTransitionRefundPeriodOver from '../InboxTransition/InboxTransitionRefundPeriodOver';
import InboxTransitionBookingStartGettingCloser from '../InboxTransition/InboxTransitionBookingStartGettingCloser';
import InboxTransitionBookingStart from '../InboxTransition/InboxTransitionBookingStart';
import InboxTransitionOperatorEarlyCancel from '../InboxTransition/InboxTransitionOperatorEarlyCancel';
import InboxTransitionOperatorCancel from '../InboxTransition/InboxTransitionOperatorCancel';
import InboxTransitionOperatorLateCancel from '../InboxTransition/InboxTransitionOperatorLateCancel';
import InboxTransitionCustomerCancelWithRefund from '../InboxTransition/InboxTransitionCustomerCancelWithRefund';
import InboxTransitionCustomerCancelWithoutRefund from '../InboxTransition/InboxTransitionCustomerCancelWithoutRefund';
import InboxTransitionCustomerLateCancel from '../InboxTransition/InboxTransitionCustomerLateCancel';
import InboxTransitionProviderEarlyCancel from '../InboxTransition/InboxTransitionProviderEarlyCancel';
import InboxTransitionProviderCancel from '../InboxTransition/InboxTransitionProviderCancel';
import InboxTransitionProviderLateCancel from '../InboxTransition/InboxTransitionProviderLateCancel';
import InboxTransitionReviewByCustomerLateCancel from '../InboxTransition/InboxTransitionReviewByCustomerLateCancel';
import InboxTransitionExpireCustomerReviewPeriodLateCancel from '../InboxTransition/InboxTransitionExpireCustomerReviewPeriodLateCancel';
import InboxTransitionComplete from '../InboxTransition/InboxTransitionComplete';
import InboxTransitionReview1 from '../InboxTransition/InboxTransitionReview1';
import InboxTransitionReview2 from '../InboxTransition/InboxTransitionReview2';
import InboxTransitionExpireReviewPeriod from '../InboxTransition/InboxTransitionExpireReviewPeriod';
import SaleActionButtons from './Buttons/SaleActionButtons';
import BookNextTripButton from './Buttons/BookNextTripButton';
import InboxTransitionProviderSpecialOfferAfterExpiredEnquiry from '../InboxTransition/InboxTransitionProviderSpecialOfferAfterExpiredEnquiry';
import { formatMoney } from '../../util/currency';
import InboxTransitionRequestedChanges from '../InboxTransition/InboxTransitionRequestedChanges';
import { PrimaryButton } from '../Button/Button';
import InboxTransitionAcceptedChanges from '../InboxTransition/InboxTransitionAcceptedChanges';
import InboxTransitionDeclinedChanges from '../InboxTransition/InboxTransitionDeclinedChanges';

function formatMinutesToHours(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours}h ${remainingMinutes}m`;
}

const InboxDetailsManager = ({
  history,
  transaction,
  currentUser,
  intl,
  params,
  processTransitions,
  checkUserVerification,
  onChangeBookingDetails,
  onRefetchTransaction,
}) => {
  const [remainingTimeFormat, setRemainingTimeFormat] = useState(null);

  const currentTransaction = ensureTransaction(transaction);
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);
  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  const lastTransition = theLastTransition(transaction);

  useEffect(() => {
    let intervalId;

    const updateRemainingTime = () => {
      const remainingTime = calculateRemainingTimeForTransaction(currentTransaction, currentUser);

      const formattedTime = remainingTime.remainingMinutes
        ? formatMinutesToHours(remainingTime.remainingMinutes)
        : null;
      setRemainingTimeFormat(formattedTime);

      if (!formattedTime) {
        clearInterval(intervalId);
      }
    };

    // Update remaining time immediately
    updateRemainingTime();

    // Set interval to update remaining time every minute
    intervalId = setInterval(updateRemainingTime, 60000);

    return () => clearInterval(intervalId);
  }, [currentTransaction, currentUser]);

  if (!isRelevantPastTransition(lastTransition.transition) || !transitionsAvailable) {
    return null;
  }

  const transactionState = getTxState(
    intl,
    currentTransaction,
    ownRole,
    currentCustomer,
    currentProvider,
    processTransitions
  );

  const buttons = [];

  if (transactionState.showCustomerAcceptSpecialOffer) {
    buttons.push(
      <SaleActionButtons
        currentTransaction={currentTransaction}
        currentUser={currentUser}
        currentListing={currentListing}
        currentProvider={currentProvider}
        stateData={transactionState}
        isSpecialOffer
        checkUserVerification={checkUserVerification}
        onRefetchTransaction={onRefetchTransaction}
      />
    );
  }

  if (transactionState.showSaleButtons) {
    buttons.push(
      <SaleActionButtons
        currentTransaction={currentTransaction}
        stateData={transactionState}
        currentUser={currentUser}
        currentListing={currentListing}
        currentProvider={currentProvider}
        onRefetchTransaction={onRefetchTransaction}
      />
    );
  }

  if (
    [
      TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
      TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD_LATE_CANCEL,
      TRANSITION_EXPIRE,
    ].includes(lastTransition.transition)
  ) {
    buttons.push(<BookNextTripButton />);
  }

  if (transactionState.showViewRequestButton) {
    buttons.push(
      <PrimaryButton onClick={onChangeBookingDetails} isAnimating>
        <FormattedMessage id="TransactionPanel.viewRequestButton" />
      </PrimaryButton>
    );
  }

  const user = txRoleIsProvider(ownRole) ? currentCustomer : currentProvider;
  const deletedListing = intl.formatMessage({
    id: 'BookingStatus.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;

  const onGoToReview = () => {
    const { id } = currentTransaction;
    const { uuid } = id;
    // eslint-disable-next-line no-param-reassign
    params.slug = createSlug(currentListing.attributes.title);
    const isSportsman = ownRole === 'customer';
    const state = isSportsman
      ? {
          from: createResourceLocatorString('OrderPage', routeConfiguration(), params, {}),
        }
      : {
          from: createResourceLocatorString('SalePage', routeConfiguration(), params, {}),
        };

    return history.push(
      createResourceLocatorString(
        'ReviewPage',
        routeConfiguration(),
        { transactionRole: ownRole, id: uuid },
        {}
      ),
      state
    );
  };

  const totalPrice = get(currentTransaction, 'attributes.payoutTotal', 0);
  const earnings = totalPrice && formatMoney(intl, totalPrice);
  const bookingStart = get(currentTransaction, 'booking.attributes.start', null);
  const daysUntilStart = bookingStart ? differenceInDays(bookingStart, new Date()) : null;

  switch (lastTransition.transition) {
    case TRANSITION_PROVIDER_SPECIAL_OFFER:
      return (
        <InboxTransitionProviderSpecialOffer
          provider={currentProvider}
          transition={lastTransition}
          ownRole={ownRole}
          user={user}
          buttons={buttons}
        />
      );
    case TRANSITION_PROVIDER_SPECIAL_OFFER_AFTER_EXPIRED_ENQUIRY:
      return (
        <InboxTransitionProviderSpecialOfferAfterExpiredEnquiry
          provider={currentProvider}
          transition={lastTransition}
          ownRole={ownRole}
          user={user}
          buttons={buttons}
        />
      );
    case TRANSITION_ENQUIRE_GATED:
      return (
        <InboxTransitionEnquireGated
          transaction={transaction}
          ownRole={ownRole}
          buttons={buttons}
          user={user}
          intl={intl}
        />
      );
    case TRANSITION_ENQUIRE_EXPIRE:
      return (
        <InboxTransitionEnquireExpire
          transaction={transaction}
          ownRole={ownRole}
          buttons={buttons}
          user={user}
        />
      );
    case TRANSITION_CUSTOMER_ACCEPT_OFFER:
      return (
        <InboxTransitionCustomerAcceptOffer
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_SPECIAL_CUSTOMER_PAYMENT:
      return (
        <InboxTransitionSpecialCustomerPayment
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_CUSTOMER_DECLINE_OFFER:
      return (
        <InboxTransitionCustomerDeclineOffer
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_OFFER_EXPIRE:
      return <InboxTransitionOfferExpire transition={lastTransition} buttons={buttons} />;
    case TRANSITION_PROVIDER_CANCEL_OFFER:
      return (
        <InboxTransitionProviderCancelOffer
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_CONFIRM_PAYMENT:
      return (
        <InboxTransitionConfirmPayment
          user={user}
          provider={currentProvider}
          transition={lastTransition}
          ownRole={ownRole}
          listingTitle={listingTitle}
          buttons={buttons}
          earnings={earnings}
          remainingTimeFormat={remainingTimeFormat}
        />
      );
    case TRANSITION_PARTY_MEMBERS_SUBMITTED_1:
      return (
        <InboxTransitionPartyMembersSubmitted1
          user={user}
          provider={currentProvider}
          transition={lastTransition}
          ownRole={ownRole}
          listingTitle={listingTitle}
          buttons={buttons}
          earnings={earnings}
        />
      );
    case TRANSITION_REQUEST_EXPIRES_SOON:
      return (
        <InboxTransitionRequesrExpiresSoon
          user={user}
          provider={currentProvider}
          customer={currentCustomer}
          transition={lastTransition}
          ownRole={ownRole}
          listingTitle={listingTitle}
          buttons={buttons}
          earnings={earnings}
          remainingTimeFormat={remainingTimeFormat}
        />
      );
    case TRANSITION_ACCEPT:
      return (
        <InboxTransitionAccept
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_REQUESTED_CHANGES:
    case TRANSITION_REQUESTED_CHANGES_AFTER_REFUND_PERIOD:
      return (
        <InboxTransitionRequestedChanges
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_ACCEPTED_CHANGES:
    case TRANSITION_ACCEPTED_CHANGES_AFTER_REFUND_PERIOD:
      return (
        <InboxTransitionAcceptedChanges
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
          daysUntilStart={daysUntilStart}
        />
      );
    case TRANSITION_DECLINED_CHANGES:
    case TRANSITION_DECLINED_CHANGES_AFTER_REFUND_PERIOD:
      return (
        <InboxTransitionDeclinedChanges
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
          daysUntilStart={daysUntilStart}
        />
      );
    case TRANSITION_PARTY_MEMBERS_SUBMITTED_2:
      return (
        <InboxTransitionPartyMembersSubmitted2
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_DECLINE:
      return (
        <InboxTransitionDecline
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_EXPIRE:
      return (
        <InboxTransitionExpire
          user={user}
          provider={currentProvider}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_LATE_BOOKING:
      return <InboxTransitionLateBooking transition={lastTransition} buttons={buttons} />;
    case TRANSITION_PARTY_MEMBERS_SUBMITTED_3:
      return (
        <InboxTransitionPartyMembersSubmitted3 transition={lastTransition} buttons={buttons} />
      );
    case TRANSITION_REFUND_PERIOD_OVER:
      return (
        <InboxTransitionRefundPeriodOver
          transition={lastTransition}
          user={user}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_BOOKING_START_GETTING_CLOSER:
      return (
        <InboxTransitionBookingStartGettingCloser transition={lastTransition} buttons={buttons} />
      );
    case TRANSITION_BOOKING_START:
      return (
        <InboxTransitionBookingStart
          transition={lastTransition}
          buttons={buttons}
          ownRole={ownRole}
        />
      );
    case TRANSITION_OPERATOR_EARLY_CANCEL:
      return <InboxTransitionOperatorEarlyCancel transition={lastTransition} buttons={buttons} />;
    case TRANSITION_OPERATOR_CANCEL:
    case TRANSITION_OPERATOR_CANCEL_AFTER_CHANGES:
    case TRANSITION_OPERATOR_CANCEL_ENQUIRY:
    case TRANSITION_OPERATOR_CLOSE_ENQUIRY:
      return <InboxTransitionOperatorCancel transition={lastTransition} buttons={buttons} />;
    case TRANSITION_OPERATOR_LATE_CANCEL:
      return <InboxTransitionOperatorLateCancel transition={lastTransition} buttons={buttons} />;
    case TRANSITION_CUSTOMER_CANCEL_WITH_REFUND:
      return (
        <InboxTransitionCustomerCancelWithRefund
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_CUSTOMER_CANCEL_WITHOUT_REFUND:
      return (
        <InboxTransitionCustomerCancelWithoutRefund
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_CUSTOMER_LATE_CANCEL:
      return (
        <InboxTransitionCustomerLateCancel
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_PROVIDER_EARLY_CANCEL:
      return (
        <InboxTransitionProviderEarlyCancel
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_PROVIDER_CANCEL:
      return (
        <InboxTransitionProviderCancel
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_PROVIDER_LATE_CANCEL:
      return (
        <InboxTransitionProviderLateCancel
          transaction={currentTransaction}
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          onGoToReview={onGoToReview}
          buttons={buttons}
        />
      );
    case TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL:
      return (
        <InboxTransitionReviewByCustomerLateCancel
          user={user}
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD_LATE_CANCEL:
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
      return (
        <InboxTransitionExpireCustomerReviewPeriodLateCancel
          transition={lastTransition}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_COMPLETE:
      return (
        <InboxTransitionComplete
          transaction={currentTransaction}
          transition={lastTransition}
          onGoToReview={onGoToReview}
          buttons={buttons}
        />
      );
    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      return (
        <InboxTransitionReview1
          transaction={currentTransaction}
          transition={lastTransition}
          onGoToReview={onGoToReview}
          user={user}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
      return (
        <InboxTransitionReview2
          transition={lastTransition}
          user={user}
          ownRole={ownRole}
          buttons={buttons}
        />
      );
    case TRANSITION_EXPIRE_REVIEW_PERIOD:
      return <InboxTransitionExpireReviewPeriod transition={lastTransition} buttons={buttons} />;
    default:
      return null;
  }
};

export default injectIntl(InboxDetailsManager);
