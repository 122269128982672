import React from 'react';
import { bool, func } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { ResponsiveModal, TertiaryButton } from '..';

import css from './ArchiveModal.css';

const ArchiveModal = ({
  id,
  intl,
  isOpen,
  onCloseModal,
  onManageDisableScrolling,
  disabled,
  inProgress,
  onArchive,
}) => {
  return (
    <ResponsiveModal
      containerClassName={css.container}
      className={css.root}
      id={id}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      content={
        <div className={css.header}>
          <FormattedMessage id="ArchiveModal.header" />
        </div>
      }
      buttons={
        <TertiaryButton
          className={css.buttonYes}
          onClick={onArchive}
          disabled={disabled}
          inProgress={inProgress}
          isFullWidth
        >
          <FormattedMessage id="ArchiveModal.buttonYes" />
        </TertiaryButton>
      }
      closeButtonMessage={intl.formatMessage({ id: 'ArchiveModal.close' })}
    />
  );
};

ArchiveModal.defaultProps = {
  disabled: false,
  inProgress: false,
};

ArchiveModal.propTypes = {
  intl: intlShape.isRequired,
  onCloseModal: func.isRequired,
  onArchive: func.isRequired,
  disabled: bool,
  inProgress: bool,
};

export default injectIntl(ArchiveModal);
