/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { bool, object, oneOfType, string } from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { get } from 'lodash';
import format from 'date-fns/format';
import { AvatarMedium, NamedLink } from '..';
import { InlineTextButton } from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';
import { propTypes } from '../../util/types';
import { ReactComponent as IconStar } from '../../assets/icons/rating-star-gold.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/trust-check.svg';

import css from './TrustCard.css';
import { useUserReviews } from '../../hooks/queries/useUserReviews';

const UserTrust = ({
  otherUser,
  userIsLandowner,
  currentTransaction,
  repeatGuest,
  displayName,
  insuranceOffered,
}) => {
  const { publicData, metadata } = otherUser?.attributes?.profile;

  const landownerBookings = get(metadata, 'allTimeStats.booking.accepted', null);
  const completedBookings = get(metadata, 'allTimeStatsAsCustomer.completedBookings', null);
  const usState = get(publicData, 'usState.selectedPlace.address', null);
  const createdAt = get(otherUser, 'attributes.createdAt', null);
  const hasVerifiedId =
    get(currentTransaction, 'attributes.protectedData.verifiedId.status', null) === 'verified';
  const insuranceAccepted = get(
    currentTransaction,
    'attributes.protectedData.tripInsurance',
    false
  );

  return (
    <div className={css.trustContainer}>
      {!userIsLandowner ? (
        <>
          <span className={css.indvidualCheck}>
            <CheckIcon className={css.verifictionIcon} />
            <FormattedMessage id="TrustCard.landownerEmailVerified" />
          </span>
          {landownerBookings > 0 && (
            <span className={css.indvidualCheck}>
              <CheckIcon className={css.verifictionIcon} />
              <FormattedMessage
                id="TrustCard.landownerBookings"
                values={{ count: landownerBookings }}
              />
            </span>
          )}
        </>
      ) : (
        <>
          {hasVerifiedId && (
            <span className={css.indvidualCheck}>
              <CheckIcon className={css.verifictionIcon} />
              <FormattedMessage id="TrustCard.guestVerified" />
            </span>
          )}
          {completedBookings > 0 && (
            <span className={css.indvidualCheck}>
              <CheckIcon className={css.verifictionIcon} />
              <FormattedMessage id="TrustCard.guestTrips" values={{ count: completedBookings }} />
            </span>
          )}
        </>
      )}
      {usState && (
        <span className={css.indvidualCheck}>
          <CheckIcon className={css.verifictionIcon} />
          <FormattedMessage
            id="TrustCard.usState"
            values={{
              state: usState,
            }}
          />
        </span>
      )}
      {createdAt && (
        <span className={css.indvidualCheck}>
          <CheckIcon className={css.verifictionIcon} />
          <FormattedMessage
            id="TrustCard.joined"
            values={{ createdAt: format(createdAt, 'MMM YYYY') }}
          />
        </span>
      )}
      <NamedLink
        className={css.profileLink}
        name="ProfilePage"
        params={{ id: otherUser.id.uuid }}
        target="_blank"
      >
        <FormattedMessage id="TrustCard.viewProfile" />
      </NamedLink>

      {userIsLandowner && (
        <div className={css.boxContainerWrapper}>
          {userIsLandowner && repeatGuest && (
            <div className={css.boxContainer}>
              <FormattedHTMLMessage
                id="TrustCard.repeatGuest"
                values={{ displayName: displayName.split(' ')[0] }}
              />
            </div>
          )}

          {userIsLandowner && insuranceOffered && (
            <div className={css.boxContainer}>
              <FormattedHTMLMessage
                id={
                  insuranceAccepted
                    ? 'TrustCard.landownerInsuranceAccepted'
                    : 'TrustCard.landownerInsuranceDeclined'
                }
                values={{ guest: displayName.split(' ')[0] }}
              />
              <Tooltip
                hideArrow
                placement="bottom-start"
                trigger="click"
                tooltip={
                  <div className={css.tooltipContent}>
                    <FormattedHTMLMessage
                      id={
                        insuranceAccepted
                          ? 'TrustCard.landownerInsuranceAcceptedMoreInfo'
                          : 'TrustCard.landownerInsuranceDeclinedMoreInfo'
                      }
                    />
                  </div>
                }
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: () => {
                        return [-15, 10];
                      },
                    },
                  },
                ]}
              >
                <InlineTextButton className={css.moreInfo}>
                  <FormattedMessage id="TrustCard.moreInfo" />
                </InlineTextButton>
              </Tooltip>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

UserTrust.defaultProps = {
  otherUser: {},
  userIsLandowner: false,
  currentTransaction: null,
  displayName: '',
  insuranceOffered: false,
};

UserTrust.propTypes = {
  otherUser: object,
  userIsLandowner: bool,
  currentTransaction: object,
  displayName: string,
  insuranceOffered: bool,
};

const TrustCard = props => {
  const {
    landowner,
    sportsman,
    currentUser,
    currentTransaction,
    onFetchTransactionsTogether,
    currentUsersHaveOrdersTogether,
    insuranceOffered,
  } = props;

  useEffect(() => {
    onFetchTransactionsTogether(sportsman?.id?.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userIsLandowner = landowner?.id?.uuid === currentUser?.id?.uuid;
  const otherUser = userIsLandowner ? sportsman : landowner;

  const { data: reviewsData } = useUserReviews({
    userId: otherUser?.id?.uuid,
  });

  const { displayName } = otherUser.attributes.profile;

  const reviewsLength = reviewsData?.reviews?.length;
  const hasReviews = reviewsLength > 0;
  const reviewsRatingAvg = reviewsData?.reviewsRatingAvg;

  return (
    <div className={css.userCardContainer}>
      <div className={css.header}>
        <FormattedMessage
          id={userIsLandowner ? 'TrustCard.headerLandowner' : 'TrustCard.headerGuest'}
        />
      </div>

      <div className={css.content}>
        <div className={css.flexContainer}>
          <div className={css.avatarContainer}>
            <AvatarMedium className={css.avatar} user={otherUser} />
          </div>
          <div className={css.hostedContainer}>
            <h3 className={css.userName}>{displayName}</h3>

            {hasReviews && (
              <div className={css.reviewWrapper}>
                <IconStar className={css.reviewStarIcon} />

                <span className={css.reviewAvg}>{reviewsRatingAvg && reviewsRatingAvg}</span>
                <span className={css.reviewTotal}>
                  (
                  <FormattedMessage
                    id="TrustCard.reviews"
                    values={{
                      count: reviewsLength,
                    }}
                  />
                  )
                </span>
              </div>
            )}
          </div>
        </div>

        <UserTrust
          otherUser={otherUser}
          userIsLandowner={userIsLandowner}
          currentTransaction={currentTransaction}
          repeatGuest={currentUsersHaveOrdersTogether}
          displayName={displayName}
          insuranceOffered={insuranceOffered}
        />
      </div>
    </div>
  );
};

TrustCard.defaultProps = {
  landowner: null,
  sportsman: null,
  currentUser: null,
  currentUsersHaveOrdersTogether: false,
  insuranceOffered: false,
};

TrustCard.propTypes = {
  landowner: oneOfType([propTypes.user, propTypes.currentUser]),
  sportsman: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  currentUsersHaveOrdersTogether: bool,
  insuranceOffered: bool,
};

export default TrustCard;
