import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconReviewStar } from '..';
import { REVIEW_RATINGS } from '../../util/types';

import css from './ReviewRating.css';

const ReviewRating = props => {
  const { className, rootClassName, reviewStarClassName, rating, gold } = props;
  const classes = classNames(rootClassName, className);
  const reviewStarClasses = classNames(reviewStarClassName, css.reviewStar);

  const stars = REVIEW_RATINGS;

  const roundUpRating = Math.round(rating);

  return (
    <span className={classes}>
      {stars.map(star => (
        <IconReviewStar
          key={`star-${star}`}
          className={reviewStarClasses}
          isFilled={star <= roundUpRating}
          gold={gold}
        />
      ))}
    </span>
  );
};

ReviewRating.defaultProps = {
  rootClassName: null,
  className: null,
  reviewStarClassName: null,
};

const { string, oneOf } = PropTypes;

ReviewRating.propTypes = {
  rating: oneOf(REVIEW_RATINGS).isRequired,
  reviewStartClassName: string,
  rootClassName: string,
  className: string,
};

export default ReviewRating;
