/* eslint-disable no-param-reassign */
import pick from 'lodash/pick';
import get from 'lodash/get';
import axios from 'axios';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { getUserToken } from '../../util/api';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { buildTimeSlotsRequests } from '../../util/listing';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESSS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_TRANSACTION_REQUEST = 'app/ListingPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/ListingPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/ListingPage/FETCH_TRANSACTION_ERROR';

export const FETCH_ACTIVITY_DATA_SUCCESS = '/app/ListingPage/FETCH_ACTIVITY_DATA_SUCCESS';
export const FETCH_ACTIVITY_DATA_REQUEST = '/app/ListingPage/FETCH_ACTIVITY_DATA_REQUEST';

export const FETCH_ACTIVITY_LOG_LIKES_SUCCESS = '/app/ListingPage/FETCH_ACTIVITY_LOG_LIKES_SUCCESS';
export const FETCH_ACTIVITY_LOG_LIKES_REQUEST = '/app/ListingPage/FETCH_ACTIVITY_LOG_LIKES_REQUEST';
export const FETCH_PACKAGE_ACTIVITY_DATA_REQUEST =
  '/app/ListingPage/FETCH_PACKAGE_ACTIVITY_DATA_REQUEST';
export const FETCH_PACKAGE_ACTIVITY_DATA_SUCCESS =
  '/app/ListingPage/FETCH_PACKAGE_ACTIVITY_DATA_SUCCESS';

export const FETCH_REVIEWS_DATA_REQUEST = '/app/ListingPage/FETCH_REVIEWS_DATA_REQUEST';
export const FETCH_REVIEWS_DATA_SUCCESS = '/app/ListingPage/FETCH_REVIEWS_DATA_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingInitial: true,
  showListingInProgress: false,
  showListingError: null,
  reviews: [],
  customerReviews: [],
  providerReviews: [],
  reviewsMeta: null,
  reviewsByIds: [],
  fetchReviewsError: null,
  fetchReviewByIdError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  activityFeedData: [],
  activityPackageFeedData: [],
  fetchingActivityFeedDataInProgress: false,
  fetchingPackageActivityFeedDataInProgress: false,
  fetchingReviewsDataInProgress: false,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return {
        ...state,
        id: payload.id,
        showListingError: null,
        showListingInProgress: true,
        showListingInitial: false,
      };

    case SHOW_LISTING_SUCCESS:
      return { ...state, showListingError: null, showListingInProgress: false };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload, showListingInProgress: true };
    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };
    case FETCH_ACTIVITY_DATA_REQUEST:
      return {
        ...state,
        fetchingActivityFeedDataInProgress: true,
      };

    case FETCH_ACTIVITY_LOG_LIKES_REQUEST:
      return {
        ...state,
        fetchingActivityLogLikesInProgress: true,
      };
    case FETCH_PACKAGE_ACTIVITY_DATA_REQUEST:
      return {
        ...state,
        fetchingPackageActivityFeedDataInProgress: true,
      };
    case FETCH_ACTIVITY_LOG_LIKES_SUCCESS: {
      return {
        ...state,
        activityLogLikes: payload,
        fetchingActivityLogLikesInProgress: false,
      };
    }
    case FETCH_ACTIVITY_DATA_SUCCESS: {
      const existingRecords = state.activityFeedData?.items || [];
      let newRecords = [...existingRecords, ...payload?.items];
      const listingId = state.id.uuid;
      const seenItems = {};
      newRecords = newRecords?.filter(item => {
        const keeper = item.listingId === listingId && !seenItems[item.id];
        seenItems[item.id] = true;
        return keeper;
      });
      return {
        ...state,
        activityFeedData: {
          items: newRecords,
          limit: payload?.limit,
          offset: payload?.offset,
          totalRecordCount: payload?.totalRecordCount,
        },
        fetchingActivityFeedDataInProgress: false,
      };
    }
    case FETCH_PACKAGE_ACTIVITY_DATA_SUCCESS: {
      const existingRecords = state.activityPackageFeedData?.items || [];
      let newRecords = [...existingRecords, ...payload?.items];
      const listingId = state.id.uuid;
      const seenItems = {};
      newRecords = newRecords?.filter(item => {
        const keeper = item.listingId === listingId && !seenItems[item.id];
        seenItems[item.id] = true;
        return keeper;
      });
      return {
        ...state,
        activityPackageFeedData: {
          items: newRecords,
          limit: payload?.limit,
          offset: payload?.offset,
          totalRecordCount: payload?.totalRecordCount,
        },
        fetchingPackageActivityFeedDataInProgress: false,
      };
    }

    case FETCH_REVIEWS_DATA_REQUEST:
      return {
        ...state,
        fetchingReviewsDataInProgress: true,
      };

    case FETCH_REVIEWS_DATA_SUCCESS: {
      return {
        ...state,
        reviews: payload.reviews,
        customerReviews: payload.customerReviews,
        providerReviews: payload.providerReviews,
        reviewsMeta: payload.reviewsMeta,
        fetchingReviewsDataInProgress: false,
      };
    }

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingSuccess = id => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = error => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const fetchActivityFeedDataSuccess = item => ({
  type: FETCH_ACTIVITY_DATA_SUCCESS,
  payload: item,
});

export const fetchActivityFeedDataRequest = () => ({
  type: FETCH_ACTIVITY_DATA_REQUEST,
});

export const fetchActivityLogLikesSuccess = item => ({
  type: FETCH_ACTIVITY_LOG_LIKES_SUCCESS,
  payload: item,
});

export const fetchActivityLogLikesRequest = () => ({
  type: FETCH_ACTIVITY_LOG_LIKES_REQUEST,
});

export const fetchPackageActivityFeedDataRequest = () => ({
  type: FETCH_PACKAGE_ACTIVITY_DATA_REQUEST,
});

export const fetchPackageActivityFeedDataSuccess = item => ({
  type: FETCH_PACKAGE_ACTIVITY_DATA_SUCCESS,
  payload: item,
});

export const fetchReviewsDataRequest = () => ({
  type: FETCH_REVIEWS_DATA_REQUEST,
});

export const fetchReviewsDataSuccess = reviews => ({
  type: FETCH_REVIEWS_DATA_SUCCESS,
  payload: reviews,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());

  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      dispatch(showListingSuccess(listingId));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchTimeSlots = (listingId, listingEndDate) => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  const timeSlots = [];
  const reqs = buildTimeSlotsRequests(listingId, sdk, listingEndDate);

  reqs.map(req => {
    return req
      .then(slots => {
        timeSlots.push(...slots);

        dispatch(fetchTimeSlotsSuccess(timeSlots));
      })
      .catch(e => {
        dispatch(fetchTimeSlotsError(storableError(e)));
      });
  });

  return Promise.resolve();
};

export const fetchActivityLogLikes = activityLogId => async dispatch => {
  dispatch(fetchActivityLogLikesRequest());
  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        query ActivityLogLikes($activityLogId: String) {
          ActivityLogLikes(input: { activityLogId: $activityLogId }) {
            likes {
              userId
            }
            totalLikes
          }
        }`,
        variables: { activityLogId },
      },
      {
        headers: {
          'X-Api-Key': `${process.env.REACT_APP_ACTIVITY_GRAPHQL_API_KEY}`,
        },
      }
    );
    const likesData = response.data?.data?.ActivityLogLikes;
    dispatch(fetchActivityLogLikesSuccess(likesData));
    return likesData;
  } catch (error) {
    return error;
  }
};

export const likeActivityLogItem = async (activityLogId, userId) => {
  const token = getUserToken();

  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        mutation likeActivityLogItem($activityLogId: ID!, $userId: ID!) {
          LikeActivityLogItem(input: { activityLogId: $activityLogId, userId: $userId })
        }`,
        variables: { activityLogId, userId },
      },
      {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const unlikeActivityLogItem = async (activityLogId, userId) => {
  const token = getUserToken();

  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        mutation unlikeActivityLogItem($activityLogId: ID!, $userId: ID!) {
          UnlikeActivityLogItem(input: { activityLogId: $activityLogId, userId: $userId })
        }`,
        variables: { activityLogId, userId },
      },
      {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchActivityFeed = (listingId, limit, offset) => async dispatch => {
  dispatch(fetchActivityFeedDataRequest());
  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        query FetchActivityFeedDataForListing($subjectIds: [ID!]!, $limit: Int!, $offset: Int!, $activityTypes: [ActivityType!]) {
          ActivityLogItems(input: {subjectIds: $subjectIds, limit: $limit, offset: $offset, activityTypes: $activityTypes}) {
            items {
              __typename
              ... on ListingStatusActivity {
                id
                createdAt
                description
                listingId
                photos {
                  description
                  path
                }
                tags
              }
              ... on NewPackageActivity {
                id
                author {
                  id
                }
                createdAt
                listingId
                packageId
                tags
              }
              ... on NewReviewActivity {
                id
                author {
                  id
                }
                listingId
                review {
                  author {
                    id
                  }
                  createdAt
                  id
                  transaction {
                    id
                  }
                }
                tags
              }
              ... on NewPackagePhotoActivity {
                id
                author {
                  id
                }
                createdAt
                listingId
                packageId
                photos {
                  description
                  path
                }
                tags
              }
            }
            limit
            offset
            totalRecordCount
          }
        }`,
        variables: {
          subjectIds: [listingId],
          limit,
          offset,
          activityTypes: [
            'LISTING_STATUS_UPDATE',
            'NEW_PACKAGE',
            'NEW_REVIEW',
            'NEW_PACKAGE_PHOTO',
          ],
        },
      },
      {
        headers: {
          'X-Api-Key': `${process.env.REACT_APP_ACTIVITY_GRAPHQL_API_KEY}`,
        },
      }
    );
    dispatch(fetchActivityFeedDataSuccess(response.data?.data?.ActivityLogItems));
    return Promise.resolve();
  } catch (error) {
    return error;
  }
};

export const fetchPackageActivityFeed = (packageId, limit, offset) => async dispatch => {
  dispatch(fetchPackageActivityFeedDataRequest());
  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        query FetchActivityFeedDataForListing($packageIds: [ID!]!, $limit: Int!, $offset: Int!, $activityTypes: [ActivityType!]) {
          PackageActivityLogItems(input: {packageIds: $packageIds, limit: $limit, offset: $offset, activityTypes: $activityTypes}) {
            items {
              __typename
              ... on ListingStatusActivity {
                id
                createdAt
                description
                listingId
                photos {
                  description
                  path
                }
                tags
              }
              ... on NewPackageActivity {
                id
                author {
                  id
                }
                createdAt
                listingId
                packageId
                tags
              }
              ... on NewReviewActivity {
                id
                author {
                  id
                }
                listingId
                review {
                  author {
                    id
                  }
                  createdAt
                  id
                  transaction {
                    id
                  }
                }
                tags
              }
              ... on NewPackagePhotoActivity {
                id
                author {
                  id
                }
                createdAt
                listingId
                packageId
                photos {
                  description
                  path
                }
                tags
              }
            }
            limit
            offset
            totalRecordCount
          }
        }`,
        variables: {
          packageIds: [packageId],
          limit,
          offset,
          activityTypes: [
            'LISTING_STATUS_UPDATE',
            'NEW_PACKAGE',
            'NEW_REVIEW',
            'NEW_PACKAGE_PHOTO',
          ],
        },
      },
      {
        headers: {
          'X-Api-Key': `${process.env.REACT_APP_ACTIVITY_GRAPHQL_API_KEY}`,
        },
      }
    );
    dispatch(fetchPackageActivityFeedDataSuccess(response.data?.data?.PackageActivityLogItems));
    return Promise.resolve();
  } catch (error) {
    return error;
  }
};

export const fetchReviewsData = (listingId, packageId) => async (dispatch, getState, sdk) => {
  dispatch(fetchReviewsDataRequest());
  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        query FetchListingReviews($listingId: ID!, $packageId: ID) {
          ListingReviews(input: { listingId: $listingId, packageId: $packageId }) {
            items {
              customer {
                authorId
                authorRole
                createdAt
                id
                listingId
                packageId
                rating
                reviewContent
                reviewQuestions
                transactionId
                status
                photos
              }
              provider {
                authorId
                authorRole
                createdAt
                id
                listingId
                packageId
                rating
                reviewContent
                reviewQuestions
                transactionId
                status
                photos
              }
            }
            meta {
              customerAverageRating
              providerAverageRating
            }
          }
        }`,
        variables: {
          listingId,
          packageId: packageId || null,
        },
      },
      {
        headers: {
          'X-Api-Key': `${process.env.REACT_APP_ACTIVITY_GRAPHQL_API_KEY}`,
        },
      }
    );

    const reviews = response.data?.data?.ListingReviews?.items;
    const reviewsMeta = response.data?.data?.ListingReviews?.meta;

    // Unique author ids
    const reviewsAuthorsIdsSet = new Set();

    reviews.forEach(item => {
      if (item.customer) {
        reviewsAuthorsIdsSet.add(item.customer.authorId);
      }
      if (item.provider) {
        reviewsAuthorsIdsSet.add(item.provider.authorId);
      }
    });

    const reviewsAuthorsIds = Array.from(reviewsAuthorsIdsSet);

    // Fetch user data for each author ID
    const authorDataPromises = reviewsAuthorsIds.map(userId =>
      sdk.users.show({
        id: userId,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      })
    );
    const authorsData = (await Promise.all(authorDataPromises)).map(authorResponse => {
      const entities = denormalisedResponseEntities(authorResponse);
      const user = entities[0];

      return user;
    });

    // Create a map of author data for quick lookup
    const authorsMap = new Map();
    authorsData.forEach(author => {
      authorsMap.set(author.id.uuid, author);
    });

    const updatedReviews = reviews.map(review => {
      if (review.customer && authorsMap.has(review.customer.authorId)) {
        review.customer.author = authorsMap.get(review.customer.authorId);
      }
      if (review.provider && authorsMap.has(review.provider.authorId)) {
        review.provider.author = authorsMap.get(review.provider.authorId);
      }
      return review;
    });

    const customerReviews = updatedReviews
      .filter(item => !!item.customer)
      .map(item => item.customer);
    const providerReviews = updatedReviews
      .filter(item => !!item.provider)
      .map(item => item.provider);

    reviewsMeta.customerAverageRating = parseFloat(reviewsMeta.customerAverageRating).toFixed(1);
    reviewsMeta.providerAverageRating = parseFloat(reviewsMeta.providerAverageRating).toFixed(1);

    // Dispatching action to handle successful data retrieval
    dispatch(
      fetchReviewsDataSuccess({
        reviews: updatedReviews,
        customerReviews,
        providerReviews,
        reviewsMeta,
      })
    );
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const loadData = params => async dispatch => {
  const { id, packageId } = params;
  const listingId = new UUID(id);

  // Rest of the listing data loading
  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    await dispatch(showListing(listingId, true));
    return;
  }

  const listingResponse = await dispatch(showListing(listingId));

  if (config.enableAvailability && packageId) {
    const listing = listingResponse.data.data;
    const packages = get(listing, 'attributes.publicData.packages', []);
    const packageItem = packages.find(item => item.id === packageId);
    const { availableDateTo } = packageItem;
    await dispatch(fetchTimeSlots(listingId, availableDateTo));
  }

  // Only fetch listing-specific reviews if needed
  await dispatch(fetchReviewsData(id, packageId));
};
