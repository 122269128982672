import React, { useState } from 'react';
import { bool, func, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  required,
  validateIfEmpty,
  validCoordinates,
  validateInSupportedCountries,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  PrimaryButton,
  FieldTextInput,
  EditListingActions,
  EditListingError,
  EditListingFieldWrapper,
  EditListingSection,
} from '../../components';

// eslint-disable-next-line no-unused-vars
export const EditListingLocationFormComponent = ({ backLink, isPublished, ...formProps }) => {
  const { coordinates } = formProps.initialValues;

  const [showLatLong, setShowLatLong] = useState(!!coordinates);

  return (
    <FinalForm
      {...formProps}
      render={fieldRenderProps => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          form,
        } = fieldRenderProps;
        const classes = classNames(className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <EditListingSection>
              <EditListingError fetchErrors={fetchErrors} />

              <EditListingFieldWrapper>
                <LocationAutocompleteInputField
                  autoFocus
                  name="location"
                  label={intl.formatMessage({ id: 'EditListingLocationForm.address' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingLocationForm.addressPlaceholder',
                  })}
                  help={intl.formatMessage({
                    id: 'EditListingLocationForm.addressHelp',
                  })}
                  useDefaultPredictions={false}
                  format={null}
                  valueFromForm={values.location}
                  validate={composeValidators(
                    validateIfEmpty(
                      form,
                      'coordinates',
                      intl.formatMessage({
                        id: 'EditListingLocationForm.addressRequired',
                      }),
                      autocompleteSearchRequired
                    ),
                    validateIfEmpty(
                      form,
                      'coordinates',
                      intl.formatMessage({
                        id: 'EditListingLocationForm.addressNotRecognized',
                      }),
                      autocompletePlaceSelected
                    )
                  )}
                  fullAddress
                  usState
                  allowCustomLocation
                  showButton={false}
                  onPredictionSelected={prediction => {
                    setShowLatLong(prediction === null);
                  }}
                />
              </EditListingFieldWrapper>

              {showLatLong && (
                <EditListingFieldWrapper>
                  <FieldTextInput
                    autoFocus
                    type="text"
                    name="coordinates"
                    id="coordinates"
                    label={intl.formatMessage({ id: 'EditListingLocationForm.coordinates' })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({ id: 'EditListingLocationForm.coordinatesRequired' })
                      ),
                      validCoordinates(
                        intl.formatMessage({ id: 'EditListingLocationForm.coordinatesInvalid' })
                      ),
                      validateInSupportedCountries(
                        intl.formatMessage({
                          id: 'EditListingLocationForm.coordinatesOutsideRange',
                        })
                      )
                    )}
                  />
                </EditListingFieldWrapper>
              )}

              <EditListingFieldWrapper>
                <FieldTextInput
                  type="number"
                  name="acreage"
                  id="acreage"
                  label={intl.formatMessage({ id: 'EditListingLocationForm.acreage' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingLocationForm.acreagePlaceholder',
                  })}
                  validate={composeValidators(
                    required(
                      intl.formatMessage({
                        id: 'EditListingLocationForm.acreageRequired',
                      })
                    )
                  )}
                />
              </EditListingFieldWrapper>

              <EditListingFieldWrapper>
                <LocationAutocompleteInputField
                  name="nearestTown"
                  label={intl.formatMessage({ id: 'EditListingLocationForm.town' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingLocationForm.townPlaceholder',
                  })}
                  useDefaultPredictions={false}
                  format={null}
                  valueFromForm={values.nearestTown}
                  showButton={false}
                  validate={composeValidators(
                    autocompleteSearchRequired(
                      intl.formatMessage({
                        id: 'EditListingLocationForm.nearestTownRequired',
                      })
                    ),
                    autocompletePlaceSelected(
                      intl.formatMessage({
                        id: 'EditListingLocationForm.addressNotRecognized',
                      })
                    )
                  )}
                  townOnly
                  fullAddress
                />
              </EditListingFieldWrapper>
            </EditListingSection>

            <EditListingActions>
              {backLink && <EditListingActions.Back>{backLink}</EditListingActions.Back>}

              <EditListingActions.Forward>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </PrimaryButton>
              </EditListingActions.Forward>
            </EditListingActions>
          </Form>
        );
      }}
    />
  );
};

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  backLink: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  backLink: node,
};

export default compose(injectIntl)(EditListingLocationFormComponent);
