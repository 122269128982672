/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import config from '../../config';
import { speciesFoundations } from '../../marketplace-custom-config';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import * as log from '../../util/log';
import {
  propTypes,
  LINE_ITEM_NIGHT,
  LINE_ITEM_LODGING_TAX,
  LINE_ITEM_LODGING_FEE,
  LINE_ITEM_CONSERVATION_DONATION,
} from '../../util/types';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureBooking,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
} from '../../util/data';
import { minutesBetween, nightsBetween, daysAdded, daysBetween } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isAccountNotStripeConnected,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import {
  txIsPaymentPending,
  txIsPaymentExpired,
  TRANSITION_ENQUIRE_GATED,
  calculateLodgingTaxUnitPrice,
  calculateLodgingOnlyUnitPrice,
} from '../../util/transaction';
import {
  BookingListingBreakdownSection,
  MobileListingAvatarSection,
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  PricingDetails,
  PackagesBreadcrumbs,
  StripeCheckoutForm,
  EarlyAccessBar,
} from '../../components';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { handleCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { fetchCurrentUser, followSubject } from '../../ducks/user.duck';
import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  confirmPayment,
  sendMessage,
} from './CheckoutPage.duck';
import { storeData, storedData, clearData } from './CheckoutPageSessionHelpers';
import css from './CheckoutPage.css';

import { types as sdkTypes } from '../../util/sdkLoader';
import { hasPremiumMembership, isVerified } from '../../util/user';
import {
  getListingTimezone,
  getLodgingTax,
  listingStateLinks,
  getLodgingPricesOptions,
} from '../../util/listing';
import BookingDetails from '../../components/TransactionPanel/BookingDetails';
import { LODGING_TYPE, getPackageLodgingPrices, isEarlyAccessAvailable } from '../../util/package';

const { Money } = sdkTypes;

const STORAGE_KEY = 'CheckoutPage';

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

const stripePromise = loadStripe(config.stripe.publishableKey);

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      usedPromoCode: false,
    };
    this.stripe = null;

    this.customPricingParams = this.customPricingParams.bind(this);
    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleApplyPromoCode = this.handleApplyPromoCode.bind(this);
    this.handleApplyInsurance = this.handleApplyInsurance.bind(this);
    this.getSpeculateTransaction = this.getSpeculateTransaction.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.props;

    if (currentUser) {
      this.loadInitialData();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;

    if (currentUser && !prevProps.currentUser) {
      this.loadInitialData();
    }
  }

  // eslint-disable-next-line class-methods-use-this, consistent-return
  async handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      history,
    } = this.props;

    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);
    const tx = speculatedTransaction || storedTx;

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );
    const currentTransaction = get(history, 'location.state.currentTransaction', null);

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);
    const { listing } = pageData;
    const landownerId = get(listing, 'author.id.uuid', null);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = async fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }

      // If paymentIntent exists, order has been initiated previously.
      return onInitiateOrder(
        fnParams,
        currentTransaction?.id ? currentTransaction.id : storedTx.id,
        currentTransaction
      );
    };

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};
    const bookingFeeLineItem = tx.attributes.lineItems;
    // filter out customer/provider commission.  This is calculated on the backend.
    // TODO: refactor code to not include commissions in the speculated transaction attributes
    // in the first place.
    const filteredLineItems = bookingFeeLineItem.filter(lineItem => {
      return (
        lineItem.code !== 'line-item/customer-commission' &&
        lineItem.code !== 'line-item/provider-commission'
      );
    });

    const lineItems = filteredLineItems || null;

    const orderParams = {
      listingId: pageData.listing.id,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      bookingDisplayStart: tx.booking.attributes.displayStart,
      bookingDisplayEnd: tx.booking.attributes.displayEnd,
      landownerId,
      ...optionalPaymentParams,
      lineItems,
      protectedData: {
        ...tx.attributes.protectedData,
      },
      metadata: {
        ...tx.attributes.metadata,
      },
      currentUser: {
        id: currentUser.id,
      },
    };

    try {
      const paymentResponse = await fnRequestPayment(orderParams);
      const order = ensureTransaction(paymentResponse);

      if (order.id) {
        // Store order.
        const { bookingData, bookingDates } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const confirmPaymentResponse = await onConfirmPayment({
        transactionId: order.id,
      });

      const sendMessageResponse = await onSendMessage({
        ...confirmPaymentResponse,
        txId: order.id,
        message,
        customerId: currentUser.id.uuid,
        providerId: landownerId,
        senderType: 'customer',
      });

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, paymentIntent.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...sendMessageResponse, paymentMethodSaved: false };
            }
            return { ...sendMessageResponse, paymentMethodSaved: true };
          })
          .catch(() => {
            // Real error cases are caught already in paymentMethods page.
            return { ...sendMessageResponse, paymentMethodSaved: false };
          });
      }

      return Promise.resolve({ ...sendMessageResponse, paymentMethodSaved: true });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async handleApplyPromoCode(promoCode) {
    const { pageData } = this.state;
    const { speculatedTransaction } = this.props;
    const tripInsurance = get(speculatedTransaction, 'attributes.metadata.tripInsurance', null);

    await this.getSpeculateTransaction(
      {
        ...pageData,
        tripInsurance,
        promoCode,
      },
      true
    );
  }

  async handleApplyInsurance(tripInsurance) {
    const { pageData } = this.state;
    const { speculatedTransaction } = this.props;
    const promoCode = get(speculatedTransaction, 'attributes.protectedData.promoCode.code', null);

    await this.getSpeculateTransaction(
      {
        ...pageData,
        tripInsurance,
        promoCode,
      },
      true
    );
  }

  async handleSubmit(values) {
    try {
      const { fetchUser } = this.props;
      const { paymentIntent, message, saveAfterOnetimePayment, paymentMethod } = values;

      await fetchUser();

      const { history, speculatedTransaction, currentUser, dispatch } = this.props;

      if (!isVerified(currentUser)) {
        this.setState({
          submitting: true,
        });

        return;
      }

      this.setState({ submitting: true });

      const { pageData } = this.state;
      const bookingFees = get(pageData, 'bookingData', null);
      const storedTx = ensureTransaction(pageData.transaction);
      const tx = speculatedTransaction || storedTx;

      // taking the UTC date and changing it to local time at midnight for the bookingdisplay start/end
      const startString = tx.booking.attributes.start;

      const startM = moment(startString)
        .utc()
        .format('LL');
      const bookingDisplayStart = new Date(startM);

      const endString = tx.booking.attributes.end;
      const endM = moment(endString)
        .utc()
        .format('LL');
      const bookingDisplayEnd = new Date(endM);

      const requestPaymentParams = {
        pageData,
        speculatedTransaction,
        paymentIntent,
        bookingDisplayStart,
        bookingDisplayEnd,
        bookingFees,
        message,
        selectedPaymentMethod: paymentMethod,
        saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      };

      const res = await this.handlePaymentIntent(requestPaymentParams);

      const { orderId, messageSuccess, paymentMethodSaved } = res;

      const routes = routeConfiguration();
      const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
      const orderDetailsPath = pathByRouteName('InboxV2Page', routes, {
        tab: 'orders',
        id: orderId.uuid,
      });
      const initialValues = {
        initialMessageFailedToTransaction,
        savePaymentMethodFailed: !paymentMethodSaved,
      };

      initializeOrderPage(initialValues, routes, dispatch);
      clearData(STORAGE_KEY);
      history.push({
        pathname: orderDetailsPath,
        state: { showBookingModal: true },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      log.error(error, 'stripe-checkout-page-form-submit-failed');
    } finally {
      this.setState({ submitting: false });
    }
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;
    const { pageData } = this.state;
    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = pageData ? pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }

  async getSpeculateTransaction(pageData, isUpdating) {
    const {
      params,
      fetchSpeculatedTransaction,
      currentUser,
      speculatedTransaction,
      intl,
    } = this.props;
    const { bookingStart, bookingEnd } = pageData.bookingDates;

    const bookingStartForAPI = bookingStart;
    const bookingEndForAPI = bookingEnd;
    let includePromoCode = false;
    let usedPromoCode = false;
    if (pageData.promoCode) {
      const promoCodes = get(currentUser, 'attributes.profile.protectedData.promoCodes', []);
      const lowerCasePromoCode = pageData.promoCode.toLowerCase();

      includePromoCode = !promoCodes.some(code => code.toLowerCase() === lowerCasePromoCode);
      usedPromoCode = !includePromoCode;
    }
    this.setState({ usedPromoCode });

    const packages = get(pageData.listing, 'attributes.publicData.packages', []);
    const guestSize = get(pageData, 'bookingData.party', 1);
    const packageInfo = packages.find(item => item.id === params.packageId);
    const days = daysAdded(bookingStart, bookingEnd);

    const lodgingPricesOptions = getLodgingPricesOptions(pageData.listing, intl);

    const { optionalLodging, includedLodging } = getPackageLodgingPrices(
      packageInfo,
      lodgingPricesOptions
    );

    const lodgingAddons = get(pageData, 'bookingData.lodgingAddons', []);
    let lodgingPrices = (lodgingAddons || []).map(addon => {
      const lodgingPrice = JSON.parse(optionalLodging[addon].price, sdkTypes.reviver);

      return {
        price: lodgingPrice,
        type: optionalLodging[addon].type,
      };
    });

    if (includedLodging.length) {
      lodgingPrices = lodgingPrices.concat(
        includedLodging.map(item => {
          const lodgingPrice = JSON.parse(item.price, sdkTypes.reviver);

          return {
            price: lodgingPrice,
            type: item.type,
          };
        })
      );
    }

    const lodgingPrice = lodgingPrices.reduce((acc, curr) => {
      if (curr?.price?.amount) {
        return acc + curr.price.amount;
      }

      return acc;
    }, 0);
    const lodgingPriceForTax = lodgingPrices
      .filter(lodgingPriceItem =>
        [LODGING_TYPE.HOUSE, LODGING_TYPE.CABIN].includes(lodgingPriceItem.type)
      )
      .reduce((acc, curr) => {
        if (curr?.price?.amount) {
          return acc + curr.price.amount;
        }

        return acc;
      }, 0);
    const lodgingFee = lodgingPrice && new Money(lodgingPrice, config.currency);
    const lodgingFeeForTax = lodgingPriceForTax && new Money(lodgingPriceForTax, config.currency);

    let packageItem = null;

    if (packageInfo) {
      packageItem = {
        code: `line-item/${packageInfo.title.replace(/\s/g, '-')}`,
        title: packageInfo.title,
        description: packageInfo.description,
        unitPrice: JSON.parse(packageInfo.price),
        quantity: guestSize * days,
        guestSize,
        activity: packageInfo.activity,
        hasLodging: pageData?.includeLodging || packageInfo?.hasLodging,
        id: packageInfo.id,
        lodgingFee,
        lodgingFeeForTax,
      };
    }

    const stripePaymentIntents = get(
      speculatedTransaction,
      'attributes.protectedData.stripePaymentIntents',
      null
    );

    // Fetch speculated transaction for showing price in booking breakdown
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    // const alligatorFee = pageData.bookingData['alligator'];
    // const bookingFees = Object.entries(pageData.bookingData);
    await fetchSpeculatedTransaction(
      this.customPricingParams({
        listing: pageData.listing,
        bookingStart: bookingStartForAPI,
        bookingEnd: bookingEndForAPI,
        bookingFees: pageData.bookingData,
        packageItem,
        promoCode: includePromoCode ? pageData.promoCode : '',
        tripInsurance: pageData.tripInsurance,
        stripePaymentIntents,
      }),
      isUpdating
    );
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */

  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      includeLodging,
      fetchStripeCustomer,
      history,
    } = this.props;
    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';
    const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY, includeLodging);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction, includeLodging }
      : storedData(STORAGE_KEY);
    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.bookingData &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      !isBookingCreated;

    if (shouldFetchSpeculatedTransaction) {
      this.getSpeculateTransaction(pageData, false);
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  /**
   * Constructs a request params object that can be used when creating bookings
   * using custom pricing.
   * @param {} params An object that contains bookingStart, bookingEnd and listing
   * @return a params object for custom pricing bookings
   */

  // eslint-disable-next-line class-methods-use-this
  customPricingParams(params) {
    const protectedData = {};
    const metadata = {};

    const { currentUser } = this.props;
    const sizeRenderer = { _sdkType: 'Money', amount: 0, currency: 'USD' };
    const {
      bookingStart,
      bookingEnd,
      listing,
      bookingFees,
      packageItem,
      promoCode,
      tripInsurance,
      stripePaymentIntents,
    } = params;

    const { anchorTag, listingState, stateStatute } = listingStateLinks(listing);
    const lodgingTaxInfo = getLodgingTax(listing, packageItem?.id);
    const landownerId = get(listing, 'author.id.uuid', null);

    const { amount, currency } =
      listing && listing.attributes && listing.attributes.price
        ? listing.attributes.price
        : sizeRenderer;

    const { party } = bookingFees;
    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;

    const quantity = isNightly
      ? nightsBetween(bookingStart, bookingEnd)
      : daysAdded(bookingStart, bookingEnd);
    const fees = Object.entries(bookingFees);

    const totalPartyCost = fees.reduce(function partyFunc(total, arr) {
      const correctedAmounts = arr[1]?.amount ? arr[1].amount : 0;
      return total + correctedAmounts;
    }, 0);

    // We wanna get the date at the listing location.
    // This will then be used for the display dates which are hooked
    // with our process.end transitions
    const timezone = getListingTimezone(listing);

    const bookingStartDate = moment(bookingStart).format('YYYY-MM-DD');
    const bookingEndDate = moment(bookingEnd).format('YYYY-MM-DD');

    const bookingStartDateInLocale = moment.tz(`${bookingStartDate} 00:00:00`, timezone);
    const bookingEndDateInLocale = moment.tz(`${bookingEndDate} 23:59:59`, timezone);

    const bookingStartDateInLocaleInUTC = moment(bookingStartDateInLocale).utc();
    const bookingEndDateInLocaleInUTC = moment(bookingEndDateInLocale).utc();

    const bookingDisplayStart = bookingStartDateInLocaleInUTC.toDate();
    const bookingDisplayEnd = bookingEndDateInLocaleInUTC.toDate();

    const totalDays =
      daysBetween(bookingStartDateInLocale.format(), bookingEndDateInLocale.format()) + 1;

    // We will store the booking dates and timezone into protected data
    // in order to access it later when displaying the dates to the guests
    // (using the listing time, NOT local guest time!!!).
    protectedData.bookingDates = {
      start: bookingStartDateInLocale.format(),
      end: bookingEndDateInLocale.format(),
      timezone,
      days: daysBetween(bookingStartDateInLocale.format(), bookingEndDateInLocale.format()) + 1,
    };

    // The API booking end date is always exclusive. In order to have e.g.
    // 1st of Jul - 2nd of July we need to send end date as 3rd.
    // Important, the 3rd of July won't be blocked from future bookings.
    const apiBookingEnd = moment
      .utc(bookingEnd)
      .set({ hour: 0, minute: 0, seconds: 0 })
      .add(1, 'd')
      .toDate();
    const apiBookingStart = moment
      .utc(bookingStart)
      .set({ hour: 0, minute: 0 })
      .toDate();

    const partyCostTransaction = (party - 1) * quantity;
    const conservationDonationItems = [];

    const lineItemsMaybe = fees
      .filter(([name]) => {
        // For packages we don't need that line item
        if (packageItem && name === 'party') {
          return false;
        }

        if (!partyCostTransaction && name === 'party') {
          return false;
        }

        if (packageItem && name === 'lodgingAddons') {
          return false;
        }

        return true;
      })
      .map(([name, unitPrice]) => {
        if (name === 'party') {
          return {
            code: `line-item/${name}-size`,
            unitPrice: { _sdkType: 'Money', amount: totalPartyCost, currency: 'USD' },
            quantity: partyCostTransaction,
          };
        }

        if (speciesFoundations[name]) {
          conservationDonationItems.push({
            speciesPrice: unitPrice.amount,
            species: speciesFoundations[name].species,
          });
        }

        return {
          code: `line-item/${name}-fee`,
          unitPrice,
          quantity,
          guestSize: packageItem && packageItem.guestSize,
        };
      });

    if (packageItem) {
      lineItemsMaybe.push(packageItem);
      protectedData.packageLineItem = {
        id: packageItem.id,
        title: packageItem.title,
        description: packageItem.description,
        price: {
          amount: packageItem.unitPrice.amount / 100,
          currency: packageItem.unitPrice.currency,
        },
        guestSize: packageItem.guestSize,
        activity: packageItem.activity,
      };
      protectedData.lodgingTaxInfo = lodgingTaxInfo;
      metadata.tripInsurance = !!tripInsurance;
    }

    // Sort donation lines by price, and choose the expensive one - edge case.
    conservationDonationItems.sort((a, b) => b.speciesPrice - a.speciesPrice);

    if (conservationDonationItems.length) {
      protectedData.conservationDonationSpecies = conservationDonationItems[0].species;
    }

    if (conservationDonationItems.length || protectedData.packageLineItem) {
      lineItemsMaybe.push({
        code: LINE_ITEM_CONSERVATION_DONATION,
        unitPrice: new Money(1000, config.currency),
        quantity: 1,
        includeFor: ['customer'],
      });
    }

    // TAXES
    const lodgingTax = getLodgingTax(listing, packageItem?.id);
    const lodgingPrice = get(packageItem, 'lodgingFee', null);
    const lodgingPriceForTax = get(packageItem, 'lodgingFeeForTax', null);

    const lodgingFeeLineItemAdded = lodgingPrice && lodgingPrice.amount > 0 && totalDays > 1;
    protectedData.lodgingPrice = {};

    if (lodgingTax && lodgingPriceForTax && totalDays > 1) {
      protectedData.lodgingPrice.priceForTax = lodgingPriceForTax.amount;

      lineItemsMaybe.push({
        code: LINE_ITEM_LODGING_TAX,
        unitPrice: calculateLodgingTaxUnitPrice(lodgingTax, totalDays - 1, lodgingPriceForTax),
        quantity: 1,
        includeFor: ['customer'],
      });
    }

    if (lodgingFeeLineItemAdded) {
      protectedData.lodgingPrice.price = lodgingPrice.amount;

      const lodgingFeeUnitPrice = calculateLodgingOnlyUnitPrice(lodgingPrice, totalDays - 1);

      lineItemsMaybe.push({
        code: LINE_ITEM_LODGING_FEE,
        includeFor: ['customer', 'provider'],
        unitPrice: lodgingFeeUnitPrice,
        quantity: 1,
        lineTotal: lodgingFeeUnitPrice,
      });
    }

    if (stateStatute) {
      protectedData.stateStatute = stateStatute;
      protectedData.listingState = { anchor: anchorTag, state: listingState };
    }

    return {
      listingId: listing?.id,
      listingTitle: listing?.attributes?.title,
      bookingStart: apiBookingStart,
      bookingEnd: apiBookingEnd,
      bookingDisplayStart,
      bookingDisplayEnd,
      lineItems: [
        ...lineItemsMaybe,

        {
          code: unitType,
          unitPrice: new Money(amount, currency),
          quantity,
        },
      ],
      protectedData,
      metadata,
      promoCode,
      tripInsurance,
      landownerId,
      currentUser,
      stripePaymentIntents,
    };
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      intl,
      params,
      currentUser,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      fetchUser,
      history,
      followSubjectMutation,
    } = this.props;

    const { dataLoaded, pageData, usedPromoCode, submitting } = this.state;

    const isLoading = !dataLoaded || speculateTransactionInProgress;
    const currentTransaction = get(history, 'location.state.currentTransaction', null);
    const guestHomeState = get(
      currentUser,
      'attributes.profile.publicData.usState.selectedPlace.address',
      null
    );
    const showInsuranceForm = guestHomeState && guestHomeState !== ('New York' || 'Hawaii');
    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (!currentUser || isLoading) {
      return (
        <Page>
          {topbar}

          <div className={css.contentContainer}>
            <div className={css.leftLoadingColumn}>
              <div className={css.leftLoader} />
            </div>
            <div className={css.rightLoadingColumn}>
              <div className={css.rightLoader} />
            </div>
          </div>
        </Page>
      );
    }

    const stripeClientSecret = get(
      speculatedTransactionMaybe,
      'attributes.protectedData.stripePaymentIntents.default.stripePaymentIntentClientSecret',
      null
    );

    const stripeOptions = {
      clientSecret: stripeClientSecret,
      appearance: config.stripe.appearance,
    };

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const { listing, bookingDates, transaction } = pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );
    const hasRequiredData = hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        bookingDates,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;

    const txBooking = ensureBooking(tx.booking);
    const breakdown =
      tx.id && txBooking.id ? (
        <>
          <BookingDetails
            transaction={tx}
            stateData={{
              showPropertyAddress: false,
              showArrivalInstructions: false,
              showListingWaypoints: false,
              showBookingDetails: true,
              showBookingDetailsHeader: true,
            }}
          />

          <hr className={css.totalDivider} />

          <PricingDetails
            className={css.bookingBreakdown}
            userRole="customer"
            unitType={config.bookingUnitType}
            transaction={tx}
            booking={txBooking}
          />
        </>
      ) : null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    const listingTitle = currentListing.attributes.title;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    // guest/party size info
    const packages = get(pageData.listing, 'attributes.publicData.packages', []);

    const packageInfo = packages.find(item => item.id === params.packageId);

    const userHasPremiumMembership = hasPremiumMembership(currentUser);
    const isEarlyAccess = isEarlyAccessAvailable(packageInfo?.earlyAccessExpiration);

    const listingText = (
      <NamedLink
        className={css.listingText}
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorAtCheckout" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedHTMLMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
        </p>
      );
    }

    const speculateTransactionErrorMessage = null;
    let speculateErrorMessage = null;
    if (isAccountNotStripeConnected(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedHTMLMessage id="CheckoutPage.providerStripeAccountNotConnectedError" />
        </p>
      );
    } else if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedHTMLMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" values={{ listingText }} />
        </p>
      );
    }

    const currentTransactionId = get(
      currentTransaction,
      'attributes.protectedData.selectedPackage.packageId',
      null
    );
    const showInitialMessageInput = !(
      currentTransaction &&
      currentTransaction?.attributes?.lastTransition === TRANSITION_ENQUIRE_GATED &&
      currentTransactionId === packageInfo.id
    );

    const pageProps = { listingTitle, scrollingDisabled };

    if (isLoading) {
      return (
        <Page {...pageProps} noindex nofollow>
          {topbar}

          <div className={css.contentContainer}>
            <div className={css.leftLoadingColumn}>
              <div className={css.leftLoader} />
            </div>
            <div className={css.rightLoadingColumn}>
              <div className={css.rightLoader} />
            </div>
          </div>
        </Page>
      );
    }

    return (
      <Page {...pageProps} noindex nofollow>
        {topbar}

        <EarlyAccessBar
          isEarlyAccess={isEarlyAccess}
          userHasPremiumMembership={userHasPremiumMembership}
          expirationTimestamp={packageInfo?.earlyAccessExpiration}
        />

        <div className={css.contentContainer}>
          <div className={css.mobileBreadcrumbs}>
            <PackagesBreadcrumbs params={params} listing={listing} step="checkout" />{' '}
          </div>
          <MobileListingAvatarSection
            listing={listing}
            packageInfo={packageInfo}
            currentUser={currentUser}
          />

          <div className={css.bookListingContainer}>
            <div className={css.desktopBreadcrumbs}>
              <PackagesBreadcrumbs params={params} listing={listing} step="checkout" isDesktop />{' '}
            </div>

            <section className={css.paymentContainer}>
              {initiateOrderErrorMessage}
              {listingNotFoundErrorMessage}
              {speculateErrorMessage}
              {retrievePaymentIntentError ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.retrievingStripePaymentIntentFailed"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}

              {stripeClientSecret && (
                <Elements stripe={stripePromise} options={stripeOptions}>
                  <StripeCheckoutForm
                    submitClassName={css.submitClassName}
                    onSubmit={this.handleSubmit}
                    onApplyPromoCode={this.handleApplyPromoCode}
                    onApplyInsurance={this.handleApplyInsurance}
                    showInitialMessageInput={showInitialMessageInput}
                    followSubjectMutation={followSubjectMutation}
                    authorDisplayName={currentAuthor.attributes.profile.displayName}
                    transaction={tx}
                    usedPromoCode={usedPromoCode}
                    clientSecret={stripeClientSecret}
                    defaultPaymentMethod={
                      hasDefaultPaymentMethod
                        ? currentUser.stripeCustomer.defaultPaymentMethod
                        : null
                    }
                    isSubmitting={submitting}
                    setIsSubmitting={isSubmitting => {
                      this.setState({
                        submitting: isSubmitting,
                      });
                    }}
                    showInsuranceForm={showInsuranceForm}
                    fetchUser={fetchUser}
                    user={currentUser}
                    listing={listing}
                  />
                </Elements>
              )}
            </section>
          </div>

          <hr className={css.totalDivider} />

          <BookingListingBreakdownSection
            error={speculateTransactionErrorMessage}
            listing={listing}
            breakdown={breakdown}
            packageInfo={packageInfo}
          />
        </div>
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  updateSpeculateTransactionError: null,
  updateSpeculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
  handleCardPaymentError: null,
  includeLodging: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  updateSpeculateTransactionInProgress: bool.isRequired,
  updateSpeculateTransactionError: propTypes.error,
  updateSpeculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,
  includeLodging: bool,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    updateSpeculateTransactionInProgress,
    updateSpeculateTransactionError,
    updateSpeculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    includeLodging,
  } = state.CheckoutPage;
  const { currentUser } = state.user;

  const { handleCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    updateSpeculateTransactionInProgress,
    updateSpeculateTransactionError,
    updateSpeculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    includeLodging,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: (params, isUpdating) =>
    dispatch(speculateTransaction(params, isUpdating)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  fetchUser: () => dispatch(fetchCurrentUser()),
  followSubjectMutation: (userId, subjectId, notifyBy) =>
    dispatch(followSubject(userId, subjectId, notifyBy)),
  onInitiateOrder: (params, transactionId, tx) =>
    dispatch(initiateOrder(params, transactionId, tx)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  // eslint-disable-next-line no-shadow
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const CheckoutPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
  if (saveToSessionStorage) {
    const { listing, bookingData, bookingDates, includeLodging } = initialValues;
    storeData(bookingData, bookingDates, listing, null, STORAGE_KEY, includeLodging);
  }

  return setInitialValues(initialValues);
};

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
