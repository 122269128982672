import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import InboxFeedItems from './InboxFeedItems';

import css from './InboxFeed.css';

const InboxFeed = ({
  currentTransaction,
  currentUser,
  fetchMessagesError,
  fetchMessagesInProgress,
  initialMessageFailed,
  messages,
  oldestMessagePageFetched,
  onShowMoreMessages,
  totalMessagePages,
  transactionState,
  onUpdatePartyMemberDetails,
  isCustomer,
}) => {
  const txTransitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;

  const showFeed =
    messages.length > 0 || txTransitions.length > 0 || initialMessageFailed || fetchMessagesError;

  if (!showFeed) {
    return null;
  }

  return (
    <div className={css.root}>
      {initialMessageFailed ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.initialMessageFailed" />
        </p>
      ) : null}
      {fetchMessagesError ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
        </p>
      ) : null}

      <InboxFeedItems
        messages={messages}
        transaction={currentTransaction}
        currentUser={currentUser}
        hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
        onShowOlderMessages={onShowMoreMessages}
        fetchMessagesInProgress={fetchMessagesInProgress}
        transactionState={transactionState}
        onUpdatePartyMemberDetails={onUpdatePartyMemberDetails}
        isCustomer={isCustomer}
      />
    </div>
  );
};

export default injectIntl(InboxFeed);
