import React, { useState, useEffect, useCallback } from 'react';
import PropTypes, { object } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { IconSpinner } from '..';

import css from './ReviewsImageCarousel.css';
import { ScrollingCarousel, Step } from '../ScrollingCarousel/ScrollingCarousel';

const KEY_CODE_LEFT_ARROW = 37;
const KEY_CODE_RIGHT_ARROW = 39;

const ReviewsImageCarousel = ({
  images,
  captions,
  selectedImage,
  rootClassName,
  className,
  intl,
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(
    selectedImage ? images.findIndex(image => image === selectedImage) : 0
  );

  const [selectedImageLoaded, setSelectedImageLoaded] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const currentImage = images[selectedImageIndex];
  const currentCaption = captions[currentImage];
  const naturalIndex = selectedImageIndex + 1;
  const imageIndex =
    images.length > 0 ? (
      <span className={css.imageIndex}>
        {naturalIndex}/{images.length}
      </span>
    ) : null;

  const imageAltText = intl.formatMessage(
    {
      id: 'ImageCarousel.imageAltText',
    },
    {
      index: naturalIndex,
      count: images.length,
    }
  );
  const currentImageIsLoaded = images.length === 0 || selectedImageLoaded;
  const loadingIconClasses = classNames(css.loading, {
    [css.loadingVisible]: !currentImageIsLoaded,
  });
  const imageClasses = classNames(css.image, {
    [css.imageLoading]: !currentImageIsLoaded,
  });

  const prev = () => {
    const count = images.length;

    if (count < 2) {
      return;
    }

    const newIndex = count > 0 ? (count + selectedImageIndex - 1) % count : 0;

    setSelectedImageIndex(newIndex);
  };

  const next = () => {
    const count = images.length;

    if (count < 2) {
      return;
    }

    const newIndex = count > 0 ? (count + selectedImageIndex + 1) % count : 0;

    setSelectedImageIndex(newIndex);
  };

  const onKeyUp = useCallback(
    e => {
      switch (e.keyCode) {
        case KEY_CODE_LEFT_ARROW:
          prev();
          break;
        case KEY_CODE_RIGHT_ARROW:
          next();
          break;
        default:
      }
    },
    [prev, next]
  );

  const markImageLoaded = index => () => {
    if (selectedImageIndex === index) {
      setSelectedImageLoaded(true);
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  const prevButton = images.length > 1 ? <button className={css.prev} onClick={prev} /> : null;
  const nextButton = images.length > 1 ? <button className={css.next} onClick={next} /> : null;

  return (
    <div className={classes}>
      <div className={css.contentContainer}>
        <div className={css.imageWrapper}>
          <IconSpinner className={loadingIconClasses} />
          <img
            src={`${currentImage}`}
            alt={imageAltText}
            className={imageClasses}
            onLoad={markImageLoaded(selectedImageIndex)}
            onError={markImageLoaded(selectedImageIndex)}
            sizes="(max-width: 767px) 100vw, 80vw"
          />
        </div>
        <div className={css.controlsContainer}>
          {prevButton}
          {imageIndex}
          {nextButton}
        </div>
        <div className={css.captionsContainer}>{currentCaption}</div>
      </div>

      <ScrollingCarousel className={css.thumbnailCarousel} current={selectedImageIndex}>
        {images.map((image, index) => (
          <Step
            key={image}
            onClick={() => setSelectedImageIndex(index)}
            className={css.thumbnailStep}
          >
            <img
              src={`${image}?w=300`}
              alt=""
              className={classNames(css.thumbnail, {
                [css.active]: selectedImageIndex === index,
              })}
              sizes="(max-width: 767px) 100vw, 80vw"
            />
          </Step>
        ))}
      </ScrollingCarousel>
    </div>
  );
};

ReviewsImageCarousel.defaultProps = {
  rootClassName: null,
  className: null,
  captions: [],
};

const { string, arrayOf } = PropTypes;

ReviewsImageCarousel.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(string).isRequired,
  captions: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ReviewsImageCarousel);
