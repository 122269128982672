import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PartyMembers from '../PartyMembers/PartyMembers';
import { PrimaryButton } from '../Button/Button';
import { ReactComponent as IconPlane } from '../../assets/icons/paper-plane-light.svg';

import css from './TransactionPanel.css';

const OfferDetails = props => {
  const { showButton, formComplete, showVerificationMessage, showForm } = props;

  // eslint-disable-next-line no-nested-ternary
  const offerDetailsCopy = showForm ? null : showButton &&
    !formComplete &&
    showVerificationMessage ? (
    <div>
      <p className={css.verificationHeader}>
        <FormattedHTMLMessage id="PartyMembersSection.VerificationRequired" />
      </p>
      <p>
        <FormattedMessage id="PartyMembersSection.InviteInfo" />
      </p>
    </div>
  ) : (
    <div>
      <span className={css.iconWrapper}>
        <IconPlane />
      </span>
      <p className={css.inviteSent}>
        <FormattedHTMLMessage id="PartyMembersSection.InviteSent" />
      </p>
    </div>
  );
  return offerDetailsCopy;
};

// Functional component as a helper to build ActivityFeed section
const PartyMembersSection = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    currentUser,
    partyMembers,
    onUpdatePartyMemberDetails,
    intl,
    formComplete,
  } = props;
  const classes = classNames(rootClassName || css.partyMembersComplete, className);
  const [showForm, setShowForm] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showVerificationMessage, setShowVerificationMessage] = useState(true);
  const setBoth = () => {
    setShowForm(false);
    setShowButton(false);
  };

  return (
    <div className={classes}>
      <OfferDetails
        currentTransaction={currentTransaction}
        intl={intl}
        showButton={showButton}
        formComplete={formComplete}
        partyMembers={partyMembers}
        showVerificationMessage={showVerificationMessage}
        showForm={showForm}
      />
      {showForm && !formComplete && (
        <PartyMembers
          transaction={currentTransaction}
          currentUser={currentUser}
          partyMembers={partyMembers}
          onUpdatePartyMemberDetails={onUpdatePartyMemberDetails}
          setBoth={setBoth}
        />
      )}

      {!showForm && !formComplete && showButton && (
        <PrimaryButton
          onClick={() => {
            setShowForm(true);
            setShowVerificationMessage(false);
          }}
          className={css.partyButton}
        >
          <FormattedMessage id="PartyMembersSection.PartyButton" />
        </PrimaryButton>
      )}
    </div>
  );
};

export default PartyMembersSection;
