import React, { useState } from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { ensureCurrentUser } from '../../util/data';
import { InlineTextButton, NamedLink } from '..';

import css from './TopbarMobile.css';
import { ReactComponent as CalendarIcon } from '../../assets/icons/icon-calendar.svg';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/icon-hamburger-menu-small.svg';
import { ReactComponent as PinIcon } from '../../assets/icons/icon-map-pin-location-regular.svg';
import { ReactComponent as PinFilledIcon } from '../../assets/icons/icon-map-pin-location-filled.svg';
import { ReactComponent as CommentIcon } from '../../assets/icons/icon-comment.svg';
import { ReactComponent as CommentFilledIcon } from '../../assets/icons/icon-comment-filled.svg';
import { ReactComponent as LodgeIcon } from '../../assets/icons/icon-lodge.svg';
import { ReactComponent as FieldNotesIcon } from '../../assets/icons/icon-field-notes-updates.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/icon-user.svg';
import { ReactComponent as HeartIcon } from '../../assets/icons/icon-heart.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/icon-search.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/icon-logout.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/icon-help.svg';

import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const TopbarMobile = props => {
  const { currentUser, params, match, onLogout } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const user = ensureCurrentUser(currentUser);
  const { profile } = user.attributes;
  const role = get(profile, 'protectedData.role', []);
  const isLandowner = role && role.includes('landowner');

  // Check if we're on the package page or listing page
  const isPackagePage = match.path.includes('/package/');
  const isListingPage = match.path.startsWith('/l/');

  const isActive = name => {
    const pathname = pathByRouteName(name, routeConfiguration(), params);
    return match.url && match.url.startsWith(pathname);
  };

  // Don't show the landowner navbar on the package page or listing page
  if (isPackagePage || isListingPage) {
    return null;
  }

  return (
    <div className={css.root}>
      {isMenuOpen && (
        <div className={css.rootMenu}>
          <div className={css.menuHeader}>Menu</div>

          <ul className={css.menuList}>
            <li className={css.menuItem}>
              <NamedLink name="FieldNotesPage" className={css.menuLink}>
                <FieldNotesIcon className={css.menuIcon} />
                <FormattedMessage id="TopbarMobile.fieldNotes" />
              </NamedLink>
            </li>

            <li className={css.menuItem}>
              <NamedLink name="ProfileSettingsBasePage" className={css.menuLink}>
                <UserIcon
                  className={css.menuIcon}
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '2px',
                    marginLeft: '2px',
                  }}
                />
                <FormattedMessage id="TopbarMobile.profile" />
              </NamedLink>
            </li>

            <li className={css.menuItem}>
              <NamedLink
                name="FavoritesPage"
                params={{
                  id: user.id.uuid,
                  tab: 'packages',
                }}
                className={css.menuLink}
              >
                <HeartIcon className={css.menuIcon} />
                <FormattedMessage id="TopbarMobile.favorites" />
              </NamedLink>
            </li>

            <li className={css.menuItem}>
              <NamedLink name="ContactPage" className={css.menuLink}>
                <HelpIcon className={css.menuIcon} />
                <FormattedMessage id="TopbarMobile.help" />
              </NamedLink>
            </li>

            <li className={css.menuItem}>
              <NamedLink name="LandingPage" className={css.menuLink}>
                <SearchIcon className={css.menuIcon} />
                <FormattedMessage id="TopbarMobile.browseProperties" />
              </NamedLink>
            </li>

            <li className={css.menuItem}>
              <InlineTextButton
                type="button"
                className={css.menuLink}
                onClick={() => {
                  onLogout();
                }}
              >
                <LogoutIcon className={css.menuIcon} />
                <FormattedMessage id="TopbarMobile.logout" />
              </InlineTextButton>
            </li>
          </ul>
        </div>
      )}

      <div className={css.rootTrayIcon}>
        <NamedLink name="DashboardPage" className={css.link} activeClassName={css.activeLink}>
          <LodgeIcon className={css.icon} />

          <span className={css.label}>
            <FormattedMessage id="TopbarMobile.home" />
          </span>
        </NamedLink>

        <NamedLink
          name="BookingOrTripsPage"
          params={{
            slug: isLandowner ? 'bookings' : 'trips',
          }}
          className={css.link}
          activeClassName={css.activeLink}
        >
          <CalendarIcon className={css.icon} />

          <span className={css.label}>
            {isLandowner ? (
              <FormattedMessage id="TopbarMobile.bookings" />
            ) : (
              <FormattedMessage id="TopbarMobile.trips" />
            )}
          </span>
        </NamedLink>

        <NamedLink name="InboxV2BasePage" className={css.link} activeClassName={css.activeLink}>
          {isActive('InboxV2BasePage') ? (
            <CommentFilledIcon className={css.icon} />
          ) : (
            <CommentIcon className={css.icon} />
          )}

          <span className={css.label}>
            <FormattedMessage id="TopbarMobile.inbox" />
          </span>
        </NamedLink>

        {isLandowner ? (
          <NamedLink
            name="ManageListingsPage"
            className={css.link}
            activeClassName={css.activeLink}
          >
            {isActive('ManageListingsPage') ? (
              <PinFilledIcon className={css.icon} />
            ) : (
              <PinIcon className={css.icon} />
            )}

            <span className={css.label}>
              <FormattedMessage id="TopbarMobile.listings" />
            </span>
          </NamedLink>
        ) : (
          <NamedLink name="FieldNotesPage" className={css.link} activeClassName={css.activeLink}>
            <FieldNotesIcon className={css.icon} />

            <span className={css.label}>
              <FormattedMessage id="TopbarMobile.fieldNotes" />
            </span>
          </NamedLink>
        )}

        <InlineTextButton
          type="button"
          className={css.link}
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <HamburgerIcon className={css.icon} />

          <span className={css.label}>
            <FormattedMessage id="TopbarMobile.menu" />
          </span>
        </InlineTextButton>
      </div>
    </div>
  );
};

export default withRouter(TopbarMobile);
