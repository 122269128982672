import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';
import moment from 'moment';
import { daysAdded } from '../../util/dates';

import { TRANSITION_ENQUIRE_GATED } from '../../util/transaction';

import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const InboxTransitionEnquireGated = ({ transaction, ownRole, buttons, user, isMessage, intl }) => {
  const transitions = get(transaction, 'attributes.transitions', []);
  const transition = transitions.find(item => item.transition === TRANSITION_ENQUIRE_GATED);
  const isOwnTransition = transition.by === ownRole;

  const startDate = get(transaction, 'attributes.protectedData.startDate', '');
  const endDate = get(transaction, 'attributes.protectedData.endDate', '');
  const selectedPackage = get(transaction, 'attributes.protectedData.selectedPackage', null);
  const packageTitle = get(selectedPackage, 'packageTitle', null);
  const dayCount = daysAdded(moment(startDate), moment(endDate));
  const isSingleDay = dayCount === 1;
  const startDateFormat = isSingleDay
    ? moment(startDate).format('ddd. MMMM Do, YYYY')
    : moment(startDate).format('ddd. MMMM Do');
  const endDateFormat = moment(endDate).format('ddd. MMMM Do, YYYY');

  const dateFormat = isSingleDay ? startDateFormat : `${startDateFormat} - ${endDateFormat}`;

  const formattedMessageId = isOwnTransition
    ? 'BookingStatus.ownTransitionHasPackage'
    : 'BookingStatus.transitionHasPackage';

  const inquiryDisplayName = getFirstName(get(user, 'attributes.profile.displayName', ''));

  const potentialEarnings = get(transaction, 'attributes.protectedData.potentialEarnings', null);
  const potentialEarningsMoney = potentialEarnings?.amount
    ? new Money(potentialEarnings.amount * 100, potentialEarnings.currency)
    : null;

  const potentialEarningsText = potentialEarningsMoney
    ? formatMoney(intl, potentialEarningsMoney)
    : null;

  const listingTitle = get(transaction, 'listing.attributes.title', '');

  return (
    <InboxTransitionBase
      date={transition.createdAt}
      variation={isOwnTransition ? 'dark' : 'light'}
      direction={isOwnTransition ? 'right' : 'left'}
      buttons={buttons}
      isMessage={isMessage}
      header={
        !isMessage ? (
          <>
            {isOwnTransition ? (
              <FormattedMessage
                id="BookingStatus.transitionOwnEnquiryGated"
                values={{ displayName: inquiryDisplayName }}
              />
            ) : (
              <FormattedMessage
                id="BookingStatus.transitionEnquiryGated"
                values={{ name: listingTitle }}
              />
            )}
          </>
        ) : null
      }
    >
      {isMessage ? (
        <FormattedHTMLMessage
          id={formattedMessageId}
          values={{
            inquiryDisplayName,
            dateFormat,
            packageTitle,
          }}
        />
      ) : (
        <>
          {isOwnTransition ? (
            <FormattedMessage id="BookingStatus.transitionOwnEnquiryGatedSubtext" />
          ) : (
            <FormattedMessage
              id="BookingStatus.transitionEnquiryGatedSubtext"
              values={{
                earnings: potentialEarningsText,
              }}
            />
          )}
        </>
      )}
    </InboxTransitionBase>
  );
};

export default injectIntl(InboxTransitionEnquireGated);
