/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import ActivityFeed from '../ActivityFeed/ActivityFeed';
import routeConfiguration from '../../routeConfiguration';
import {
  getListingSchemaTitleType,
  getListingImageCaptions,
  getListingImages,
  averageListingRating,
} from '../../util/listing';
import { getImagesByTag } from '../../util/photoLibrary';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
  formatNumber,
} from '../../util/data';
import {
  huntingFaqs,
  outdoorRecFaqs,
  HUNT_ACTIVITY,
  OUTDOOR_RECREATION_ACTIVITY,
} from '../../marketplace-custom-config';
import { richText } from '../../util/richText';
import { SUMMARY } from '../../util/editListing';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import {
  toggleFavoriteListings,
  fetchFollowedSubjects,
  followSubject,
  unfollowSubject,
  updateFollowedSubject,
} from '../../ducks/user.duck';
import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SectionAvailablePackages,
  SectionPropertyVisit,
  SectionFAQ,
} from '../../components';
import { Container } from '../../components/Layout';
import { STEP_ACTIVITY } from '../../components/InquiryWizard/InquiryWizardContext';
import { TopbarContainer, NotFoundPage } from '..';
import {
  loadData,
  setInitialValues,
  fetchActivityFeed,
  fetchActivityLogLikes,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionReviews from './SectionReviews';
import SectionHostMaybe from './SectionHostMaybe';

import SectionRules from './SectionRules';
import SectionMapMaybe from './SectionMapMaybe';
import SEOBreadcrumbs from '../../components/SEOBreadcrumbs/SEOBreadcrumbs';
import ShareButton from './ShareButton';
import FavoriteListing from '../../components/FavoriteListing/FavoriteListing';
import SectionKeyListingInfo from './SectionKeyListingInfo';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onContactUser = this.onContactUser.bind(this);
    this.handleActivitySelect = this.handleActivitySelect.bind(this);
  }

  handleActivitySelect(values) {
    const { history, getListing, params } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { activity } = values;
    const routes = routeConfiguration();

    // Sanity check to get the slug. From canonical route we don't have slug in the url param,
    // get it from listing.
    const slug = params?.slug || createSlug(listing?.attributes?.title);

    history.push(
      createResourceLocatorString(
        params.variant ? 'ActivityPageVariant' : 'ActivityPage',
        routes,
        {
          id: params.id,
          slug,
          activity,
          variant: params.variant,
        },
        {}
      )
    );
  }

  onContactUser(isOwnListing) {
    const { history, params, getListing } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    // Sanity check to get the slug. From canonical route we don't have slug in the url param,
    // get it from listing.
    const slug = params?.slug || createSlug(listing?.attributes?.title);

    if (isOwnListing) {
      // ..
    } else {
      history.push(
        createResourceLocatorString('NewInquiryPage', routeConfiguration(), {
          ...params,
          slug,
          step: STEP_ACTIVITY.path,
        })
      );
    }
  }

  render() {
    const {
      currentUser,
      getListing,
      getOwnListing,
      intl,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      customerReviews = [],
      reviewsMeta,
      fetchReviewsError,
      onToggleFavoriteListing,
      activityFeedData,
      fetchActivityFeedData,
      fetchTotalLikesData,
      followedSubjects,
      fetchFollowedSubjectsData,
      followSubjectMutation,
      unfollowSubjectMutation,
      updateFollowedSubjectMutation,
      isFetchingReviews,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };
    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = SUMMARY;

    const activities = currentListing?.attributes?.publicData?.activities || {};

    // check for hunt/outdoor_recreation in activities
    const hasHunt = HUNT_ACTIVITY in activities;
    const hasOutdoorRecreation = OUTDOOR_RECREATION_ACTIVITY in activities;

    // render faqs depending on the activities
    let faqsToRender;
    if (hasHunt) {
      faqsToRender = huntingFaqs;
    } else if (!hasHunt && hasOutdoorRecreation) {
      faqsToRender = outdoorRecFaqs;
    }
    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;
    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description,
      geolocation = null,
      title,
      publicData,
      metadata,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    }
    if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>
              <TopbarContainer
                customMobileSecondaryActions={
                  <div className={css.mobileSecondaryWrapper}>
                    <FavoriteListing
                      currentUser={currentUser}
                      listing={currentListing}
                      onUnfavoriteListing={() => onToggleFavoriteListing(currentListing)}
                      onFavoriteListing={() => onToggleFavoriteListing(currentListing)}
                      followSubjectMutation={followSubjectMutation}
                      unfollowSubjectMutation={unfollowSubjectMutation}
                      followedSubjects={followedSubjects}
                    />
                    <ShareButton
                      className={css.shareMobile}
                      buttonClassName={css.shareButtonMobile}
                    />
                  </div>
                }
              />
            </LayoutWrapperTopbar>

            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }
    if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>
              <TopbarContainer
                customMobileSecondaryActions={
                  <ShareButton
                    className={css.shareMobile}
                    buttonClassName={css.shareButtonMobile}
                  />
                }
              />
            </LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const listingImages = getImagesByTag(currentListing, 'property');

    const listingImageCaptions = getListingImageCaptions(currentListing);

    const getSchemaImages = variantName =>
      (getListingImages(currentListing) || [])
        .map(image => {
          const { variants } = image.attributes;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards compatibility only
          const { sizes } = image.attributes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const hasLodging = publicData && publicData.lodging && publicData.lodging.hasLodging;
    const propertyVisit = get(publicData, 'propertyVisit', null);

    const nearestTown = get(publicData, 'nearestTown.selectedPlace.address', null);
    const acreage = get(publicData, 'location.acreage', 0);
    const acres = formatNumber(acreage);

    const usState = get(currentListing, 'attributes.publicData.location.usState', null);
    const usCounty = get(currentListing, 'attributes.publicData.location.usCounty', null);
    const facebookImages = getSchemaImages('facebook');
    const twitterImages = getSchemaImages('twitter');
    const schemaImages = facebookImages.map(img => img.url);

    const schemaTitleTemplateId = getListingSchemaTitleType(activities);
    const schemaTitle = intl.formatMessage(
      { id: schemaTitleTemplateId },
      {
        title,
        usState,
      }
    );

    const schema = {
      '@context': 'http://schema.org',
      '@type': 'LocalBusiness',
      description,
      name: schemaTitle,
      image: schemaImages.slice(0, 5),
      address: {
        '@type': 'PostalAddress',
        ...(nearestTown && {
          addressLocality: nearestTown.split(',')[0].trim(),
        }),
        addressRegion: usState,
      },
      ...(customerReviews.length > 0 && {
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: averageListingRating(customerReviews),
          reviewCount: String(customerReviews.length),
        },
        review: customerReviews.slice(0, 3).map(review => ({
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: review.author.attributes.profile.displayName,
          },
          reviewBody: review.reviewContent,
          reviewRating: {
            '@type': 'Rating',
            ratingValue: String(review.rating),
          },
          datePublished: review.createdAt,
        })),
      }),
    };

    const seoBreadcrumbs =
      usState && usCounty
        ? [
            <a href="/" target="_blank" rel="noreferrer">
              <FormattedMessage id="ListingPage.BreadcrumbsHome" />{' '}
            </a>,
            <a href="/us" target="_blank" rel="noreferrer">
              US{' '}
            </a>,
            <a href={`/us/${usState.toLowerCase()}`} target="_blank" rel="noreferrer">
              {usState}{' '}
            </a>,
            <a
              href={`/us/${usState.toLowerCase()}/${usCounty.toLowerCase().replaceAll(' ', '-')}`}
              target="_blank"
              rel="noreferrer"
            >
              {usCounty}{' '}
            </a>,
          ]
        : [];

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={schema}
        noindex={false}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer
              customMobileSecondaryActions={
                <div className={css.mobileSecondaryWrapper}>
                  <FavoriteListing
                    currentUser={currentUser}
                    listing={currentListing}
                    onUnfavoriteListing={() => onToggleFavoriteListing(currentListing)}
                    onFavoriteListing={() => onToggleFavoriteListing(currentListing)}
                    followSubjectMutation={followSubjectMutation}
                    unfollowSubjectMutation={unfollowSubjectMutation}
                    followedSubjects={followedSubjects}
                  />
                  <ShareButton
                    className={css.shareMobile}
                    buttonClassName={css.shareButtonMobile}
                    shareDark
                  />
                </div>
              }
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.topSectionContainer}>
              <Container className={css.sectionImagesContainer}>
                <SectionImages
                  title={title}
                  listing={currentListing}
                  listingImages={listingImages}
                  listingImageCaptions={listingImageCaptions}
                  isOwnListing={isOwnListing}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingType,
                    tab: listingTab,
                  }}
                />
                <div className={css.listingActions}>
                  <div className={css.desktopLayout}>
                    <div className={css.sectionHeadingContainer}>
                      <SectionHeading
                        className={css.heading}
                        richTitle={richTitle}
                        listing={currentListing}
                        metaData={metadata}
                        reviews={customerReviews}
                        reviewsMeta={reviewsMeta}
                        isFetchingReviews={isFetchingReviews}
                      />
                    </div>
                    <div className={css.sectionKeyListingInfo}>
                      <SectionKeyListingInfo
                        className={css.listingInfo}
                        nearestTown={nearestTown}
                        hasLodging={hasLodging}
                        acres={acres}
                      />
                    </div>
                  </div>

                  <div className={css.shareContainer} data-testid="share-container">
                    <ShareButton className={css.share} buttonClassName={css.shareButton} />
                    <FavoriteListing
                      currentUser={currentUser}
                      listing={currentListing}
                      onUnfavoriteListing={() => onToggleFavoriteListing(currentListing)}
                      onFavoriteListing={() => onToggleFavoriteListing(currentListing)}
                      followSubjectMutation={followSubjectMutation}
                      unfollowSubjectMutation={unfollowSubjectMutation}
                      listingFavoriteButton
                      followedSubjects={followedSubjects}
                    />
                  </div>
                </div>
              </Container>
            </div>
            <Container className={css.contentContainer}>
              <div className={css.mainContent}>
                <SectionDescriptionMaybe
                  description={description}
                  publicData={publicData}
                  nearestTown={nearestTown}
                />
                <div className={css.activityFeedContainer}>
                  <ActivityFeed
                    metaData={metadata}
                    reviews={customerReviews}
                    propertyVisit={propertyVisit}
                    listing={currentListing}
                    rootClassName={css.activityFeedRoot}
                    isListing
                    isOwnListing={isOwnListing}
                    activityFeedData={activityFeedData}
                    fetchActivityFeedData={fetchActivityFeedData}
                    fetchTotalLikesData={fetchTotalLikesData}
                    fetchFollowedSubjectsData={fetchFollowedSubjectsData}
                    followedSubjects={followedSubjects}
                    followSubjectMutation={followSubjectMutation}
                    unfollowSubjectMutation={unfollowSubjectMutation}
                    updateFollowedSubjectMutation={updateFollowedSubjectMutation}
                    currentUser={currentUser}
                  />
                </div>
                <hr className={css.totalDivider} />
                <SectionHostMaybe
                  className={css.panelHostMobile}
                  listing={currentListing}
                  onContactUser={() => this.onContactUser(isOwnListing)}
                  currentUser={currentUser}
                  mobileHost
                />
                <hr className={css.totalDivider} />

                <SectionAvailablePackages
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  listingPage
                  followSubjectMutation={followSubjectMutation}
                />

                {propertyVisit && (
                  <SectionPropertyVisit
                    propertyVisit={propertyVisit}
                    rootClassName={css.propertyVisitRoot}
                  />
                )}

                <SectionFAQ faqs={faqsToRender} />
                <SectionRules publicData={publicData} intl={intl} />
                <SectionReviews
                  reviews={reviews}
                  customerReviews={customerReviews}
                  reviewsMeta={reviewsMeta}
                  fetchReviewsError={fetchReviewsError}
                  isFetchingReviews={isFetchingReviews}
                />
                <SectionHostMaybe
                  listing={currentListing}
                  onContactUser={() => this.onContactUser(isOwnListing)}
                  currentUser={currentUser}
                  fullProfile
                  showRate
                />

                <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                />
                {usState && usCounty && <SEOBreadcrumbs breadcrumbs={seoBreadcrumbs} />}
              </div>
              <div className={css.secondaryContent}>
                <SectionHostMaybe
                  className={css.panelHost}
                  listing={currentListing}
                  onContactUser={() => this.onContactUser(isOwnListing)}
                  currentUser={currentUser}
                  hideExtraSpace
                />
              </div>
            </Container>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  currentUser: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  scrollingDisabled: bool.isRequired,
  showListingError: propTypes.error,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    customerReviews,
    reviewsMeta,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    fetchingReviewsDataInProgress,
  } = state.ListingPage;

  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);

    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingError,
    reviews,
    customerReviews,
    reviewsMeta,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    activityFeedData: state.ListingPage.activityFeedData,
    followedSubjects: state.user.followedSubjects,
    reviewsByIds: state.ListingPage.reviewsByIds,
    isFetchingReviews: fetchingReviewsDataInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onToggleFavoriteListing: listing => dispatch(toggleFavoriteListings(listing)),
  fetchActivityFeedData: (listingId, limit, offset) =>
    dispatch(fetchActivityFeed(listingId, limit, offset)),
  fetchTotalLikesData: updateId => dispatch(fetchActivityLogLikes(updateId)),
  fetchFollowedSubjectsData: userId => dispatch(fetchFollowedSubjects(userId)),
  followSubjectMutation: (userId, subjectId, notifyBy) =>
    dispatch(followSubject(userId, subjectId, notifyBy)),
  unfollowSubjectMutation: (id, userId) => dispatch(unfollowSubject(id, userId)),
  updateFollowedSubjectMutation: (id, notifyBy) => dispatch(updateFollowedSubject(id, notifyBy)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
