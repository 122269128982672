/* eslint-disable no-nested-ternary */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionConfirmPayment = ({
  transition,
  user,
  provider,
  ownRole,
  listingTitle,
  buttons,
  earnings,
  remainingTimeFormat,
}) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = get(user, 'attributes.profile.displayName', '');
  const authorDisplayName = get(provider, 'attributes.profile.displayName', '');

  const firstName = getFirstName(displayName);
  const authorFirstName = getFirstName(authorDisplayName);

  return (
    <InboxTransitionBase
      date={transition.createdAt}
      direction="left"
      header={
        isOwnTransition ? (
          <FormattedMessage
            id="BookingStatus.ownTransitionRequest"
            values={{ authorDisplayName: authorFirstName }}
          />
        ) : (
          <FormattedMessage
            id="BookingStatus.transitionRequest"
            values={{ displayName: firstName, listingTitle }}
          />
        )
      }
      buttons={buttons}
      buttonsTitle={
        <FormattedMessage
          id="BookingStatus.ownTransitionRequestButtonTitle"
          values={{
            timer: remainingTimeFormat,
          }}
        />
      }
    >
      {isOwnTransition ? (
        <FormattedMessage id="BookingStatus.ownTransitionRequestSubtext" />
      ) : earnings ? (
        <FormattedMessage id="BookingStatus.transitionRequestSubtext" values={{ earnings }} />
      ) : null}
    </InboxTransitionBase>
  );
};

export default InboxTransitionConfirmPayment;
