import React from 'react';
import SectionPropertyPostUpdate from './ListingUpdate/SectionPropertyPostUpdate';
import SectionPackageUpdate from './ListingUpdate/SectionPackageUpdate';
import SectionReviewsUpdate from './ListingUpdate/SectionReviewsUpdate';
import SectionPhotosUpdate from './ListingUpdate/SectionPhotosUpdate';
import NotificationsIcon from '../../assets/icons/ListingPage/bell-avatar.webp';
import css from './ActivityFeed.css';

const activityComponents = {
  ListingStatusActivity: SectionPropertyPostUpdate,
  NewPackagePhotoActivity: SectionPhotosUpdate,
  NewPackageActivity: SectionPackageUpdate,
  NewReviewActivity: SectionReviewsUpdate,
};

const RenderActivities = ({
  listing,
  reviews,
  currentPackage,
  notificationsEnabled,
  fetchTotalLikesData,
  authUser,
  allActivities,
  handleImageClick,
  isListing,
}) => {
  return (
    <>
      {allActivities.map((activity, index) => {
        if (activity.listingId !== listing?.id?.uuid) {
          return null;
        }

        const renderBellIcon = notificationsEnabled ? (
          <div className={css.bellWrapper}>
            <img src={NotificationsIcon} alt="Notifications" className={css.bellStyles} />
          </div>
        ) : null;

        // eslint-disable-next-line dot-notation
        const ActivityComponent = activityComponents[activity['__typename']];
        if (!ActivityComponent) return null;

        const elements = [
          <ActivityComponent
            key={activity.id}
            listing={listing}
            activity={activity}
            renderBellIcon={renderBellIcon}
            currentPackage={currentPackage}
            isListing={isListing}
            reviews={reviews}
            fetchTotalLikesData={fetchTotalLikesData}
            handleImageClick={handleImageClick}
            authUser={authUser}
          />,
        ];

        // Only display an <hr /> if it's not the last item
        if (index !== allActivities.length - 1) {
          elements.push(<hr key={`hr-${activity.id}`} className={css.sectionDivider} />);
        }

        return elements;
      })}
    </>
  );
};

export default RenderActivities;
