import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { ResponsiveModal } from '..';

import CancelActionButtonsMaybe from '../TransactionPanel/CancelActionButtonMaybe';
import { propTypes } from '../../util/types';

const CancelModal = props => {
  const {
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    showCancelOffer,
    showCancelButtonCustomerWithRefund,
    showCancelButtonCustomerWithoutRefund,
    showCancelButtonProviderEarlyCancel,
    showCancelButtonProviderCancel,
    showCancelButtonCustomerLateCancel,
    showCancelButtonProviderLateCancel,
    cancelInProgress,
    cancelSaleError,
    onCancelSaleCustomerWithRefund,
    onCancelSaleCustomerWithoutRefund,
    onCancelSaleCustomerLate,
    onCancelSaleProviderEarly,
    onCancelSaleProvider,
    onCancelSaleProviderLate,
    onCancelOffer,
    onRefetchTransaction,
    currentTransaction,
  } = props;

  const handleCloseModal = async () => {
    await onRefetchTransaction(currentTransaction.id.uuid);
    onCloseModal();
  };

  const closeButtonMessage = intl.formatMessage({ id: 'CancelModal.goBack' });
  const providerCancelOffer = intl.formatMessage({
    id: 'CancelModal.providerCancelOffer',
  });
  const customerCancelWithRefund = intl.formatMessage({
    id: 'CancelModal.customerCancelWithRefund',
  });
  const customerCancelWithoutRefund = intl.formatMessage({
    id: 'CancelModal.customerCancelWithoutRefund',
  });
  const customerCancelLate = intl.formatMessage({
    id: 'CancelModal.customerCancelLate',
  });
  const providerCancelEarly = intl.formatMessage({ id: 'CancelModal.providerCancelEarly' });
  const providerCancel = intl.formatMessage({ id: 'CancelModal.providerCancel' });
  const providerCancelLate = intl.formatMessage({ id: 'CancelModal.providerCancelLate' });

  let cancelButtonSelector = null;

  if (showCancelButtonCustomerWithRefund) {
    cancelButtonSelector = customerCancelWithRefund;
  } else if (showCancelOffer) {
    cancelButtonSelector = providerCancelOffer;
  } else if (showCancelButtonCustomerWithoutRefund) {
    cancelButtonSelector = customerCancelWithoutRefund;
  } else if (showCancelButtonCustomerLateCancel) {
    cancelButtonSelector = customerCancelLate;
  } else if (showCancelButtonProviderEarlyCancel) {
    cancelButtonSelector = providerCancelEarly;
  } else if (showCancelButtonProviderCancel) {
    cancelButtonSelector = providerCancel;
  } else if (showCancelButtonProviderLateCancel) {
    cancelButtonSelector = providerCancelLate;
  }

  return (
    <ResponsiveModal
      id={id}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={closeButtonMessage}
      content={<span>{cancelButtonSelector}</span>}
      buttons={
        <CancelActionButtonsMaybe
          showCancelOffer={showCancelOffer}
          showCancelButtonCustomerWithRefund={showCancelButtonCustomerWithRefund}
          showCancelButtonCustomerWithoutRefund={showCancelButtonCustomerWithoutRefund}
          showCancelButtonProviderEarlyCancel={showCancelButtonProviderEarlyCancel}
          showCancelButtonProviderCancel={showCancelButtonProviderCancel}
          showCancelButtonCustomerLateCancel={showCancelButtonCustomerLateCancel}
          showCancelButtonProviderLateCancel={showCancelButtonProviderLateCancel}
          cancelInProgress={cancelInProgress}
          cancelSaleError={cancelSaleError}
          onCancelOffer={async () => {
            await onCancelOffer();
            handleCloseModal();
          }}
          onCancelSaleCustomerWithRefund={async () => {
            await onCancelSaleCustomerWithRefund();
            handleCloseModal();
          }}
          onCancelSaleCustomerWithoutRefund={async () => {
            await onCancelSaleCustomerWithoutRefund();
            handleCloseModal();
          }}
          onCancelSaleCustomerLate={async () => {
            await onCancelSaleCustomerLate();
            handleCloseModal();
          }}
          onCancelSaleProviderEarly={async () => {
            await onCancelSaleProviderEarly();
            handleCloseModal();
          }}
          onCancelSaleProvider={async () => {
            await onCancelSaleProvider();
            handleCloseModal();
          }}
          onCancelSaleProviderLate={async () => {
            await onCancelSaleProviderLate();
            handleCloseModal();
          }}
        />
      }
    />
  );
};

const { bool } = PropTypes;

CancelModal.defaultProps = {
  showCancelButtonCustomerWithRefund: false,
  showCancelOffer: false,
  showCancelButtonCustomerWithoutRefund: false,
  showCancelButtonProviderEarlyCancel: false,
  showCancelButtonProviderCancel: false,
  showCancelButtonCustomerLateCancel: false,
  showCancelButtonProviderLateCancel: false,
  cancelInProgress: false,
  cancelSaleError: null,
};

CancelModal.propTypes = {
  intl: intlShape.isRequired,
  showCancelButtonCustomerWithRefund: bool,
  showCancelOffer: bool,
  showCancelButtonCustomerWithoutRefund: bool,
  showCancelButtonProviderEarlyCancel: bool,
  showCancelButtonProviderCancel: bool,
  showCancelButtonCustomerLateCancel: bool,
  showCancelButtonProviderLateCancel: bool,
  cancelInProgress: bool,
  cancelSaleError: propTypes.error,
};

export default injectIntl(CancelModal);
