import React from 'react';
import { FormattedMessage } from 'react-intl';

import InboxTransitionBase from './InboxTransitionBase';

const InboxTransitionBookingStart = ({ transition, buttons, ownRole }) => {
  const isProvider = ownRole === 'provider';

  return (
    <InboxTransitionBase
      header={
        isProvider ? (
          <FormattedMessage id="BookingStatus.transitionBookingStartProvider" />
        ) : (
          <FormattedMessage id="BookingStatus.transitionBookingStart" />
        )
      }
      date={transition.createdAt}
      direction="left"
      buttons={buttons}
    >
      {isProvider && <FormattedMessage id="BookingStatus.transitionBookingStartProviderSubtext" />}
    </InboxTransitionBase>
  );
};

export default InboxTransitionBookingStart;
