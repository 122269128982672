import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ReviewForm } from '../../forms';
import {
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  Page,
  NamedRedirect,
  Avatar,
  NamedLink,
  PrimaryButton,
  ReviewRating,
} from '../../components';
import {
  TRANSITION_COMPLETE,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD_LATE_CANCEL,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL,
} from '../../util/transaction';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { TopbarContainer } from '..';
import { loadData, sendReview, fetchReviewData, setInitialValues } from './ReviewPage.duck';
import { ensureTransaction } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import ClockImage from '../../assets/images/splash/clock.png';

import css from './ReviewPage.css';

const { UUID } = sdkTypes;
const PROVIDER = 'provider';
const CUSTOMER = 'customer';

const ReviewPageComponent = props => {
  const {
    intl,
    sendReviewInProgress,
    sendReviewError,
    history,
    params,
    initData,
    onSendReview,
    transaction,
    currentUser,
    fetchTransactionError,
    onFetchReviewData,
  } = props;
  const [isReviewLoaded, setIsReviewLoaded] = useState(false);
  const [isTransactionLoaded, setIsTransactionLoaded] = useState(false);

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        await initData(params);

        setIsTransactionLoaded(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setIsTransactionLoaded(true);
      }
    };

    fetchTransaction();
  }, [initData, params]);

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const review = await onFetchReviewData(transaction.id, currentUser.id);

        if (review) {
          history.push(
            createResourceLocatorString('ReviewPageV2', routeConfiguration(), {
              ...params,
              id: review.id,
            })
          );

          return;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsReviewLoaded(true);
      }
    };

    if (transaction?.id?.uuid && currentUser?.id?.uuid) {
      fetchReview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction?.id?.uuid, currentUser?.id?.uuid]);

  if (!isReviewLoaded) {
    return null;
  }

  const { transactionRole } = params;
  const currentTransaction = ensureTransaction(transaction);
  const txId = get(transaction, 'id.uuid', null);
  const listingTitle = get(currentTransaction, 'listing.attributes.title', null);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;
  const transactionPackageActivity = get(
    currentTransaction,
    'attributes.protectedData.packageLineItem.activity',
    null
  );
  const lastTransition = get(currentTransaction, 'attributes.lastTransition', null);

  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxV2BasePage" params={{ tab: 'sales' }} />;
  }
  if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxV2BasePage" params={{ tab: 'orders' }} />;
  }
  if (isTransactionLoaded && !isDataAvailable) {
    // eslint-disable-next-line no-console
    console.error('No Transaction Data Available');
    return <NamedRedirect name="InboxV2BasePage" params={{ tab: 'orders' }} />;
  }

  let otherUserDisplayName = '';
  const currentUserIsCustomer = params && params.transactionRole === 'customer';

  if (currentUserIsCustomer) {
    otherUserDisplayName = get(
      transaction,
      'provider.attributes.profile.displayName',
      'the landowner'
    );
  } else {
    otherUserDisplayName = get(
      transaction,
      'customer.attributes.profile.displayName',
      'the sportsman'
    );
  }
  const reviewee = <span className={css.reviewee}>{otherUserDisplayName.split(' ')[0]}</span>;
  const onSubmitReview = values => {
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      // eslint-disable-next-line no-console
      .then(() => {
        return history.push(
          createResourceLocatorString(
            currentUserIsCustomer ? 'OrderPage' : 'SalePage',
            routeConfiguration(),
            { id: txId },
            {}
          )
        );
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error', e);
      });
  };

  if (!isTransactionLoaded) {
    return null;
  }
  const isReviewable =
    lastTransition === TRANSITION_COMPLETE ||
    (isCustomerRole && lastTransition === TRANSITION_REVIEW_1_BY_PROVIDER) ||
    (isProviderRole && lastTransition === TRANSITION_REVIEW_1_BY_CUSTOMER);

  const reviewExpired =
    lastTransition === TRANSITION_EXPIRE_REVIEW_PERIOD ||
    (isCustomerRole &&
      [
        TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
        TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD_LATE_CANCEL,
      ].includes(lastTransition)) ||
    (isProviderRole && lastTransition === TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD);

  const alreadyReviewed =
    [TRANSITION_REVIEW_2_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(lastTransition) ||
    (isCustomerRole &&
      [
        TRANSITION_REVIEW_BY_CUSTOMER_LATE_CANCEL,
        TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
      ].includes(lastTransition)) ||
    (isProviderRole && lastTransition === TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD);

  const reviewedbyOne =
    (isCustomerRole && lastTransition === TRANSITION_REVIEW_1_BY_CUSTOMER) ||
    (isProviderRole && lastTransition === TRANSITION_REVIEW_1_BY_PROVIDER);

  const reviewTooEarly = !isReviewable && !reviewExpired && !alreadyReviewed && !reviewedbyOne;

  let reviewPageContent = null;

  if (isReviewable) {
    reviewPageContent = (
      <div className={css.reviewWrapper}>
        <div className={css.reviewTitle}>
          <div className={css.avatars}>
            <Avatar showBadge={false} className={css.avatarLeft} user={transaction.customer} />
            <Avatar showBadge={false} className={css.avatarRight} user={transaction.provider} />
          </div>

          <FormattedMessage id="ReviewPage.subTitle" values={{ reviewee }} />

          <div className={css.listingTitle}>{listingTitle}</div>
        </div>

        <div className={css.reviewContent}>
          <ReviewForm
            onSubmit={onSubmitReview}
            sendReviewInProgress={sendReviewInProgress}
            sendReviewError={sendReviewError}
            isCustomer={currentUserIsCustomer}
            initialValues={{
              reviewRating: '5',
            }}
            packageActivity={transactionPackageActivity}
          />
        </div>
      </div>
    );
  }

  if (reviewExpired) {
    reviewPageContent = (
      <div className={css.reviewWrapper}>
        <div className={css.reviewTitle}>
          <div className={css.avatars}>
            <img src={ClockImage} alt="clock" className={css.clockImage} />
          </div>
          <div className={css.expiredTitle}>
            <FormattedMessage id="ReviewPage.expiredTitle" />
          </div>
        </div>

        <div className={css.reviewContent}>
          <FormattedMessage id="ReviewPage.expiredInfo" />
        </div>
        <div className={css.reviewCTA}>
          <NamedLink name="ContactPage">
            <PrimaryButton>
              <FormattedMessage id="ReviewPage.email" />
            </PrimaryButton>
          </NamedLink>
        </div>
      </div>
    );
  }

  if (alreadyReviewed || reviewedbyOne) {
    const reviews = get(currentTransaction, 'reviews', []);
    const review = reviews.find(obj => {
      if (isCustomerRole) {
        return obj?.attributes?.type === 'ofProvider';
      }
      return obj?.attributes?.type === 'ofCustomer';
    });
    reviewPageContent = (
      <div className={css.reviewWrapper}>
        <div className={css.reviewTitle}>
          <div className={css.avatars}>
            <Avatar className={css.avatarLeft} user={transaction.customer} />
            <Avatar className={css.avatarRight} user={transaction.provider} />
          </div>

          <FormattedMessage id="ReviewPage.leftReviewTitle" values={{ reviewee }} />

          <div className={css.listingTitle}>{listingTitle}</div>
        </div>
        {alreadyReviewed ? (
          <>
            <div className={css.starRating}>
              <ReviewRating rating={review.attributes.rating} gold />
            </div>
            <div className={css.leftReviewContent}>
              <strong>
                <FormattedMessage id="ReviewPage.yourReview" />
              </strong>

              <div>{review.attributes.content}</div>
            </div>
          </>
        ) : (
          <div className={css.reviewContent}>
            <FormattedMessage id="ReviewPage.awaitingReview" values={{ reviewee }} />
          </div>
        )}
        <div className={css.reviewCTA}>
          {alreadyReviewed ? (
            <NamedLink
              name="ProfilePage"
              params={{
                id: isCustomerRole ? transaction.provider.id.uuid : transaction.customer.id.uuid,
              }}
            >
              <PrimaryButton>
                <FormattedMessage id="ReviewPage.goToProfile" values={{ reviewee }} />
              </PrimaryButton>
            </NamedLink>
          ) : (
            <NamedLink name="LandingPage">
              <PrimaryButton>
                <FormattedMessage id="ReviewPage.goHome" />
              </PrimaryButton>
            </NamedLink>
          )}
        </div>
      </div>
    );
  }
  if (reviewTooEarly) {
    reviewPageContent = (
      <div className={css.reviewWrapper}>
        <div className={css.reviewTitle}>
          <div className={css.avatars}>
            <img src={ClockImage} alt="clock" className={css.clockImage} />
          </div>
          <div className={css.expiredTitle}>
            <FormattedMessage id="ReviewPage.notYetTitle" />
          </div>
        </div>
        <div className={css.reviewContent}>
          <strong>
            <FormattedMessage id="ReviewPage.notYetSubtitle" />
          </strong>
        </div>
        <div className={css.reviewContent}>
          <FormattedMessage id="ReviewPage.notYet" />
        </div>
        <div className={css.reviewCTA}>
          <NamedLink name="ContactPage">
            <PrimaryButton>
              <FormattedMessage id="ReviewPage.email" />
            </PrimaryButton>
          </NamedLink>
        </div>
      </div>
    );
  }

  return (
    <Page title={intl.formatMessage({ id: 'ReviewPage.title' })} scrollingDisabled={false}>
      <LayoutWrapperTopbar>
        <TopbarContainer currentPage="ReviewPage" customDesktopSecondaryActions={<span />} />
      </LayoutWrapperTopbar>

      <LayoutWrapperMain>{reviewPageContent}</LayoutWrapperMain>
    </Page>
  );
};

const { object, bool, shape, func } = PropTypes;

ReviewPageComponent.defaultProps = {
  transaction: null,
};

ReviewPageComponent.propTypes = {
  transaction: object,
  sendReviewInProgress: bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  sendReviewError: propTypes.error,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};
const mapStateToProps = (state, ownProps) => {
  const { currentUser } = state.user;
  const { sendReviewInProgress, sendReviewError, fetchTransactionError } = state.ReviewPage;
  const txId = get(ownProps, 'params.id', null);
  const id = new UUID(txId);
  const ref = { id, type: 'transaction' };

  const transactions = getMarketplaceEntities(state, ref ? [ref] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;

  return {
    transaction,
    sendReviewInProgress,
    sendReviewError,
    currentUser,
    fetchTransactionError,
  };
};

const mapDispatchToProps = dispatch => ({
  initData: params => dispatch(loadData(params)),
  onSendReview: (role, tx, reviewRating, reviewContent) =>
    dispatch(sendReview(role, tx, reviewRating, reviewContent)),
  onFetchReviewData: (txId, userId) => dispatch(fetchReviewData(txId, userId)),
});

const ReviewPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ReviewPageComponent);

ReviewPage.setInitialValues = initialValues => setInitialValues(initialValues);

export default ReviewPage;
