/* eslint-disable react/no-danger */
import React, { Component, useEffect, useState } from 'react';
import { string, func, oneOfType, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { HashLink as Link } from 'react-router-hash-link';
import Skeleton from 'react-loading-skeleton';
import { AvatarMedium, NamedLink, SecondaryButton, EarlyAccessModal } from '..';
import { ensureUser, ensureCurrentUser } from '../../util/data';
import { scrollWithOffset } from '../../util/listing';
import { propTypes } from '../../util/types';
import { ReactComponent as IconStar } from '../../assets/icons/rating-star-gold.svg';
import { ReactComponent as VerificationIcon } from './verification.svg';
import { hasPremiumMembership } from '../../util/user';

import ContactLandownerButton from '../ContactLandownerButton/ContactLandownerButton';

import css from './UserCard.css';
import { fetchReviewsData } from '../../ducks/user.duck';

class ExpandableBio extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { className, bio } = this.props;

    return (
      <p
        className={className}
        dangerouslySetInnerHTML={{
          __html: bio,
        }}
      />
    );
  }
}

ExpandableBio.defaultProps = { className: null };

ExpandableBio.propTypes = {
  className: string,
  bio: string.isRequired,
};

const UserCard = props => {
  const {
    landowner,
    currentUser,
    onContactUser,
    fullProfile,
    mobileHost,
    showRate,
    noBio,
    isEarlyAccess,
    isContactDisabled,
    hideExtraSpace,
    onFetchReviews,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [reviewsRatingAvg, setReviewsRatingAvg] = useState(0);
  const [isFetchingReviews, setIsFetchingReviews] = useState(false);

  const isPremium = hasPremiumMembership(currentUser);

  const authorHasStripe = get(landowner, 'attributes.profile.publicData.hasStripe', null);
  const userIsCurrentUser = landowner && currentUser && landowner.id?.uuid === currentUser.id?.uuid;

  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(landowner) : ensureUser(landowner);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { displayName, bio, metadata } = ensuredUser.attributes.profile;

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setIsFetchingReviews(true);
        const reviewsData = await onFetchReviews(landowner.id);

        if (reviewsData) {
          setReviews(reviewsData.reviews);
          setReviewsRatingAvg(reviewsData.reviewsRatingAvg);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        setIsFetchingReviews(false);
      }
    };

    if (landowner?.id?.uuid) {
      fetchReviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landowner?.id?.uuid]);

  const handleContactUserClick = () => {
    if (isEarlyAccess && !isPremium) {
      setIsModalOpen(true);
    } else {
      onContactUser(landowner);
    }
  };

  const hasBio = !!bio;

  const contact = onContactUser && (
    <div>
      <ContactLandownerButton
        landowner={landowner}
        onContactUser={handleContactUserClick}
        mobileHost={mobileHost}
        isDisabled={isContactDisabled}
      />
    </div>
  );

  const goSeeBio = (
    <Link to="#host" scroll={scrollWithOffset}>
      <SecondaryButton className={mobileHost ? css.seeBioMobile : css.seeBioButton}>
        <FormattedMessage id="ListingPage.viewBioButton" />
      </SecondaryButton>
    </Link>
  );

  const editProfile = userIsCurrentUser ? (
    <NamedLink className={css.editProfile} name="ProfileSettingsBasePage">
      <FormattedMessage id="ListingPage.editProfileLink" />
    </NamedLink>
  ) : null;

  const links =
    ensuredUser.id && !noBio ? (
      <div className={css.linkButtons}>
        {!isCurrentUser && contact}
        {!isCurrentUser && !fullProfile && goSeeBio}
      </div>
    ) : null;

  const rawRate = get(metadata, 'allTimeStats.responseRate', 0);
  const rate = parseInt(rawRate, 10);

  return (
    <div className={css.userCardContainer}>
      <EarlyAccessModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <div className={css.content}>
        <div
          className={
            hasBio
              ? css.flexContainer
              : `${css.flexContainer} ${hideExtraSpace ? '' : css.withExtraSpace}`
          }
        >
          <div className={css.avatarContainer}>
            {authorHasStripe && <VerificationIcon className={css.verifictionIcon} />}
            <AvatarMedium className={css.avatar} user={landowner} />
          </div>
          <div className={css.hostedContainer}>
            <p className={css.hostedBy}>
              <FormattedMessage id="UserCard.hostedBy" />
            </p>

            {ensuredUser?.id?.uuid && (
              <NamedLink
                className={css.link}
                name="ProfilePage"
                params={{ id: ensuredUser.id.uuid }}
              >
                <h3 className={css.heading}>{displayName}</h3>
              </NamedLink>
            )}
            <div className={css.headingRow}>{editProfile}</div>

            <div className={css.reviewWrapper}>
              {isFetchingReviews ? (
                <div className={css.reviewLoaderWrapper}>
                  <Skeleton borderRadius={4} height={24} width="100%" />
                </div>
              ) : (
                <>
                  {reviews.length > 0 && (
                    <>
                      <IconStar className={css.reviewStarIcon} />

                      <span className={css.reviewAvg}>{reviewsRatingAvg}</span>
                      <span className={css.reviewTotal}>
                        (
                        <FormattedMessage
                          id="UserCard.reviews"
                          values={{
                            count: reviews.length,
                          }}
                        />
                        )
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {showRate && rate > 49 && (
          <div className={css.responseRateWrapper}>
            <div>
              <b>
                <FormattedMessage id="ListingPage.responseRate" values={{ rate }} />
              </b>
            </div>
          </div>
        )}

        <div className={css.info}>
          {hasBio && fullProfile ? <ExpandableBio className={css.desktopBio} bio={bio} /> : null}
        </div>
        {!isCurrentUser && !fullProfile && !noBio && (
          <div className={mobileHost ? css.linkContainerMobile : css.linkContainer}>
            <div className={css.linkDescription}>
              <FormattedMessage id="UserCard.linkDescription" />
            </div>

            {links}
          </div>
        )}
        {!isCurrentUser && fullProfile && (
          <div
            className={
              hasBio ? css.fullProfileContact : `${css.fullProfileContact} ${css.withExtraSpace}`
            }
          >
            {contact}
          </div>
        )}
      </div>
      {hasBio && fullProfile ? <ExpandableBio className={css.mobileBio} bio={bio} /> : null}
    </div>
  );
};

UserCard.defaultProps = {
  landowner: null,
  currentUser: null,
  fullProfile: true,
  mobileHost: false,
  showRate: false,
  hideExtraSpace: false,
};

UserCard.propTypes = {
  landowner: oneOfType([propTypes.landowner, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func.isRequired,
  fullProfile: bool,
  mobileHost: bool,
  showRate: bool,
  hideExtraSpace: bool,
};

const mapDispatchToProps = dispatch => ({
  onFetchReviews: userId => dispatch(fetchReviewsData(userId)),
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(UserCard);
