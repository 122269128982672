import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionAcceptedChanges = ({ transition, user, ownRole, buttons, daysUntilStart }) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = get(user, 'attributes.profile.displayName', '');
  const firstName = getFirstName(displayName);

  return (
    <InboxTransitionBase
      header={
        isOwnTransition ? (
          <FormattedMessage
            id="BookingStatus.ownTransitionAcceptedChanges"
            values={{ displayName: firstName, days: daysUntilStart }}
          />
        ) : (
          <FormattedMessage
            id="BookingStatus.transitionAcceptedChanges"
            values={{ displayName: firstName, days: daysUntilStart }}
          />
        )
      }
      date={transition.createdAt}
      direction="right"
      variation="dark"
      buttons={buttons}
    >
      {isOwnTransition ? (
        <FormattedMessage
          id="BookingStatus.ownTransitionAcceptedChangesSubtext"
          values={{ displayName: firstName }}
        />
      ) : (
        <FormattedMessage id="BookingStatus.transitionAcceptedChangesSubtext" />
      )}
    </InboxTransitionBase>
  );
};

export default InboxTransitionAcceptedChanges;
