/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/**
 *
 *
 * THIS PROFILE PAGE IS DEPRECATED!!!
 * This page was moved to the new UI!
 * Make any necessary changes in lambtrust-ui
 *
 *
 *
 *
 */
import axios from 'axios';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import config from '../../config';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const FETCH_REVIEWS_DATA_REQUEST = 'app/ProfilePage/FETCH_REVIEWS_DATA_REQUEST';
export const FETCH_REVIEWS_DATA_SUCCESS = 'app/ProfilePage/FETCH_REVIEWS_DATA_SUCCESS';
export const FETCH_REVIEWS_DATA_ERROR = 'app/ProfilePage/FETCH_REVIEWS_DATA_ERROR';

export const FETCH_LISTINGS_RATINGS_REQUEST = 'app/ProfilePage/FETCH_LISTINGS_RATINGS_REQUEST';
export const FETCH_LISTINGS_RATINGS_SUCCESS = 'app/ProfilePage/FETCH_LISTINGS_RATINGS_SUCCESS';
export const FETCH_LISTINGS_RATINGS_ERROR = 'app/ProfilePage/FETCH_LISTINGS_RATINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  reviewsRatingAvg: 0,
  fetchingReviewsDataInProgress: false,
  userListingRatings: [],
  fetchingListingRatingsInProgress: false,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case FETCH_REVIEWS_DATA_REQUEST:
      return {
        ...state,
        fetchingReviewsDataInProgress: true,
      };
    case FETCH_REVIEWS_DATA_SUCCESS: {
      return {
        ...state,
        reviews: payload.reviews,
        fetchingReviewsDataInProgress: false,
        reviewsRatingAvg: payload.reviewsRatingAvg,
      };
    }

    case FETCH_LISTINGS_RATINGS_REQUEST:
      return {
        ...state,
        fetchingListingRatingsInProgress: true,
      };

    case FETCH_LISTINGS_RATINGS_SUCCESS:
      return {
        ...state,
        fetchingListingRatingsInProgress: false,
        userListingRatings: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsDataRequest = () => ({
  type: FETCH_REVIEWS_DATA_REQUEST,
});

export const fetchReviewsDataSuccess = reviews => ({
  type: FETCH_REVIEWS_DATA_SUCCESS,
  payload: reviews,
});

export const fetchListingsRatingsRequest = () => ({
  type: FETCH_LISTINGS_RATINGS_REQUEST,
});

export const fetchListingsRatingsSuccess = ratings => ({
  type: FETCH_LISTINGS_RATINGS_SUCCESS,
  payload: ratings,
});

// ================ Thunks ================ //

export const queryUserListings = userId => async (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));

  try {
    const response = await sdk.listings.query({
      author_id: userId,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    });
    // Pick only the id and type properties from the response listings
    const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
    const listingIds = listingRefs.map(ref => ref.id.uuid);

    // eslint-disable-next-line no-use-before-define
    await dispatch(fetchListingsRatings(listingIds));

    dispatch(addMarketplaceEntities(response));
    dispatch(queryListingsSuccess(listingRefs));
    return response;
  } catch (e) {
    return dispatch(queryListingsError(storableError(e)));
  }
};

export const fetchReviewsData = userId => async (dispatch, getState, sdk) => {
  dispatch(fetchReviewsDataRequest());
  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        query FetchUserReviews($userId: ID!) {
          UserReviews(input: { userId: $userId }) {
            items {
              customer {
                authorId
                authorRole
                createdAt
                id
                listingId
                packageId
                rating
                reviewContent
                reviewQuestions
                transactionId
                status
                photos
              }
              provider {
                authorId
                authorRole
                createdAt
                id
                listingId
                packageId
                rating
                reviewContent
                reviewQuestions
                transactionId
                status
                photos
              }
            }
            meta {
              ratingAvg
              totalReviews
            }
          }
        }`,
        variables: {
          userId: userId.uuid,
        },
      },
      {
        headers: {
          'X-Api-Key': `${process.env.REACT_APP_ACTIVITY_GRAPHQL_API_KEY}`,
        },
      }
    );

    const reviews = response.data?.data?.UserReviews?.items;
    const reviewsMeta = response.data?.data?.UserReviews?.meta;

    // Unique author ids
    const reviewsAuthorsIdsSet = new Set();

    reviews.forEach(item => {
      if (item.customer) {
        reviewsAuthorsIdsSet.add(item.customer.authorId);
      }
      if (item.provider) {
        reviewsAuthorsIdsSet.add(item.provider.authorId);
      }
    });

    const reviewsAuthorsIds = Array.from(reviewsAuthorsIdsSet);

    // Fetch user data for each author ID
    const authorDataPromises = reviewsAuthorsIds.map(authorId =>
      sdk.users.show({
        id: authorId,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      })
    );
    const authorsData = (await Promise.all(authorDataPromises)).map(authorResponse => {
      const entities = denormalisedResponseEntities(authorResponse);
      const user = entities[0];

      return user;
    });

    // Create a map of author data for quick lookup
    const authorsMap = new Map();
    authorsData.forEach(author => {
      authorsMap.set(author.id.uuid, author);
    });

    const updatedReviews = reviews.map(review => {
      if (review.customer && authorsMap.has(review.customer.authorId)) {
        review.customer.author = authorsMap.get(review.customer.authorId);
      }
      if (review.provider && authorsMap.has(review.provider.authorId)) {
        review.provider.author = authorsMap.get(review.provider.authorId);
      }
      return review;
    });

    reviewsMeta.ratingAvg = parseFloat(reviewsMeta.ratingAvg).toFixed(1);

    // Dispatching action to handle successful data retrieval
    dispatch(
      fetchReviewsDataSuccess({
        reviews: updatedReviews,
        reviewsRatingAvg: reviewsMeta.ratingAvg,
      })
    );
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchListingsRatings = listingIds => async dispatch => {
  dispatch(fetchListingsRatingsRequest());

  try {
    const response = await axios.post(
      config.graphQLapi,
      {
        query: `
        query FetchListingsRatings($listingIds: [ID!]!) {
          ListingsRatings(input: {listingIds: $listingIds}) {
            items {
              averageRating
              listingId
            }
          }
        }`,
        variables: {
          listingIds,
        },
      },
      {
        headers: {
          'X-Api-Key': `${process.env.REACT_APP_ACTIVITY_GRAPHQL_API_KEY}`,
        },
      }
    );

    const ratings = response.data?.data?.ListingsRatings?.items;

    dispatch(fetchListingsRatingsSuccess(ratings));

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

// eslint-disable-next-line no-unused-vars
export const loadData = userId => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId)),
    dispatch(fetchReviewsData(userId)),
  ]);
};
