/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import css from './OptionMenu.css';
import { ReactComponent as Contact } from '../../assets/icons/contact.svg';
import { ReactComponent as Heart } from '../../assets/icons/icon-heart.svg';
import { ReactComponent as HeartFilled } from '../../assets/icons/icon-heart-filled.svg';
import {
  likeActivityLogItem,
  unlikeActivityLogItem,
} from '../../containers/ListingPage/ListingPage.duck';
import { toast } from '../Toaster/Toaster';

import { ReactComponent as Share } from '../../assets/icons/icon-share.svg';

const OptionMenu = props => {
  const { updateId, listingUrl, authUser, fetchTotalLikesData } = props;
  const [hasLiked, setHasLiked] = useState(false);
  const [totalLikes, setTotalLikes] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (updateId && fetchTotalLikesData) {
      fetchTotalLikesData(updateId)
        .then(response => {
          const liked = response?.likes?.some(like => like?.userId === authUser?.id?.uuid);
          setHasLiked(liked);
          setTotalLikes(response?.totalLikes || 0);
        })
        .catch(error => console.error('Error fetching likes:', error));
    }
  }, []);

  const onLikeUpdate = () => {
    if (authUser) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (hasLiked) {
        setHasLiked(false);
        setTotalLikes(prevLikes => prevLikes - 1);

        const newTimeoutId = setTimeout(async () => {
          try {
            await unlikeActivityLogItem(updateId, authUser.id.uuid);
          } catch (error) {
            setHasLiked(true);
            setTotalLikes(prevLikes => prevLikes + 1);
          }
        }, 1000);

        setTimeoutId(newTimeoutId);
      } else {
        setHasLiked(true);
        setTotalLikes(prevLikes => prevLikes + 1);

        const newTimeoutId = setTimeout(async () => {
          try {
            await likeActivityLogItem(updateId, authUser.id.uuid);
          } catch (error) {
            setHasLiked(false);
            setTotalLikes(prevLikes => prevLikes - 1);
          }
        }, 1000);

        setTimeoutId(newTimeoutId);
      }
    } else {
      window.open(
        `${process.env.REACT_APP_CANONICAL_ROOT_URL || 'https://landtrust.com'}/login`,
        '_blank'
      );
    }
  };

  const hostUrl = process.env.REACT_APP_CANONICAL_ROOT_URL || 'https://landtrust.com';

  const shareUrl = listingUrl && `${listingUrl}/inquiry/activity`;

  // Fallback method for manual copying to clipboard
  const fallbackCopyTextToClipboard = text => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      toast(
        <span className={css.toastStyles}>
          <FormattedMessage id="OptionMenu.linkCTA" />
        </span>
      );
    } catch (err) {
      console.error('Fallback: Could not copy text: ', err);
    }
    document.body.removeChild(textArea);
  };

  const handleShare = () => {
    toast(
      <span className={css.toastStyles}>
        <FormattedMessage id="OptionMenu.linkCTA" />
      </span>
    );

    const url = `${hostUrl}/fieldnotes?id=${updateId}`;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile && navigator.share) {
      // Trigger the native iOS share menu
      navigator
        .share({
          url,
        })
        .then(() => {
          navigator.clipboard.writeText(url).catch(err => {
            console.error('Failed to copy link:', err);
          });
        })
        .catch(error => {
          console.error('Error showing share menu:', error);
        });
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(url).catch(err => {
        console.error('Failed to copy using Clipboard API, falling back', err);
        fallbackCopyTextToClipboard(url);
      });
    } else {
      fallbackCopyTextToClipboard(url);
    }
  };

  const sendInquiry = () => {
    window.open(shareUrl, '_blank');
  };

  return (
    <div className={css.actionButtonWrapper}>
      <div className={css.actionStyles} onClick={onLikeUpdate} role="button">
        <div className={css.tooltipButtonStyles}>
          {hasLiked ? (
            <HeartFilled className={css.iconStyles} />
          ) : (
            <Heart className={css.iconStyles} />
          )}
        </div>
        <FormattedMessage id="OptionMenu.likeCTA" values={{ totalLikes }} />
      </div>
      <div className={css.actionStyles} onClick={sendInquiry} role="button">
        <div className={css.tooltipButtonStyles}>
          <Contact className={css.iconStyles} />
        </div>
        <FormattedMessage id="OptionMenu.contactCTA" />
      </div>
      <div className={css.actionStyles} onClick={handleShare} role="button">
        <div className={css.tooltipButtonStyles}>
          <Share className={css.iconStyles} />
        </div>
        <FormattedMessage id="OptionMenu.shareCTA" />
      </div>
    </div>
  );
};

const { bool, string } = PropTypes;

OptionMenu.defaultProps = {
  authUser: null,
};
OptionMenu.propTypes = {
  updateId: string.isRequired,
  listingUrl: string.isRequired,
  authUser: bool,
};

export default OptionMenu;
