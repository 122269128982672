import React, { useState } from 'react';
import { object, string } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { updateTransactionMetadata } from '../../ducks/Transaction.duck';
import { getArchivedTransactions, storeArchivedTransaction } from '../../util/transaction';
import { ArchiveModal, SecondaryButton } from '..';
import { ReactComponent as IconArchive } from '../../assets/icons/icon-archive.svg';

import css from './ArchiveButton.css';

const ArchiveButtonComponent = ({
  currentTransaction,
  transaction,
  onUpdateMetadata,
  userType,
  onArchive,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);

  const isArchived = get(
    currentTransaction,
    `attributes.metadata.archivedBy${userType.charAt(0).toUpperCase() + userType.slice(1)}`,
    false
  );

  const archivedTransactions = getArchivedTransactions(userType);
  const isLocallyArchived = archivedTransactions.some(
    archivedTx => archivedTx.id === currentTransaction?.id?.uuid
  );

  if (!currentTransaction || isLocallyArchived || isArchived) {
    return null;
  }

  const handleArchive = async () => {
    setIsArchiving(true);

    let metadata = {};

    if (userType === 'customer') {
      metadata = {
        archivedByCustomer: new Date().toISOString(),
      };
    } else if (userType === 'provider') {
      metadata = {
        archivedByProvider: new Date().toISOString(),
      };
    }

    try {
      await onUpdateMetadata(currentTransaction.id.uuid, metadata);

      // Store the transaction in localStorage before closing the modal
      if (transaction) {
        storeArchivedTransaction(transaction, userType);
      }

      setIsModalOpen(false);
      setIsArchiving(false);
      onArchive();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Archive failed:', error);
    } finally {
      setIsArchiving(false);
    }
  };

  if (isArchived) {
    return null;
  }

  return (
    <>
      <SecondaryButton className={css.button} isSmall onClick={() => setIsModalOpen(true)}>
        <IconArchive className={css.icon} />
        <FormattedMessage id="ArchiveButton.archive" />
      </SecondaryButton>

      <ArchiveModal
        id="archive-transaction-modal"
        isOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        onArchive={handleArchive}
        disabled={isArchiving}
        inProgress={isArchiving}
      />
    </>
  );
};

ArchiveButtonComponent.propTypes = {
  currentTransaction: object.isRequired,
  userType: string.isRequired,
  transaction: object,
};

ArchiveButtonComponent.defaultProps = {
  transaction: null,
};

const mapDispatchToProps = dispatch => ({
  onUpdateMetadata: (transactionId, metadata) =>
    dispatch(updateTransactionMetadata(transactionId, metadata)),
});

const ArchiveButton = connect(
  null,
  mapDispatchToProps
)(ArchiveButtonComponent);

export default ArchiveButton;
