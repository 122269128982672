/* eslint-disable no-shadow */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { shape, func, arrayOf, bool } from 'prop-types';
import moment from 'moment';
import format from 'date-fns/format';
import Decimal from 'decimal.js';
import { get } from 'lodash';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import { composeValidators, required } from '../../util/validators';
import { daysAdded } from '../../util/dates';
import { getLodgingTax } from '../../util/listing';
import { getRVDetails, hasLodgingForPackage } from '../../util/package';
import { generateTransaction, isLegacyPaymentFlow } from '../../util/transaction';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  Form,
  FieldCheckbox,
  FieldPartySizeSelect,
  FieldDates,
  PrimaryButton,
  PackageAvailabilityNotifyButton,
  FormValidationTooltip,
  EarlyAccessButtonWithTimer,
  CredovaPlugin,
  PackageBadges,
} from '..';
import { PACKAGE_QUANTITY_BADGE_VARIANTS } from '../PackageQuantityBadge/PackageQuantityBadge';
import EstimatedBreakdownMaybe from '../../forms/BookingDatesForm/EstimatedBreakdownMaybe';
import routeConfiguration from '../../routeConfiguration';

import css from './PackagePricingPanel.css';

const { Money } = sdkTypes;

const PackagePricingPanel = ({ intl, ...rest }) => {
  const {
    history,
    packageInfo,
    includedLodging,
    optionalLodging,
    timeSlots,
    listing,
    unitType,
    startingPrice,
    currentUser,
    callSetInitialValues,
    onInitializeCardPaymentData,
    sticky,
    rootClassName,
    transaction,
    isAvailable,
    outOfBookingRange,
    isBlockedByEarlyAccess,
    formattedPackageAvailableDate,
    availabilityPackageBadges,
    setIsPackageAvailabilityModalOpen,
    isOnWaitingList,
    setIsEarlyAccessModalOpen,
    setIsCredovaModalOpen,
    currentUserInit,
    onPriceChange,
    showCredova,
  } = rest;
  const { price: unitPrice, state: listingState } = listing.attributes;
  const { days, isPublished } = packageInfo || {};

  // guest/party size info
  const packageMinParty = get(packageInfo, 'guests.min', 1);
  const packageMaxParty = get(packageInfo, 'guests.max', 1);

  const currentTransaction = transaction || get(history, 'location.state.currentTransaction', null);
  const hasLodging = hasLodgingForPackage(packageInfo);

  const handleFormSubmit = values => {
    const selectedPartySize = values && values.partySize ? values.partySize : packageMinParty;

    const formattedValues = {
      bookingDates: values.dates,
      party: parseInt(selectedPartySize, 10),
      lodgingAddons: values.lodgingAddons,
    };

    const { bookingDates, ...bookingData } = formattedValues;

    const initialValues = {
      listing,
      bookingData,
      includeLodging: values?.lodgingAddOns?.length > 0 || hasLodging,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !currentUser;
    const routes = routeConfiguration();

    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);
    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    const nextRoute = createResourceLocatorString(
      'CheckoutPackagePage',
      routes,
      {
        id: listing.id.uuid,
        packageId: packageInfo.id,
        slug: createSlug(listing.attributes.title),
      },
      {}
    );

    if (isLegacyPaymentFlow(currentTransaction)) {
      history.push(nextRoute, { currentTransaction: null });
    } else {
      history.push(nextRoute, { currentTransaction });
    }
  };

  const renderPackageBadges = () => {
    const notifyButton = (
      <PackageAvailabilityNotifyButton
        onClick={() => {
          setIsPackageAvailabilityModalOpen(true);
        }}
      />
    );

    const getNotified = (
      <div className={css.getNotified}>
        {isOnWaitingList ? (
          <b>
            <FormattedMessage id="PackagePage.waitingListNotified" />
          </b>
        ) : (
          <FormattedMessage id="PackagePricingPanel.soldOutgetNotified" />
        )}
      </div>
    );

    let badge;

    if (!isAvailable) {
      badge = {
        variant: PACKAGE_QUANTITY_BADGE_VARIANTS.DANGER,
        text: (
          <FormattedMessage
            id="PackagePricingPanel.soldOutSimple"
            values={{ date: formattedPackageAvailableDate }}
          />
        ),
      };
    } else if (outOfBookingRange) {
      badge = {
        variant: PACKAGE_QUANTITY_BADGE_VARIANTS.WARNING,
        text: (
          <FormattedMessage
            id="PackagePricingPanel.outOfRange"
            values={{ date: formattedPackageAvailableDate }}
          />
        ),
      };
    }

    if (!badge) {
      return null;
    }

    return (
      <>
        <div className={css.badgeWrapper}>{availabilityPackageBadges}</div>

        {!isOnWaitingList && notifyButton}

        {getNotified}
      </>
    );
  };

  const renderFormComponent = (
    handleSubmit,
    packagePartyisEqual,
    calendarInfoDays,
    bookingDateText,
    form,
    startDate,
    endDate,
    formattedStartDate,
    formattedEndDate,
    showLodgingAddOn,
    inProgress,
    submitDisabled,
    submitError,
    bookingData,
    onTransactionPriceChange,
    showCredova,
    setIsCredovaModalOpen
  ) => {
    if (!isAvailable || outOfBookingRange) {
      return renderPackageBadges();
    }

    const estimatedTransation = generateTransaction(bookingData, listing);

    const totalPrice = get(estimatedTransation, 'attributes.payinTotal', null);
    const formattedTotalPrice = totalPrice ? convertMoneyToNumber(totalPrice) : 0;

    if (onTransactionPriceChange) {
      onTransactionPriceChange(formattedTotalPrice);
    }

    return (
      <Form onSubmit={handleSubmit}>
        <div className={css.formFieldWrapper}>
          <>
            <div className={css.formFieldHeading}>
              <FormattedMessage id="PricingPage.partySizeLabel" />
            </div>

            <FieldPartySizeSelect
              name="partySize"
              minPartySize={packageMinParty}
              maxPartySize={packageMaxParty}
              price={JSON.parse(packageInfo.price)}
              parse={value => (value === '' ? null : Number(value))}
              format={value => String(value)}
              validate={composeValidators(
                required(intl.formatMessage({ id: 'PricingPage.partySizeRequired' }))
              )}
            />
          </>
        </div>

        <div className={css.formFieldWrapper}>
          <FieldDates
            fieldDateProps={{
              endDateOffset:
                packageInfo.days.min === packageInfo.days.max
                  ? day => day.add(packageInfo.days.min - 1, 'days')
                  : undefined,
              minimumNights:
                packageInfo.days.min !== packageInfo.days.max && packageInfo.days.min - 1,
              maximumNights:
                packageInfo.days.max > 0 && packageInfo.days.min !== packageInfo.days.max
                  ? packageInfo.days.max - 1
                  : undefined,
              calendarInfo: (
                <div className={css.dateInfo}>
                  <div className={css.dateInfoTitle}>
                    <FormattedMessage
                      id="PricingPage.datesFieldInfoPackageTitle"
                      values={{ days: calendarInfoDays }}
                    />
                  </div>
                </div>
              ),
            }}
            timeSlots={timeSlots}
            label={<FormattedMessage id="PricingPage.datesFieldHeadingPackage" />}
            text={bookingDateText}
            onChange={dates => {
              form.change('dates', dates);
            }}
            isPackage
            onReset={() => {
              form.change('dates', null);
            }}
          />
        </div>

        {startDate && endDate && (
          <div className={css.availableDates}>
            <FormattedMessage
              id="InquiryWizard.selectedDate"
              values={{ dates: formattedStartDate === formattedEndDate ? 1 : 0 }}
            />
          </div>
        )}
        {showLodgingAddOn && (
          <div className={css.formFieldWrapper}>
            <div className={css.lodgingContainer}>
              <FormattedMessage id="PricingPage.addOnsTitle" />
              {optionalLodging.map((item, index) => {
                return (
                  <FieldCheckbox
                    labelClassName={css.lodging}
                    id={`lodgingAddons-${index}`}
                    name="lodgingAddons"
                    value={index}
                    label={
                      <span>
                        {item.shortLabel} + {item.priceLabel}
                      </span>
                    }
                  />
                );
              })}
            </div>
          </div>
        )}

        <div className={css.formFieldWrapper}>
          {!startDate || !endDate ? (
            <FormValidationTooltip
              className={css.submitButtonWrapper}
              placement="top"
              body={<FormattedMessage id="PricingPage.fieldRequirements" />}
              title={<FormattedMessage id="PricingPage.fieldRequirementsTitle" />}
            >
              <div className={css.formFieldWrapper}>
                <PrimaryButton
                  data-testId="continue-to-payment-button"
                  isFullWidth
                  type="submit"
                  inProgress={inProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="PricingPage.continueToPayment" />
                </PrimaryButton>
              </div>
            </FormValidationTooltip>
          ) : (
            <PrimaryButton
              data-testId="continue-to-payment-button"
              isFullWidth
              type="submit"
              inProgress={inProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="PricingPage.continueToPayment" />
            </PrimaryButton>
          )}
        </div>

        {submitError ? <div className={css.error}>{submitError}</div> : null}

        {showCredova && (
          <CredovaPlugin price={formattedTotalPrice} onModalOpen={setIsCredovaModalOpen} />
        )}

        <div>
          {bookingData && (
            <>
              <div className={css.divider} />
              <EstimatedBreakdownMaybe bookingData={bookingData} listing={listing} />
            </>
          )}
        </div>
      </Form>
    );
  };

  if (!currentUserInit) {
    return null;
  }

  return (
    <FinalForm
      onSubmit={handleFormSubmit}
      keepDirtyOnReinitialize
      render={formRenderProps => {
        const { handleSubmit, form, values, inProgress, invalid, submitError } = formRenderProps;

        const packagePartyisEqual = packageMinParty === packageMaxParty;

        let bookingDateText = <FormattedMessage id="CheckoutPage.datesFieldPlaceholderPackage" />;
        const dateValues = values && values.dates;

        const isSingleDay =
          daysAdded(dateValues && values.dates.startDate, dateValues && values.dates.endDate) === 1;

        if (dateValues) {
          bookingDateText = `${format(values.dates.startDate, 'MMM DD, YYYY')} - ${format(
            values.dates.endDate,
            'MMM DD, YYYY'
          )}`;
        }
        if (dateValues && isSingleDay) {
          bookingDateText = format(
            values && values.dates && values.dates.startDate,
            'MMM DD, YYYY'
          );
        }
        const { startDate, endDate } = values && values.dates ? values.dates : {};

        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

        let packageItem = null;

        const packagePrice = JSON.parse(packageInfo.price);
        const packageFee = new Money(packagePrice.amount, config.currency);
        const includeLodgings = values?.lodgingAddons?.length > 0 || hasLodging;

        const today = moment();

        const minDaysEndDate = moment().add(days?.min - 1 || 0, 'd');
        const partySizeMaybe = values.partySize ? parseInt(values.partySize, 10) : packageMinParty;
        let lodgingFees = (values.lodgingAddons || []).map(addon => {
          const lodgingPrice = JSON.parse(optionalLodging[addon].price, sdkTypes.reviver);

          return {
            price: lodgingPrice,
            type: optionalLodging[addon].type,
          };
        });

        if (includedLodging?.length) {
          lodgingFees = lodgingFees.concat(
            includedLodging.map(item => {
              const lodgingPrice = JSON.parse(item.price, sdkTypes.reviver);

              return {
                price: lodgingPrice,
                type: item.type,
              };
            })
          );
        }

        packageItem = {
          code: `line-item/${packageInfo.title.replace(/\s/g, '-')}`,
          title: packageInfo.title,
          includeFor: ['customer', 'provider'],
          unitPrice: packageFee,
          quantity: new Decimal(1),
          lineTotal: packageFee,
          reversal: false,
          donation: {
            package: true,
          },
          guestSize: partySizeMaybe,
          activity: packageInfo.activity,
          hasLodging: includeLodgings,
          lodgingFees,
        };

        const rvDetails = getRVDetails(listing, packageInfo);

        const dynamicBadgesItem = {
          days: {
            min:
              startDate && endDate
                ? daysAdded(startDate, endDate)
                : daysAdded(today, minDaysEndDate),
          },
          guests: { min: partySizeMaybe },
          hasLodging: includeLodgings && (minDaysEndDate > 1 || daysAdded(startDate, endDate) > 1),
          isRvFriendly: rvDetails.isRvFriendly,
          hasRVSites: rvDetails.hasRVSites,
        };

        const lodgingTax = dynamicBadgesItem.hasLodging ? getLodgingTax(listing) : null;

        const bookingData = {
          unitType,
          unitPrice,
          startDate: startDate || today,
          endDate: endDate || minDaysEndDate,
          quantity: 1,
          partySize: partySizeMaybe,
          packageItem,
          lodgingTax,
        };

        const submitDisabled =
          invalid ||
          inProgress ||
          !startDate ||
          !endDate ||
          listingState !== 'published' ||
          !isPublished;

        const classes = classNames(
          rootClassName || css.root,
          sticky && submitDisabled && css.rootSticky
        );

        let calendarInfoDays;

        if (days.min === days.max) {
          calendarInfoDays = days.min;
        } else if (days.max === 365) {
          calendarInfoDays = `${days.min}+`;
        } else {
          calendarInfoDays = `${days.min}-${days.max}`;
        }

        const showLodgingAddOn =
          optionalLodging?.length > 0 && (packageInfo.days.min > 1 || !isSingleDay);

        const tripLength = daysAdded(startDate, endDate);
        const pricePerDay = bookingData?.packageItem?.unitPrice?.amount;
        const totalPricePerGuest = tripLength * pricePerDay;

        const totalPriceFormatted = pricePerDay
          ? new Money(totalPricePerGuest, config.currency)
          : null;

        return (
          <div className={classes}>
            <div className={css.formWrapper}>
              <div className={css.priceWrapper}>
                <div className={css.priceContainer}>
                  {!startDate || !endDate ? (
                    <span className={css.priceText}>{formatMoney(intl, startingPrice)}</span>
                  ) : (
                    <span className={css.priceText}>{formatMoney(intl, totalPriceFormatted)}</span>
                  )}
                  {startingPrice?.amount && (
                    <span className={css.perGuestText}>
                      <FormattedMessage id="PackageCard.perGuestText" />
                    </span>
                  )}
                </div>
              </div>

              <div className={css.packageIncludes}>
                <div className={css.packageIncludesHeading}>
                  <FormattedMessage id="PackageCard.includes" />
                </div>
                <PackageBadges packageInfo={packageInfo} />
              </div>

              <div className={css.formWrapper}>
                {isBlockedByEarlyAccess && isAvailable && !outOfBookingRange ? (
                  <EarlyAccessButtonWithTimer
                    expirationTimestamp={packageInfo?.earlyAccessExpiration}
                    setIsModalOpen={setIsEarlyAccessModalOpen}
                  />
                ) : (
                  renderFormComponent(
                    handleSubmit,
                    packagePartyisEqual,
                    calendarInfoDays,
                    bookingDateText,
                    form,
                    startDate,
                    endDate,
                    formattedStartDate,
                    formattedEndDate,
                    showLodgingAddOn,
                    inProgress,
                    submitDisabled,
                    submitError,
                    bookingData,
                    onPriceChange,
                    showCredova,
                    setIsCredovaModalOpen
                  )
                )}
              </div>

              <div className={css.footer}>
                <FormattedMessage id="PackagePage.footerText" />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

PackagePricingPanel.defaultProps = {
  unitType: config.bookingUnitType,
  timeSlots: null,
  onPriceChange: null,
  showCredova: false,
};

PackagePricingPanel.propTypes = {
  unitType: propTypes.bookingUnitType,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  listing: propTypes.listing.isRequired,
  packageInfo: propTypes.package.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  startingPrice: propTypes.money.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  currentUserInit: bool.isRequired,
  onPriceChange: func,
  showCredova: bool,
};

const mapDispatchToProps = dispatch => ({
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  ),
  injectIntl
)(PackagePricingPanel);
