import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import css from './InboxPageMobile.css';
import {
  InboxList,
  InboxMessages,
  InboxDetails,
  InboxAction,
  InboxDetailsSendMessage,
} from '../../components';
import { MessagesLoader } from './InboxV2Loading';
import InboxProviderResponseRate from '../../components/InboxProviderResponseRate/InboxProviderResponseRate';

const InboxPageMobile = ({
  state,
  currentUser,
  currentUserInit,
  currentTransaction,
  currentListing,
  transactions,
  isTransactionsFetching,
  hasNextPage,
  loadMore,
  isFetchingNextPage,
  handleFilterChange,
  currentFilter,
  currentUnreadOnly,
  handleUpdateUnreadOnly,
  params,
  user,
  isCustomer,
  isOrder,
  sendMessageInProgress,
  sendMessageError,
  handleSendMessage,
  handleOpenChangeBookingDetails,
  handleSubmitSpecialOfferRequest,
  initiateOrderError,
  timeSlots,
  fetchTimeSlotsError,
  updatingWaitingListInProgress,
  onSendMessage,
  checkUserVerification,
  transactionState,
  allMessages,
  intl,
  history,
  setState,
  isConfirmPayment,
  isEnquiry,
  messagesContentRef,
  onRefetchTransaction,
}) => {
  const [activeTab, setActiveTab] = useState('details');

  const mainContainerClassName = classNames(css.mainContainer, {
    [css.mainContainerMobile]: params.id,
    [css.mainContainerMobileConfirmPayment]: isConfirmPayment,
  });

  const listClassName = classNames(css.listContainer, {
    [css.listContainerHidden]: params.id,
  });

  const messagesClassName = classNames(css.messagesContainer, {
    [css.messagesContainerHidden]: !params.id,
  });

  const actionClassName = classNames(css.actionContainer, {
    [css.actionContainerHidden]: !params.id,
  });

  const detailsRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const detailsElement = detailsRef.current;
      const messagesElement = messagesContentRef.current;

      if (detailsElement && messagesElement) {
        const messagesRect = messagesElement.getBoundingClientRect();

        // Check which element is more in view
        if (messagesRect.top >= 0 && messagesRect.top < (window.innerHeight * 2) / 3) {
          setActiveTab('messages');
        } else {
          setActiveTab('details');
        }
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the scroll event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={mainContainerClassName}>
      <div id="details" ref={detailsRef} />

      <div className={listClassName}>
        <InboxList
          isMobile
          showTabs={state.showTabs}
          transactions={transactions}
          params={params}
          currentUser={currentUser}
          isFetching={(!transactions.length && isTransactionsFetching) || state.isFetchingList}
          hasMore={hasNextPage}
          loadMore={loadMore}
          isLoadingMore={isFetchingNextPage}
          onFilterChange={handleFilterChange}
          selectedFilters={currentFilter}
          unreadOnly={currentUnreadOnly}
          onUpdateUnreadOnly={handleUpdateUnreadOnly}
          onReset={() => {
            setState(prevState => ({
              ...prevState,
              defaultTransactionId: null,
            }));
          }}
        />
      </div>

      <div className={css.detailsWrapper}>
        <div className={actionClassName}>
          <div className={css.mobileDetailsContainer}>
            {transactionState && (
              <InboxDetails
                history={history}
                transaction={state.currentTransaction}
                processTransitions={state.processTransitions}
                params={params}
                currentUser={currentUser}
                checkUserVerification={checkUserVerification}
                isFetching={state.isFetching}
                transactionState={transactionState}
                currentListing={currentListing}
                isMobile
                onChangeBookingDetails={handleOpenChangeBookingDetails}
                onRefetchTransaction={onRefetchTransaction}
              />
            )}
          </div>

          {!isEnquiry && !state.isFetching && (
            <div className={css.mobileLinks}>
              <a
                href="#details"
                onClick={e => {
                  e.preventDefault();
                  document.getElementById('details').scrollIntoView({ behavior: 'smooth' });
                  setActiveTab('details');
                }}
                className={classNames(css.mobileLink, {
                  [css.mobileLinkActive]: activeTab === 'details',
                })}
              >
                <FormattedMessage id="InboxPage.tripDetails" />
              </a>
              <a
                href="#messages"
                onClick={e => {
                  e.preventDefault();
                  document.getElementById('messages').scrollIntoView({ behavior: 'smooth' });
                  setActiveTab('messages');
                }}
                className={classNames(css.mobileLink, {
                  [css.mobileLinkActive]: activeTab === 'messages',
                })}
              >
                <FormattedMessage id="InboxPage.messages" />
              </a>
            </div>
          )}

          {transactionState && (
            <InboxAction
              transactionState={transactionState}
              currentListing={currentListing}
              currentUser={currentUser}
              currentUserInit={currentUserInit}
              currentTransaction={currentTransaction}
              transactionRole={params.transactionRole}
              timeSlots={timeSlots}
              fetchTimeSlotsError={fetchTimeSlotsError}
              updatingWaitingListInProgress={updatingWaitingListInProgress}
              onSubmitSpecialOffer={handleSubmitSpecialOfferRequest}
              sendMessageInProgress={sendMessageInProgress}
              onSendMessage={onSendMessage}
              initiateOrderError={initiateOrderError}
              isMobile
              onChangeBookingDetails={handleOpenChangeBookingDetails}
              onRefetchTransaction={onRefetchTransaction}
            />
          )}
        </div>

        <div className={messagesClassName}>
          {state.isFetching ? (
            <MessagesLoader />
          ) : (
            <>
              <div className={css.messagesContent} ref={messagesContentRef}>
                <div id="messages" />
                {transactionState && (
                  <InboxMessages
                    transaction={currentTransaction}
                    messages={allMessages}
                    params={params}
                    currentUser={currentUser}
                    isFetching={state.isFetching}
                    transactionState={transactionState}
                    isCustomer={isCustomer}
                  />
                )}
              </div>

              <div className={css.sendMessageContent}>
                {isOrder && <InboxProviderResponseRate provider={user} intl={intl} />}
                <InboxDetailsSendMessage
                  currentUser={currentUser}
                  currentTransaction={currentTransaction}
                  sendMessageInProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onSendMessage={handleSendMessage}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InboxPageMobile;
