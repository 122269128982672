import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  acceptSale,
  declineSale,
  acceptSpecialOffer,
  declineSpecialOffer,
} from '../../../ducks/Transaction.duck';
import { PrimaryButton, SecondaryButton, DeclineModal } from '../..';

import css from './Buttons.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtons = props => {
  const {
    className,
    rootClassName,
    stateData,
    currentTransaction,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onAcceptSpecialOffer,
    onDeclineSale,
    onDeclineSpecialOffer,
    isSpecialOffer,
    checkUserVerification,
    currentListing,
    currentUser,
    currentProvider,
    onRefetchTransaction,
  } = props;
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);

  const showButtons = stateData.showCustomerAcceptSpecialOffer || stateData.showSaleButtons;

  const buttonsDisabled = acceptInProgress || declineInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  const handleCancelClick = async () => {
    if (isSpecialOffer) {
      await onDeclineSpecialOffer(currentTransaction.id);
    } else {
      await onDeclineSale(currentTransaction.id);
    }

    await onRefetchTransaction(currentTransaction.id.uuid);
  };

  const handleAcceptClick = async () => {
    if (isSpecialOffer) {
      if (await checkUserVerification()) {
        const params = {
          currentUser,
          currentListing,
          currentProvider,
        };

        await onAcceptSpecialOffer(params, currentTransaction.id);
      }
    } else {
      await onAcceptSale(currentTransaction.id);
    }

    await onRefetchTransaction(currentTransaction.id.uuid);
  };

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={handleAcceptClick}
          className={css.acceptButton}
          isAnimating
        >
          <FormattedMessage
            id={isSpecialOffer ? 'TransactionPanel.specialOffer' : 'TransactionPanel.acceptButton'}
          />
        </PrimaryButton>

        <SecondaryButton
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={() => setIsDeclineModalOpen(true)}
          className={css.declineButton}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>
      </div>

      <DeclineModal
        isOpen={isDeclineModalOpen}
        onCloseModal={() => setIsDeclineModalOpen(false)}
        onDecline={handleCancelClick}
        inProgress={declineInProgress}
        disabled={buttonsDisabled}
        isSpecialOffer={isSpecialOffer}
      />
    </div>
  ) : null;
};

const mapStateToProps = state => {
  const {
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
  } = state.Transaction;

  return {
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
  };
};

const mapDispatchToProps = dispatch => ({
  onAcceptSale: transactionId => dispatch(acceptSale(transactionId)),
  onDeclineSale: transactionId => dispatch(declineSale(transactionId)),
  onAcceptSpecialOffer: (params, transactionId) =>
    dispatch(acceptSpecialOffer(params, transactionId)),
  onDeclineSpecialOffer: transactionId => dispatch(declineSpecialOffer(transactionId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SaleActionButtons);
