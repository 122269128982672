import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as CommentIcon } from '../../assets/icons/icon-comment-regular.svg';
import { QuinaryButton } from '..';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import css from './InboxFilters.css';

const InboxFilters = ({ onFilterChange, selectedFilters, unreadOnly, onUpdateUnreadOnly }) => {
  const [isUnreadSelected, setIsUnreadSelected] = useState(unreadOnly);

  useEffect(() => {
    setIsUnreadSelected(unreadOnly);
  }, [unreadOnly]);

  const handleFilterChange = values => {
    onFilterChange(values);
  };

  const handleUnreadClick = () => {
    const newValue = !unreadOnly;
    setIsUnreadSelected(newValue);
    onUpdateUnreadOnly(newValue);
  };

  return (
    <div className={css.filters}>
      <QuinaryButton
        className={classNames(css.filterButton, {
          [css.filterButtonSelected]: isUnreadSelected,
        })}
        isXSmall
        onClick={handleUnreadClick}
      >
        <CommentIcon width="18" />
        <span>Unread</span>
      </QuinaryButton>

      <div className={css.filterDropdownWrapper}>
        <FilterDropdown
          className={css.filterDropdown}
          buttonClassName={css.filterButton}
          buttonSelectedClassName={css.filterButtonSelected}
          buttonOpenClassName={css.filterButtonOpen}
          onFilterChange={handleFilterChange}
          selectedFilters={selectedFilters}
        />
      </div>
    </div>
  );
};

InboxFilters.defaultProps = {};

InboxFilters.propTypes = {
  onFilterChange: func.isRequired,
  selectedFilters: arrayOf(string).isRequired,
  unreadOnly: bool.isRequired,
  onUpdateUnreadOnly: func.isRequired,
};

export default InboxFilters;
