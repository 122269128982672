/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { get } from 'lodash';
import moment from 'moment';
import parseAddress from 'parse-address';
import tilebelt from '@mapbox/tilebelt';
import tiles from '@mapbox/timespace/lib/timezones.json';
import config from '../config';
import {
  gameTypeMap,
  stateRegulationsMap,
  stateCodes,
  lodgingTaxStatesRules,
  OUTDOOR_RECREATION_ACTIVITY,
  HUNT_ACTIVITY,
} from '../marketplace-custom-config';
import { types as sdkTypes } from './sdkLoader';
import { denormalisedResponseEntities } from './data';
import { parseMoneyObjString, formatMoney } from './currency';
import { CAMPSITES_TYPE } from './campsite';

export const getListingStartingPrice = (
  gameTypes,
  moneyLength,
  includeDecimals = true,
  includeOnlySpecies = []
) => {
  const activeSpecies = gameTypes
    ? Object.keys(gameTypes)
        .filter(item => {
          if (item && includeOnlySpecies && includeOnlySpecies.length) {
            return includeOnlySpecies.includes(item);
          }

          return true;
        })
        .map(i => gameTypes[i])
        .filter(({ isActive }) => isActive === true)
        .filter(({ price }) => JSON.parse(price))
    : null;

  const activeSpeciesPrice = activeSpecies
    ? activeSpecies.map(a => JSON.parse(a.price).amount)
    : [];

  const minimumFee = Math.min(...activeSpeciesPrice);

  return minimumFee === Infinity
    ? null
    : moneyLength
    ? parseInt(minimumFee, 10)
    : includeDecimals
    ? parseFloat(minimumFee / 100).toFixed(2)
    : parseInt(minimumFee / 100, 10);
};

export const getListingPackageMinPrice = (packages, activities, includeOnlySpecies) => {
  if (packages && packages.length) {
    let filteredPackages = packages.filter(item => item.isPublished);

    if (activities && activities.length) {
      filteredPackages = packages.filter(item => activities.includes(item.activity));
    } else {
      filteredPackages = packages;
    }

    const packagesPrices = filteredPackages
      .filter(item => {
        if (item?.species?.length && includeOnlySpecies && includeOnlySpecies.length) {
          return includeOnlySpecies.some(speciesItem => {
            return item.species.includes(speciesItem);
          });
        }

        return true;
      })
      .map(item => {
        const minGuests = item?.guests?.min || 1;
        const parsedPrice = JSON.parse(item.price);
        return parseInt(parsedPrice.amount / 100, 10) * minGuests;
      });

    return Math.min(...packagesPrices);
  }

  return 0;
};

// Use these functions to separate and properly display Categories
export const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export const capitalizeCats = (categoryArray = []) =>
  categoryArray.map(cats =>
    cats
      .split(' ')
      .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  );

export const LISTING_TITLE_MIN_LENGTH_FOR_LONG_WORDS = 10;

export const averageListingRating = reviews => {
  const aggregateReviews = reviews.reduce((a, e) => a + e.rating, 0);
  const reviewsAverage = (aggregateReviews / reviews.length).toFixed(1);
  return reviewsAverage;
};

export const getAddOnFees = (intl, listing) => {
  const gameTypes = get(listing, 'attributes.publicData.gameTypes');

  if (!gameTypes) {
    return [];
  }

  // transform public data into array of objects
  return (
    Object.entries(gameTypes)
      // filter out non-existent species that may still be present
      // in the old listing data
      .filter(([name]) => gameTypeMap.findIndex(s => s.key === name) > -1)
      .map(([name, speciesData]) => {
        const { isActive, price } = speciesData;
        const moneyObj = parseMoneyObjString(price);
        return {
          name,
          isActive,
          price: moneyObj,
          checkBoxLabel: intl.formatMessage({
            id: `BookingDatesForm.AddOnLabel.${name}`,
          }),
        };
      })
  );
};

export const hasCategory = (listing, category) => {
  const categories = get(listing, 'attributes.publicData.categories', []);

  return categories.indexOf(category) > -1;
};

export const hasFishing = listing => hasCategory(listing, 'fish');

export const hasHunting = listing => hasCategory(listing, 'hunt');

export const buildTimeSlotsRequests = (listingId, sdk, listingEndDate) => {
  const maxTimeSlots = 90;
  const bookingRange = config.dayCountAvailableForBooking;
  let actualRange = bookingRange;

  if (listingEndDate) {
    const listingEnd = moment.utc(listingEndDate).startOf('day');
    const today = moment.utc().startOf('day');
    const daysUntilEnd = listingEnd.diff(today, 'days');
    actualRange = Math.min(daysUntilEnd, bookingRange);
  }

  const totalRequests = Math.ceil(actualRange / maxTimeSlots);

  let remainingDays = actualRange;
  let start = moment.utc().startOf('day');
  let end = moment()
    .utc()
    .startOf('day')
    .add(maxTimeSlots, 'days');

  const requests = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < totalRequests; i++) {
    if (listingEndDate) {
      end = moment.min(
        end,
        moment
          .utc(listingEndDate)
          .startOf('day')
          .add(1, 'days')
      );
    }

    const request = sdk.timeslots
      .query({ listingId, start: start.toDate(), end: end.toDate(), per_page: maxTimeSlots })
      .then(response => denormalisedResponseEntities(response));

    requests.push(request);

    remainingDays -= maxTimeSlots;

    const daysToAdd = remainingDays < maxTimeSlots ? remainingDays : maxTimeSlots;

    start = moment.utc(end);
    end = moment.utc(start).add(daysToAdd, 'days');
  }

  return requests;
};

export const listingStateLinks = currentListing => {
  const usStates = get(currentListing, 'attributes.publicData.location.usState', null);

  const stateObject = stateRegulationsMap.find(obj => {
    return obj.name === usStates;
  });
  return {
    listingState: get(stateObject, 'name', null),
    regulationsLink: get(stateObject, 'regulations', null),
    licenseLink: get(stateObject, 'license', null),
    stateStatute: get(stateObject, 'statute', null),
    anchorTag: get(stateObject, 'anchor', null),
  };
};

export const getListingLocation = listing => {
  let listingLocation = '';
  const publicData = get(listing, 'attributes.publicData');

  const nearestTown = get(publicData, 'nearestTown.selectedPlace.address', null);
  const location = get(publicData, 'location', null);
  const locationOverride = get(publicData, 'locationOverride', null);
  const parsedLocation =
    location && location.address ? parseAddress.parseLocation(location.address) : null;

  if (locationOverride) {
    listingLocation = locationOverride;
  } else if (parsedLocation && parsedLocation.city && parsedLocation.state) {
    listingLocation = `${parsedLocation.city}, ${parsedLocation.state}`;
  } else if (nearestTown) {
    listingLocation = `${nearestTown.replace(', United States', '')}`;

    // Search for states and replace with code if exists
    const stateFound = stateCodes.some(item => {
      if (listingLocation.indexOf(`, ${item.state}`) > -1) {
        listingLocation = listingLocation.replace(item.state, item.code);

        return true;
      }

      return false;
    });

    if (parsedLocation && parsedLocation.state && !stateFound) {
      listingLocation += `, ${parsedLocation.state}`;
    }
  } else if (parsedLocation && parsedLocation.state) {
    listingLocation += `${parsedLocation.state}`;
  }

  return listingLocation;
};

export const getListingStateCode = listing => {
  const publicData = get(listing, 'attributes.publicData');
  const nearestTown = get(publicData, 'nearestTown.selectedPlace.address', null);
  const location = get(publicData, 'location', null);
  const parsedLocation =
    location && location.address ? parseAddress.parseLocation(location.address) : null;

  if (parsedLocation?.state) {
    return parsedLocation.state;
  }

  if (nearestTown) {
    let stateCode = '';

    // Search for states and replace with code if exists
    const stateFound = stateCodes.some(item => {
      if (nearestTown.indexOf(`, ${item.state}`) > -1) {
        stateCode = item.code;
        return true;
      }

      return false;
    });

    if (stateFound) {
      return stateCode;
    }
  }
};

export const getListingState = listing => {
  const stateCode = getListingStateCode(listing);

  const state = stateCodes.find(item => item.code === stateCode);

  return state;
};

export const getListingTimezone = listing => {
  let geolocation = get(listing, 'attributes.geolocation');

  if (!geolocation) {
    geolocation = get(listing, 'attributes.publicData.nearestTown.selectedPlace.origin', {});
  }

  const z = Object.keys(tiles)[0]
    .split('/')
    .map(Number)[2];
  const tile = tilebelt.pointToTile(geolocation.lng, geolocation.lat, z).join('/');

  const timezone = tiles[tile];

  return timezone;
};

export const getActivityTitles = (categories, publicData) => {
  const activityFilter = categories.filter(({ key }) => {
    const listingActivities = get(publicData, 'categories', []);

    return listingActivities.includes(key);
  });

  return activityFilter.map(({ schemaTitle }) => schemaTitle);
};

export const getListingSchemaTitleType = activities => {
  if (HUNT_ACTIVITY in activities && OUTDOOR_RECREATION_ACTIVITY in activities) {
    return 'ListingPage.huntPlusRecSchemaTitle';
  }
  if (HUNT_ACTIVITY in activities) {
    return 'ListingPage.huntSchemaTitle';
  }
  if (OUTDOOR_RECREATION_ACTIVITY in activities) {
    return 'ListingPage.outdoorRecSchemaTitle';
  }
  return 'ListingPage.defaultSchemaTitle';
};

export const scrollWithOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

export const getListingImages = currentListing => {
  const { photoLibraryTags = {} } = get(currentListing, 'attributes.publicData', {});

  const listingImages = Object.entries(photoLibraryTags)
    .filter(([, tags]) => tags.includes('property'))
    .map(([uuid]) => uuid);

  return (currentListing.images || []).filter(image => listingImages.includes(image?.id?.uuid));
};

export const getListingImageCaptions = currentListing => {
  return get(currentListing, 'attributes.publicData.imageCaptions');
};

export const getImagesByTag = (listing, tag) => {
  const images = get(listing, 'images', []);
  const publicData = get(listing, 'attributes.publicData', {});
  const imageTags = get(publicData, 'imageTags', {});

  return images.filter(image => {
    const tags = imageTags[image.id.uuid] || [];
    return tags.includes(tag);
  });
};

export const getPackageImages = (listing, packageId) => {
  const images = get(listing, 'images', []);
  const publicData = get(listing, 'attributes.publicData', {});
  const packages = get(publicData, 'packages', []);
  const packageData = packages.find(item => item.id === packageId);

  if (packageData) {
    const { images: packageImages } = packageData || {};

    return (packageImages || [])
      .map(imageId => {
        const packageImage = images.find(image => image.id.uuid === imageId);

        return packageImage;
      })
      .filter(Boolean);
  }
};

export const getActiveActivityImages = (listing, activity) => {
  const images = get(listing, 'images', []);
  const publicData = get(listing, 'attributes.publicData', {});
  const imageTags = get(publicData, 'imageTags', {});

  const activityImage = imageTags
    ? Object.keys(imageTags).find(key => imageTags[key] === activity)
    : null;
  return images.find(each => each.id.uuid === activityImage) || null;
};

export const updateImagesWithActivity = (currentImages, activityImages, newIds) => {
  // Remove current hunt images from all images
  let updatedImages = currentImages
    .filter(image => {
      if (activityImages.some(activityImage => activityImage.id.uuid === image.id.uuid)) {
        return false;
      }

      return true;
    })
    .map(image => image.id.uuid);

  // Add new images
  updatedImages = updatedImages.concat(newIds);

  return updatedImages;
};

export const updateImageTags = (currentTags, newTags, tag) => {
  // Remove current tag images
  const updatedTags = {};

  Object.keys(currentTags).forEach(imageId => {
    if (currentTags[imageId] !== tag) {
      updatedTags[imageId] = currentTags[imageId];
    }
  });

  return {
    ...updatedTags,
    ...newTags,
  };
};

export const updateImageCaptions = (currentCaptions, newCaptions, oldIds) => {
  // Remove current image captions
  const updatedCaptions = {};

  Object.keys(currentCaptions).forEach(imageId => {
    if (!oldIds.includes(imageId)) {
      updatedCaptions[imageId] = currentCaptions[imageId];
    }
  });

  return {
    ...updatedCaptions,
    ...newCaptions,
  };
};

export const getCategoryLabels = (categories, keys) => {
  return (
    keys &&
    keys.map(key => {
      const category = categories.find(c => c.key === key);

      return category ? category.label : key;
    })
  );
};

export const getLodgingTax = listing => {
  const listingStateCode = getListingStateCode(listing);

  const rules =
    listingStateCode && lodgingTaxStatesRules[listingStateCode]
      ? lodgingTaxStatesRules[listingStateCode]
      : null;

  if (rules) {
    return rules;
  }
};

export const getPackage = (listing, packageId) => {
  const packages = get(listing, 'attributes.publicData.packages', []);
  const packageDetails = packages.find(item => item.id === packageId);

  return packageDetails;
};

export const isAllowingRVs = listing => {
  return get(listing, 'attributes.publicData.policies.allowsRVs', false);
};

export const getCampsites = listing => {
  return get(listing, 'attributes.publicData.campsites', []);
};

export const getLodging = listing => {
  return get(listing, 'attributes.publicData.lodging', []);
};

export const getLodgingPricesOptions = (listing, intl) => {
  const lodging = get(listing, 'attributes.publicData.lodging', null);
  const hasLodging = get(lodging, 'hasLodging', false);

  const lodgingPricesOptions = [];

  if (hasLodging) {
    const lodgingType = get(lodging, 'type', '');
    const lodgingPrice = get(lodging, 'price', null);
    const lodgingInfo = get(lodging, 'overallInfo', null);

    let lodgingTypeLabel = '';
    let priceLabel = '';

    if (lodgingType === 'house') {
      lodgingTypeLabel = intl.formatMessage({
        id: 'EditListingCreatePackageForm.logingHouseLabel',
      });
    } else if (lodgingType === 'cabin') {
      lodgingTypeLabel = intl.formatMessage({
        id: 'EditListingCreatePackageForm.logingCabinLabel',
      });
    }

    if (lodgingPrice) {
      const lodgingPriceParsed = JSON.parse(lodgingPrice, sdkTypes.reviver);

      priceLabel = intl.formatMessage(
        {
          id: 'EditListingCreatePackageForm.logingPriceLabel',
        },
        {
          price: formatMoney(intl, lodgingPriceParsed, true),
        }
      );
    }

    lodgingPricesOptions.push({
      price: lodgingPrice,
      label: lodgingTypeLabel,
      shortLabel: lodgingTypeLabel,
      priceLabel,
      type: lodgingType,
      info: lodgingInfo,
      index: 0,
    });
  }

  const campsites = get(listing, 'attributes.publicData.campsites', []);
  const lodgingRVs = campsites.filter(campsite => campsite.allowsRvs);
  const hasSingleRVSite = lodgingRVs.length === 1;

  let tentIndex = 1;
  let rvIndex = 1;

  campsites.forEach((campsite, index) => {
    if (campsite.allowsTents) {
      const tentPrice = get(campsite, 'tent.price', null);
      const tentInfo = get(campsite, 'tent.info', null);
      let priceLabel = '';

      if (tentPrice) {
        const tentPriceParsed = JSON.parse(tentPrice, sdkTypes.reviver);

        priceLabel = intl.formatMessage(
          {
            id: 'EditListingCreatePackageForm.logingPriceLabel',
          },
          {
            price: formatMoney(intl, tentPriceParsed, true),
          }
        );
      }

      lodgingPricesOptions.push({
        price: tentPrice,
        priceLabel,
        label: intl.formatMessage(
          {
            id: 'EditListingCreatePackageForm.logingTentLabel',
          },
          {
            index: tentIndex,
          }
        ),
        shortLabel: intl.formatMessage({
          id: 'EditListingCreatePackageForm.logingTentLabel',
        }),
        type: CAMPSITES_TYPE.TENT,
        info: tentInfo,
        index,
      });

      tentIndex += 1;
    }

    if (campsite.allowsRvs) {
      const rvPrice = get(campsite, 'rv.price', null);
      const rvCampsiteName = get(campsite, 'rv.campsiteName', '');
      const rvInfo = get(campsite, 'rv.info', null);
      let priceLabel = '';

      if (rvPrice) {
        const rvPriceParsed = JSON.parse(rvPrice, sdkTypes.reviver);

        priceLabel = intl.formatMessage(
          {
            id: 'EditListingCreatePackageForm.logingPriceLabel',
          },
          {
            price: formatMoney(intl, rvPriceParsed, true),
          }
        );
      }

      let label = intl.formatMessage({
        id: 'EditListingCreatePackageForm.logingRVLabel',
      });
      let shortLabel = intl.formatMessage({
        id: 'EditListingCreatePackageForm.logingRVShortLabel',
      });

      if (!hasSingleRVSite) {
        label = `${label} #${rvIndex}`;
        shortLabel = `${shortLabel} #${rvIndex}`;
      }

      if (rvCampsiteName) {
        label = `${label} ${rvCampsiteName}`;
      }

      lodgingPricesOptions.push({
        price: rvPrice,
        priceLabel,
        label,
        shortLabel,
        type: CAMPSITES_TYPE.RV,
        name: rvCampsiteName,
        info: rvInfo,
        index,
      });

      rvIndex += 1;
    }
  });

  return lodgingPricesOptions;
};
