/* eslint-disable prefer-destructuring */
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { callGraphQLApi } from '../../util/api';
import { getArchivedTransactions, removeArchivedTransaction } from '../../util/transaction';
import { getTransactionsQuery, INBOX_PAGE_SIZE } from '../../hooks/queries/useTransactions';

export const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPageV2/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPageV2/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPageV2/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_ORDER_OR_SALE_SUCCESS = 'app/InboxPageV2/FETCH_ORDER_OR_SALE_SUCCESS';

export const FETCH_TRANSACTIONS_REQUEST = 'app/InboxPageV2/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/InboxPageV2/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/InboxPageV2/FETCH_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const mergeTransactionRefs = (existingRefs, newRefs) => {
  const refMap = new Map();

  existingRefs.forEach(ref => {
    const id = ref.id.uuid;
    refMap.set(id, ref);
  });

  newRefs.forEach(ref => {
    const id = ref.id.uuid;
    refMap.set(id, ref);
  });

  return Array.from(refMap.values());
};

const mergeTransactions = (existingRefs, newRefs) => {
  const refMap = new Map();

  existingRefs.forEach(ref => {
    const id = ref.id;
    refMap.set(id, ref);
  });

  newRefs.forEach(ref => {
    const id = ref.id;
    refMap.set(id, ref);
  });

  return Array.from(refMap.values());
};

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchTransactionsError: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  transactionSalesRefs: [],
  transactionOrdersRefs: [],
  salesTransactions: [],
  ordersTransactions: [],
  nextToken: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchTransactionsError: null,
      };

    case FETCH_TRANSACTIONS_SUCCESS: {
      const { data, type: transactionType, requestNextToken } = payload;
      let salesTransactions = state.salesTransactions;
      let ordersTransactions = state.ordersTransactions;

      if (transactionType === 'sales') {
        salesTransactions =
          requestNextToken === null ? data.items : mergeTransactions(salesTransactions, data.items);
      } else if (transactionType === 'orders') {
        ordersTransactions =
          requestNextToken === null
            ? data.items
            : mergeTransactions(ordersTransactions, data.items);
      }

      return {
        ...state,
        fetchInProgress: false,
        salesTransactions,
        ordersTransactions,
        nextToken: data.nextToken,
      };
    }

    case FETCH_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        fetchInProgress: false,
        fetchTransactionsError: payload,
      };

    case FETCH_ORDER_OR_SALE_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };

      // Initialize sales and orders refs
      let transactionSalesRefs = state.transactionSalesRefs;
      let transactionOrdersRefs = state.transactionOrdersRefs;

      // Depending on the type, add the transaction to the appropriate refs
      if (payload.type === 'sales') {
        transactionSalesRefs = mergeTransactionRefs(
          state.transactionSalesRefs,
          [transactionRef] // Passing the single transactionRef as an array
        );
      } else if (payload.type === 'orders') {
        transactionOrdersRefs = mergeTransactionRefs(
          state.transactionOrdersRefs,
          [transactionRef] // Passing the single transactionRef as an array
        );
      }

      return {
        ...state,
        transactionSalesRefs,
        transactionOrdersRefs,
      };
    }

    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      const newTransactionRefs = entityRefs(transactions);

      // Deduplicate transactionRefs
      const transactionRefs = mergeTransactionRefs(state.transactionRefs, newTransactionRefs);

      // Initialize sales and orders refs
      let transactionSalesRefs = state.transactionSalesRefs;
      let transactionOrdersRefs = state.transactionOrdersRefs;

      if (payload.type === 'sales') {
        transactionSalesRefs = mergeTransactionRefs(state.transactionSalesRefs, newTransactionRefs);
      } else if (payload.type === 'orders') {
        transactionOrdersRefs = mergeTransactionRefs(
          state.transactionOrdersRefs,
          newTransactionRefs
        );
      }

      return {
        ...state,
        fetchInProgress: false,
        transactionRefs,
        transactionSalesRefs,
        transactionOrdersRefs,
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchTransactionsRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });

const fetchTransactionsSuccess = (response, requestNextToken) => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: {
    ...response,
    requestNextToken,
  },
});

const fetchTransactionsError = error => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

const fetchTransactions = async (
  userId,
  limit,
  nextToken,
  type,
  filter = null,
  unreadOnly = false
) => {
  const variables = {
    input: {
      userId,
      limit,
      nextToken,
      unreadOnly,
    },
  };

  if (filter && filter !== 'all') {
    if (filter === 'archived') {
      variables.input.filter = `archived_by_${type.toLowerCase()}`;
    } else {
      variables.input.filter = filter;
    }
  }

  const response = await callGraphQLApi(getTransactionsQuery(type), variables);
  const result = response.data[`${type}Transactions`];

  // Get archived transactions from localStorage
  const userType = type === 'Provider' ? 'provider' : 'customer';

  // If filter is 'archived', merge archived transactions with server response
  if (filter === 'archived') {
    const archivedTransactions = getArchivedTransactions(userType);
    let updatedArchivedTransactions = [];

    // Clean up localStorage by removing transactions that are in the server response
    if (archivedTransactions.length) {
      updatedArchivedTransactions = archivedTransactions.filter(transaction => {
        const filterResult = !result.items.some(tx => tx.id === transaction.id);

        if (!filterResult) {
          removeArchivedTransaction(transaction.id, userType);
        }

        return filterResult;
      });
    }

    const mergedItems = [...result.items, ...updatedArchivedTransactions];

    // Sort by lastTransitionedAt
    const sortedItems = reverse(sortBy(mergedItems, item => item.lastTransitionedAt));

    return {
      items: sortedItems,
      nextToken: result.nextToken,
    };
  }

  return result;
};

export const fetchProviderTransactions = (
  userId,
  nextToken = null,
  limit,
  filter = null,
  unreadOnly = false
) => async dispatch => {
  dispatch(fetchTransactionsRequest());

  try {
    const response = await fetchTransactions(
      userId,
      limit,
      nextToken,
      'Provider',
      filter,
      unreadOnly
    );
    dispatch(
      fetchTransactionsSuccess(
        {
          data: response,
          type: 'sales',
        },
        nextToken
      )
    );

    return Promise.resolve(response);
  } catch (error) {
    dispatch(fetchTransactionsError(error));
    return Promise.reject(error);
  }
};

export const fetchCustomerTransactions = (
  userId,
  nextToken = null,
  limit,
  filter = null,
  unreadOnly = false
) => async dispatch => {
  dispatch(fetchTransactionsRequest());

  try {
    const response = await fetchTransactions(
      userId,
      limit,
      nextToken,
      'Customer',
      filter,
      unreadOnly
    );
    dispatch(
      fetchTransactionsSuccess(
        {
          data: response,
          type: 'orders',
        },
        nextToken
      )
    );

    return Promise.resolve(response);
  } catch (error) {
    dispatch(fetchTransactionsError(error));
    return Promise.reject(error);
  }
};

export const loadData = (
  params,
  nextToken = null,
  currentUser,
  filter = null,
  unreadOnly = false
) => async dispatch => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  const userId = currentUser.id.uuid;

  dispatch(fetchTransactionsRequest());

  try {
    if (tab === 'sales') {
      return await dispatch(
        fetchProviderTransactions(userId, nextToken, INBOX_PAGE_SIZE, filter, unreadOnly)
      );
    }

    if (tab === 'orders') {
      return await dispatch(
        fetchCustomerTransactions(userId, nextToken, INBOX_PAGE_SIZE, filter, unreadOnly)
      );
    }

    throw new Error(`Invalid tab: ${tab}`);
  } catch (error) {
    dispatch(fetchTransactionsError(error));
    throw error;
  }
};

export const initialFetch = userId => () => {
  const salesPromise = fetchTransactions(userId, 1, null, 'Provider');
  const ordersPromise = fetchTransactions(userId, 1, null, 'Customer');

  const responses = Promise.all([salesPromise, ordersPromise]);

  return responses.then(
    ([salesResponse, ordersResponse]) => {
      return {
        hasSales: salesResponse.items.length > 0 || salesResponse.nextToken !== null,
        hasOrders: ordersResponse.items.length > 0 || ordersResponse.nextToken !== null,
      };
    },
    e => {
      throw e;
    }
  );
};
