import 'react-loading-skeleton/dist/skeleton.css';

import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { get } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '..';
import { TopbarSearchForm } from '../../forms';

import css from './TopbarDesktop.css';
import { PrimaryButton } from '../Button/Button';
import { Container } from '../Layout';
import { uiElementClicked } from '../../ducks/UI.duck';
import { SIGNUP_CLICK } from '../../tracking/types';

import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import ActivityDot from '../ActivityDot/ActivityDot';

const TopbarSkeletonLinks = () => (
  <div className={css.skeletonLinks}>
    <Skeleton width={160} height={40} borderRadius={20} />
    <Skeleton width={80} height={18} />
    <Skeleton width={80} height={18} />
    <Skeleton width={80} height={18} />
    <Skeleton circle width={40} height={40} />
  </div>
);

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserHasListingsInProgress,
    activityCount,
    intl,
    isAuthenticated,
    authInProgress,
    isFullWidth,
    shouldShowSearchForm,
    onSearchSubmit,
    initialSearchFormValues,
    onLogout,
    onUiElementClicked,
    customPrimaryActions,
    showTabs,
    isPremium,
    customSecondaryActions,
    hideActivity,
  } = props;
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const isLoading =
    !mounted ||
    authInProgress ||
    currentUserHasListingsInProgress ||
    (isAuthenticated && !currentUser);

  const user = ensureCurrentUser(currentUser);
  const { profile } = user.attributes;
  const role = get(profile, 'protectedData.role', []);
  const userId = get(user, 'id.uuid', null);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const defaultRootClass = isFullWidth ? css.rootFullWidth : css.root;
  const classes = classNames(rootClassName || defaultRootClass, className);

  const search = (
    <TopbarSearchForm
      form="TopbarSearchFormDesktop"
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      className={classNames(css.searchForm, {
        [css.searchFormVisible]: shouldShowSearchForm,
      })}
    />
  );

  const notificationDot = null;

  const isLandowner = role && role.includes('landowner');
  const isSportsman = role && role.includes('sportsman');

  let titleComponent;

  if (!isAuthenticated) {
    titleComponent = <FormattedMessage id="TopbarDesktop.createListingTitle" />;
  } else if (!isLandowner) {
    titleComponent = <FormattedMessage id="TopbarDesktop.hookUpTitle" />;
  } else if (currentUserHasListings) {
    titleComponent = <FormattedMessage id="TopbarDesktop.dashboardTitle" />;
  } else {
    titleComponent = <FormattedMessage id="TopbarDesktop.createListingTitle" />;
  }

  const huntPrivateLink = (
    <NamedLink className={classNames(css.link, css.linkMargin)} name="LTPlusPage">
      <FormattedMessage id="TopbarDesktop.getLandtrustPlus" />
    </NamedLink>
  );

  const fieldNotesLink = (
    <NamedLink className={classNames(css.link, css.linkMargin)} name="FieldNotesPage">
      <FormattedMessage id="TopbarDesktop.fieldNotes" />
    </NamedLink>
  );

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={classNames(css.link, css.linkMargin)}
      name="InboxV2Page"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <FormattedMessage id="TopbarDesktop.inbox" />
      {notificationDot}
    </NamedLink>
  ) : null;

  const activityLink = authenticatedOnClientSide ? (
    <NamedLink className={classNames(css.link, css.linkMargin)} name="RecentActivityPage">
      {activityCount > 0 && <ActivityDot count={activityCount} />}
      <BellIcon className={css.bellIcon} />
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    return currentPage === page ? css.currentPage : null;
  };

  const menuItems = [];

  menuItems.push(
    <MenuItem key="BookingOrTripsPage">
      <NamedLink
        className={classNames(css.dropdownLinkItem, currentPageClass('BookingOrTripsPage'))}
        params={{ slug: isLandowner ? 'bookings' : 'trips' }}
        name="BookingOrTripsPage"
      >
        <FormattedMessage
          id={isLandowner ? 'TopbarDesktop.bookingsLink' : 'TopbarDesktop.tripsLink'}
        />
      </NamedLink>
    </MenuItem>
  );

  if (isLandowner) {
    menuItems.push(
      <MenuItem key="ManageListingsPage">
        <NamedLink
          className={classNames(css.dropdownLinkItem, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarDesktop.yourListingsLink" />
        </NamedLink>
      </MenuItem>
    );
  }

  let buttonName;

  if (!isAuthenticated) {
    buttonName = 'SignupPage';
  } else if (!isLandowner && isAuthenticated) {
    buttonName = 'PerksPage';
  } else if (currentUserHasListings) {
    buttonName = 'DashboardPage';
  } else {
    buttonName = 'LandownersPage';
  }

  menuItems.push(
    <MenuItem key="ProfileSettingsBasePage">
      <NamedLink
        className={classNames(css.dropdownLinkItem, currentPageClass('ProfileSettingsPage'))}
        name="ProfileSettingsBasePage"
      >
        <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      </NamedLink>
    </MenuItem>
  );

  if (userId) {
    const favoriteListings = get(user, 'attributes.profile.publicData.favoriteListings', []);

    const favoritePackages = get(user, 'attributes.profile.publicData.favoritePackages', []);

    const tab =
      favoriteListings.length <= 0 && favoritePackages.length > 0 ? 'packages' : 'listings';

    menuItems.push(
      <MenuItem key="FavoritesPage">
        <NamedLink
          className={classNames(css.dropdownLinkItem, currentPageClass('FavoritesPage'))}
          name="FavoritesPage"
          params={{ id: userId, tab }}
        >
          <FormattedMessage id="TopbarDesktop.favoritesLink" />
        </NamedLink>
      </MenuItem>
    );
  }

  if (isAuthenticated && !isLandowner) {
    menuItems.push(
      <MenuItem key="PerksPage">
        <NamedLink
          className={classNames(css.dropdownLinkItem, currentPageClass('PerksPage'))}
          name="PerksPage"
        >
          <FormattedMessage id="TopbarDesktop.hookUpTitle" />
        </NamedLink>
      </MenuItem>
    );
  }

  menuItems.push(
    <MenuItem key="logout">
      <InlineTextButton rootClassName={css.dropdownLinkItem} onClick={onLogout}>
        <FormattedMessage id="TopbarDesktop.logout" />
      </InlineTextButton>
    </MenuItem>
  );

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel>
        <Avatar user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.dropdownMenu}>{menuItems}</MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="SignupPage"
      className={css.signupLink}
      onClick={() => onUiElementClicked({ event: SIGNUP_CLICK })}
    >
      <FormattedMessage id="TopbarDesktop.joinForFree" />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={classNames(css.link, css.linkMargin)}>
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );

  const listMyLandLink = (
    <NamedLink name="LandownersPage" className={classNames(css.link, css.linkMargin)}>
      <FormattedMessage id="TopbarDesktop.createListingTitle" />
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <Container isFullWidth={isFullWidth}>
        <div className={css.content}>
          {customPrimaryActions || (
            <NamedLink name="LandingPage">
              <Logo
                format="desktop"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              />
            </NamedLink>
          )}

          {search}
          {showTabs &&
            (isLoading ? (
              <TopbarSkeletonLinks />
            ) : (
              customSecondaryActions || (
                <div className={css.links}>
                  {!isAuthenticated && listMyLandLink}
                  {isAuthenticated && isLandowner && !currentUserHasListingsInProgress ? (
                    <PrimaryButton
                      component={NamedLink}
                      name={buttonName}
                      isSmall
                      className={classNames(css.addPropertyButton, css.linkMargin)}
                    >
                      {titleComponent}
                    </PrimaryButton>
                  ) : null}
                  {!isPremium && <div>{huntPrivateLink}</div>}
                  {(isSportsman || !isAuthenticated) && <div>{fieldNotesLink}</div>}
                  {inboxLink}
                  {!hideActivity && activityLink}
                  {profileMenu}
                  {loginLink}
                  {!isAuthenticated && (
                    <PrimaryButton
                      component={NamedLink}
                      name={buttonName}
                      isSmall
                      className={classNames(css.addPropertyButton, css.linkMargin)}
                    >
                      {signupLink}
                    </PrimaryButton>
                  )}
                </div>
              )
            ))
          }
        </div>
      </Container>
    </nav>
  );
};

const { bool, func, string, node } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  isFullWidth: false,
  shouldShowSearchForm: false,
  showTabs: true,
  customPrimaryActions: null,
  customSecondaryActions: null,
  hideActivity: true,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserHasListingsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  authInProgress: bool.isRequired,
  isFullWidth: bool,
  shouldShowSearchForm: bool,
  onLogout: func.isRequired,
  showTabs: bool,
  customPrimaryActions: node,
  customSecondaryActions: node,
  intl: intlShape.isRequired,
  hideActivity: bool,
};

const mapDispatchToProps = dispatch => ({
  onUiElementClicked: payload => dispatch(uiElementClicked(payload)),
});

export default connect(
  null,
  mapDispatchToProps
)(TopbarDesktop);
