/* eslint-disable react/no-array-index-key */
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FormattedHTMLMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { getListingLocation, scrollWithOffset } from '../../util/listing';

import { ReactComponent as IconStarAlt } from '../../assets/icons/rating-star-gold.svg';

import css from './ListingPage.css';

const SectionHeading = props => {
  const {
    richTitle,
    listing,
    reviews = [],
    hideListingLocation,
    reviewsMeta,
    isFetchingReviews,
  } = props;

  const fixedRating = reviewsMeta?.customerAverageRating || 0;

  const totalReviews = reviews.length;
  const isReviewed = reviews.length > 0;
  const listingLocation = getListingLocation(listing);
  const listingInfo = listingLocation && !hideListingLocation;
  const showlistingInfo = isReviewed || listingInfo;

  return (
    <div className={css.headingContainer}>
      <h1 className={css.title}>{richTitle}</h1>

      {showlistingInfo && (
        <>
          {isFetchingReviews ? (
            <div className={css.headerReviewLoader}>
              <Skeleton borderRadius={4} height={24} width="60%" />
            </div>
          ) : (
            <div className={css.headerReviewsContainer}>
              {isReviewed && (
                <div className={css.listingRating}>
                  <IconStarAlt className={css.keyInfoIcon} />
                  <Link
                    to="#listingReviews"
                    scroll={scrollWithOffset}
                    onClick={e => e.preventDefault()}
                  >
                    <FormattedHTMLMessage
                      id="ListingPage.keyInfoReviews"
                      values={{ rating: fixedRating, ratingsLength: totalReviews }}
                    />
                  </Link>
                </div>
              )}
              {isReviewed && <span className={css.infoSeparator}>•</span>}
              {listingLocation && !hideListingLocation && (
                <Link
                  className={css.locationLinkDecor}
                  to="#listingMap"
                  scroll={scrollWithOffset}
                  onClick={e => e.preventDefault()}
                >
                  in {listingLocation}
                </Link>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SectionHeading;
