import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { string } from 'prop-types';
import classNames from 'classnames';
import { QuinaryButton, NamedLink } from '..';
import { ReactComponent as IconChevronDown } from '../../assets/icons/chevron-down.svg';
import css from './InboxListTypeDropdown.css';

const InboxListTypeDropdown = ({ className, onReset, selectedType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = classNames(className);

  useEffect(() => {
    const handleEscKey = event => {
      if (event.key === 'Escape' && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen]);

  const types = [{ id: 'orders', label: 'Booking' }, { id: 'sales', label: 'Hosting' }];

  const selectedTypeObj = types.find(type => type.id === selectedType) || types[0];

  const buttonClasses = classNames(css.filterButton, {
    [css.filterButtonOpen]: isOpen,
  });

  const iconClasses = classNames(css.iconArrow, {
    [css.iconArrowOpen]: isOpen,
  });

  const handleReset = () => {
    onReset();
    setIsOpen(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className={classes}>
        <QuinaryButton
          className={buttonClasses}
          isXSmall
          isFullWidth
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <span>{selectedTypeObj.label}</span>
          <span className={iconClasses}>
            <IconChevronDown className={css.iconArrow} />
          </span>
        </QuinaryButton>

        {isOpen && (
          <div className={css.dropdown}>
            {types.map(type => (
              <NamedLink
                key={type.id}
                className={classNames(css.typeOption, {
                  [css.selectedType]: type.id === selectedType,
                })}
                name="InboxV2Page"
                params={{
                  tab: type.id,
                }}
                onClick={handleReset}
              >
                {type.label}
              </NamedLink>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

InboxListTypeDropdown.defaultProps = {
  className: null,
  selectedType: 'orders',
};

InboxListTypeDropdown.propTypes = {
  className: string,
  selectedType: string,
};

export default InboxListTypeDropdown;
