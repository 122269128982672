/* eslint-disable react/no-array-index-key */
import React from 'react';

import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import classNames from 'classnames';

import { string, node, arrayOf, object, bool } from 'prop-types';
import { withViewport } from '../../util/contextHelpers';
import { formatDate } from '../../util/dates';
import { AvatarSmall } from '../Avatar/Avatar';

import css from './InboxTransitionBase.css';
import { getFirstName } from '../../util/user';
import { MAX_MOBILE_SCREEN_WIDTH } from '../../marketplace-custom-config';
import {
  TRANSACTION_MESSAGE_SOURCE_SHARETRIBE,
  TRANSACTION_MESSAGE_SOURCE_SMS,
  TRANSACTION_MESSAGE_SOURCE_TWILIO,
} from '../../util/transaction';

const InboxTransitionBase = ({
  viewport,
  children,
  header,
  date,
  user,
  intl,
  variation,
  direction,
  buttons,
  buttonsTitle,
  isMessage,
  source,
}) => {
  const isMobile = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const todayString = intl.formatMessage({ id: 'BookingStatus.today' });
  const userName = get(user, 'attributes.profile.displayName', '');
  const firstName = getFirstName(userName);

  const rootClassName = classNames(css.root);

  const containerClassName = classNames(css.container, {
    [css.containerLeft]: direction === 'left' && isMessage,
    [css.containerRight]: direction === 'right' && isMessage,
  });
  const messageClassName = classNames(css.message, {
    [css.messageDark]: variation === 'dark',
    [css.messageLeft]: direction === 'left',
    [css.messageRight]: direction === 'right',
    [css.messageTransition]: !isMessage,
  });
  const dateClassName = classNames(css.date, {
    [css.dateLeft]: direction === 'left',
    [css.dateRight]: direction === 'right',
  });
  const buttonsClassName = classNames(css.buttons, {
    [css.buttonsMobile]: isMobile,
  });

  return (
    <div className={rootClassName}>
      <div className={containerClassName}>
        {user && variation !== 'dark' && (
          <div className={css.user}>
            <AvatarSmall className={css.avatar} user={user} showBadge={false} />
            <span className={css.userName}>{firstName}</span>
          </div>
        )}
        <div className={messageClassName}>
          <>
            {header && <div className={css.messageHeader}>{header}</div>}
            {children}
          </>
        </div>

        {isMessage && (
          <div className={dateClassName}>
            {formatDate(intl, todayString, date)}

            {source &&
              [TRANSACTION_MESSAGE_SOURCE_TWILIO, TRANSACTION_MESSAGE_SOURCE_SMS].includes(
                source
              ) && <span>&nbsp;&bull; Sent via SMS</span>}
          </div>
        )}

        {buttons.length > 0 && (
          <div className={buttonsClassName}>
            {buttonsTitle && <div className={css.buttonsTitle}>{buttonsTitle}</div>}
            {buttons.map((button, index) => (
              <div key={`button-${index}`}>{button}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

InboxTransitionBase.defaultProps = {
  variation: 'light',
  direction: 'left',
  header: null,
  buttons: [],
  user: null,
  isMessage: false,
  source: TRANSACTION_MESSAGE_SOURCE_SHARETRIBE,
};

InboxTransitionBase.propTypes = {
  variation: string,
  direction: string,
  header: node,
  buttons: arrayOf(node),
  user: object,
  isMessage: bool,
  source: string,
};

export default withViewport(injectIntl(InboxTransitionBase));
