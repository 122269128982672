/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import classNames from 'classnames';
import {
  txIsEnquired,
  txIsEnquiryExpired,
  getBookingDates,
  getTxState,
  getUserTxRoleGraphQL,
} from '../../util/transaction';
import { dateFromAPIToLocalNoon, daysAdded, formatRelativeDate } from '../../util/dates';
import { types as sdkTypes } from '../../util/sdkLoader';
import { UserDisplayName, Avatar, NamedLink, ResponsiveImage } from '..';

import css from './InboxListItem.css';
import { formatMoney } from '../../util/currency';
import InboxListItemBadge from './InboxListItemBadge';

const { Money, UUID } = sdkTypes;

const parseTransaction = (transaction, isOrder) => {
  try {
    const isEnquiry = txIsEnquired(transaction);
    const isEnquiryExpired = txIsEnquiryExpired(transaction);
    const {
      protectedData,
      lastTransitionedAt,
      messages,
      booking,
      payinTotal,
      payoutTotal,
      imageUrl: firstImage,
    } = transaction;

    if (!protectedData || Object.keys(protectedData).length === 0) {
      return null;
    }

    let bookingDates;
    let startDateParsed;
    let endDateParsed;
    let isSingleDay;
    const lastMessage = messages && messages.length > 0 ? messages[messages.length - 1] : null;

    let lastTransitionDate = moment(lastTransitionedAt);

    if (lastMessage) {
      const lastMessageDate = moment(lastMessage.attributes.createdAt);

      // Compare the dates and use the more recent one
      lastTransitionDate = lastMessageDate.isAfter(lastTransitionDate)
        ? lastMessageDate
        : lastTransitionDate;
    }

    if (booking) {
      bookingDates = getBookingDates(transaction);
    }

    const { package: packageData } = protectedData || {};

    if (isEnquiry || isEnquiryExpired) {
      const { startDate, endDate, potentialEarnings } = protectedData || {};

      const dayCount = daysAdded(moment(startDate), moment(endDate));
      isSingleDay = dayCount === 1;

      if (startDate && endDate) {
        const bookingStart = moment(dateFromAPIToLocalNoon(startDate));
        const bookingEnd = moment(dateFromAPIToLocalNoon(endDate));
        isSingleDay = daysAdded(bookingStart.utc().format(), bookingEnd.utc().format()) === 1;
        startDateParsed = bookingStart;
        endDateParsed = bookingEnd;
      } else if (bookingDates) {
        startDateParsed = bookingDates.bookingStart;
        endDateParsed = bookingDates.bookingEnd;
        isSingleDay = bookingDates.isSingleDay;
      }

      return {
        title: packageData?.title,
        packagePrice: packageData?.price,
        startDate: startDateParsed,
        endDate: endDateParsed,
        isSingleDay,
        price: new Money(potentialEarnings.amount * 100, potentialEarnings.currency),
        firstImage,
        lastTransitionDate,
      };
    }

    if (packageData) {
      if (bookingDates) {
        startDateParsed = bookingDates.bookingStart;
        endDateParsed = bookingDates.bookingEnd;
        isSingleDay = bookingDates.isSingleDay;
      }

      const bookingPrice = isOrder ? payinTotal : payoutTotal;

      return {
        title: packageData?.title,
        packagePrice: packageData?.price,
        startDate: startDateParsed,
        endDate: endDateParsed,
        isSingleDay,
        price: new Money(bookingPrice.amount, bookingPrice.currency),
        firstImage,
        lastTransitionDate,
      };
    }

    return null;
  } catch (e) {
    return null;
  }
};

const InboxListItem = ({ transaction, params, intl, currentUser, messages = [] }) => {
  const { tab, id } = params;
  const { customer, provider } = transaction;
  const isOrder = tab === 'orders';
  const user = isOrder ? provider : customer;

  const ownRole = getUserTxRoleGraphQL(currentUser.id, transaction);

  const transformedTransaction = {
    ...transaction,
    customer: {
      id: new UUID(transaction.customer.id),
    },
    provider: {
      id: new UUID(transaction.provider.id),
    },
    id: new UUID(transaction.id),
    booking: {
      attributes: {
        ...transaction.booking,
      },
    },
    messages,
    attributes: {
      lastTransition: transaction.lastTransition,
      transitions: transaction.transitions.map(transition => {
        return {
          transition,
        };
      }),
    },
  };

  delete transformedTransaction.lastTransition;

  const parsedTransaction = parseTransaction(
    transformedTransaction,
    isOrder,
    currentUser?.id?.uuid,
    ownRole,
    intl
  );

  const transactionState = getTxState(intl, transformedTransaction, ownRole);

  if (!parsedTransaction) {
    return null;
  }

  const {
    title,
    startDate,
    endDate,
    isSingleDay,
    firstImage,
    lastTransitionDate,
    price,
  } = parsedTransaction;

  const dateInfo = isSingleDay
    ? startDate.format('ddd MMM DD')
    : `${startDate.format('ddd MMM DD')} - ${endDate.format('ddd MMM DD')}`;

  const lastTransitonDateInfo = formatRelativeDate(lastTransitionDate);
  const formattedPrice = price ? formatMoney(intl, price) : 0;
  const isWaitingForReply = false;

  const handleEnterItem = transactionId => {
    try {
      const lastReadTimestamps = JSON.parse(localStorage.getItem('lastReadTimestamps')) || {};
      lastReadTimestamps[transactionId] = new Date().toISOString();
      localStorage.setItem('lastReadTimestamps', JSON.stringify(lastReadTimestamps));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error accessing localStorage:', error);
    }
  };

  const isCancelled =
    transactionState?.state ===
    intl.formatMessage({
      id: 'InboxPage.stateCancelled',
    });

  const transformedUser = {
    id: new UUID(user?.id),
    attributes: {
      banned: user?.profile?.banned || false,
      deleted: user?.profile?.deleted || false,
      profile: {
        displayName: user?.profile?.displayName,
        abbreviatedName: user?.profile?.abbreviatedName,
      },
    },
    profileImage: user?.profile?.profileImage?.id
      ? {
          id: new UUID(user?.profile?.profileImage?.id),
          attributes: {
            variants: {
              default: {
                height: user?.profile?.profileImage?.height,
                width: user?.profile?.profileImage?.width,
                url: user?.profile?.profileImage?.url,
              },
            },
          },
        }
      : null,
  };

  const classes = classNames(css.root, {
    [css.rootSelected]: transformedTransaction.id.uuid === id,
  });

  const headerClass = classNames(css.itemHeader, {
    [css.itemHeaderBold]: isWaitingForReply,
  });

  const titleClass = classNames(css.itemTitle, {
    [css.itemTitleBold]: isWaitingForReply,
  });

  return (
    <NamedLink
      className={classes}
      name="InboxV2Page"
      params={{
        tab,
        id: transformedTransaction.id.uuid,
      }}
      onClick={() => handleEnterItem(transformedTransaction.id.uuid)}
    >
      <div className={css.container}>
        <div className={css.imageWrapper}>
          <ResponsiveImage
            rootClassName={css.image}
            alt={title}
            image={
              firstImage
                ? {
                    attributes: {
                      variants: {
                        'scaled-large': {
                          url: firstImage,
                          name: 'scaled-large',
                          height: 683,
                          width: 1024,
                        },
                      },
                    },
                  }
                : null
            }
            variants={['scaled-large']}
          />
          <div className={css.avatarContainer}>
            <Avatar user={transformedUser} disableProfileLink className={css.avatar} />
          </div>
        </div>

        <div className={css.itemContainer}>
          <div className={headerClass}>
            <UserDisplayName user={transformedUser} intl={intl} />
            <div className={css.itemHeaderDate}>{lastTransitonDateInfo}</div>
          </div>

          <div className={titleClass}>{title}</div>
          <div className={css.itemDate}>{dateInfo}</div>

          <div className={css.itemFooter}>
            <div className={css.itemStateLabel}>
              {transactionState?.state} {!isCancelled && <>&bull; {formattedPrice}</>}
            </div>
            <InboxListItemBadge transaction={transformedTransaction} user={currentUser} />
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

export default injectIntl(InboxListItem);
