/* eslint-disable one-var */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { throttle } from 'lodash';

import { ReactComponent as CommentIcon } from '../../assets/icons/icon-comment-regular.svg';

import { SecondaryButton } from '..';
import InboxFilters from '../InboxFilters/InboxFilters';
import InboxListTypeDropdown from '../InboxListTypeDropdown/InboxListTypeDropdown';

import css from './InboxList.css';
import InboxListItem from '../InboxListItem/InboxListItem';
import { ListLoader } from '../../containers/InboxPageV2/InboxV2Loading';

export const InboxList = ({
  isMobile,
  transactions,
  params,
  intl,
  currentUser,
  isFetching,
  onReset,
  loadMore,
  hasMore,
  isLoadingMore,
  showTabs,
  onFilterChange,
  selectedFilters,
  unreadOnly,
  onUpdateUnreadOnly,
}) => {
  const sentinelRef = useRef();
  const contentRef = useRef();
  const isLoadingMoreRef = useRef(isLoadingMore);
  const hasMoreRef = useRef(hasMore);

  const { tab } = params;
  const [selectedType, setSelectedType] = useState(tab);

  useEffect(() => {
    setSelectedType(params.tab);
  }, [params]);

  const handleFilterChange = values => {
    const newFilters = values.filters || [];
    onFilterChange(newFilters);
  };

  useEffect(() => {
    isLoadingMoreRef.current = isLoadingMore;
  }, [isLoadingMore]);

  useEffect(() => {
    hasMoreRef.current = hasMore;
  }, [hasMore]);

  useEffect(() => {
    const scrollContainer = isMobile ? document : contentRef.current;

    const handleScroll = throttle(() => {
      if (!hasMoreRef.current || isLoadingMoreRef.current) {
        return;
      }

      let scrollTop, scrollHeight, clientHeight;

      if (isMobile) {
        const scrollElement = document.documentElement || document.body;
        scrollTop = window.scrollY || scrollElement.scrollTop;
        scrollHeight = scrollElement.scrollHeight;
        clientHeight = window.innerHeight;
      } else if (scrollContainer) {
        scrollTop = scrollContainer.scrollTop;
        scrollHeight = scrollContainer.scrollHeight;
        clientHeight = scrollContainer.clientHeight;
      }

      if (scrollHeight - scrollTop - clientHeight < 400) {
        if (isMobile && params.id) {
          return;
        }

        loadMore();
      }
    }, 200);

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadMore, isMobile, params.id]);

  const contentClassName = classNames(css.content, {
    [css.contentLoading]: isFetching,
  });

  return (
    <div className={css.root}>
      <div className={css.headerContainer}>
        <div className={css.header}>Messages</div>
        {showTabs && (
          <div className={css.typeDropdownContainer}>
            <InboxListTypeDropdown
              selectedType={selectedType}
              onReset={onReset}
              buttonClassName={css.typeButton}
              buttonSelectedClassName={css.typeButtonSelected}
            />
          </div>
        )}
      </div>

      <div className={css.filters}>
        <InboxFilters
          onFilterChange={handleFilterChange}
          selectedFilters={selectedFilters}
          unreadOnly={unreadOnly}
          onUpdateUnreadOnly={onUpdateUnreadOnly}
        />
      </div>

      <div className={contentClassName} ref={contentRef}>
        {isFetching ? (
          <div className={css.loadingContainer}>
            <ListLoader skipHeader />
          </div>
        ) : (
          <>
            {transactions.map(transaction => (
              <InboxListItem
                key={transaction.id}
                transaction={transaction}
                params={params}
                intl={intl}
                currentUser={currentUser}
              />
            ))}

            {isLoadingMore && (
              <div className={css.loadingMore}>
                <FormattedMessage id="InboxPage.loadingMore" />
              </div>
            )}

            {!isLoadingMore && transactions.length === 0 && (
              <div className={css.noTransactions}>
                <CommentIcon width="42" />
                <span>No messages found</span>
                <SecondaryButton
                  className={css.clearFiltersButton}
                  onClick={() => {
                    handleFilterChange({ filters: [] });
                    onUpdateUnreadOnly(false);
                  }}
                  isSmall
                >
                  Clear all filters
                </SecondaryButton>
              </div>
            )}

            <div ref={sentinelRef} className={css.sentinel} />
          </>
        )}
      </div>
    </div>
  );
};

export default injectIntl(InboxList);
