import { useInfiniteQuery } from '@tanstack/react-query';
import { callGraphQLApi } from '../../util/api';

export const INBOX_PAGE_SIZE = 50;

export const getTransactionsQuery = type => `
    query Get${type}Transactions($input: TransactionsInput!) {
      ${type}Transactions(input: $input) {
        items {
          id
          createdAt
          lastTransition
          lastTransitionedAt
          transitions
          booking {
            id
            start
            end
            displayStart
            displayEnd
            status
          }
          messages
          payinTotal {
            amount
            currency
          }
          payoutTotal {
            amount
            currency
          }
          customer {
            id
            profile {
              abbreviatedName
              displayName
              banned
              deleted
              profileImage {
                id
                height
                width
                url
              }
            }
          }
          provider {
            id
            profile {
              abbreviatedName
              displayName
              banned
              deleted
              profileImage {
                id
                height
                width
                url
              }
            }
          }
          protectedData {
            startDate
            endDate
            message
            potentialEarnings {
              amount
              currency
            }
            package {
              activity
              description
              id
              party
              title
              price {
                amount
                currency
              }
            }
          }
          imageUrl
          status
          providerLastReadAt
          customerLastReadAt
          lastMessage {
            senderId
            snippet
            timestamp
          }
        }
        nextToken
      }
    }
  `;

export const TRANSACTIONS_QUERY_KEY = 'transactions';

// Add this function to update the transaction cache
export const updateTransactionLastReadAt = (queryClient, transactionId, userType, timestamp) => {
  queryClient.setQueriesData([TRANSACTIONS_QUERY_KEY], oldData => {
    if (!oldData) return oldData;

    return {
      pages: oldData.pages.map(page => ({
        ...page,
        items: page.items.map(item => {
          if (item.id === transactionId) {
            return {
              ...item,
              [userType === 'provider' ? 'providerLastReadAt' : 'customerLastReadAt']: timestamp,
            };
          }
          return item;
        }),
      })),
      pageParams: oldData.pageParams,
    };
  });
};

const fetchTransactionsFromApi = async ({
  userId,
  type,
  limit = INBOX_PAGE_SIZE,
  nextToken = null,
  filter = null,
  unreadOnly = false,
}) => {
  const variables = {
    input: {
      userId,
      limit,
      nextToken,
      unreadOnly,
    },
  };

  const updatedFilters = filter.map(item => {
    if (item === 'archived') {
      return `archived_by_${type.toLowerCase()}`;
    }

    return item;
  });

  variables.input.filter = updatedFilters;

  const query = getTransactionsQuery(type);

  const response = await callGraphQLApi(query, variables);
  const result = response.data[`${type}Transactions`];

  return result;
};

export const useTransactions = ({
  userId,
  type,
  limit = INBOX_PAGE_SIZE,
  filter = null,
  unreadOnly = false,
  enabled = true,
}) => {
  return useInfiniteQuery({
    queryKey: [TRANSACTIONS_QUERY_KEY, userId, type, filter, unreadOnly, limit],
    queryFn: ({ pageParam }) =>
      fetchTransactionsFromApi({
        userId,
        type,
        limit,
        nextToken: pageParam,
        filter,
        unreadOnly,
      }),
    getNextPageParam: lastPage => {
      return lastPage.items.length === 0 ? undefined : lastPage.nextToken;
    },
    enabled: !!userId && !!type && enabled,
    staleTime: 1000 * 60 * 5, // Consider data stale after 5 minutes
    cacheTime: 1000 * 60 * 10, // Keep data in cache for 10 minutes
  });
};
