import React from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { ReviewRating, Reviews } from '../../components';

import css from './ListingPage.css';

const SectionReviews = props => {
  const { reviews, customerReviews, reviewsMeta, fetchReviewsError, isFetchingReviews } = props;
  const fixedRating = reviewsMeta?.customerAverageRating || 0;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  if (isFetchingReviews) {
    return (
      <div className={css.sectionReviews} id="listingReviews">
        <Skeleton borderRadius={8} height={168} width="100%" />

        <div className={css.reviewsList}>
          <div className={css.reviewLoadingWrapper}>
            <div className={css.reviewLoadingHeader}>
              <Skeleton circle width={40} height={40} />
              <Skeleton borderRadius={8} height={40} width="100px" />
            </div>

            <div className={css.reviewLoadingContent}>
              <Skeleton borderRadius={8} height={192} width="100%" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return reviews.length > 0 ? (
    <div className={css.sectionReviews} id="listingReviews">
      <div className={css.reviewsHeading}>
        <span className={css.reviewRating}>{fixedRating}</span>
        <span className={css.reviewStars}>
          <ReviewRating rating={fixedRating} reviewStarClassName={css.reviewRatingStar} />
        </span>

        <h3 className={css.reviewsOverall}>
          <FormattedMessage
            id="ListingPage.reviewsHeading"
            values={{ count: customerReviews.length }}
          />
        </h3>
      </div>
      {fetchReviewsError ? reviewsError : null}

      <div className={css.reviewsList}>
        <Reviews reviews={reviews} />
      </div>
    </div>
  ) : null;
};

export default SectionReviews;
