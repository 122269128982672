import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  AgritourismStatutesPage,
  MembershipSignupPage,
  AuthenticationPage,
  IDVerificationPage,
  CheckoutPage,
  EditListingPage,
  EmailVerificationPage,
  FAQLandownersInfoPage,
  InboxPageV2,
  LandingPage,
  LandownerReferralPage,
  ListingPage,
  ManageListingsPage,
  MobileTermsOfServicePage,
  NotFoundPage,
  PasswordRecoveryPage,
  PasswordResetPage,
  PrivacyPolicyPage,
  PricingPage,
  ProfilePage,
  ProfileSettingsPage,
  SearchPage,
  StyleguidePage,
  TermsOfServicePage,
  TransactionPage,
  NewInquiryPage,
  ReviewPage,
  PackagePage,
} from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect, InquiryWizardProvider } from './components';

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

// NOTE: this could get into an infinite reload loop, if not run
// under proxy
const NewUI = () => {
  useEffect(() => {
    window.location.reload();
  }, []);

  return null;
};

const RedirectToLandingPage = () => {
  const { affiliate } = useParams();
  const location = useLocation();
  let referralMethodFromURL = '';

  if (affiliate) {
    referralMethodFromURL = 'affiliate';
  } else {
    referralMethodFromURL = new URLSearchParams(location.search).get('referralMethod') || '';
  }

  return (
    <NamedRedirect
      name="LandingPage"
      search={referralMethodFromURL ? `?referralMethod=${referralMethodFromURL}` : ''}
    />
  );
};

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: props =>
        // To avoid infinite redirect loop in dev we still want to load the old version
        process.env.REACT_APP_IN_PREVIEW === 'true' ? <LandingPage {...props} /> : <NewUI />,
      loadData: LandingPage.loadData,
    },
    {
      path: '/faq/landowners',
      name: 'FAQLandownersPage',
      component: () => <NamedRedirect name="LandownersPage" />,
    },
    {
      path: '/landowners',
      name: 'LandownersPage',
      component: () => <NewUI />,
    },
    {
      path: '/dashboard',
      name: 'DashboardPage',
      component: () => <NewUI />,
    },
    {
      path: '/onland',
      name: 'OnlandPage',
      component: () => <NewUI />,
    },
    {
      path: '/faq/landowners-info',
      name: 'FAQLandownersInfoPage',
      component: props => <FAQLandownersInfoPage {...props} />,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: () => <NewUI />,
    },
    {
      path: '/partners/conservation',
      name: 'ConservationPage',
      component: () => <NewUI />,
    },
    {
      path: '/contact',
      name: 'ContactPage',
      component: () => <NewUI />,
    },
    {
      path: '/partners/conservation',
      name: 'ConservationPartners',
      component: () => <NewUI />,
    },
    {
      path: '/insurance',
      name: 'InsurancePage',
      component: () => <NewUI />,
    },
    {
      path: '/perks',
      name: 'PerksPage',
      component: () => <NewUI />,
    },
    {
      path: '/media',
      name: 'MediaPage',
      component: () => <NewUI />,
    },
    {
      path: '/u/:id/favorites/:tab',
      name: 'FavoritesPage',
      component: () => <NewUI />,
    },
    {
      path: '/states/:slug',
      name: 'StatesPage',
      component: () => <NewUI />,
    },
    {
      path: '/t/:slug',
      name: 'BookingOrTripsPage',
      component: () => <NewUI />,
    },
    {
      path: '/boa',
      name: 'BusinessOfAgriculture',
      component: function BusinessOfAgriculture() {
        useEffect(() => {
          window.location.href = 'https://landowners.landtrust.com/business-of-agriculture';
        }, []);

        return null;
      },
    },
    {
      path: '/ranchinvestor',
      name: 'RanchInvestor',
      component: function RanchInvestor() {
        useEffect(() => {
          window.location.href = 'https://landowners.landtrust.com/ranchinvestor';
        }, []);

        return null;
      },
    },
    {
      path: '/review/:transactionRole/:id',
      name: 'ReviewPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ReviewPage {...props} />,
      loadData: ReviewPage.loadData,
    },
    {
      path: '/v2/review/:transactionRole/:id',
      name: 'ReviewPageV2',
      component: () => <NewUI />,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: props =>
        process.env.REACT_APP_IN_PREVIEW === 'true' ? <SearchPage {...props} /> : <NewUI />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/filters',
      name: 'SearchFiltersPage',
      component: props => <SearchPage {...props} tab="filters" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/listings',
      name: 'SearchListingsPage',
      component: props => <SearchPage {...props} tab="listings" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/map',
      name: 'SearchMapPage',
      component: props => <SearchPage {...props} tab="map" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/package/:packageId',
      name: 'PackagePage',
      component: props => <PackagePage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/inquiry/:step',
      name: 'NewInquiryPage',
      component: props => {
        const { params, history, location } = props;
        return (
          <InquiryWizardProvider defaultValues={{ params, history, location }}>
            <NewInquiryPage {...props} />
          </InquiryWizardProvider>
        );
      },
      loadData: NewInquiryPage.loadData,
    },
    {
      path: '/l/:slug/:id/a/:activity/:variant',
      name: 'ActivityPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        const { params } = props;

        return <NamedRedirect name="ListingPageVariant" params={{ ...params }} />;
      },
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/a/:activity',
      name: 'ActivityPage',
      component: props => {
        const { params } = props;

        return <NamedRedirect name="ListingPage" params={{ ...params }} />;
      },
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/package/:packageId/pricing',
      name: 'PricingPackagePage',
      component: props => <PricingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/package/:packageId/checkout',
      name: 'CheckoutPackagePage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/package/:packageId/:variant',
      name: 'PackagePageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PackagePage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'location' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/p/:packageId',
      name: 'EditListingPagePackage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect name="ProfileSettingsPage" params={{ tab: 'account-details' }} />
      ),
    },
    {
      path: '/profile-settings/payout-details/:returnURLType',
      name: 'ProfileSettingsPayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        const { params } = props;

        const newProps = {
          ...props,
          params: {
            ...params,
            tab: 'payout-details',
          },
        };
        return <ProfileSettingsPage {...newProps} />;
      },
    },
    {
      path: '/profile-settings/id-verification',
      name: 'IDVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <IDVerificationPage {...props} />,
      loadData: IDVerificationPage.loadData,
    },
    {
      path: '/profile-settings/:tab',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: props => <AuthenticationPage {...props} tab="login" />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: props => <AuthenticationPage {...props} tab="signup" />,
    },
    {
      path: '/plus/membership/signup',
      name: 'MembershipSignupPage',
      component: props => <MembershipSignupPage {...props} tab="signup" />,
    },
    {
      path: '/a/:affiliate?',
      name: 'Affiliate',
      component: props => <AuthenticationPage {...props} tab="signup" />,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: props => <AuthenticationPage {...props} tab="confirm" />,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/activity',
      name: 'RecentActivityPage',
      component: () => <NewUI />,
    },
    {
      path: '/huntprivate',
      name: 'HuntPrivatePage',
      component: () => <NewUI />,
    },
    {
      path: '/plus',
      name: 'LTPlusPage',
      component: () => <NewUI />,
    },
    {
      path: '/plus/membership#discount',
      name: 'LTPlusCheckoutPageDiscount',
      component: () => <NewUI />,
    },
    {
      path: '/plus/membership',
      name: 'LTPlusCheckoutPage',
      component: () => <NewUI />,
    },
    {
      path: '/fieldnotes',
      name: 'FieldNotesPage',
      component: () => <NewUI />,
    },

    {
      path: '/inbox',
      name: 'InboxV2BasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxV2Page" params={{ tab: 'orders' }} />,
    },
    {
      path: '/inbox/:tab/:id?',
      name: 'InboxV2Page',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        const { params } = props || {};

        const newParams = {
          ...params,
        };

        if (params?.tab === 'sales') {
          newParams.transactionRole = 'provider';
        } else {
          newParams.transactionRole = 'customer';
        }

        return <InboxPageV2 {...props} params={newParams} />;
      },
      loadData: InboxPageV2.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        const { params } = props;

        return <NamedRedirect name="OrderDetailsPage" params={{ ...params }} />;
      },
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        const { params } = props;

        return (
          <NamedRedirect
            name="InboxV2Page"
            params={{ ...params, tab: 'orders', transactionRole: 'customer' }}
          />
        );
      },
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        const { params } = props;

        return <NamedRedirect name="SaleDetailsPage" params={{ ...params }} />;
      },
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => {
        const { params } = props;

        return (
          <NamedRedirect
            name="InboxV2Page"
            params={{ ...params, tab: 'sales', transactionRole: 'provider' }}
          />
        );
      },
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
      loadData: ManageListingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ProfileSettingsBasePage" />,
    },

    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: props => <TermsOfServicePage {...props} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },
    {
      path: '/landowner-referral',
      name: 'LandownerReferralPage',
      component: props => <LandownerReferralPage {...props} />,
    },
    {
      path: '/agritourism-statutes',
      name: 'AgritourismStatutesPage',
      component: props => <AgritourismStatutesPage {...props} />,
    },
    {
      path: '/mobile-terms-of-service',
      name: 'MobileTermsOfServicePage',
      component: props => <MobileTermsOfServicePage {...props} />,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },
  ];
};

export default routeConfiguration;
