import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { txIsReviewed, txRoleIsCustomer, txIsInFirstReviewBy } from '../../util/transaction';
import { PrimaryButton } from '../Button/Button';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer instead of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

const InboxTransitionReview1 = ({
  transaction,
  transition,
  onGoToReview,
  ownRole,
  user,
  buttons,
}) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = getFirstName(get(user, 'attributes.profile.displayName', ''));
  // show the leave a review link if current user is not the first
  // one to leave a review
  const reviewPeriodIsOver = txIsReviewed(transaction);
  const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
  const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
    <PrimaryButton onClick={onGoToReview} isAnimating>
      <FormattedMessage id="BookingStatus.leaveAReviewSecond" />
    </PrimaryButton>
  ) : null;

  if (reviewAsSecondLink) {
    buttons.push(reviewAsSecondLink);
  }

  return (
    <InboxTransitionBase
      header={
        isOwnTransition ? (
          <FormattedMessage id="BookingStatus.ownTransitionReview" values={{ displayName }} />
        ) : (
          <FormattedMessage
            id="BookingStatus.transitionReview"
            values={{ displayName, reviewLink: '' }}
          />
        )
      }
      date={transition.createdAt}
      direction="left"
      buttons={buttons}
    />
  );
};

export default InboxTransitionReview1;
