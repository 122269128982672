import React from 'react';

import css from './InboxPageDesktop.css';
import {
  InboxList,
  InboxMessages,
  InboxDetails,
  InboxAction,
  InboxDetailsSendMessage,
  UserDisplayName,
  ArchiveButton,
} from '../../components';
import { MessagesLoader } from './InboxV2Loading';
import InboxProviderResponseRate from '../../components/InboxProviderResponseRate/InboxProviderResponseRate';
import { InboxV2EmptyStateContainer } from './InboxV2EmptyState';

const InboxPageDesktop = ({
  state,
  currentUser,
  currentUserInit,
  currentTransaction,
  currentListing,
  transactions,
  isTransactionsFetching,
  hasNextPage,
  loadMore,
  isFetchingNextPage,
  handleFilterChange,
  currentFilter,
  currentUnreadOnly,
  handleUpdateUnreadOnly,
  params,
  user,
  isCustomer,
  isOrder,
  sendMessageInProgress,
  sendMessageError,
  handleSendMessage,
  handleOpenChangeBookingDetails,
  handleSubmitSpecialOfferRequest,
  initiateOrderError,
  timeSlots,
  fetchTimeSlotsError,
  updatingWaitingListInProgress,
  onSendMessage,
  checkUserVerification,
  transactionState,
  allMessages,
  transactionRaw,
  intl,
  history,
  setState,
  messagesContentRef,
  onRefetchTransaction,
  onArchive,
}) => {
  return (
    <div className={css.mainContainer}>
      <div className={css.listContainer}>
        <InboxList
          isMobile={false}
          showTabs={state.showTabs}
          transactions={transactions}
          params={params}
          currentUser={currentUser}
          isFetching={(!transactions.length && isTransactionsFetching) || state.isFetchingList}
          hasMore={hasNextPage}
          loadMore={loadMore}
          isLoadingMore={isFetchingNextPage}
          onFilterChange={handleFilterChange}
          selectedFilters={currentFilter}
          unreadOnly={currentUnreadOnly}
          onUpdateUnreadOnly={handleUpdateUnreadOnly}
          onReset={() => {
            setState(prevState => ({
              ...prevState,
              defaultTransactionId: null,
            }));
          }}
        />
      </div>

      {!state.isFetching && !state.currentTransaction && (
        <div className={css.emptyStateContainer}>
          <InboxV2EmptyStateContainer params={params} />
        </div>
      )}

      {state.isFetching ? (
        <div className={css.messagesOnlyContainerLoader}>
          <MessagesLoader />
        </div>
      ) : (
        <>
          {state.currentTransaction && (
            <div className={css.messagesContainer}>
              <div className={css.messagesHeader}>
                <UserDisplayName user={user} intl={intl} />

                <ArchiveButton
                  currentTransaction={currentTransaction}
                  transaction={transactionRaw}
                  userType={params.transactionRole}
                  onArchive={onArchive}
                />
              </div>

              <div className={css.messagesContent} ref={messagesContentRef}>
                {transactionState && (
                  <InboxMessages
                    transaction={currentTransaction}
                    messages={allMessages}
                    params={params}
                    currentUser={currentUser}
                    isFetching={state.isFetching}
                    transactionState={transactionState}
                    isCustomer={isCustomer}
                  />
                )}
              </div>

              <div className={css.sendMessageContent}>
                {isOrder && <InboxProviderResponseRate provider={user} intl={intl} />}
                <InboxDetailsSendMessage
                  currentUser={currentUser}
                  currentTransaction={currentTransaction}
                  sendMessageInProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onSendMessage={handleSendMessage}
                />
              </div>
            </div>
          )}
        </>
      )}

      {(state.isFetching || transactionState) && (
        <div className={css.actionContainer}>
          <div className={css.detailsContainer}>
            {transactionState && (
              <InboxDetails
                history={history}
                transaction={state.currentTransaction}
                processTransitions={state.processTransitions}
                params={params}
                currentUser={currentUser}
                checkUserVerification={checkUserVerification}
                isFetching={state.isFetching}
                transactionState={transactionState}
                currentListing={currentListing}
                isMobile={false}
                onChangeBookingDetails={handleOpenChangeBookingDetails}
                onRefetchTransaction={onRefetchTransaction}
              />
            )}
          </div>

          {transactionState && (
            <InboxAction
              transactionState={transactionState}
              currentListing={currentListing}
              currentUser={currentUser}
              currentUserInit={currentUserInit}
              currentTransaction={currentTransaction}
              transactionRole={params.transactionRole}
              timeSlots={timeSlots}
              fetchTimeSlotsError={fetchTimeSlotsError}
              updatingWaitingListInProgress={updatingWaitingListInProgress}
              onSubmitSpecialOffer={handleSubmitSpecialOfferRequest}
              sendMessageInProgress={sendMessageInProgress}
              onSendMessage={onSendMessage}
              initiateOrderError={initiateOrderError}
              isMobile={false}
              onChangeBookingDetails={handleOpenChangeBookingDetails}
              onRefetchTransaction={onRefetchTransaction}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default InboxPageDesktop;
