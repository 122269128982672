import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { func, string, arrayOf } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FieldCheckbox, QuinaryButton } from '..';
import { ReactComponent as FilterIcon } from '../../assets/icons/icon-filter.svg';

import css from './FilterDropdown.css';

const FilterDropdown = ({
  className,
  onFilterChange,
  selectedFilters,
  buttonClassName,
  buttonSelectedClassName,
  buttonOpenClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = classNames(className);

  useEffect(() => {
    const handleEscKey = event => {
      if (event.key === 'Escape' && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen]);

  const handleSubmit = values => {
    onFilterChange(values);
  };

  const allFilters = [
    { id: 'open_inquiry', label: 'Open Inquiries' },
    { id: 'booking_request', label: 'Booking Requests' },
    { id: 'upcoming_trip', label: 'Upcoming Trips' },
    { id: 'past_trip', label: 'Past Trips' },
    { id: 'archived', label: 'Archived' },
    { id: 'expired_canceled', label: 'Expired/Canceled' },
    { id: 'declined', label: 'Declined' },
  ];

  return (
    <FinalForm
      initialValues={{ filters: Array.isArray(selectedFilters) ? selectedFilters : [] }}
      onSubmit={handleSubmit}
      subscription={{ submitting: true, pristine: true, values: true }}
      render={({ form, values }) => {
        const filters = values.filters || [];
        const selectedCount = filters.length;
        const isSelected = selectedCount > 0;

        const buttonClasses = classNames(
          buttonClassName,
          {
            [buttonSelectedClassName || css.filterButtonSelected]: isSelected,
          },
          {
            [buttonOpenClassName || css.filterButtonOpen]: isOpen,
          }
        );

        const handleFilterChange = filterId => {
          const newFilters = filters.includes(filterId)
            ? filters.filter(f => f !== filterId)
            : [...filters, filterId];
          form.change('filters', newFilters);
          form.submit();
        };

        return (
          <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            <div className={classes}>
              <QuinaryButton
                className={buttonClasses}
                isXSmall
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <FilterIcon width="18" />
                <span>Filters {isSelected ? `(${selectedCount})` : ``}</span>
              </QuinaryButton>

              {isOpen && (
                <div className={css.dropdown}>
                  {allFilters.map(filter => (
                    <FieldCheckbox
                      key={filter.id}
                      id={`filter-${filter.id}`}
                      name={`filters.${filter.id}`}
                      label={filter.label}
                      value={filter.id}
                      labelClassName={css.filterLabel}
                      checked={filters.includes(filter.id)}
                      onChange={() => {
                        handleFilterChange(filter.id);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </OutsideClickHandler>
        );
      }}
    />
  );
};

FilterDropdown.defaultProps = {
  className: null,
  selectedFilters: [],
  buttonClassName: null,
};

FilterDropdown.propTypes = {
  className: string,
  onFilterChange: func.isRequired,
  selectedFilters: arrayOf(string),
  buttonClassName: string,
};

export default FilterDropdown;
