import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { txIsProviderCancelledLate } from '../../util/transaction';
import { PrimaryButton } from '../Button/Button';
import InboxTransitionBase from './InboxTransitionBase';
import { getFirstName } from '../../util/user';

const InboxTransitionProviderLateCancel = ({
  transaction,
  transition,
  user,
  ownRole,
  onGoToReview,
  buttons,
}) => {
  const isOwnTransition = transition.by === ownRole;
  const displayName = getFirstName(get(user, 'attributes.profile.displayName', ''));

  const reviewPeriodLate = txIsProviderCancelledLate(transaction);
  const reviewLateLink = reviewPeriodLate ? (
    <PrimaryButton onClick={onGoToReview} isAnimating>
      <FormattedMessage id="BookingStatus.leaveAReview" />
    </PrimaryButton>
  ) : null;

  if (reviewLateLink) {
    buttons.push(reviewLateLink);
  }

  return (
    <InboxTransitionBase
      date={transition.createdAt}
      direction="left"
      header={
        isOwnTransition ? (
          <FormattedMessage id="BookingStatus.ownProviderLateCancel" values={{ displayName }} />
        ) : (
          <FormattedMessage
            id="BookingStatus.transitionProviderLateCancel"
            values={{ displayName }}
          />
        )
      }
      buttons={buttons}
    >
      {isOwnTransition ? (
        <FormattedMessage
          id="BookingStatus.ownProviderLateCancelSubtext"
          values={{ displayName }}
        />
      ) : (
        <FormattedMessage
          id="BookingStatus.transitionProviderLateCancelSubtext"
          values={{ reviewLink: '', displayName }}
        />
      )}
    </InboxTransitionBase>
  );
};

export default InboxTransitionProviderLateCancel;
