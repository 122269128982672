import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { get } from 'lodash';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Button,
  LimitedAccessBanner,
  Logo,
  Modal,
  ModalMissingInformation,
  NamedLink,
  TopbarDesktop,
  TopbarMobileMenu,
  TopbarMobile,
} from '..';

import MenuIcon from './MenuIcon';
import { ReactComponent as MapIcon } from '../../assets/icons/map.svg';
import { ReactComponent as ListIcon } from '../../assets/icons/list.svg';
import css from './Topbar.css';

import { useDocumentScroll } from '../../hooks';
import { TopbarSearchForm } from '../../forms';
import ActivityDot from '../ActivityDot/ActivityDot';
import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import { hasPremiumMembership } from '../../util/user';
import { ensureCurrentUser } from '../../util/data';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const TopbarComponent = props => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  const handleMobileMenuOpen = () => {
    setIsMobileMenuOpened(true);
  };

  const handleMobileMenuClose = () => {
    setIsMobileMenuOpened(false);
  };

  const handleSubmit = values => {
    const { currentSearchParams } = props;
    const { search, selectedPlace } = values.location;
    const { history } = props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const handleLogout = () => {
    const { onLogout, history } = props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  };

  const {
    className,
    rootClassName,
    desktopClassName,
    mobileClassName,
    isAuthenticated,
    isFullWidth,
    searchEnabled,
    searchAlwaysVisible,
    mapToggleVisible,
    mapActive,
    onToggleMap,
    searchScrollOffset,
    authScopes,
    authInProgress,
    currentUser,
    currentUserHasListings,
    currentUserHasListingsInProgress,
    currentUserHasOrders,
    currentPage,
    notificationCount,
    activityCount,
    showTabs,
    viewport,
    intl,
    location,
    missingInformationReminderShown,
    onManageDisableScrolling,
    onResendVerificationEmail,
    onMissingInformationReminderShown,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    showMissingInfoModal,
    customMobilePrimaryActions,
    customMobileSecondaryActions,
    customDesktopPrimaryActions,
    customDesktopSecondaryActions,
    specialOfferHidden,
  } = props;

  const isPremium = hasPremiumMembership(currentUser);

  const { address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const user = ensureCurrentUser(currentUser);
  const { profile } = user.attributes;
  const role = get(profile, 'protectedData.role', []);
  const isLandowner = role && role.includes('landowner');

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isMobileMenuOpen = isMobileLayout && isMobileMenuOpened;

  const mobileMenu = (
    <TopbarMobileMenu
      isAuthenticated={isAuthenticated}
      currentUserHasListings={currentUserHasListings}
      currentUser={currentUser}
      onLogout={handleLogout}
      notificationCount={notificationCount}
      activityCount={activityCount}
      isPremium={isPremium}
      currentPage={currentPage}
    />
  );

  // Only render current search if full place object is available in the URL params
  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;
  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
  };

  const [shouldShowSearchForm, setShouldShowSearchForm] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const shouldShowSearch = searchEnabled && (searchAlwaysVisible || shouldShowSearchForm);

  const search = (
    <TopbarSearchForm
      form="TopbarSearchFormDesktop"
      onSubmit={handleSubmit}
      initialValues={initialSearchFormValues}
      className={classNames(css.searchForm, {
        [css.searchFormVisible]: shouldShowSearch,
      })}
    />
  );

  useDocumentScroll(callbackData => {
    const { currentScrollTop } = callbackData;
    const isMinimumScrolled = currentScrollTop > searchScrollOffset;

    setTimeout(() => {
      setShouldShowSearchForm(isMinimumScrolled);
    }, 100);
  });

  return (
    <div className={classes}>
      <LimitedAccessBanner
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        currentUser={currentUser}
        onLogout={handleLogout}
        currentPage={currentPage}
      />
      <div className={classNames(css.container, mobileClassName)}>
        {customMobilePrimaryActions || (
          <>
            {isLandowner ? (
              <TopbarMobile currentUser={currentUser} onLogout={handleLogout} />
            ) : (
              <Button
                rootClassName={css.burgerMenu}
                onClick={handleMobileMenuOpen}
                title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
              >
                <MenuIcon />
              </Button>
            )}

            {specialOfferHidden}
            <div className={css.logo}>
              <NamedLink name="LandingPage" title={intl.formatMessage({ id: 'Topbar.logoIcon' })}>
                <Logo format="desktop" />
              </NamedLink>
            </div>
          </>
        )}

        {search}

        {customMobileSecondaryActions || (
          <>
            {!mapToggleVisible && (
              <NamedLink className={classNames(css.activityLink)} name="RecentActivityPage">
                {activityCount > 0 && <ActivityDot count={activityCount} />}
                <BellIcon className={css.activityBellIcon} />
              </NamedLink>
            )}

            {mapToggleVisible && (
              <Button
                rootClassName={css.mapToggle}
                onClick={onToggleMap}
                title={intl.formatMessage({ id: mapActive ? 'Topbar.mapIcon' : 'Topbar.listIcon' })}
              >
                {mapActive ? <ListIcon /> : <MapIcon />}
              </Button>
            )}
          </>
        )}
      </div>
      <TopbarDesktop
        className={desktopClassName}
        currentUserHasListings={currentUserHasListings}
        currentUserHasListingsInProgress={currentUserHasListingsInProgress}
        currentUser={currentUser}
        currentPage={currentPage}
        initialSearchFormValues={initialSearchFormValues}
        intl={intl}
        isAuthenticated={isAuthenticated}
        authInProgress={authInProgress}
        isFullWidth={isFullWidth}
        shouldShowSearchForm={shouldShowSearch}
        notificationCount={notificationCount}
        activityCount={activityCount}
        onLogout={handleLogout}
        onSearchSubmit={handleSubmit}
        showTabs={showTabs}
        customPrimaryActions={customDesktopPrimaryActions}
        isPremium={isPremium}
        customSecondaryActions={customDesktopSecondaryActions}
      />
      <Modal
        id="TopbarMobileMenu"
        isOpen={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
      >
        {authInProgress ? null : mobileMenu}
      </Modal>
      {showMissingInfoModal && (
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          missingInformationReminderShown={missingInformationReminderShown}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          onMissingInformationReminderShown={onMissingInformationReminderShown}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />
      )}
    </div>
  );
};

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  activityCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  isFullWidth: false,
  searchEnabled: false,
  searchAlwaysVisible: false,
  showMissingInfoModal: true,
  mapToggleVisible: false,
  mapActive: false,
  onToggleMap: null,
  searchScrollOffset: 320,
  customMobilePrimaryActions: null,
  customMobileSecondaryActions: null,
  customDesktopPrimaryActions: null,
  customDesktopSecondaryActions: null,
};

const { array, func, number, shape, string, bool, node } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  isFullWidth: bool,
  searchEnabled: bool,
  searchAlwaysVisible: bool,
  mapToggleVisible: bool,
  mapActive: bool,
  onToggleMap: func,
  searchScrollOffset: number,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasListingsInProgress: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  activityCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showMissingInfoModal: bool,
  customMobilePrimaryActions: node,
  customMobileSecondaryActions: node,
  customDesktopPrimaryActions: node,
  customDesktopSecondaryActions: node,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(
  withViewport,
  injectIntl
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
