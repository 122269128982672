import React from 'react';
import getBytescaleImageURL from '../../util/bytescale';

export default function BytescaleImage({ imagePath, width, height, className, onClick }) {
  if (!imagePath) {
    return null;
  }

  const url = getBytescaleImageURL(imagePath, width, height);
  const basename = imagePath.split('/').reverse()[0];
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
  return <img src={url} alt={basename} className={className} onClick={onClick} />;
}
