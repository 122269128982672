import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserDisplayName } from '..';
import css from './InboxProviderResponseRate.css';

const InboxProviderResponseRate = ({ provider, intl }) => {
  const responseMetrics = provider?.attributes?.profile?.metadata?.metrics?.responseTime;
  const hasResponseData = responseMetrics && responseMetrics.last30DaysAverageHours;

  if (!hasResponseData) {
    return null;
  }

  return (
    <div className={css.root}>
      <UserDisplayName user={provider} intl={intl} useOnlyFirstName />
      &nbsp;
      <FormattedMessage
        id="InboxProviderResponseRate.responseTime"
        values={{
          responseTime:
            responseMetrics.last30DaysAverageHours < 1
              ? `${Math.round(responseMetrics.last30DaysAverageHours * 60)} minutes`
              : `${Math.round(responseMetrics.last30DaysAverageHours)} hours`,
        }}
      />
    </div>
  );
};

export default InboxProviderResponseRate;
