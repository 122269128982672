import React from 'react';
import InboxTransitionBase from '../../InboxTransition/InboxTransitionBase';

const InboxFeedItem = ({ message, currentUser }) => {
  const isOwnMessage =
    message.sender &&
    message.sender.id &&
    currentUser &&
    currentUser.id &&
    message.sender.id.uuid === currentUser.id.uuid;

  return (
    <InboxTransitionBase
      user={message.sender}
      date={message.attributes.createdAt}
      source={message.source}
      direction={isOwnMessage ? 'right' : 'left'}
      variation={isOwnMessage ? 'dark' : 'light'}
      isMessage
    >
      {message.attributes.content}
    </InboxTransitionBase>
  );
};

export default InboxFeedItem;
