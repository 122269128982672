/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import moment from 'moment';
import format from 'date-fns/format';
import Decimal from 'decimal.js';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createSlug } from '../../util/urlHelpers';
import { ensureListing, ensureUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { composeValidators, required } from '../../util/validators';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import EstimatedBreakdownMaybe from '../../forms/BookingDatesForm/EstimatedBreakdownMaybe';
import routeConfiguration from '../../routeConfiguration';
import { getPackageLodgingPrices } from '../../util/package';
import PackageNotFoundPage from '../PackageNotFoundPage/PackageNotFoundPage';
import { getPackage, getLodgingTax, getLodgingPricesOptions } from '../../util/listing';
import {
  CredovaPlugin,
  Form,
  FieldDates,
  FieldCheckbox,
  Logo,
  BookingListingBreakdownSection,
  MobileListingAvatarSection,
  NamedLink,
  NamedRedirect,
  Page,
  PrimaryButton,
  FieldPartySizeSelect,
  PackagesBreadcrumbs,
  FormValidationTooltip,
  PackageBadges,
} from '../../components';
import { daysAdded } from '../../util/dates';

import css from './PricingPage.css';

const { UUID, Money } = sdkTypes;

const PricingPageComponent = ({
  history,
  intl,
  listing,
  isListingLoading,
  fetchTimeSlotsError,
  params: rawParams,
  timeSlots,
  unitType,
  callSetInitialValues,
  currentUser,
  onInitializeCardPaymentData,
  params,
}) => {
  const currentListing = ensureListing(listing);
  const currentAuthor = ensureUser(currentListing.author);
  const [estimatedTotalPrice, setEstimatedTotalPrice] = useState(0);

  const isOwnListing =
    currentUser &&
    currentUser.id &&
    currentAuthor &&
    currentAuthor.id &&
    currentAuthor.id.uuid === currentUser.id.uuid;

  // Redirect back to ListingPage if its the own listing
  // Redirection must happen before any data format error is thrown (e.g. wrong currency)
  if (isOwnListing) {
    return <NamedRedirect name="ListingPage" params={params} />;
  }

  const listingTitle = currentListing.attributes.title;
  const publicData = get(currentListing, 'attributes.publicData');
  const partySize = get(publicData, 'policies.sportsmen', 1);
  const packages = get(publicData, 'packages', []);
  const packageInfo = packages.find(item => item.id === params.packageId);
  const hasPackage = !!packageInfo;

  if (!packageInfo) {
    return <PackageNotFoundPage />;
  }

  const { hasLodging } = packageInfo || {};

  // guest/party size info
  const packageMinParty = get(packageInfo, 'guests.min', 1);
  const packageMaxParty = get(packageInfo, 'guests.max', 1);

  const diyPartySizeMax = [];
  for (let i = 1; i <= partySize; i++) {
    diyPartySizeMax.push(i);
  }
  const { price: listingPrice } = currentListing.attributes;

  const title = intl.formatMessage({ id: 'PricingPage.title' }, { listingTitle });

  const pageProps = { title, scrollingDisabled: false };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
          format="desktop"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  if (isListingLoading) {
    return <Page {...pageProps}>{topbar}</Page>;
  }

  const currentTransaction = get(history, 'location.state.currentTransaction', null);

  const handleFormSubmit = values => {
    const selectedPartySize = values && values.partySize ? values.partySize : packageMinParty;

    const formattedValues = {
      bookingDates: values.dates,
      party: parseInt(selectedPartySize, 10),
      lodgingAddons: values.lodgingAddons,
    };

    const { bookingDates, ...bookingData } = formattedValues;

    const initialValues = {
      listing,
      bookingData,
      includeLodging: values?.lodgingAddons?.length > 0 || hasLodging,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !currentUser;
    const routes = routeConfiguration();

    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);
    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    const nextRoute = createResourceLocatorString(
      'CheckoutPackagePage',
      routes,
      {
        id: listing.id.uuid,
        packageId: packageInfo.id,
        slug: createSlug(listing.attributes.title),
      },
      {}
    );

    history.push(nextRoute, { currentTransaction });
  };

  const handlePriceChange = price => {
    setEstimatedTotalPrice(price);
  };

  return (
    <Page {...pageProps} noindex nofollow>
      {topbar}

      <div className={css.contentContainer}>
        <div className={css.mobileBreadcrumbs}>
          <PackagesBreadcrumbs params={params} listing={listing} step="pricing" />
        </div>

        <MobileListingAvatarSection
          listing={listing}
          packageInfo={packageInfo}
          currentUser={currentUser}
        />

        <FinalForm
          unitPrice={listingPrice}
          onSubmit={handleFormSubmit}
          render={formRenderProps => {
            const {
              handleSubmit,
              form,
              values,
              unitPrice,
              inProgress,
              invalid,
              submitError,
            } = formRenderProps;

            let bookingDateText = hasPackage ? (
              <FormattedMessage id="CheckoutPage.datesFieldPlaceholderPackage" />
            ) : (
              <FormattedMessage id="CheckoutPage.datesFieldPlaceholder" />
            );
            const dateValues = values && values.dates;

            const isSingleDay =
              daysAdded(
                dateValues && values.dates.startDate,
                dateValues && values.dates.endDate
              ) === 1;

            if (dateValues) {
              bookingDateText = `${format(values.dates.startDate, 'MMM DD')} - ${format(
                values.dates.endDate,
                'MMM DD, YYYY'
              )}`;
            }
            if (dateValues && isSingleDay) {
              bookingDateText = format(values && values.dates && values.dates.startDate, 'MMM DD');
            }

            const { startDate, endDate } = values && values.dates ? values.dates : {};

            const { days } = packageInfo || {};

            const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
            const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
            const additionalItems = get(values, 'additionalItems', []);

            let submitDisabled;

            if (hasPackage) {
              submitDisabled = invalid || inProgress || !startDate || !endDate;
            } else {
              submitDisabled =
                invalid || inProgress || !startDate || !endDate || !additionalItems.length;
            }

            let packageItem = null;

            const lodgingPricesOptions = getLodgingPricesOptions(currentListing, intl);

            const currentPackage = getPackage(currentListing, rawParams.packageId);

            const { optionalLodging, includedLodging } = getPackageLodgingPrices(
              currentPackage,
              lodgingPricesOptions
            );

            let lodgingFees = (values.lodgingAddons || []).map(addon => {
              const lodgingPrice = JSON.parse(optionalLodging[addon].price, sdkTypes.reviver);

              return {
                price: lodgingPrice,
                type: optionalLodging[addon].type,
              };
            });

            if (includedLodging?.length) {
              lodgingFees = lodgingFees.concat(
                includedLodging.map(item => {
                  const lodgingPrice = JSON.parse(item.price, sdkTypes.reviver);

                  return {
                    price: lodgingPrice,
                    type: item.type,
                  };
                })
              );
            }

            const includeLodgings = values?.lodgingAddons?.length > 0 || hasLodging;

            const packagePrice = JSON.parse(packageInfo.price);

            const packageFee = new Money(packagePrice.amount, config.currency);
            const today = moment();
            const minDaysEndDate = moment().add(days?.min - 1 || 0, 'd');
            const partySizeMaybe = values.partySize
              ? parseInt(values.partySize, 10)
              : packageMinParty;

            packageItem = {
              code: `line-item/${packageInfo.title.replace(/\s/g, '-')}`,
              title: packageInfo.title,
              includeFor: ['customer', 'provider'],
              unitPrice: packageFee,
              quantity: new Decimal(1),
              lineTotal: packageFee,
              reversal: false,
              donation: {
                package: true,
              },
              guestSize: partySizeMaybe,
              activity: packageInfo.activity,
              hasLodging: includeLodgings,
              lodgingFees,
            };

            const lodgingTax = getLodgingTax(listing, packageInfo?.id);

            const bookingData = {
              unitType,
              unitPrice,
              startDate: startDate || today,
              endDate: endDate || minDaysEndDate,
              quantity: 1,
              partySize: partySizeMaybe,
              packageItem,
              lodgingTax: includeLodgings && lodgingTax,
            };
            const timeSlotsError = fetchTimeSlotsError ? (
              <p className={css.error}>
                <FormattedMessage id="PricingPage.timeSlotsError" />
              </p>
            ) : null;

            let calendarInfoDays;

            if (days.min === days.max) {
              calendarInfoDays = days.min;
            } else if (days.max === 365) {
              calendarInfoDays = `${days.min}+`;
            } else {
              calendarInfoDays = `${days.min}-${days.max}`;
            }

            const showLodgingAddOn =
              optionalLodging?.length > 0 && (packageInfo.days.min > 1 || !isSingleDay);

            return (
              <>
                <div className={css.detailsWrapper}>
                  <div>
                    <div className={css.desktopBreadcrumbs}>
                      <PackagesBreadcrumbs
                        params={params}
                        history={history}
                        listing={listing}
                        step="pricing"
                      />
                    </div>

                    <div className={css.packageIncludes}>
                      <div className={css.packageIncludesHeading}>
                        <FormattedMessage id="PackageCard.includes" />
                      </div>
                      <PackageBadges packageInfo={packageInfo} />
                    </div>

                    <div className={css.formWrapper}>
                      <Form onSubmit={handleSubmit}>
                        {timeSlotsError}
                        <div className={css.formFieldWrapper}>
                          {hasPackage && (
                            <div>
                              <div className={css.packagePartySize}>
                                <FormattedMessage id="PricingPage.partySizeLabel" />
                              </div>

                              {packageMinParty > 1 && (
                                <div className={css.minPartyDisclaimer}>
                                  <FormattedMessage
                                    id="PricingPage.minimumPartySize"
                                    values={{ min: packageMinParty }}
                                  />
                                </div>
                              )}
                              <div>
                                <FieldPartySizeSelect
                                  className={css.fieldPartySize}
                                  name="partySize"
                                  minPartySize={packageMinParty}
                                  maxPartySize={packageMaxParty}
                                  price={JSON.parse(packageInfo.price)}
                                  parse={value => (value === '' ? null : Number(value))}
                                  format={value => String(value)}
                                  validate={composeValidators(
                                    required(
                                      intl.formatMessage({ id: 'PricingPage.partySizeRequired' })
                                    )
                                  )}
                                />
                              </div>
                            </div>
                          )}

                          <div className={css.formFieldWrapper}>
                            <FieldDates
                              fieldDateProps={{
                                endDateOffset:
                                  packageInfo.days.min === packageInfo.days.max
                                    ? day => day.add(packageInfo.days.min - 1, 'days')
                                    : undefined,
                                minimumNights:
                                  packageInfo.days.min !== packageInfo.days.max &&
                                  packageInfo.days.min - 1,
                                maximumNights:
                                  packageInfo.days.max > 0 &&
                                  packageInfo.days.min !== packageInfo.days.max
                                    ? packageInfo.days.max - 1
                                    : undefined,
                                calendarInfo: (
                                  <div className={css.dateInfo}>
                                    <div className={css.dateInfoTitle}>
                                      <FormattedMessage
                                        id="PricingPage.datesFieldInfoPackageTitle"
                                        values={{ days: calendarInfoDays }}
                                      />
                                    </div>
                                  </div>
                                ),
                              }}
                              timeSlots={timeSlots}
                              label={<FormattedMessage id="PricingPage.datesFieldHeadingPackage" />}
                              text={bookingDateText}
                              onChange={dates => {
                                form.change('dates', dates);
                              }}
                              isPackage
                            />
                          </div>
                          {startDate && endDate && (
                            <div className={css.availableDates}>
                              <FormattedMessage
                                id="InquiryWizard.selectedDate"
                                values={{ dates: formattedStartDate === formattedEndDate ? 1 : 0 }}
                              />
                            </div>
                          )}
                          {showLodgingAddOn && (
                            <div className={css.formFieldWrapper}>
                              <div className={css.lodgingContainer}>
                                <FormattedMessage id="PricingPage.addOnsTitle" />
                                {optionalLodging.map((item, index) => {
                                  return (
                                    <FieldCheckbox
                                      labelClassName={css.lodging}
                                      id={`lodgingAddons-${index}`}
                                      name="lodgingAddons"
                                      value={index}
                                      label={
                                        <span>
                                          {item.shortLabel} + {item.priceLabel}
                                        </span>
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={css.submitButtonWrapper}>
                          {!startDate || !endDate ? (
                            <FormValidationTooltip
                              placement="top"
                              body={<FormattedMessage id="PricingPage.fieldRequirements" />}
                              title={<FormattedMessage id="PricingPage.fieldRequirementsTitle" />}
                            >
                              <PrimaryButton
                                isFullWidth
                                type="submit"
                                inProgress={inProgress}
                                disabled={submitDisabled}
                              >
                                <FormattedMessage id="PricingPage.continueToPayment" />
                              </PrimaryButton>
                            </FormValidationTooltip>
                          ) : (
                            <PrimaryButton
                              isFullWidth
                              type="submit"
                              inProgress={inProgress}
                              disabled={submitDisabled}
                            >
                              <FormattedMessage id="PricingPage.continueToPayment" />
                            </PrimaryButton>
                          )}
                        </div>

                        {submitError ? <div className={css.error}>{submitError}</div> : null}
                      </Form>
                    </div>
                  </div>
                </div>

                <BookingListingBreakdownSection
                  listing={listing}
                  breakdown={
                    bookingData && (
                      <EstimatedBreakdownMaybe
                        bookingData={bookingData}
                        listing={listing}
                        onPriceChange={handlePriceChange}
                      />
                    )
                  }
                />

                <CredovaPlugin price={estimatedTotalPrice} />
              </>
            );
          }}
        />
      </div>
    </Page>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    showListingInProgress,
    showListingInitial,
    showListingError,
    timeSlots,
    fetchTimeSlotsError,
  } = state.ListingPage;

  const { currentUser } = state.user;

  const { params } = ownProps;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);

    return listings.length === 1 ? listings[0] : null;
  };

  const listingId = new UUID(params.id);
  const listing = getListing(listingId);

  return {
    currentUser,
    listing,
    isListingLoading: showListingInitial || showListingInProgress,
    showListingError,
    timeSlots,
    fetchTimeSlotsError,
  };
};

const mapDispatchToProps = dispatch => ({
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

PricingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
};

PricingPageComponent.propTypes = {
  unitType: propTypes.bookingUnitType,
};

const PricingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PricingPageComponent);

export default PricingPage;
