/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';

import { FormattedMessage } from 'react-intl';
import css from './ListingPage.css';
import Modal from '../../components/Modal/Modal';

const SectionDescriptionMaybe = ({ description, descriptionTitle }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef(null);

  const formattedDescription =
    description &&
    (/<\/?[a-z][\s\S]*>/i.test(description)
      ? description
      : description.trim().replace(/\n/g, '<br />'));

  useEffect(() => {
    if (contentRef.current) {
      setIsTruncated(contentRef.current.scrollHeight > contentRef.current.clientHeight);
    }
  }, [description]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  return description ? (
    <>
      <div>
        <div className={css.sectionDescription}>
          {descriptionTitle && <div className={css.descriptionHeader}>{descriptionTitle}</div>}
          <div className={css.descriptionContent} ref={contentRef}>
            <div
              dangerouslySetInnerHTML={{
                __html: formattedDescription,
              }}
            />
            {isTruncated && (
              <div className={css.showMoreContainer}>
                <button onClick={handleOpenModal} className={css.showMoreButton} type="button">
                  <FormattedMessage id="ListingPage.showMore" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        id="ListingPage.huntModalss"
        isOpen={isModalOpen}
        onClose={() => setModalOpen(!isModalOpen)}
        contentClassName={css.modalContent}
        containerClassName={css.containerStyles}
      >
        <div className={css.contentWrapper}>
          <div className={css.contentHeader}>
            <FormattedMessage id="PackagePage.descriptionTitle" />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
      </Modal>
    </>
  ) : null;
};

export default SectionDescriptionMaybe;
