import React, { useLayoutEffect } from 'react';
import { array, bool, func, number, object, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FullStoryAPI } from 'react-fullstory';
import { propTypes } from '../../util/types';
import { sendVerificationEmail, hasCurrentUserErrors } from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { manageDisableScrolling, setMissingInformationReminderShown } from '../../ducks/UI.duck';
import { Topbar } from '../../components';

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasListingsInProgress,
    currentUserHasOrders,
    history,
    isAuthenticated,
    isFullWidth,
    searchEnabled,
    searchAlwaysVisible,
    authScopes,
    hasGenericError,
    showMissingInfoModal,
    showTabs,
    location,
    notificationCount,
    activityCount,
    missingInformationReminderShown,
    onLogout,
    onManageDisableScrolling,
    onMissingInformationReminderShown,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    customMobilePrimaryActions,
    customMobileSecondaryActions,
    customDesktopPrimaryActions,
    customDesktopSecondaryActions,
    ...rest
  } = props;

  useLayoutEffect(() => {
    if (currentUser) {
      try {
        const { id, attributes } = currentUser;
        const { profile, email } = attributes;

        FullStoryAPI('identify', id.uuid, {
          displayName: profile.displayName,
          email,
        });
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }, [currentUser]);

  return (
    <Topbar
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentSearchParams={currentSearchParams}
      currentUser={currentUser}
      currentUserHasListings={currentUserHasListings}
      currentUserHasListingsInProgress={currentUserHasListingsInProgress}
      currentUserHasOrders={currentUserHasOrders}
      history={history}
      isAuthenticated={isAuthenticated}
      isFullWidth={isFullWidth}
      searchEnabled={searchEnabled}
      searchAlwaysVisible={searchAlwaysVisible}
      authScopes={authScopes}
      location={location}
      showTabs={showTabs}
      showMissingInfoModal={showMissingInfoModal}
      notificationCount={notificationCount}
      activityCount={activityCount}
      missingInformationReminderShown={missingInformationReminderShown}
      onLogout={onLogout}
      onManageDisableScrolling={onManageDisableScrolling}
      onResendVerificationEmail={onResendVerificationEmail}
      onMissingInformationReminderShown={onMissingInformationReminderShown}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
      customMobilePrimaryActions={customMobilePrimaryActions}
      customMobileSecondaryActions={customMobileSecondaryActions}
      customDesktopPrimaryActions={customDesktopPrimaryActions}
      customDesktopSecondaryActions={customDesktopSecondaryActions}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  activityCount: 0,
  sendVerificationEmailError: null,
  showMissingInfoModal: true,
  authScopes: null,
  isFullWidth: false,
  searchEnabled: false,
  searchAlwaysVisible: false,
  customMobilePrimaryActions: null,
  customMobileSecondaryActions: null,
  customDesktopPrimaryActions: null,
  customDesktopSecondaryActions: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasListingsInProgress: bool.isRequired,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  isFullWidth: bool,
  searchEnabled: bool,
  searchAlwaysVisible: bool,
  showMissingInfoModal: bool,
  authScopes: array,
  notificationCount: number,
  activityCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,
  customMobilePrimaryActions: node,
  customMobileSecondaryActions: node,
  customDesktopPrimaryActions: node,
  customDesktopSecondaryActions: node,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes } = state.Auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasListingsInProgress,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    currentUserActivityCount: activityCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;

  const { missingInformationReminderShown } = state.UI;

  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasListingsInProgress,
    currentUserHasOrders,
    notificationCount,
    activityCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    missingInformationReminderShown,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onMissingInformationReminderShown: state => dispatch(setMissingInformationReminderShown(state)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TopbarContainerComponent);

export default TopbarContainer;
