import { useInfiniteQuery } from '@tanstack/react-query';
import { callGraphQLApi } from '../../util/api';
import { getTransactionMessages, removeTransactionMessage } from '../../util/transaction';

export const MESSAGES_QUERY_KEY = 'messages';

const fetchMessagesFromApi = async ({ transactionId, limit = 100, nextToken }) => {
  const query = `
    query GetMessages($input: MessagesInput!) {
      Messages(input: $input) {
        items {
          content
          createdAt
          customerId
          messageSource
          messageId
          providerId
          senderId
          transactionId
          sharetribeMessageId
        }
        nextToken
      }
    }
  `;

  const variables = {
    input: {
      transactionId,
      limit,
      nextToken,
    },
  };

  const response = await callGraphQLApi(query, variables);
  const apiMessages = response?.data?.Messages?.items || [];
  const nextTokenFromResponse = response?.data?.Messages?.nextToken;

  // Get locally stored messages
  const localMessages = getTransactionMessages(transactionId) || [];

  // Merge API messages with local messages, removing duplicates based on createdAt timestamp
  const mergedMessages = [...apiMessages];

  // Add local messages that don't exist in API response and clean up duplicates
  localMessages.forEach(localMsg => {
    const exists = apiMessages.some(apiMsg => apiMsg.sharetribeMessageId === localMsg.messageId);
    if (!exists) {
      mergedMessages.push(localMsg);
    } else {
      // If message exists in API, remove it from local storage
      removeTransactionMessage(transactionId, localMsg.messageId);
    }
  });

  // Sort messages by createdAt timestamp
  mergedMessages.sort((a, b) => {
    const timeA = parseInt(a.createdAt, 10);
    const timeB = parseInt(b.createdAt, 10);
    return timeA - timeB;
  });

  return {
    messages: mergedMessages,
    nextToken: nextTokenFromResponse,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const useMessages = ({ transactionId, limit = 100, enabled = true }) => {
  return useInfiniteQuery({
    queryKey: [MESSAGES_QUERY_KEY, transactionId],
    queryFn: ({ pageParam }) =>
      fetchMessagesFromApi({
        transactionId,
        limit,
        nextToken: pageParam,
      }),
    getNextPageParam: lastPage => lastPage.nextToken,
    enabled: !!transactionId && enabled,
    staleTime: 1000 * 60 * 5, // Consider data stale after 5 minute
    cacheTime: 1000 * 60 * 10, // Keep data in cache for 10 minutes
  });
};
