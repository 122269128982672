import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';

import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  fetchNextTransitions,
  fetchTransaction,
  specialOfferOrder,
  sendMessage,
  requestChanges,
  acceptChanges,
  declineChanges,
  fetchReview,
  updateTransactionMetadata,
  markMessagesRead,
} from '../../ducks/Transaction.duck';
import routeConfiguration from '../../routeConfiguration';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { isVerified } from '../../util/user';
import { createResourceLocatorString } from '../../util/routes';
import {
  Page,
  BookingChanges,
  PageSlider,
  IDVerification,
  NamedRedirect,
  Modal,
} from '../../components';
import { TopbarContainer } from '..';

import { initialFetch } from './InboxPageV2.duck';
import css from './InboxPageV2.css';
import { ensureListing, ensureUser } from '../../util/data';
import {
  getArchivedTransactions,
  getTxState,
  getUserTxRole,
  theLastTransition,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REQUEST_EXPIRES_SOON,
  txIsEnquired,
  txIsEnquiryExpired,
} from '../../util/transaction';
import { createListingLink } from '../../components/TransactionListingHeading/TransactionListingHeading';
import { getPackage } from '../../util/listing';
import InboxV2Loading from './InboxV2Loading';
import { MAX_MOBILE_SCREEN_WIDTH } from '../../marketplace-custom-config';
import InboxV2EmptyState from './InboxV2EmptyState';
import BookingSuccessModal from '../../components/SuccessModals/BookingSuccessModal';
import InquirySuccessModal from '../../components/SuccessModals/InquirySuccessModal';
import { useMessages } from '../../hooks/queries/useMessages';
import { useTransactions, INBOX_PAGE_SIZE } from '../../hooks/queries/useTransactions';
import InboxPageMobile from './InboxPageMobile';
import InboxPageDesktop from './InboxPageDesktop';

const { UUID } = sdkTypes;

export const InboxV2PageComponent = ({
  viewport,
  history,
  intl,
  params,
  currentUser,
  currentUserInit,
  fetchUser,
  fetchInProgress,
  onFetchTransaction,
  nextTransitions,
  onFetchNextTransitions,
  timeSlots,
  fetchTimeSlotsError,
  updatingWaitingListInProgress,
  onSpecialOffer,
  sendMessageInProgress,
  onSendMessage,
  initiateOrderError,
  onRequestChanges,
  requestedChangesInProgress,
  acceptChangesInProgress,
  declineChangesInProgress,
  onAcceptChanges,
  onDeclineChanges,
  sendMessageError,
  fetchTransactionError,
  onInitialFetch,
  onMarkMessagesRead,
  onFetchReview,
}) => {
  const [currentFilter, setCurrentFilter] = useState([
    'open_inquiry',
    'booking_request',
    'upcoming_trip',
  ]);
  const [currentUnreadOnly, setCurrentUnreadOnly] = useState(false);

  const isMobile = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const {
    data: transactionsData,
    fetchNextPage,
    hasNextPage,
    isFetching: isTransactionsFetching,
    isFetchingNextPage,
  } = useTransactions({
    userId: currentUser?.id?.uuid,
    type: params.tab === 'sales' ? 'Provider' : 'Customer',
    limit: INBOX_PAGE_SIZE,
    filter: currentFilter,
    unreadOnly: currentUnreadOnly,
    enabled: !!currentUser?.id?.uuid && !!params.tab,
  });

  const transactions = useMemo(() => {
    if (!transactionsData?.pages) return [];
    return transactionsData.pages.flatMap(page => page.items);
  }, [transactionsData?.pages]);

  const [showBookingChanges, setShowBookingChanges] = useState(false);
  const [state, setState] = useState({
    isIdVerified: false,
    showMissingInformationReminder: false,
    isSubmitting: false,
    isDefaultSet: false,
    defaultTransactionId: null,
    isFetching: false,
    isFetchingList: false,
    initialFetch: false,
    showTabs: true,
    isEmpty: false,
    isFilteredEmpty: false,
    currentTransaction: null,
    processTransitions: null,
    currentMessages: [],
  });

  const location = useLocation();
  const [isInquiryModalOpen, setIsInquiryModalOpen] = useState(false);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);

  const messagesContentRef = useRef(null);

  useEffect(() => {
    // Check if the state to show modal is passed
    if (location.state?.showInquiryModal) {
      setIsInquiryModalOpen(true);
      history.replace({ ...location, state: { ...location.state, showInquiryModal: false } });
    }
    if (location.state?.showBookingModal) {
      setIsBookingModalOpen(true);
      history.replace({ ...location, state: { ...location.state, showBookingModal: false } });
    }
  }, [location, history]);

  let title = intl.formatMessage({ id: 'InboxPage.ordersTitle' });

  useEffect(() => {
    const initialFetchData = async userId => {
      const { hasSales, hasOrders } = await onInitialFetch(userId);

      if (hasSales && hasOrders) {
        setState(prevState => ({
          ...prevState,
          showTabs: true,
          initialFetch: true,
        }));
      } else if (!hasSales && !hasOrders) {
        setState(prevState => ({
          ...prevState,
          showTabs: false,
          isEmpty: true,
          initialFetch: true,
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          showTabs: false,
          initialFetch: true,
        }));

        if (hasSales) {
          history.push(
            createResourceLocatorString('InboxV2Page', routeConfiguration(), {
              ...params,
              tab: 'sales',
            })
          );
        } else if (hasOrders) {
          history.push(
            createResourceLocatorString('InboxV2Page', routeConfiguration(), {
              ...params,
              tab: 'orders',
            })
          );
        }
      }
    };

    if (currentUser?.id?.uuid) {
      initialFetchData(currentUser?.id?.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id?.uuid]);

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, isFetchingList: true }));

      if (transactions.length > 0) {
        const defaultTransactionId = transactions[0].id;

        setState(prevState => ({
          ...prevState,
          isFilteredEmpty: false,
          defaultTransactionId,
        }));
      } else {
        setState(prevState => ({ ...prevState, isFilteredEmpty: true }));
      }

      setState(prevState => ({ ...prevState, isFetchingList: false }));
    };

    if (currentUser?.id?.uuid && state.initialFetch) {
      fetchData();
    }
  }, [params.tab, currentUser?.id?.uuid, state.initialFetch, transactions]);

  useEffect(() => {
    if (
      !isMobile &&
      !params.id &&
      transactions.length &&
      !fetchInProgress &&
      state.defaultTransactionId
    ) {
      history.push(
        createResourceLocatorString('InboxV2Page', routeConfiguration(), {
          ...params,
          id: state.defaultTransactionId,
        })
      );
    }
  }, [
    isMobile,
    state.defaultTransactionId,
    params.id,
    transactions,
    fetchInProgress,
    history,
    params,
  ]);

  const transactionRaw = useMemo(() => {
    return transactions.find(tx => tx.id === params.id);
  }, [params.id, transactions]);

  useEffect(() => {
    if (transactionRaw) {
      const { createdAt, providerLastReadAt, customerLastReadAt, lastMessage } = transactionRaw;

      const lastMessageCreatedAt = lastMessage?.timestamp;

      const userType = params.transactionRole;
      const lastReadAt = userType === 'provider' ? providerLastReadAt : customerLastReadAt;
      const shouldUpdate =
        lastMessage &&
        lastMessageCreatedAt &&
        (parseInt(lastMessageCreatedAt, 10) > parseInt(lastReadAt, 10) || !lastReadAt);

      if (shouldUpdate) {
        onMarkMessagesRead(transactionRaw.id, createdAt, userType, lastMessageCreatedAt);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionRaw, params.transactionRole]);

  const fetchTransactionData = async id => {
    const txId = new UUID(id);

    const currentTransaction = await onFetchTransaction(txId, params.transactionRole, params);
    const processTransitions = await onFetchNextTransitions(txId);
    await onFetchReview(txId, currentUser.id);

    setState(prevState => ({
      ...prevState,
      currentTransaction,
      processTransitions,
    }));
  };

  useEffect(() => {
    const fetchData = async id => {
      try {
        setState(prevState => ({ ...prevState, isFetching: true }));

        await fetchTransactionData(id);
      } finally {
        setState(prevState => ({ ...prevState, isFetching: false }));
      }
    };

    if (params?.id && currentUser?.id) {
      fetchData(params?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, currentUser?.id?.uuid]);

  const handleFilterChange = filter => {
    setCurrentFilter(filter);
    try {
      localStorage.setItem('inboxFilter', JSON.stringify(filter));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving filter to localStorage:', error);
    }
  };

  const handleUpdateUnreadOnly = unreadOnly => {
    setCurrentUnreadOnly(unreadOnly);
    try {
      localStorage.setItem('inboxUnreadOnly', JSON.stringify(unreadOnly));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving unreadOnly to localStorage:', error);
    }
  };

  const loadMore = () => {
    if (!hasNextPage) return;
    fetchNextPage();
  };

  const checkUserVerification = useCallback(async () => {
    await fetchUser();

    const verifiedUser = isVerified(currentUser);

    if (!verifiedUser) {
      setState(prevState => ({
        ...prevState,
        isIdVerified: false,
        showMissingInformationReminder: true,
        isSubmitting: true,
      }));

      return false;
    }

    setState(prevState => ({
      ...prevState,
      isIdVerified: true,
      showMissingInformationReminder: false,
    }));

    return true;
  }, [fetchUser, currentUser]);

  const { currentTransaction } = state;

  const transactionState = useMemo(
    () =>
      currentTransaction
        ? getTxState(
            intl,
            currentTransaction,
            getUserTxRole(currentUser?.id, currentTransaction),
            ensureUser(currentTransaction.customer),
            ensureUser(currentTransaction.provider),
            nextTransitions
          )
        : null,
    [currentTransaction, intl, currentUser?.id, nextTransitions]
  );

  const { data: messagesData } = useMessages({
    transactionId: params?.id,
    limit: 100,
    enabled: !!params?.id && !!currentUser?.id,
  });

  const allMessages = useMemo(() => {
    if (!messagesData?.pages) return [];
    return messagesData.pages.flatMap(page => page.messages);
  }, [messagesData?.pages]);

  useEffect(() => {
    if (messagesContentRef.current && !state.isFetching) {
      setTimeout(() => {
        if (messagesContentRef.current) {
          messagesContentRef.current.scrollTop = messagesContentRef?.current?.scrollHeight || 0;
        }
      }, 0);
    }
  }, [allMessages, state.isFetching]);

  if (!currentTransaction && !state.initialFetch) {
    return <InboxV2Loading title={title} />;
  }

  if (state.isEmpty) {
    return <InboxV2EmptyState title={title} params={params} />;
  }

  const currentListing = ensureListing(currentTransaction?.listing);

  // FIXME: this is a temporary solution to apply extra space for different transitions
  const lastTransition = currentTransaction ? theLastTransition(currentTransaction) : null;
  const isConfirmPayment = [TRANSITION_CONFIRM_PAYMENT, TRANSITION_REQUEST_EXPIRES_SOON].includes(
    lastTransition?.transition
  );
  const isEnquiry = txIsEnquired(currentTransaction) || txIsEnquiryExpired(currentTransaction);

  const handleSubmitSpecialOfferRequest = async values => {
    const { specialOfferCalendar, ...bookingData } = values;

    const initialValues = {
      listing: currentListing,
      transaction: currentTransaction,
      bookingData,
      bookingDates: {
        bookingStart: specialOfferCalendar.startDate,
        bookingEnd: specialOfferCalendar.endDate,
      },
      confirmPaymentError: null,
    };

    await onSpecialOffer(initialValues, currentTransaction.id, currentTransaction);
    await fetchTransactionData(currentTransaction.id.uuid);
  };

  const handleOpenChangeBookingDetails = () => {
    setShowBookingChanges(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseChangeBookingDetails = () => {
    setShowBookingChanges(false);
    const scrollY = document.body.style.top;
    document.body.style.overflow = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  };

  const handleRequestChanges = async (currentParams, id) => {
    await onRequestChanges(currentParams, id);
    await fetchTransactionData(currentTransaction.id.uuid);

    window.scrollTop = 0;
  };

  const handleSendMessage = async (txId, message) => {
    try {
      const newMessage = await onSendMessage({
        txId,
        message,
        customerId: currentTransaction.customer.id.uuid,
        providerId: currentTransaction.provider.id.uuid,
        senderType: params.transactionRole,
      });

      const userType = params.transactionRole;
      const txCreatedAt = currentTransaction.attributes.createdAt.toISOString();

      await onMarkMessagesRead(
        txId.uuid,
        txCreatedAt,
        userType,
        parseInt(newMessage.createdAt, 10)
      );

      setTimeout(() => {
        if (messagesContentRef.current) {
          messagesContentRef.current.scrollTop = messagesContentRef.current.scrollHeight;
        }
      }, 100);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleOnArchive = async () => {
    const archivedTransactions = getArchivedTransactions(params.transactionRole);

    const updatedTransactions = transactions.filter(tx => {
      const isArchived = archivedTransactions.some(archivedTx => archivedTx.id === tx.id);
      return !isArchived;
    });

    if (updatedTransactions.length === 0) {
      setState(prevState => ({
        ...prevState,
        defaultTransactionId: null,
        currentTransaction: null,
        isFilteredEmpty: true,
      }));

      history.push(
        createResourceLocatorString('InboxV2Page', routeConfiguration(), {
          ...params,
          tab: params.tab,
          id: null,
        })
      );
    }
  };

  const packageId =
    get(currentTransaction, 'attributes.protectedData.selectedPackage.packageId', null) ||
    get(currentTransaction, 'attributes.protectedData.packageLineItem.id', null);
  const currentPackage = getPackage(currentListing, packageId);
  const packageTitle = currentPackage && currentPackage.title;
  title = packageTitle || get(currentListing, 'attributes.title', '');

  const listingLink = createListingLink(
    currentListing,
    title,
    currentPackage,
    {},
    css.listingTitleLink
  );

  const isCustomer = params.transactionRole === 'customer';
  const isProvider = params.transactionRole === 'provider';

  const isOrder = params.tab === 'orders';
  const user = isOrder ? currentTransaction?.provider : currentTransaction?.customer;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable && isProvider && currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable && isCustomer && currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProvider && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxV2Page" params={{ tab: 'sales' }} />;
  }
  if (isDataAvailable && isCustomer && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxV2Page" params={{ tab: 'orders' }} />;
  }

  // We need to filter out the archived transactions which are stored in localStorage
  let updatedTransactions = [];

  const archivedTransactions = getArchivedTransactions(params.transactionRole);

  if (currentFilter.includes('archived')) {
    updatedTransactions = transactions;

    archivedTransactions.forEach(tx => {
      const isArchived = transactions.some(t => t.id === tx.id);
      if (!isArchived) {
        updatedTransactions.push(tx);
      }
    });
  } else {
    updatedTransactions = transactions.filter(tx => {
      const isArchived = archivedTransactions.some(archivedTx => archivedTx.id === tx.id);
      return !isArchived;
    });
  }

  return (
    <Page title={title} scrollingDisabled={false} className={css.pageRoot}>
      <div className={css.root}>
        {isMobile && params.id ? null : (
          <div className={css.topbarContainer}>
            <TopbarContainer
              className={css.topbar}
              mobileRootClassName={css.mobileTopbar}
              desktopClassName={css.desktopTopbar}
              currentPage="InboxV2Page"
            />
          </div>
        )}

        {isMobile ? (
          <InboxPageMobile
            state={state}
            currentUser={currentUser}
            currentUserInit={currentUserInit}
            currentTransaction={currentTransaction}
            currentListing={currentListing}
            transactions={updatedTransactions}
            isTransactionsFetching={isTransactionsFetching}
            hasNextPage={hasNextPage}
            loadMore={loadMore}
            isFetchingNextPage={isFetchingNextPage}
            handleFilterChange={handleFilterChange}
            currentFilter={currentFilter}
            currentUnreadOnly={currentUnreadOnly}
            handleUpdateUnreadOnly={handleUpdateUnreadOnly}
            params={params}
            user={user}
            isCustomer={isCustomer}
            isOrder={isOrder}
            sendMessageInProgress={sendMessageInProgress}
            sendMessageError={sendMessageError}
            handleSendMessage={handleSendMessage}
            handleOpenChangeBookingDetails={handleOpenChangeBookingDetails}
            handleSubmitSpecialOfferRequest={handleSubmitSpecialOfferRequest}
            initiateOrderError={initiateOrderError}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            updatingWaitingListInProgress={updatingWaitingListInProgress}
            onSendMessage={onSendMessage}
            checkUserVerification={checkUserVerification}
            transactionState={transactionState}
            allMessages={allMessages}
            intl={intl}
            history={history}
            setState={setState}
            isConfirmPayment={isConfirmPayment}
            isEnquiry={isEnquiry}
            messagesContentRef={messagesContentRef}
            onRefetchTransaction={fetchTransactionData}
            onArchive={handleOnArchive}
          />
        ) : (
          <InboxPageDesktop
            state={state}
            currentUser={currentUser}
            currentUserInit={currentUserInit}
            currentTransaction={currentTransaction}
            currentListing={currentListing}
            transactions={updatedTransactions}
            isTransactionsFetching={isTransactionsFetching}
            hasNextPage={hasNextPage}
            loadMore={loadMore}
            isFetchingNextPage={isFetchingNextPage}
            handleFilterChange={handleFilterChange}
            currentFilter={currentFilter}
            currentUnreadOnly={currentUnreadOnly}
            handleUpdateUnreadOnly={handleUpdateUnreadOnly}
            params={params}
            user={user}
            isCustomer={isCustomer}
            isOrder={isOrder}
            sendMessageInProgress={sendMessageInProgress}
            sendMessageError={sendMessageError}
            handleSendMessage={handleSendMessage}
            handleOpenChangeBookingDetails={handleOpenChangeBookingDetails}
            handleSubmitSpecialOfferRequest={handleSubmitSpecialOfferRequest}
            initiateOrderError={initiateOrderError}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            updatingWaitingListInProgress={updatingWaitingListInProgress}
            onSendMessage={onSendMessage}
            checkUserVerification={checkUserVerification}
            transactionState={transactionState}
            allMessages={allMessages}
            transactionRaw={transactionRaw}
            intl={intl}
            history={history}
            setState={setState}
            messagesContentRef={messagesContentRef}
            onRefetchTransaction={fetchTransactionData}
            onArchive={handleOnArchive}
          />
        )}
      </div>

      {!state.isIdVerified && state.showMissingInformationReminder && (
        <PageSlider
          active={state.showMissingInformationReminder}
          onClose={() => {
            setState(prevState => ({
              ...prevState,
              showMissingInformationReminder: false,
              isSubmitting: false,
            }));
          }}
          width="50%"
        >
          <IDVerification
            className={css.innerIDModal}
            onClose={() => {
              setState(prevState => ({
                ...prevState,
                showMissingInformationReminder: false,
                isSubmitting: false,
              }));

              fetchUser();
            }}
            showMissingInformationReminder={state.showMissingInformationReminder}
            isSpecialOffer
          />
        </PageSlider>
      )}

      {showBookingChanges && (
        <PageSlider
          active={showBookingChanges}
          onClose={() => {
            handleCloseChangeBookingDetails();
          }}
          width="100%"
        >
          <BookingChanges
            currentListing={currentListing}
            currentUser={currentUser}
            provider={currentTransaction?.provider}
            customer={currentTransaction?.customer}
            listingLink={listingLink}
            packageId={packageId}
            currentTransaction={currentTransaction}
            timeSlots={timeSlots}
            onSubmit={handleRequestChanges}
            inProgress={requestedChangesInProgress}
            onAccept={onAcceptChanges}
            acceptChangesInProgress={acceptChangesInProgress}
            onDecline={onDeclineChanges}
            declineChangesInProgress={declineChangesInProgress}
            stateData={transactionState}
            isProvider={isProvider}
            isCustomer={isCustomer}
            onClose={() => {
              handleCloseChangeBookingDetails();
            }}
          />
        </PageSlider>
      )}

      {isBookingModalOpen && (
        <Modal
          id="BookingSuccessModal"
          isOpen={isBookingModalOpen}
          onClose={() => setIsBookingModalOpen(false)}
        >
          <div>
            <BookingSuccessModal user={currentUser} />
          </div>
        </Modal>
      )}
      {isInquiryModalOpen && (
        <Modal
          id="InquirySuccessModal"
          isOpen={isInquiryModalOpen}
          onClose={() => setIsInquiryModalOpen(false)}
        >
          <div>
            <InquirySuccessModal user={currentUser} />
          </div>
        </Modal>
      )}
    </Page>
  );
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination } = state.InboxPageV2;
  const { currentUser, currentUserInit, updatingWaitingListInProgress } = state.user;
  const {
    processTransitions,
    timeSlots,
    fetchTimeSlotsError,
    sendMessageInProgress,
    sendMessageError,
    initiateOrderError,
    requestedChangesInProgress,
    acceptChangesInProgress,
    declineChangesInProgress,
    fetchTransactionError,
  } = state.Transaction;

  return {
    currentUser,
    currentUserInit,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    nextTransitions: processTransitions,
    timeSlots,
    updatingWaitingListInProgress,
    fetchTimeSlotsError,
    sendMessageInProgress,
    sendMessageError,
    initiateOrderError,
    requestedChangesInProgress,
    acceptChangesInProgress,
    declineChangesInProgress,
    fetchTransactionError,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchCurrentUser()),
  onFetchTransaction: (id, txRole, params) => dispatch(fetchTransaction(id, txRole, params)),
  onFetchNextTransitions: id => dispatch(fetchNextTransitions(id)),
  onFetchReview: (transactionId, userId) => dispatch(fetchReview(transactionId, userId)),
  onSpecialOffer: (params, transactionId, currentTransaction) =>
    dispatch(specialOfferOrder(params, transactionId, currentTransaction)),
  onSendMessage: (txId, message, customerId, providerId, senderType) =>
    dispatch(sendMessage(txId, message, customerId, providerId, senderType)),
  onRequestChanges: (params, transactionId) => dispatch(requestChanges(params, transactionId)),
  onAcceptChanges: transactionId => dispatch(acceptChanges(transactionId)),
  onDeclineChanges: transactionId => dispatch(declineChanges(transactionId)),
  onInitialFetch: userId => dispatch(initialFetch(userId)),
  onUpdateMetadata: (transactionId, metadata) =>
    dispatch(updateTransactionMetadata(transactionId, metadata)),
  onMarkMessagesRead: (transactionId, createdAt, userType, msgCreatedAt) =>
    dispatch(markMessagesRead(transactionId, createdAt, userType, msgCreatedAt)),
});

const InboxV2Page = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(InboxV2PageComponent);

export default withViewport(InboxV2Page);
