/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Modal } from '../../components';

import { InlineTextButton } from '../../components/Button/Button';

import css from './ListingPage.css';
import ReviewsImageCarousel from '../../components/ReviewsImageCarousel/ReviewsImageCarousel';

const ReviewsImages = ({ images, reviewId }) => {
  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const hasImages = images && images.length > 0;
  const maxCollageImages = images && images.length > 3;

  const handleImageClick = image => {
    setImageCarouselOpen(true);
    setSelectedImage(image || null);
  };

  const onImageCarouselClose = () => {
    setImageCarouselOpen(false);
    setSelectedImage(null);
  };

  const collageImages = images.slice(0, 3);

  const viewPhotosButton =
    hasImages && maxCollageImages ? (
      <div className={css.reviewImagesWrapper}>
        <div className={css.reviewPhotosBlurWrapper}>
          <div className={css.reviewPhotosBlur} />
        </div>
        <InlineTextButton className={css.reviewPhotosButton} onClick={() => handleImageClick()}>
          <div className={css.viewPhotosText}>
            <FormattedMessage
              id="ListingPage.viewImagesNumber"
              values={{ count: images.length - 3 }}
            />
          </div>
        </InlineTextButton>
      </div>
    ) : null;

  const reviewPhotoWrapperClass = classNames(css.reviewPhotoWrapper, {
    [css.reviewPhotoWrapperOne]: collageImages.length === 1,
    [css.reviewPhotoWrapperTwo]: collageImages.length === 2,
  });

  return (
    <div className={css.sectionImages} data-testid="images-section">
      <div>
        <div className={css.reviewPhotos}>
          {collageImages.map((image, index) => (
            <div
              key={`${reviewId}-photos-${index}`}
              className={reviewPhotoWrapperClass}
              onClick={() => handleImageClick()}
            >
              <img src={`${image}?w=300`} alt="" className={css.reviewPhoto} />

              {index + 1 === collageImages.length && viewPhotosButton}
            </div>
          ))}
        </div>
      </div>

      {/* TODO: I'm not sure why modals render their contents when closed */}
      {imageCarouselOpen && (
        <Modal
          id="ListingPage.imageCarousel"
          scrollLayerClassName={css.carouselModalScrollLayer}
          containerClassName={css.carouselModalContainer}
          contentClassName={css.modalContentContainer}
          lightCloseButton
          isOpen={imageCarouselOpen}
          onClose={onImageCarouselClose}
        >
          <ReviewsImageCarousel images={images} selectedImage={selectedImage} />
        </Modal>
      )}
    </div>
  );
};

export default ReviewsImages;
